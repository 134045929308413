import React from "react";
import { Container, Navbar } from "react-bootstrap";
// import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import logo from "../assets/images/allcatholics-logo.png";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import TrendingUpTwoToneIcon from "@mui/icons-material/TrendingUpTwoTone";

function Header({ handler = () => {}, count = "" }) {
  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        height: "50px",
      }}>
      <Navbar bg="white" fixed="top" className="py-0 my-0">
        <Container className="py-0">
          <Navbar.Brand
            href=""
            className="align-items-center d-flex"
            onClick={handler}>
            {/* <img
            src={logo}
            alt="AllCatholics.SG"
            width="50px"
            height="50px"
            className="me-1"
          /> */}
            <div className="roundicon p-1 me-1 text-center bg-danger d-flex align-items-center">
              <AppsOutlinedIcon className="text-white" />
            </div>
            <span className="text-dark fs-2 font-fam">CalenGrid</span>
          </Navbar.Brand>

          <Navbar.Collapse className="justify-content-end">
            <div className="d-flex justify-content-end align-items-center">
              <div className="pulsecontainer py-1 align-items-center d-flex">
                <TrendingUpTwoToneIcon className="text-success me-1" /> {count}{" "}
                <span className="pulsedot"></span>
              </div>
            </div>
            {/* <Navbar.Text>
            <div className="text-dark pointer" onClick={() => {}}>
              <ExitToAppRoundedIcon /> Logout
            </div>
          </Navbar.Text> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;
