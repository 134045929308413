export function getContents() {
  return [
    {
      id: 1,
      date: "19 Aug 1919",
      time: 0,
      type: 0,
      title: "Afganistan Independence Day",
      description:
        "Independence from British control over Afganistan foreign affairs. In Photo: resident Hamid Karzai observing the honor guard of the Afghan Armed Forces during the 2011 Afghan Independence  Day in Kabul.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1919%2F08%2F19%2Fthumbs%2F01-2011_Afghan_Independence_Day-2_600x600.jpeg?alt=media&token=c3a1023c-a6dc-427f-9624-b78c3a335b7d",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1919%2F08%2F19%2F01-2011_Afghan_Independence_Day-2.jpeg?alt=media&token=33586045-dad8-4ca2-983d-3ecbafd32df0",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Afghan_Independence_Day",
    },
    {
      id: 2,
      date: "28 Nov 1912",
      time: 0,
      type: 0,
      title: "Albanian Independence Day",
      description:
        "Albanian Flag Day: Raising of the Albanian flag in Vlore to announce the Albanian Declaration of Independence in 1912. In Photo: Photomontage of the original document of the Declaration of Independence ",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1912%2F11%2F28%2Fthumbs%2F02-Albania-Declaration-of-Independence_600x600.jpeg?alt=media&token=e6e9092a-f2cc-4f67-9989-d1dc0b1a49ee",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1912%2F11%2F28%2F02-Albania-Declaration-of-Independence.jpeg?alt=media&token=928541d4-0d2a-4a03-9729-6d4abb4cceb3",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Albanian_Declaration_of_Independence",
    },
    {
      id: 3,
      date: "15 Dec 1945",
      time: 0,
      type: 0,
      title: "Alderney (British Islands) Homecoming Day",
      description:
        "Homecoming Day: the return of the Islanders after the end of the German occupation of the Channel Islands during World War II 1945. In Photo: As part of the Atlantic wall, between 1940 and 1945 the occupying German forces and the Organisation Todt constructed fortifications around the coasts of the Channel Islands such as this observation tower at Battery Moltke. ",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1945%2F12%2F15%2Fthumbs%2F03-Alderney-Homecoming-Day_600x600.jpeg?alt=media&token=775abcba-726c-4e4e-ae2a-ad5f18f12076",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1945%2F12%2F15%2F03-Alderney-Homecoming-Day.jpeg?alt=media&token=efaa1f58-3f37-472a-9253-a31f0df44c94",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/German_occupation_of_the_Channel_Islands",
    },
    {
      id: 4,
      date: "5 July 1962",
      time: 0,
      type: 0,
      title: "Algeria Independence Day",
      description:
        "Independence day observed annually on 5 July, is a National Holiday in Algeria commemorating colonial Algerian independence from France on 5 July 1962. In Photo: Front page Free Algeria in 1950 for the celebration of the 13th anniversary of the Algerian People's Party. ",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1962%2F07%2F05%2Fthumbs%2F04-Algeria-Independence-Day_600x600.jpeg?alt=media&token=f5ccc1ba-e053-4ff0-a60c-1b119497332a",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1962%2F07%2F05%2F04-Algeria-Independence-Day.jpeg?alt=media&token=749d7e13-1ddf-416c-b11e-a706513d1870",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Independence_Day_(Algeria)",
    },
    {
      id: 5,
      date: "17 Apr 1900",
      time: 0,
      type: 0,
      title: "American Samoa (United States) US Flag Day",
      description:
        "US Flag Day, commemorates the date American Samoa US Flag Day, commemorates the date American Samoa became a US territory and first flew the US flag in 1900, as well as the date of adoption of the American Samoan flag in 1960.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1900%2F04%2F17%2Fthumbs%2F05-US-Flag-Day_600x600.jpeg?alt=media&token=c2d0f3ed-5449-4a3c-924f-22ee2c116c3c",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1900%2F04%2F17%2F05-US-Flag-Day.png?alt=media&token=8172b689-ee92-48f9-bfbc-d86ba92a8740",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Flag_of_the_United_States",
    },
    {
      id: 6,
      date: "28 Feb 1980",
      time: 0,
      type: 0,
      title: "Andalusia (Spain) Autonomy Initiative Referendum",
      description:
        "Commemorates the date of the 1980 referendum on the Statute of Autonomy of Andalusia that established the region as an autonomous community",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1980%2F02%2F28%2Fthumbs%2F06-Andalusia-Flag_600x600.jpeg?alt=media&token=ef9dcb03-5482-4211-bbd8-630d42beb089",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1980%2F02%2F28%2F06-Andalusia-Flag.png?alt=media&token=53c5937f-6019-42c2-a2d7-fcf67665cbdb",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/1980_Andalusian_autonomy_initiative_referendum",
    },
    {
      id: 7,
      date: "11 Nov 1975",
      time: 0,
      type: 0,
      title: "Angola Independence Day",
      description:
        "Angola (/ænˈɡoʊlə/ i an-GOH-lə; Portuguese: [ɐ̃ˈɡɔlɐ]; Kongo: Ngola, pronounced [ŋɔla]), officially the Republic of Angola (Portuguese: República de Angola), is a country on the west-central coast of Southern Africa. It is the second-largest Lusophone (Portuguese-speaking) country in both total area and population (behind Brazil in both cases), and is the seventh-largest country in Africa.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1975%2F11%2F11%2Fthumbs%2F07-Angola-flag_600x600.jpeg?alt=media&token=fa4d736d-e65f-4fa3-98cc-f17b1b3282cc",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1975%2F11%2F11%2F07-Angola-flag.png?alt=media&token=b72283b9-4f86-4233-84ee-552575e2332f",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Angola",
    },
    {
      id: 8,
      date: "30 May 1967",
      time: 0,
      type: 0,
      title: "Anguilla Day",
      description:
        "Anguilla Day; the beginning of the Anguillian Revolution in 1967",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1967%2F05%2F30%2Fthumbs%2F08-Anguilla-flag_600x600.jpeg?alt=media&token=45050c11-9303-4a12-b1df-dacbe07c49f7",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1967%2F05%2F30%2F08-Anguilla-flag.png?alt=media&token=f847c607-9c40-4ae8-a57d-636dbc229ef3",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Republic_of_Anguilla",
    },
    {
      id: 9,
      date: "1 Nov 1981",
      time: 0,
      type: 0,
      title: "Antigua and Barbuda Independence Day",
      description:
        "Antigua and Barbuda (UK: /ænˈtiːɡə ... bɑːrˈbuːdə/, US: /ænˈtiːɡwə ... bɑːrˈbjuːdə/) is a sovereign island country in the West Indies. It lies at the conjuncture of the Caribbean Sea and the Atlantic Ocean in the Leeward Islands part of the Lesser Antilles.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1981%2F11%2F01%2Fthumbs%2F09-Flag_of_Antigua_and_Barbuda.svg_600x600.jpeg?alt=media&token=99216223-24cd-4e8b-a364-d644be5e9f0b",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1981%2F11%2F01%2F09-Flag_of_Antigua_and_Barbuda.svg.png?alt=media&token=16ca8fd2-e8ac-41a9-99f2-2d74411e59ab",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Antigua_and_Barbuda",
    },
    {
      id: 11,
      date: "21 Sep 1991",
      time: 0,
      type: 0,
      title: "Armenia Independence Day",
      description: "Independence from the Soviet Union recognised in 1991",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1991%2F09%2F21%2Fthumbs%2F11-Flag_of_Armenia.svg_600x600.jpeg?alt=media&token=e91604ad-81e1-442b-b2a0-11027d02aa1f",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1991%2F09%2F21%2F11-Flag_of_Armenia.svg.png?alt=media&token=1436281d-0bf2-499d-a76f-29f97dd00cd2",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Armenia",
    },
    {
      id: 12,
      date: "18 Mar 1976",
      time: 0,
      type: 0,
      title: "Aruba (Kingdom of the Netherlands) Flag Day",
      description: "Flag Day; adoption of the flag in 1976",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1976%2F03%2F18%2Fthumbs%2F12-Flag_of_the_Netherlands.svg_600x600.jpeg?alt=media&token=87939b5e-62be-451c-af7f-b8c1384e33dd",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1976%2F03%2F18%2F12-Flag_of_the_Netherlands.svg.png?alt=media&token=a9c92180-078a-41f5-b94f-00d67faf9f53",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Kingdom_of_the_Netherlands",
    },
    {
      id: 13,
      date: "26 Oct 1955",
      time: 0,
      type: 0,
      title: "Austria Declaration of Neutrality",
      description:
        "The Declaration of Neutrality (German: Neutralitätserklärung) was a declaration by the Austrian Parliament declaring the country permanently neutral. It was enacted on 26 October 1955 as a constitutional act of parliament, i.e., as part of the Constitution of Austria.[1]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1955%2F10%2F26%2Fthumbs%2F13-Flag_of_Austria.svg_600x600.jpeg?alt=media&token=5d7fce35-4bb9-4474-85f2-700d5f890ce7",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1955%2F10%2F26%2F13-Flag_of_Austria.svg.png?alt=media&token=3edd791c-01c2-4671-be20-f6d69807bbd7",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Declaration_of_Neutrality",
    },
    {
      id: 14,
      date: "28 May 1918",
      time: 0,
      type: 0,
      title: "Azerbaijan Independence Day",
      description:
        "Independence Day, independence from the Transcaucasian Democratic Federative Republic 1918",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1918%2F05%2F28%2Fthumbs%2F14-Flag_of_Azerbaijan.svg_600x600.jpeg?alt=media&token=e8b34f26-f9c4-480a-83d5-9fbfd2662441",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1918%2F05%2F28%2F14-Flag_of_Azerbaijan.svg.png?alt=media&token=4c25c67e-9526-4b8a-958f-29055a4793f2",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Azerbaijan",
    },
    {
      id: 15,
      date: "10 Jul 1973",
      time: 0,
      type: 0,
      title: "Bahamas Independence Day",
      description: "Independence from the United Kingdom in 1973",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1973%2F07%2F10%2Fthumbs%2F15-Flag_of_the_Bahamas.svg_600x600.jpeg?alt=media&token=7a388d05-0131-4a29-b1ea-7a83a88907db",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1973%2F07%2F10%2F15-Flag_of_the_Bahamas.svg.png?alt=media&token=ef806081-f48a-478e-9b26-2450af92f967",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/The_Bahamas",
    },
    {
      id: 16,
      date: "16 Dec 1961",
      time: 0,
      type: 0,
      title: "Bahrain Coronation Day of Isa bin Salman Al Khalifa",
      description:
        "Coronation Day of Isa bin Salman Al Khalifa, the first Emir of Bahrain, in 1961",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1961%2F12%2F16%2Fthumbs%2F16-Flag_of_Bahrain.svg_600x600.jpeg?alt=media&token=fddfa0ee-680e-4eda-899d-9a1f46d39c4e",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1961%2F12%2F16%2F16-Flag_of_Bahrain.svg.png?alt=media&token=109f1473-9efc-4e6a-89fa-5ba70b768d5d",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Bahrain",
    },
    {
      id: 17,
      date: "1 Mar 1983",
      time: 0,
      type: 0,
      title: "Balearic Islands (Spain) Statute of Autonomy",
      description:
        "Commemorates the date that the Statute of Autonomy establishing the Balearic Islands as an autonomous community became effective in 1983",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1983%2F03%2F01%2Fthumbs%2F17-Flag_of_the_Balearic_Islands.svg_600x600.jpeg?alt=media&token=0ef1c0b3-8f74-43ef-b853-100a886d9ebb",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1983%2F03%2F01%2F17-Flag_of_the_Balearic_Islands.svg.png?alt=media&token=7f1176cd-5c30-4fe8-b217-49fe90fd29d1",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Balearic_Islands",
    },
    {
      id: 18,
      date: "16 Dec 1971",
      time: 0,
      type: 0,
      title: "Bangladesh Independence Day",
      description:
        "The Independence Day of Bangladesh (Bengali: স্বাধীনতা দিবস Shadhinôta Dibôsh) is celebrated on 26 March as a national holiday in Bangladesh. It commemorates the country's declaration of independence from Pakistan in the early hours of 25 March 1971.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1971%2F12%2F16%2Fthumbs%2F18-Flag_of_Bangladesh.svg_600x600.jpeg?alt=media&token=de88e42e-85ab-4113-9f40-6af447da1bfb",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1971%2F12%2F16%2F18-Flag_of_Bangladesh.svg.webp?alt=media&token=9f9ccb9a-74b6-41b1-bef4-efec6c5db5b7",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Bangladesh",
    },
    {
      id: 19,
      date: "30 Nov 1966",
      time: 0,
      type: 0,
      title: "Barbados",
      description: "Independence Day, from the United Kingdom in 1966.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1966%2F11%2F30%2Fthumbs%2F19-Flag_of_Barbados.svg_600x600.jpeg?alt=media&token=06af23c9-3eda-4c07-a04a-20b82de16e33",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1966%2F11%2F30%2F19-Flag_of_Barbados.svg.png?alt=media&token=18836d03-a808-4b55-b280-6f27da50b405",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Barbados",
    },
    {
      id: 20,
      date: "25 Oct 1979",
      time: 0,
      type: 0,
      title: "Basque Country Statute of Autonomy",
      description:
        "The Basque Country (/bæsk, bɑːsk/; Basque: Euskadi [eus̺kadi]; Spanish: País Vasco [paˈiz ˈβasko]; French: Pays Basque), also called Basque Autonomous Community (Basque: Euskal Autonomia Erkidegoa, EAE; Spanish: Comunidad Autónoma Vasca, CAV), is an autonomous community in northern Spain. It includes the Basque provinces of Álava, Biscay, and Gipuzkoa.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1979%2F10%2F25%2Fthumbs%2F20-Flag_of_the_Basque_Country.svg_600x600.jpeg?alt=media&token=fe5f892c-1048-4b7d-ac80-66c457d10cd4",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1979%2F10%2F25%2F20-Flag_of_the_Basque_Country.svg.png?alt=media&token=b036df1f-c3bc-4d42-bb9f-313f611e1da8",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Basque_Country_(autonomous_community)",
    },
    {
      id: 21,
      date: "3 Jul 1944",
      time: 0,
      type: 0,
      title: "Belarus Independence Day",
      description:
        "Independence Day, liberation of Minsk from German occupation by Soviet troops in 1944",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1944%2F07%2F03%2Fthumbs%2F21-Flag_of_Belarus.svg_600x600.jpeg?alt=media&token=3803cba0-74c9-4788-9edb-316536162275",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1944%2F07%2F03%2F21-Flag_of_Belarus.svg.png?alt=media&token=de4a72e5-e5d1-4cec-9782-70fcec87c24a",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Belarus",
    },
    {
      id: 23,
      date: "21 Sep 1981",
      time: 0,
      type: 0,
      title: "Belize Independence Day",
      description:
        "Belize (/bɪˈliːz, bɛ-/ i, bih-LEEZ, beh-; Belize Kriol English: Bileez) is a country on the north-eastern coast of Central America. It is bordered by Mexico to the north, the Caribbean Sea to the east, and Guatemala to the west and south. It also shares a water boundary with Honduras to the southeast.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1981%2F09%2F21%2Fthumbs%2F23-Flag_of_Belize.svg_600x600.jpeg?alt=media&token=88447708-7fe0-4713-b896-6d41952bbe46",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1981%2F09%2F21%2F23-Flag_of_Belize.svg.png?alt=media&token=a553e5c6-e92d-4828-b3b9-8981ef2405dd",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Belize",
    },
    {
      id: 24,
      date: "1 Aug 1960",
      time: 0,
      type: 0,
      title: "Benin Independence Day",
      description:
        "Benin (/bɛˈniːn/ i ben-EEN, /bɪˈniːn/ bin-EEN;[9] French: Bénin [benɛ̃], Fon: Benɛ, Fula: Benen), officially the Republic of Benin (French: République du Bénin), and formerly Dahomey,[10] is a country in West Africa. It is bordered by Togo to the west, Nigeria to the east, Burkina Faso to the north-west, and Niger to the north-east.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F08%2F01%2Fthumbs%2F24-Flag_of_Benin.svg_600x600.jpeg?alt=media&token=fcb9696b-67ca-4493-a597-6da1c89593f9",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F08%2F01%2F24-Flag_of_Benin.svg.png?alt=media&token=1ac90d1d-6a86-4d3a-8ed8-386c70509279",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Benin",
    },
    {
      id: 25,
      date: "25 Nov 1943",
      time: 0,
      type: 0,
      title: "Bosnia and Herzegovina Statehood Day",
      description:
        "Statehood Day (Serbo-Croatian: Dan državnosti / Дан државности) is a holiday celebrated in Federation of Bosnia and Herzegovina[1] that occurs every year on 25 November. On that day in 1943, at the first session of the State Anti-fascist Council for the National Liberation of Bosnia and Herzegovina (ZAVNOBiH) in Mrkonjić Grad, the Resolution of ZAVNOBiH was adopted.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1943%2F11%2F25%2Fthumbs%2F25-Flag_of_Bosnia_and_Herzegovina.svg_600x600.jpeg?alt=media&token=678411c5-c2ec-41cd-b018-c4ccd38ec021",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1943%2F11%2F25%2F25-Flag_of_Bosnia_and_Herzegovina.svg.png?alt=media&token=41a91b0c-0700-4792-967a-575c240b32fc",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Bosnia_and_Herzegovina",
    },
    {
      id: 26,
      date: "30 Sep 1966",
      time: 0,
      type: 0,
      title: "Botswania Independence Day",
      description:
        "Botswana (English: Land of the Tswana; /bɒtˈswɑːnə/ i, also UK: /bʊt-, bʊˈtʃw-/[14]), officially the Republic of Botswana (Setswana: Lefatshe la Botswana, [lɪˈfatsʰɪ la bʊˈtswana]), is a landlocked country in Southern Africa. Botswana is topographically flat, with approximately 70 per cent of its territory being the Kalahari Desert. It is bordered by South Africa to the south and southeast, Namibia to the west and north, and Zimbabwe to the northeast. It is connected by the Kazungula Bridge[15] to Zambia, across the world's shortest border between two countries.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1966%2F09%2F30%2Fthumbs%2F26-Flag_of_Botswana.svg_600x600.jpeg?alt=media&token=be5d5535-5268-4e47-becc-7c0252415531",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1966%2F09%2F30%2F26-Flag_of_Botswana.svg.png?alt=media&token=96cc8d3b-687b-483a-be26-9472e6d4bb67",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Botswana",
    },
    {
      id: 27,
      date: "23 Feb 1984",
      time: 0,
      type: 0,
      title: "Brunei National Day",
      description:
        "National Day, celebrating independence from the United Kingdom in 1984, which actually occurred on 1 January",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1984%2F02%2F23%2Fthumbs%2F27-Flag_of_Brunei.svg_600x600.jpeg?alt=media&token=aa317a83-a376-4940-bc71-a6e89c1ba2ce",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1984%2F02%2F23%2F27-Flag_of_Brunei.svg.png?alt=media&token=00f54d05-2c8a-4dc1-8578-bd82031231ba",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Brunei",
    },
    {
      id: 28,
      date: "11 Dec 1958",
      time: 0,
      type: 0,
      title: "Burkina Faso Independence Day",
      description:
        "Burkina Faso (UK: /bərˌkiːnə ˈfæsoʊ/ bər-KEE-nə FASS-oh, US: /-ˈfɑːsoʊ/ i -⁠FAH-soh;[12] French: [buʁkina faso], Fula: 𞤄𞤵𞤪𞤳𞤭𞤲𞤢 𞤊𞤢𞤧𞤮) is a landlocked country in West Africa with an area of 274,200 km2 (105,900 sq mi), bordered by Mali to the northwest, Niger to the northeast, Benin to the southeast, Togo and Ghana to the south, and the Ivory Coast to the southwest. As of 2021, the country had an estimated population of 20,321,378.[13] Previously called Republic of Upper Volta (1958–1984), it was renamed Burkina Faso by President Thomas Sankara. Its citizens are known as Burkinabè (/bɜːrˈkiːnəbeɪ/ bur-KEE-nə-bay), and its capital and largest city is Ouagadougou. Its name is often translated into English as the Land of Honest Men.[14]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1958%2F12%2F11%2Fthumbs%2F28-Flag_of_Burkina_Faso.svg_600x600.jpeg?alt=media&token=8e7695ce-ad01-4fe0-ad5d-f5581b8a854d",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1958%2F12%2F11%2F28-Flag_of_Burkina_Faso.svg.png?alt=media&token=6da9b879-51bf-4365-97f3-edc93403f7c0",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Burkina_Faso",
    },
    {
      id: 29,
      date: "1 Jul 1962",
      time: 0,
      type: 0,
      title: "Burundi Independence Day",
      description:
        "Burundi (/bəˈrʊndi/ i bə-RUUN-dee or /bəˈrʌndi/ bə-RUN-dee), officially the Republic of Burundi (Kirundi: Repuburika y’Uburundi[11] [u.βu.ɾǔː.ndi]; Swahili: Jamuhuri ya Burundi; French: République du Burundi [buʁundi, byʁyndi]), is a landlocked country in the Great Rift Valley at the junction between the African Great Lakes region and East Africa. It is bordered by Rwanda to the north, Tanzania to the east and southeast, and the Democratic Republic of the Congo to the west; Lake Tanganyika lies along its southwestern border. The capital cities are Gitega and Bujumbura, the latter being the country's largest city.[12]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1962%2F07%2F01%2Fthumbs%2F29-Flag_of_Burundi.svg_600x600.jpeg?alt=media&token=c5c31dc4-a669-4d27-9bb5-91c6a7f6e55e",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1962%2F07%2F01%2F29-Flag_of_Burundi.svg.png?alt=media&token=96e3b977-bccf-4251-ae53-55e237a105ed",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Burundi",
    },
    {
      id: 30,
      date: "9 Nov 1953",
      time: 0,
      type: 0,
      title: "Cambodia Independence Day",
      description:
        "Cambodia (/kæmˈboʊdiə/ i; Khmer: កម្ពុជា; UNGEGN: Kâmpŭchéa),[14] officially the Kingdom of Cambodia (Khmer: ព្រះរាជាណាចក្រកម្ពុជា; UNGEGN: Preâh Réachéanachâkr Kâmpŭchéa), is a country in Mainland Southeast Asia, spanning an area of 181,035 square kilometres (69,898 square miles), bordered by Thailand to the northwest, Laos to the north, Vietnam to the east, and the Gulf of Thailand to the southwest. The capital and most populous city is Phnom Penh.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1953%2F11%2F09%2Fthumbs%2F30-Flag_of_Cambodia.svg_600x600.jpeg?alt=media&token=0a8f720c-6249-455a-9a6c-485b0f8d146c",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1953%2F11%2F09%2F30-Flag_of_Cambodia.svg.png?alt=media&token=a3d7150f-3df6-4410-8edb-e3bfe5995963",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Cambodia",
    },
    {
      id: 31,
      date: "20 May 1972",
      time: 0,
      type: 0,
      title: "Cameroon National Day",
      description:
        "Cameroon (English: Cameroon,/ˌkæməˈruːn/ i CAM-ə-ROON, French: Cameroun, Duala: Kamerun, Ewondo: Kamərún, Fula: Kamerun, Fe'fe': Kamerun), officially the Republic of Cameroon (French: République du Cameroun), is a country in Central Africa. It shares boundaries with Nigeria to the west and north, Chad to the northeast, the Central African Republic to the east, and Equatorial Guinea, Gabon and the Republic of the Congo to the south. Its coastline lies on the Bight of Biafra, part of the Gulf of Guinea and the Atlantic Ocean. Due to its strategic position at the crossroads between West Africa and Central Africa, it has been categorized as being in both camps. Its nearly 27 million people speak 250 native languages and English or French or both.[11][12][13]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1972%2F05%2F20%2Fthumbs%2F31-Flag_of_Cameroon.svg_600x600.jpeg?alt=media&token=35d26f55-cf73-4032-ab43-1464bdaa3a91",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1972%2F05%2F20%2F31-Flag_of_Cameroon.svg.png?alt=media&token=fb49acbc-bf85-4066-b6bc-da593220d049",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Cameroon",
    },
    {
      id: 32,
      date: "5 Jul 1975",
      time: 0,
      type: 0,
      title: "Cape Verde Independence Day",
      description:
        "Cape Verde (/ˈvɜːrd(i)/ i, VURD or VUR-dee) or Cabo Verde (/ˌkɑːboʊ ˈvɜːrdeɪ/ i, /ˌkæb-/ KA(H)B-oh VUR-day; Portuguese: [ˈkaβu ˈveɾðɨ]), officially the Republic of Cabo Verde, is an archipelago and island country in the central Atlantic Ocean, consisting of ten volcanic islands with a combined land area of about 4,033 square kilometres (1,557 sq mi).[9] These islands lie between 600 and 850 kilometres (320 and 460 nautical miles) west of Cap-Vert, the westernmost point of continental Africa. The Cape Verde islands form part of the Macaronesia ecoregion, along with the Azores, the Canary Islands, Madeira, and the Savage Isles.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1975%2F07%2F05%2Fthumbs%2F32-Flag_of_Cape_Verde.svg_600x600.jpeg?alt=media&token=992d186a-ffe4-4673-b519-574b0ad57913",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1975%2F07%2F05%2F32-Flag_of_Cape_Verde.svg.png?alt=media&token=ad914ba3-7e07-4678-8403-65cee6005d87",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Cape_Verde",
    },
    {
      id: 33,
      date: "1 Dec 1958",
      time: 0,
      type: 0,
      title: "Central African Republic Day",
      description:
        "The Central African Republic (CAR)[a] is a landlocked country in Central Africa. It is bordered by Chad to the north, Sudan to the northeast, South Sudan to the east, the Democratic Republic of the Congo to the south, the Republic of the Congo to the southwest, and Cameroon to the west. The Central African Republic was once the French colony of Ubangi-Shari before independence.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1958%2F12%2F01%2Fthumbs%2F33-Flag_of_the_Central_African_Republic.svg_600x600.jpeg?alt=media&token=da96248a-ceae-4ef5-aeba-dc7dfc937b82",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1958%2F12%2F01%2F33-Flag_of_the_Central_African_Republic.svg.png?alt=media&token=e423e992-4915-4754-bab2-7c76d42120b3",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Central_African_Republic",
    },
    {
      id: 34,
      date: "11 Aug 1960",
      time: 0,
      type: 0,
      title: "Chad Independence Day",
      description:
        "Chad (/tʃæd/ i chad),[a] officially the Republic of Chad,[b] is a landlocked country at the crossroads of North and Central Africa. It is bordered by Libya to the north, Sudan to the east, the Central African Republic to the south, Cameroon to the southwest, Nigeria to the southwest (at Lake Chad), and Niger to the west. Chad has a population of 16 million, of which 1.6 million live in the capital and largest city of N'Djamena.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F08%2F11%2Fthumbs%2F34--Flag_of_Chad.svg_600x600.jpeg?alt=media&token=ae941424-6995-4036-bfd3-bb5ad2e0a78e",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F08%2F11%2F34--Flag_of_Chad.svg.png?alt=media&token=f561b4e7-4854-4c40-bb5f-3a8c7104af82",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Chad",
    },
    {
      id: 35,
      date: "1 Oct 1949",
      time: 0,
      type: 0,
      title: "People's Republic of China National Day",
      description:
        "China (Chinese: 中国; pinyin: Zhōngguó), officially the People's Republic of China (PRC),[k] is a country in East Asia. It is the world's second-most populous country with a population exceeding 1.4 billion. China spans the equivalent of five time zones and borders fourteen countries by land,[l] tied with Russia as having the most of any country in the world. With an area of nearly 9.6 million square kilometres (3,700,000 sq mi), it is the world's third largest country by total land area.[m] The country is divided into 22 provinces,[n] five autonomous regions, four municipalities, and two semi-autonomous special administrative regions. The",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1949%2F10%2F01%2Fthumbs%2F35-Flag_of_the_People's_Republic_of_China.svg_600x600.jpeg?alt=media&token=00fb6094-b2cd-4c6d-9a1a-cdf6089474fe",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1949%2F10%2F01%2F35-Flag_of_the_People's_Republic_of_China.svg.png?alt=media&token=a3013f87-3030-477a-b8b6-19939f2096c1",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/China",
    },
    {
      id: 36,
      date: "1 Jan 1912",
      time: 0,
      type: 0,
      title: "Republic of China Founding Day",
      description:
        "Taiwan,[II][m] officially the Republic of China (ROC),[I][n] is a country[32] in East Asia. It is located at the junction of the East and South China Seas in the northwestern Pacific Ocean, with the People's Republic of China (PRC) to the northwest, Japan to the northeast, and the Philippines to the south. The territories controlled by the ROC consist of 168 islands[o] with a combined area of 36,193 square kilometres (13,974 square miles).[18][43] The main island of Taiwan, also known as Formosa, has an area of 35,808 square kilometres (13,826 square miles), with mountain ranges dominating the eastern two-thirds and plains in the western third, where its highly urbanized population is concentrated. The capital, Taipei, forms along with New Taipei City and Keelung, the largest metropolitan area in Taiwan. With around 23.9 million inhabitants, Taiwan is among the most densely populated countries.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1912%2F01%2F01%2Fthumbs%2F36-Flag_of_the_Republic_of_China.svg_600x600.jpeg?alt=media&token=c7d5882a-5655-49f4-89cf-466e8971f6e8",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1912%2F01%2F01%2F36-Flag_of_the_Republic_of_China.svg.png?alt=media&token=66d2961d-4d22-4661-8c59-f18157bdb6c9",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Taiwan",
    },
    {
      id: 37,
      date: "6 Jul 1975",
      time: 0,
      type: 0,
      title: "Comoros Independence Day",
      description:
        "The Comoros,[note 1] officially the Union of the Comoros,[note 2] is an independent country made up of three islands in Southeastern Africa, located at the northern end of the Mozambique Channel in the Indian Ocean. Its capital and largest city is Moroni. The religion of the majority of the population, and the official state religion, is Sunni Islam. Comoros proclaimed its independence from France on 6 July 1975. A member of the Arab League, it is the only country in the Arab world which is entirely in the Southern Hemisphere. It is a member state of the African Union, the Organisation internationale de la Francophonie, the Organisation of Islamic Cooperation, and the Indian Ocean Commission. The country has three official languages: Shikomori, French and Arabic.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1975%2F07%2F06%2Fthumbs%2F37-Flag_of_the_Comoros.svg_600x600.jpeg?alt=media&token=84c9ab94-7fe5-4b51-8d3b-955a3bb98555",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1975%2F07%2F06%2F37-Flag_of_the_Comoros.svg.png?alt=media&token=81d1ef2c-6e1d-4b34-9381-dc06c3b7d94e",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Comoros",
    },
    {
      id: 38,
      date: "30 Jun 1960",
      time: 0,
      type: 0,
      title: "Democratic Republic of Congo Independence Day",
      description:
        "The Democratic Republic of the Congo (DRC), also known as Congo-Kinshasa, is a country in Central Africa. By land area, the DRC is the second-largest country in Africa and the 11th largest in the world. With a population of around 112 million, the Democratic Republic of the Congo is the most populous officially Francophone country in the world. The national capital and largest city is Kinshasa, which is also the economic center. The country is bordered by the Republic of the Congo, Central African Republic, South Sudan, Uganda, Rwanda, Burundi, Tanzania (across Lake Tanganyika), Zambia, Angola, the Cabinda exclave of Angola and the South Atlantic Ocean.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F06%2F30%2Fthumbs%2F38-Flag_of_the_Democratic_Republic_of_the_Congo.svg_600x600.jpeg?alt=media&token=1fa4a46a-a1a7-4353-8020-4fbf93972115",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F06%2F30%2F38-Flag_of_the_Democratic_Republic_of_the_Congo.svg.png?alt=media&token=5ea05d8b-d764-4da3-8629-52782e5bc5e7",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Democratic_Republic_of_the_Congo",
    },
    {
      id: 39,
      date: "15 Aug 1960",
      time: 0,
      type: 0,
      title: "Republic of Congo Independence Day",
      description:
        "The Republic of the Congo (French: République du Congo, Lingala: Republíki ya Kongó), also known as Congo-Brazzaville, the Congo Republic[7][8] or simply either Congo or the Congo, is a country located on the western coast of Central Africa to the west of the Congo River. It is bordered to the west by Gabon, to its northwest by Cameroon and its northeast by the Central African Republic, to the southeast by the Democratic Republic of the Congo, to its south by the Angolan exclave of Cabinda and to its southwest by the Atlantic Ocean.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F08%2F15%2Fthumbs%2F39-Flag_of_the_Republic_of_the_Congo.svg_600x600.jpeg?alt=media&token=e3996a13-9b48-41b1-8734-16be21a8aaf4",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F08%2F15%2F39-Flag_of_the_Republic_of_the_Congo.svg.png?alt=media&token=38efa42c-a351-4457-afb5-cfb8b26b75b1",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Republic_of_the_Congo",
    },
    {
      id: 40,
      date: "30 May 1990",
      time: 0,
      type: 0,
      title: "Croatia Statehood Day",
      description:
        "Croatia (/kroʊˈeɪʃə/ i, kroh-AY-shə; Croatian: Hrvatska, pronounced [xř̩ʋaːtskaː]), officially the Republic of Croatia (Croatian: Republika Hrvatska, (listeni)),[d] is a country located at the crossroads of Central and Southeast Europe. Its coast lies entirely on the Adriatic Sea. It borders Slovenia to the northwest, Hungary to the northeast, Serbia to the east, Bosnia and Herzegovina and Montenegro to the southeast, and shares a maritime border with Italy to the west and southwest. Its capital and largest city, Zagreb, forms one of the country's primary subdivisions, with twenty counties. The country spans 56,594 square kilometres (21,851 square miles), and has a population of nearly 3.9 million.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1990%2F05%2F30%2Fthumbs%2F40-Flag_of_Croatia.svg_600x600.jpeg?alt=media&token=5eb8a33d-7bed-4bdf-87ab-184a39c1bc45",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1990%2F05%2F30%2F40-Flag_of_Croatia.svg.png?alt=media&token=5068f2b9-2883-4614-ae60-5f06b9ac518a",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Croatia",
    },
    {
      id: 41,
      date: "1 Oct 1960",
      time: 0,
      type: 0,
      title: "Cyprus Independence Day",
      description:
        "Cyprus[f] (/ˈsaɪprəs/ i SY-prəss), officially the Republic of Cyprus,[g] is an island country located in the eastern Mediterranean Sea. It is geographically in West Asia, but culturally and geopolitically Southeast European. Cyprus is the third-largest and third-most populous island in the Mediterranean.[13][14] It is located north of Egypt, east of Greece, south of Turkey, and west of Lebanon and Syria. It borders the United Kingdom in its military bases in the British Overseas Territory of Akrotiri and Dhekelia and the UN buffer zone that separates it from the northeast portion of the island is governed by the Northern Cyprus, a de facto state recognised only by Turkey. Its capital and largest city is Nicosia.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F10%2F01%2Fthumbs%2F41-Flag_of_Cyprus.svg_600x600.jpeg?alt=media&token=aef78a5d-bb5f-4d45-81be-2ce2dd18b37e",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F10%2F01%2F41-Flag_of_Cyprus.svg.png?alt=media&token=7f955714-335e-4f14-81ef-58319fa5fcf6",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Cyprus",
    },
    {
      id: 42,
      date: "28 Oct 1918",
      time: 0,
      type: 0,
      title: "Czech Republic Independence Day",
      description:
        "The Czech Republic,[c][12] or Czechia,[d][13] is a landlocked country in Central Europe. Historically known as Bohemia,[14] it is bordered by Austria to the south, Germany to the west, Poland to the northeast, and Slovakia to the southeast.[15] The Czech Republic has a hilly landscape that covers an area of 78,871 square kilometers (30,452 sq mi) with a mostly temperate continental and oceanic climate. The capital and largest city is Prague; other major cities and urban areas include Brno, Ostrava, Plzeň and Liberec.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1918%2F10%2F28%2Fthumbs%2F42-Flag_of_the_Czech_Republic.svg_600x600.jpeg?alt=media&token=5cdeb960-95e0-4f21-af3a-0cd8b6dec75f",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1918%2F10%2F28%2F42-Flag_of_the_Czech_Republic.svg.png?alt=media&token=6f505b1e-c728-49d7-b1b1-d5a1023cf954",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Czech_Republic",
    },
    {
      id: 43,
      date: "7 Aug 1960",
      time: 0,
      type: 0,
      title: "Ivory Coast Independence Day",
      description:
        "Ivory Coast, officially the Republic of Côte d'Ivoire, also known as Côte d'Ivoire,[a] is a country on the southern coast of West Africa. Its capital is Yamoussoukro, in the centre of the country, while its largest city and economic centre is the port city of Abidjan. It borders Guinea to the northwest, Liberia to the west, Mali to the northwest, Burkina Faso to the northeast, Ghana to the east, and the Gulf of Guinea (Atlantic Ocean) to the south. Its official language is French, and indigenous languages are also widely used, including Bété, Baoulé, Dioula, Dan, Anyin, and Cebaara Senufo. In total, there are around 78 different languages spoken in Ivory Coast. The country has a religiously diverse population, including numerous followers of Islam, Christianity, and Traditional Faiths like Animism.[8][1]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F08%2F07%2Fthumbs%2F43-Flag_of_Co%CC%82te_d'Ivoire.svg_600x600.jpeg?alt=media&token=296d3405-0639-4f2a-8800-757d66831b59",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F08%2F07%2F43-Flag_of_Co%CC%82te_d'Ivoire.svg.png?alt=media&token=5b61204b-58da-4cc2-8b85-ec3060767db6",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Ivory_Coast",
    },
    {
      id: 44,
      date: "27 Jun 1977",
      time: 0,
      type: 0,
      title: "Djibouti Independence Day",
      description:
        "Djibouti,[a] officially the Republic of Djibouti,[b] is a country in the Horn of Africa, bordered by Somalia[c] to the south, Ethiopia to the southwest, Eritrea in the north, and the Red Sea and the Gulf of Aden to the east. The country has an area of 23,200 km2 (8,958 sq mi).[1]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1977%2F06%2F27%2Fthumbs%2F44-Flag_of_Djibouti.svg_600x600.jpeg?alt=media&token=18ba2652-7ece-4e95-9ce9-b68d4f1fb344",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1977%2F06%2F27%2F44-Flag_of_Djibouti.svg.png?alt=media&token=15fd843a-0ab1-4eb5-9bd3-24c05d4e3cc9",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Djibouti",
    },
    {
      id: 45,
      date: "20 May 2002",
      time: 0,
      type: 0,
      title: "East Timor Independence Day",
      description:
        "East Timor (/ˈtiːmɔːr/ i), also known as Timor-Leste (/tiˈmɔːr ˈlɛʃteɪ/; Portuguese pronunciation: [tiˈmoɾ ˈlɛʃtɨ]), officially the Democratic Republic of Timor-Leste, is a country in Southeast Asia. It comprises the eastern half of the island of Timor, of which the western half is administered by Indonesia, the exclave of Oecusse on the island's north-western half, and the minor islands of Atauro and Jaco. Australia is the country's southern neighbour, separated by the Timor Sea. The country's size is 14,874 square kilometres (5,743 sq mi). Dili is its capital and largest city.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2002%2F05%2F20%2Fthumbs%2F45-Flag_of_East_Timor.svg_600x600.jpeg?alt=media&token=d401bdca-4cd0-4f66-aadf-b09f2908a7ac",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2002%2F05%2F20%2F45-Flag_of_East_Timor.svg.png?alt=media&token=28d483ba-3b5c-4f65-b575-ed3ed5685023",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/East_Timor",
    },
    {
      id: 46,
      date: "23 Jul 1952",
      time: 0,
      type: 0,
      title: "Egypt Revolution Day",
      description:
        "Egypt (Arabic: مصر Miṣr [mesˁr], Egyptian Arabic pronunciation: [mɑsˤr]), officially the Arab Republic of Egypt, is a transcontinental country spanning the northeast corner of Africa and the Sinai Peninsula in the southwest corner of Asia. It is bordered by the Mediterranean Sea to the north, the Gaza Strip of Palestine and Israel to the northeast, the Red Sea to the east, Sudan to the south, and Libya to the west. The Gulf of Aqaba in the northeast separates Egypt from Jordan and Saudi Arabia. Cairo is the capital and largest city of Egypt, while Alexandria, the second-largest city, is an important industrial and tourist hub at the Mediterranean coast.[11] At approximately 100 million inhabitants, Egypt is the 14th-most populated country in the world, and the third-most populated in Africa, behind Nigeria and Ethiopia.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1952%2F07%2F23%2Fthumbs%2F46-Flag_of_Egypt.svg_600x600.jpeg?alt=media&token=c9c6eef5-bc42-4e3d-adcc-df2f49d47402",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1952%2F07%2F23%2F46-Flag_of_Egypt.svg.png?alt=media&token=f8e65d5f-13d7-4b22-b871-20142b9046a0",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Egypt",
    },
    {
      id: 47,
      date: "12 Oct 1968",
      time: 0,
      type: 0,
      title: "Equatiorial Guinea Independence Day",
      description:
        "Equatorial Guinea (Spanish: Guinea Ecuatorial;[a] French: Guinée équatoriale; Portuguese: Guiné Equatorial), also rarely known as Equatoguinea, officially the Republic of Equatorial Guinea (Spanish: República de Guinea Ecuatorial, French: République de Guinée équatoriale, Portuguese: República da Guiné Equatorial),[b] is a country on the west coast of Central Africa, with an area of 28,000 square kilometres (11,000 sq mi). Formerly the colony of Spanish Guinea, its post-independence name refers to its location near both the Equator and in the African region of Guinea. As of 2021, the country had a population of 1,468,777,[11] over 85% of whom are members of the Fang people, the country's dominant ethnic group. The Bubi people, indigenous to Bioko are the second largest group at approximately 6.5% of the population.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1968%2F10%2F12%2Fthumbs%2F47-Flag_of_Equatorial_Guinea.svg_600x600.jpeg?alt=media&token=3666ddf2-6990-4f31-a3ce-9a60b8dc5ae0",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1968%2F10%2F12%2F47-Flag_of_Equatorial_Guinea.svg.png?alt=media&token=afc03928-6f18-41c0-a1aa-0cfa0eebc262",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Equatorial_Guinea",
    },
    {
      id: 48,
      date: "24 Feb 1918",
      time: 0,
      type: 0,
      title: "Estonia Independence Day",
      description:
        "Estonia,[a] officially the Republic of Estonia, is a country by the Baltic Sea in Northern Europe. It is bordered to the north by the Gulf of Finland across from Finland, to the west by the sea across from Sweden, to the south by Latvia, and to the east by Lake Peipus and Russia. The territory of Estonia consists of the mainland, the larger islands of Saaremaa and Hiiumaa, and over 2,200 other islands and islets on the eastern coast of the Baltic Sea,[7] covering a total area of 45,339 square kilometres (17,505 sq mi). The capital city Tallinn and Tartu are the two largest urban areas of the country. The Estonian language is the indigenous and the official language of Estonia; it is the first language of the majority of its population, as well as the world's second most spoken Finnic language.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1918%2F02%2F24%2Fthumbs%2F48-Flag_of_Estonia.svg_600x600.jpeg?alt=media&token=f57ee8d5-3798-425e-ab9a-906421003318",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1918%2F02%2F24%2F48-Flag_of_Estonia.svg.png?alt=media&token=572e31da-4208-4335-9aeb-8fbb2281cb95",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Estonia",
    },
    {
      id: 49,
      date: "6 Sep 1968",
      time: 0,
      type: 0,
      title: "Eswatini Independence Day",
      description:
        "Eswatini (/ˌɛswɑːˈtiːni/ ESS-wah-TEE-nee; Swazi: eSwatini [ɛswáˈtʼiːni]), formally the Kingdom of Eswatini and also known by its former official name Swaziland (/ˈswɑːzilænd/ SWAH-zee-land),[10][11] is a landlocked country in Southern Africa. It is bordered by Mozambique to its northeast and South Africa to its north, west, south, and southeast. At no more than 200 km (120 mi) north to south and 130 km (81 mi) east to west, Eswatini is one of the smallest countries in Africa; despite this, its climate and topography are diverse, ranging from a cool and mountainous highveld to a hot and dry lowveld.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1968%2F09%2F06%2Fthumbs%2F49-Flag_of_Eswatini.svg_600x600.jpeg?alt=media&token=74ac1c03-a51c-4ed7-9fc7-f874727a6c2d",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1968%2F09%2F06%2F49-Flag_of_Eswatini.svg.png?alt=media&token=f588d498-cadd-4a26-8ebd-6099c221f984",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Eswatini",
    },
    {
      id: 50,
      date: "9 May 1950",
      time: 0,
      type: 0,
      title: "European Union Day",
      description:
        "The European Union (EU) is a supranational political and economic union of 27 member states that are located primarily in Europe.[7][8] The union has a total area of 4,233,255 km2 (1,634,469 sq mi) and an estimated total population of over 448 million. The EU has often been described as a sui generis political entity (without precedent or comparison) combining the characteristics of both a federation and a confederation.[9][10]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1950%2F05%2F09%2Fthumbs%2F50-Flag_of_Europe.svg_600x600.jpeg?alt=media&token=f89ffd2c-b69c-4737-b152-47ba17abc240",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1950%2F05%2F09%2F50-Flag_of_Europe.svg.png?alt=media&token=c4167d55-9256-41d8-af60-48918e438617",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/European_Union",
    },
    {
      id: 51,
      date: "14 Jun 1982",
      time: 0,
      type: 0,
      title: "Falkland Islands Liberation Day",
      description:
        "Liberation Day is the National Day of the Falkland Islands and commemorates the liberation of the Falkland Islanders from Argentine military occupation at the end of the Falklands War on 14 June 1982.[1][2] It is celebrated every year on 14 June (observed on 15 June when 14th is a Sunday) and is a public holiday.[3] Commemorations include a thanksgiving service at Christ Church Cathedral, followed by wreath laying at the Liberation Memorial and a military parade in Stanley.[4]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1982%2F06%2F14%2Fthumbs%2F51-Flag_of_the_Falkland_Islands.svg_600x600.jpeg?alt=media&token=78b0f1e0-08a2-4c6c-943e-703149c78b26",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1982%2F06%2F14%2F51-Flag_of_the_Falkland_Islands.svg.png?alt=media&token=fe7cdfbb-59ca-4ea6-a8cf-496de0e548f0",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Falkland_Islands",
    },
    {
      id: 52,
      date: "10 Oct 1970",
      time: 0,
      type: 0,
      title: "Fiji Day",
      description:
        "Fiji[n 1] (/ˈfiːdʒi/ i FEE-jee, /fiːˈdʒiː/ fee-JEE;[12] Fijian: Viti, [ˈβitʃi]; Fiji Hindi: फ़िजी, Fijī), officially the Republic of Fiji,[n 2] is an island country in Melanesia, part of Oceania in the South Pacific Ocean. It lies about 1,100 nautical miles (2,000 km; 1,300 mi) north-northeast of New Zealand. Fiji consists of an archipelago of more than 330 islands—of which about 110 are permanently inhabited—and more than 500 islets, amounting to a total land area of about 18,300 square kilometres (7,100 sq mi). The most outlying island group is Ono-i-Lau. About 87% of the total population of 924,610 live on the two major islands, Viti Levu and Vanua Levu. About three-quarters of Fijians live on Viti Levu's coasts, either in the capital city of Suva, or in smaller urban centres such as Nadi (where tourism is the major local industry) or Lautoka (where the sugar-cane industry is dominant). The interior of Viti Levu is sparsely inhabited because of its terrain.[13]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1970%2F10%2F10%2Fthumbs%2F52-Flag_of_Fiji.svg_600x600.jpeg?alt=media&token=4516a5d2-e803-4731-8c95-e263f8c4f4fc",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1970%2F10%2F10%2F52-Flag_of_Fiji.svg.png?alt=media&token=cd711988-7d16-47a5-a1f2-8779d493820a",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Fiji",
    },
    {
      id: 53,
      date: "6 Dec 1917",
      time: 0,
      type: 0,
      title: "Finland Independence Day",
      description:
        "Finland (Finnish: Suomi [ˈsuo̯mi] i; Swedish: Finland [ˈfɪ̌nland] i), officially the Republic of Finland (Finnish: Suomen tasavalta; Swedish: Republiken Finland (listen to alli)),[note 2] is a Nordic country in Northern Europe. It borders Sweden to the northwest, Norway to the north, and Russia to the east, with the Gulf of Bothnia to the west and the Gulf of Finland to the south, across from Estonia. Finland covers an area of 338,145 square kilometres (130,559 sq mi)[5] with a population of 5.6 million. Helsinki is the capital and largest city. The vast majority of the population are ethnic Finns. Finnish and Swedish are the official languages, Swedish being the native language of 5.2% of the population.[12] Finland's climate varies from humid continental in the south to boreal in the north. The land cover is primarily a boreal forest biome, with more than 180,000 recorded lakes.[13]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1917%2F12%2F06%2Fthumbs%2F53-Flag_of_Finland.svg_600x600.jpeg?alt=media&token=72939897-4ba9-44e7-9964-8c2b689ca21a",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1917%2F12%2F06%2F53-Flag_of_Finland.svg.png?alt=media&token=23398bc1-b3f8-4606-aa1f-542178acdaf0",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Finland",
    },
    {
      id: 54,
      date: "17 Aug 1960",
      time: 0,
      type: 0,
      title: "Gabon Independence Day",
      description:
        "Gabon (/ɡəˈbɒn/ gə-BON; French pronunciation: ​[ɡabɔ̃] i; Sangu: Ngabu), officially the Gabonese Republic (French: République gabonaise), is a country on the Atlantic coast of West Central Africa. Located on the equator, it is bordered by Equatorial Guinea to the northwest, Cameroon to the north, the Republic of the Congo on the east and south, and the Gulf of Guinea to the west. It has an area of nearly 270,000 square kilometres (100,000 sq mi) and its population is estimated at 2.3 million people. There are coastal plains, mountains (the Cristal Mountains and the Chaillu Massif in the centre), and a savanna in the east. Libreville is the country's capital and the largest city.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F08%2F17%2Fthumbs%2F54-Flag_of_Gabon.svg_600x600.jpeg?alt=media&token=f64a6d10-122c-4706-a576-4848a8a85484",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F08%2F17%2F54-Flag_of_Gabon.svg.png?alt=media&token=13102f03-c852-4dce-9584-fed7916ed0b2",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Gabon",
    },
    {
      id: 55,
      date: "18 Feb 1965",
      time: 0,
      type: 0,
      title: "The Gambia Independence Day",
      description:
        "The Gambia,[a] officially the Republic of The Gambia,[8] is a country in West Africa. It is the smallest country within mainland Africa[b][9] and is surrounded by Senegal, except for its western coast on the Atlantic Ocean. The Gambia is situated on both sides of the lower reaches of the Gambia River, the nation's namesake, which flows through the centre of The Gambia and empties into the Atlantic Ocean, and elucidates the long shape of the country. It has an area of 11,300 square kilometres (4,400 sq mi)[3] with a population of 1,857,181 as of the April 2013 census. Banjul is the Gambian capital and the country's largest metropolitan area,[10] while the largest cities are Serekunda and Brikama.[11]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1965%2F02%2F18%2Fthumbs%2F55-Flag_of_The_Gambia.svg_600x600.jpeg?alt=media&token=9b5bb61e-3843-46d1-a77c-28b682de0952",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1965%2F02%2F18%2F55-Flag_of_The_Gambia.svg.png?alt=media&token=f7a79b2e-a2f1-4cb2-ad4e-ab46e8cec65a",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/The_Gambia",
    },
    {
      id: 56,
      date: "26 May 1918",
      time: 0,
      type: 0,
      title: "Georgia Independence Day",
      description:
        "Georgia (Georgian: საქართველო, romanized: sakartvelo, IPA: [sakʰartʰʷelo] i) is a country located in Eastern Europe and West Asia, culturally and geopolitically considered to be European.[10][11][12][13] It is part of the Caucasus region, bounded by the Black Sea to the west, Russia to the north and northeast, Turkey to the southwest, Armenia to the south, and by Azerbaijan to the southeast. The country covers an area of 69,700 square kilometres (26,900 sq mi), and has a population of 3.7 million people.[b][14] Tbilisi is its capital and largest city, home to roughly a third of the Georgian population.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1918%2F05%2F26%2Fthumbs%2F56-Flag_of_Georgia.svg_600x600.jpeg?alt=media&token=66202bf2-98d4-4ec6-bf7d-bbb7e3810a79",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1918%2F05%2F26%2F56-Flag_of_Georgia.svg.png?alt=media&token=30aec2b9-603e-4fc0-9d3f-4b7e7b59e2c6",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Georgia_(country)",
    },
    {
      id: 57,
      date: "7 Oct 1949",
      time: 0,
      type: 0,
      title: "East Germany Republic Day",
      description:
        "East Germany (German: Ostdeutschland), officially the German Democratic Republic (GDR; Deutsche Demokratische Republik, pronounced [ˈdɔʏtʃə demoˈkʁaːtɪʃə ʁepuˈbliːk] i, DDR), was a country in Central Europe that existed from its creation on 7 October 1949 until its dissolution on 3 October 1990. Until 1989, it was generally viewed as a communist state, and it described itself as a socialist workers' and peasants' state.[6] Before its establishment, the country's territory was administered and occupied by Soviet forces with the autonomy of the native communists following the Berlin Declaration abolishing German sovereignty in World War II; when the Potsdam Agreement established the Soviet-occupied zone, bounded on the east by the Oder–Neisse line. The GDR was dominated by the Socialist Unity Party of Germany (SED), a communist party from 1949 to 1989, before being democratized and liberalized under the impact of the Revolutions of 1989 against the communist states, helping East Germany be united with the West. Unlike West Germany, SED did not see its state as the successor of the German Reich (1871–1945) and abolished the goal of unification in the constitution (1974). Under the SED rule, GDR was often judged as a Soviet satellite state; most scholars and academics described it as a totalitarian regime.[7]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1949%2F10%2F07%2Fthumbs%2F57-Flag_of_East_Germany.svg_600x600.jpeg?alt=media&token=4dd7af02-bd4f-42f1-9190-fd57639a43ad",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1949%2F10%2F07%2F57-Flag_of_East_Germany.svg.png?alt=media&token=a9f3d5da-7986-406a-a444-275fc3215da3",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/East_Germany",
    },
    {
      id: 58,
      date: "3 Oct 1990",
      time: 0,
      type: 0,
      title: "German Unity Day",
      description:
        "German Unity Day (German: Tag der Deutschen Einheit) is the National Day of Germany, celebrated on 3 October as a public holiday.[1] It commemorates German reunification in 1990 when the German Democratic Republic (East Germany) joined the Federal Republic of Germany (West Germany), so that for the first time since 1945 there existed a single German state. German Unity Day on 3 October has been the German National Holiday since 1990, when the reunification was formally completed. In Photo: The flag of unity at midnight of 3 October 1990 in front of the Reichstag",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1990%2F10%2F03%2Fthumbs%2F58-German-Unity-Day_600x600.jpeg?alt=media&token=2774502b-72df-4221-8a9c-ac2b8f976c8c",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1990%2F10%2F03%2F58-German-Unity-Day.jpeg?alt=media&token=08da98e1-60ec-4989-90a3-f331f5088349",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/German_Unity_Day",
    },
    {
      id: 59,
      date: "6 Mar 1957",
      time: 0,
      type: 0,
      title: "Ghana Independence Day",
      description:
        "Ghana (/ˈɡɑːnə/ i GAH-nə; Twi: Gaana, Ewe: Gana), officially the Republic of Ghana, is a country in West Africa.[9] It abuts the Gulf of Guinea and the Atlantic Ocean to the south, sharing borders with Ivory Coast in the west, Burkina Faso in the north, and Togo in the east.[10] Ghana covers an area of 239,535 km2 (92,485 sq mi),[11] spanning diverse biomes that range from coastal savannas to tropical rainforests. With over 32 million inhabitants, Ghana is the second-most populous country in West Africa, after Nigeria.[12] The capital and largest city is Accra; other major cities are Kumasi, Tamale, and Sekondi-Takoradi.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1957%2F03%2F06%2Fthumbs%2F59-Flag_of_Ghana.svg_600x600.jpeg?alt=media&token=dffe576c-057c-4a08-9ed0-7d8ce0a14155",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1957%2F03%2F06%2F59-Flag_of_Ghana.svg.png?alt=media&token=4e28af2c-779e-4ed8-b7dc-8baf656c0b07",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Ghana",
    },
    {
      id: 60,
      date: "10 Sep 1967",
      time: 0,
      type: 0,
      title: "Gibraltar National Day",
      description:
        "Gibraltar (/dʒɪˈbrɔːltər/ jih-BRAWL-tər, Spanish: [xiβɾalˈtaɾ]) is a British Overseas Territory[a] and city[7] located at the southern tip of the Iberian Peninsula.[8][9] It has an area of 6.7 km2 (2.6 sq mi) and is bordered to the north by Spain (Campo de Gibraltar). The landscape is dominated by the Rock of Gibraltar, at the foot of which is a densely populated tow",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1967%2F09%2F10%2Fthumbs%2F60-Flag_of_Gibraltar.svg_600x600.jpeg?alt=media&token=24dd36e6-b69d-4112-9954-2fce4673a751",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1967%2F09%2F10%2F60-Flag_of_Gibraltar.svg.png?alt=media&token=f90bd221-0af6-4136-ac40-c9ed74e44b66",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Gibraltar",
    },
    {
      id: 61,
      date: "21 Jun 1983",
      time: 0,
      type: 0,
      title: "Greenland National Day",
      description:
        "Greenland (Greenlandic: Kalaallit Nunaat, pronounced [kalaːɬːit nʉnaːt]; Danish: Grønland, pronounced [ˈkʁɶnˌlænˀ]) is a North American autonomous territory of the Kingdom of Denmark.[13] It is the largest country within the Kingdom and one of three countries which form the Kingdom, the others being Denmark proper and the Faroe Islands; the citizens of all three countries are citizens of Denmark. As Greenland is one of the Overseas Countries and Territories of the European Union, citizens of Greenland are also granted European Union citizenship.[14] The capital and largest city of Greenland is Nuuk.[14] Greenland lies between the Arctic and Atlantic oceans, east of the Canadian Arctic Archipelago. It is the world's largest island,[d] as well as the northernmost area of the world – Kaffeklubben Island off the northern coast is the world's northernmost undisputed point of land, and Cape Morris Jesup on the mainland was thought to be so until the 1960s.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1983%2F06%2F21%2Fthumbs%2F61-Flag_of_Greenland.svg_600x600.jpeg?alt=media&token=8dc59113-4ad5-4d6a-a193-fa2c6d3a726a",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1983%2F06%2F21%2F61-Flag_of_Greenland.svg.png?alt=media&token=a03ad1fd-40b8-435e-bd9b-28df91ebb685",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Greenland",
    },
    {
      id: 62,
      date: "7 Feb 1974",
      time: 0,
      type: 0,
      title: "Grenada Independence Day",
      description:
        "Grenada (/ɡrəˈneɪdə/ i grə-NAY-də; Grenadian Creole French: Gwenad /ɡwiˈnaɪd/) is an island country in the West Indies in the Caribbean Sea at the southern end of the Grenadines island chain. Grenada consists of the island of Grenada itself, two smaller islands, Carriacou and Petite Martinique, and several small islands which lie to the north of the main island and are a part of the Grenadines. It is located northwest of Trinidad and Tobago, northeast of Venezuela and southwest of Saint Vincent and the Grenadines. Its size is 348.5 square kilometres (134.6 sq mi), and it had an estimated population of 124,523 in July 2021.[10] Its capital is St. George's.[10] Grenada is",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1974%2F02%2F07%2Fthumbs%2F62-Flag_of_Grenada.svg_600x600.jpeg?alt=media&token=024b98a4-17df-4975-a178-87b4730316c7",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1974%2F02%2F07%2F62-Flag_of_Grenada.svg.png?alt=media&token=adab6737-2589-4de4-bd69-e94fb77b685a",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Grenada",
    },
    {
      id: 63,
      date: "21 Jul 1944",
      time: 0,
      type: 0,
      title: "Guam Liberation Day",
      description:
        "Guam (/ˈɡwɑːm/ i GWAHM; Chamorro: Guåhan [ˈɡʷɑhɑn]) is an organized, unincorporated territory of the United States in the Micronesia subregion of the western Pacific Ocean.[4][5] Guam's capital is Hagåtña, and the most populous village is Dededo. It is the westernmost point and territory of the United States, reckoned from the geographic center of the U.S. In Oceania, Guam is the largest and southernmost of the Mariana Islands and the largest island in Micronesia.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1944%2F07%2F21%2Fthumbs%2F63-Flag_of_Guam.svg_600x600.jpeg?alt=media&token=20a188b0-78c9-4a47-89c8-f60578aaf9e0",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1944%2F07%2F21%2F63-Flag_of_Guam.svg.png?alt=media&token=bcf4e906-a4ed-4166-9937-2b339e33dcf9",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Guam",
    },
    {
      id: 64,
      date: "9 May 1945",
      time: 0,
      type: 0,
      title: "Guernsey Liberation Day",
      description:
        "Guernsey (/ˈɡɜːrnzi/ i; Guernésiais: Guernési; French: Guernesey) is the second largest island in the Channel Islands, located 27 miles (43 km) west of the Cotentin Peninsula, Normandy. It forms the major part of the jurisdiction of the same name, which also comprises three other inhabited islands (Herm, Jethou and Lihou) and many small islets and rocks. The jurisdiction has a population of 63,950[4] and the island has a land area of 24 square miles (62 km2).[6]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1945%2F05%2F09%2Fthumbs%2F64-Flag_of_Guernsey.svg_600x600.jpeg?alt=media&token=d691e4b9-94a7-4463-9abf-b33f818b2b40",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1945%2F05%2F09%2F64-Flag_of_Guernsey.svg.png?alt=media&token=2268c977-7c10-4719-92a0-29eabc87f0b7",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Guernsey",
    },
    {
      id: 65,
      date: "2 Oct 1958",
      time: 0,
      type: 0,
      title: "Guinea Independence Day",
      description:
        "Guinea (/ˈɡɪni/ i GHIN-ee),[a] officially the Republic of Guinea (French: République de Guinée), is a coastal country in West Africa. It borders the Atlantic Ocean to the west, Guinea-Bissau to the northwest, Senegal to the north, Mali to the northeast, Cote d'Ivoire to the southeast, and Sierra Leone and Liberia to the south. It is sometimes referred to as Guinea-Conakry after its capital Conakry, to distinguish it from other territories in the eponymous region such as Guinea-Bissau and Equatorial Guinea.[8][9][10][11] Guinea has a population of 13.5 million and an area of 245,857 square kilometres (94,926 sq mi).[12]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1958%2F10%2F02%2Fthumbs%2F65-Flag_of_Guinea.svg_600x600.jpeg?alt=media&token=04ada96e-1fb7-4630-91e7-bff28f5e5721",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1958%2F10%2F02%2F65-Flag_of_Guinea.svg.png?alt=media&token=10b59fa9-8090-478d-b69e-dd97d778eb15",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Guinea",
    },
    {
      id: 66,
      date: "24 Sep 1973",
      time: 0,
      type: 0,
      title: "Guinea-Bissau Independence Day",
      description:
        "Guinea-Bissau (/ˌɡɪni bɪˈsaʊ/ i GHIN-ee biss-OW; Portuguese: Guiné-Bissau; Fula: 𞤘𞤭𞤲𞤫 𞤄𞤭𞤧𞤢𞥄𞤱𞤮, romanized: Gine-Bisaawo; Mandinka: ߖߌߣߍ ߺ ߓߌߛߊߥߏ߫ Gine-Bisawo), officially the Republic of Guinea-Bissau (Portuguese: República da Guiné-Bissau [ʁɛˈpuβlikɐ ðɐ ɣiˈnɛ βiˈsaw]), is a country in West Africa that covers 36,125 square kilometres (13,948 sq mi) with an estimated population of 2,026,778. It borders Senegal to its north and Guinea to its southeast.[10]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1973%2F09%2F24%2Fthumbs%2F66-Flag_of_Guinea-Bissau.svg_600x600.jpeg?alt=media&token=6800fb16-68b5-43f2-8564-ba9954c0ac1c",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1973%2F09%2F24%2F66-Flag_of_Guinea-Bissau.svg.png?alt=media&token=659e8516-df5f-4fda-b9eb-633638d70603",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Guinea-Bissau",
    },
    {
      id: 67,
      date: "26 May 1966",
      time: 0,
      type: 0,
      title: "Guyana Independence Day",
      description:
        "Guyana (/ɡaɪˈɑːnə/ i or /ɡaɪˈænə/ i, gy-A(H)N-ə),[7][8] officially the Co-operative Republic of Guyana,[9] is a country on the northern mainland of South America. Guyana is most probably an indigenous word which means Land of Many Waters. The capital city is Georgetown. Guyana is bordered by the Atlantic Ocean to the north, Brazil to the south and southwest, Venezuela to the west, and Suriname to the east. With 215,000 km2 (83,000 sq mi), Guyana is the third-smallest sovereign state by area in mainland South America after Uruguay and Suriname, and is the second-least populous sovereign state in South America after Suriname; it is also one of the least densely populated countries on Earth. It has a wide variety of natural habitats and a very high biodiversity.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1966%2F05%2F26%2Fthumbs%2F67-Flag_of_Guyana.svg_600x600.jpeg?alt=media&token=e7a5c597-8bba-4b2a-8c1d-ad775ae4886c",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1966%2F05%2F26%2F67-Flag_of_Guyana.svg.png?alt=media&token=d98bc632-d145-4178-87b5-0d65c5bad052",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Guyana",
    },
    {
      id: 68,
      date: "9 May 1945",
      time: 0,
      type: 0,
      title: "Herm Liberation Day",
      description:
        "Herm (Guernésiais: Haerme, ultimately from Old Norse arms 'arm', due to the shape of the island, or Old French eremite 'hermit') is one of the Channel Islands and part of the Parish of St Peter Port[2][3] in the Bailiwick of Guernsey. It is located in the English Channel, north-west of France and south of England. It is 2,183 m (7,162 ft) long and under 873 metres (2,864 ft) wide; oriented north–south, with several stretches of sand along its northern coast. The much larger island of Guernsey lies to the west, Jersey lies to the south-east, and the smaller island of Jethou is just off the south-west coast.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1945%2F05%2F09%2Fthumbs%2F68-Flag_of_Herm.svg_600x600.jpeg?alt=media&token=a47a81c6-f5c5-4a1a-b8ae-d77ac3a8a38d",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1945%2F05%2F09%2F68-Flag_of_Herm.svg.png?alt=media&token=985461e2-2243-4e28-b3cc-d88af274a6b9",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Herm",
    },
    {
      id: 69,
      date: "17 Jun 1944",
      time: 0,
      type: 0,
      title: "Iceland National Day",
      description:
        "Iceland (Icelandic: Ísland, pronounced [ˈistlant] i)[d] is a Nordic island country between the North Atlantic and Arctic Oceans, on the Mid-Atlantic Ridge between North America and Europe. It is linked culturally and politically with Europe, and is the region's most sparsely populated country.[12] Its capital and largest city is Reykjavík, which is home to about 36% of the country's roughly 380,000 residents. The official language of the country is Icelandic.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1944%2F06%2F17%2Fthumbs%2F69-Flag_of_Iceland.svg_600x600.jpeg?alt=media&token=cd8e96c3-3d3a-4218-8679-1aab85d564a8",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1944%2F06%2F17%2F69-Flag_of_Iceland.svg.png?alt=media&token=9e2ae2b3-2feb-41e1-92c6-053920d96e2a",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Iceland",
    },
    {
      id: 70,
      date: "15 Aug 1947",
      time: 0,
      type: 0,
      title: "India Independence Day",
      description:
        "India, officially the Republic of India (ISO: Bhārat Gaṇarājya),[21] is a country in South Asia. It is the seventh-largest country by area; the most populous country as of June 2023;[22][23] and from the time of its independence in 1947, the world's most populous democracy.[24][25][26] Bounded by the Indian Ocean on the south, the Arabian Sea on the southwest, and the Bay of Bengal on the southeast, it shares land borders with Pakistan to the west;[j] China, Nepal, and Bhutan to the north; and Bangladesh and Myanmar to the east. In the Indian Ocean, India is in the vicinity of Sri Lanka and the Maldives; its Andaman and Nicobar Islands share a maritime border with Thailand, Myanmar, and Indonesia.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1947%2F08%2F15%2Fthumbs%2F70-Flag_of_India.svg_600x600.jpeg?alt=media&token=aef6dacf-2a93-4454-ad1f-a174119284a6",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1947%2F08%2F15%2F70-Flag_of_India.svg.webp?alt=media&token=81b4fd1c-a3f5-4fd9-94db-01f4532e1dff",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/India",
    },
    {
      id: 71,
      date: "17 Aug 1945",
      time: 0,
      type: 0,
      title: "Indonesia Independence Day",
      description:
        "Indonesia,[a] officially the Republic of Indonesia (Indonesian: Republik Indonesia),[b] is an island nation in Southeast Asia and Oceania between the Indian and Pacific oceans. It consists of over 17,000 islands, including Sumatra, Java, Sulawesi, and parts of Borneo and New Guinea. Indonesia is the world's largest archipelagic state and the 14th-largest country by area, at 1,904,569 square kilometres (735,358 square miles). With around 278 million people, Indonesia is the world's fourth-most populous country and the most populous Muslim-majority country. Java, the world's most populous island, is home to more than half of the country's population.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1945%2F08%2F17%2Fthumbs%2F71-Flag_of_Indonesia.svg_600x600.jpeg?alt=media&token=3c2abf19-c961-4d70-aff0-6ead7283057f",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1945%2F08%2F17%2F71-Flag_of_Indonesia.svg.png?alt=media&token=784678cf-786e-4e6d-a2bd-5d5bde9e3cd8",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Indonesia",
    },
    {
      id: 72,
      date: "11 Feb 1979",
      time: 0,
      type: 0,
      title: "Iran Islamic Revolution Day",
      description:
        "Iran,[a] also known as Persia[b][11] and officially as the Islamic Republic of Iran,[c] is a country in West Asia. It is bordered by Iraq and Turkey to the west, Azerbaijan and Armenia to the northwest, the Caspian Sea and Turkmenistan to the north, Afghanistan and Pakistan to the east, and the Gulf of Oman and the Persian Gulf to the south. It covers an area of 1.64 million square kilometres (0.63 million square miles), making it the 17th-largest country. With an estimated population of 86.8 million, Iran is the 17th-most populous country, and the second largest in the Middle East. Its capital and largest city is Tehran.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1979%2F02%2F11%2Fthumbs%2F72-Flag_of_Iran.svg_600x600.jpeg?alt=media&token=4a6b5132-59f0-46bf-ae4c-03f9741beb51",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1979%2F02%2F11%2F72-Flag_of_Iran.svg.png?alt=media&token=aeb27a9e-2850-451b-beb4-b07cce55484f",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Iran",
    },
    {
      id: 73,
      date: "3 Oct 1932",
      time: 0,
      type: 0,
      title: "Iraq Independence Day",
      description:
        "Iraq,[a] officially the Republic of Iraq,[b] is a country in the Middle East. It is a federal parliamentary republic that consists of 19 governorates. The country is bordered by Turkey to the north, Iran to the east, the Persian Gulf and Kuwait to the southeast, Saudi Arabia to the south, Jordan to the southwest and Syria to the west. The capital and largest city is Baghdad. The Iraqi people are diverse, with similarly diverse geography and wildlife. Most Iraqis are Muslims – minority faiths include Christianity, Yazidism, Mandaeism, Yarsanism and Zoroastrianism.[10][2] The official languages of Iraq are Arabic and Kurdish; others also recognised in specific regions are Turkish (Turkmen), Suret (Assyrian), and Armenian.[11] Iraq is the 33rd most-populous country in the world.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1932%2F10%2F03%2Fthumbs%2F73-Flag_of_Iraq.svg_600x600.jpeg?alt=media&token=f316beeb-8723-472d-abed-c8dfae0ca289",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1932%2F10%2F03%2F73-Flag_of_Iraq.svg.png?alt=media&token=ae53d787-2502-4b10-a05b-83bc514a2d21",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Iraq",
    },
    {
      id: 74,
      date: "2 Jun 1946",
      time: 0,
      type: 0,
      title: "Italy Festa della Repubblica",
      description:
        "Italy (Italian: Italia [iˈtaːlja] i), officially the Italian Republic[a][13][14] or the Republic of Italy,[15][16] is a country in Southern[17][18][19] and Western[20][note 1] Europe. Located in the middle of the Mediterranean Sea, it consists of a peninsula delimited by the Alps and surrounded by several islands.[21] Italy shares land borders with France, Switzerland, Austria, Slovenia and the enclaved microstates of Vatican City and San Marino. It has a territorial exclave in Switzerland (Campione) and an archipelago in the African Plate (Pelagie Islands). Italy covers an area of 301,340 km2 (116,350 sq mi),[3] with a population of about 60 million;[22] it is the tenth-largest country by land area in the European continent and the third-most populous member state of the European Union. Its capital and largest city is Rome.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1946%2F06%2F02%2Fthumbs%2F74-Flag_of_Italy.svg_600x600.jpeg?alt=media&token=74fea1b8-382d-4f7f-831f-c08bf97bcb22",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1946%2F06%2F02%2F74-Flag_of_Italy.svg.png?alt=media&token=616dab58-d96b-4367-b770-dfedeb2e7663",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Italy",
    },
    {
      id: 75,
      date: "6 Aug 1962",
      time: 0,
      type: 0,
      title: "Jamaica Independence Day",
      description:
        "Jamaica (/dʒəˈmeɪkə/ i; Jamaican Patois: Jumieka, [dʒʌˈmʲeːka]) is an island country situated in the Caribbean Sea. Spanning 10,990 square kilometres (4,240 sq mi) in area, it is the third largest island — after Cuba and Hispaniola — of the Greater Antilles and the Caribbean.[11] Jamaica lies about 145 km (90 mi) south of Cuba, and 191 km (119 mi) west of Hispaniola (the island containing the countries of Haiti and the Dominican Republic); the British Overseas Territory of the Cayman Islands lies 215 km (134 mi) to the north-west.[11]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1962%2F08%2F06%2Fthumbs%2F75-Flag_of_Jamaica.svg_600x600.jpeg?alt=media&token=4de8dc54-613c-4012-8ff7-b034fdbd5ac7",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1962%2F08%2F06%2F75-Flag_of_Jamaica.svg.png?alt=media&token=637ddc4d-39d0-4630-8e22-72d17b9a2734",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Jamaica",
    },
    {
      id: 76,
      date: "9 May 1945",
      time: 0,
      type: 0,
      title: "Jersey Liberation Day",
      description:
        "Jersey (/ˈdʒɜːrzi/ JUR-zee; Jèrriais: Jèrri [ʒɛri]), also known as the Bailiwick of Jersey,[d][12][13][14] is an island country and self-governing British Crown Dependency near the coast of north-west France.[15][16][17] It is the largest of the Channel Islands and is 14 miles (23 km) from the Cotentin Peninsula in Normandy.[18] The Bailiwick consists of the main island of Jersey and some surrounding uninhabited islands and rocks including Les Dirouilles, Les Écréhous, Les Minquiers, and Les Pierres de Lecq.[19]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1945%2F05%2F09%2Fthumbs%2F76-Flag_of_Jersey.svg_600x600.jpeg?alt=media&token=9d116748-d8c2-463f-a87a-66bfcd02ea4f",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1945%2F05%2F09%2F76-Flag_of_Jersey.svg.png?alt=media&token=33a44c72-f246-46b9-ab50-392ae49ce71d",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Jersey",
    },
    {
      id: 77,
      date: "25 May 1946",
      time: 0,
      type: 0,
      title: "Jordan Independence Day",
      description:
        "Jordan (Arabic: الأردن, romanized: al-ʾUrdunn [al.ʔur.dunː]), officially the Hashemite Kingdom of Jordan,[a] is a country in West Asia. It is situated at the crossroads of Asia, Africa, and Europe,[8] within the Levant region, on the East Bank of the Jordan River. Jordan is bordered by Saudi Arabia to the south and east, Iraq to the northeast, Syria to the north, and the Palestinian West Bank and Israel to the west. The Dead Sea is located along its western border and the country has a 26 km (16 mi) coastline in its southwest on the Gulf of Aqaba's Red Sea, which separates Jordan from Egypt.[9] Amman is Jordan's capital and largest city, as well as its economic, political, and cultural centre.[10]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1946%2F05%2F25%2Fthumbs%2F77-Flag_of_Jordan.svg_600x600.jpeg?alt=media&token=f969be9c-f3a3-4963-99cd-29a5389daf76",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1946%2F05%2F25%2F77-Flag_of_Jordan.svg.png?alt=media&token=9d91f6bd-dcef-4c70-a516-6f67443de03b",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Jordan",
    },
    {
      id: 78,
      date: "16 Dec 1991",
      time: 0,
      type: 0,
      title: "Kazakhstan Independence Day",
      description:
        "Kazakhstan,[c] officially the Republic of Kazakhstan,[d] is a landlocked country located mainly in Central Asia and partly in Eastern Europe.[e] It borders Russia to the north and west, China to the east, Kyrgyzstan to the southeast, Uzbekistan to the south, and Turkmenistan to the southwest, with a coastline along the Caspian Sea. Its capital is Astana, known as Nur-Sultan from 2019 to 2022. Almaty, Kazakhstan's largest city, was the country's capital until 1997.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1991%2F12%2F16%2Fthumbs%2F78-Flag_of_Kazakhstan.svg_600x600.jpeg?alt=media&token=a77d975a-f865-4428-b167-411a93420067",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1991%2F12%2F16%2F78-Flag_of_Kazakhstan.svg.png?alt=media&token=c9eedccc-f497-4d59-8c2c-0c99c5b69ff3",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Kazakhstan",
    },
    {
      id: 79,
      date: "12 Dec 1963",
      time: 0,
      type: 0,
      title: "Kenya Independence Day",
      description:
        "Kenya, officially the Republic of Kenya (Swahili: Jamhuri ya Kenya), is a country in East Africa. A member of the Commonwealth with a population of more than 47.6 million in the 2019 census,[11] Kenya is the 28th most populous country in the world[6] and 7th most populous in Africa. Kenya's capital and largest city is Nairobi, while its oldest and second largest city, which until 1907 was also Kenya's first capital city, is the coastal city of Mombasa which includes Mombasa Island in the Indian Ocean and the surrounding mainland. Kisumu is the third-largest city and also an inland port in the Winam Gulf which, along with its numerous bays and human settlements, is one of the important maritime transport, fishing, farming, commercial, history and tourism hubs on Lake Victoria. As of 2020, Kenya is the third-largest economy in sub-Saharan Africa after Nigeria and South Africa.[12] Kenya is bordered by South Sudan to the northwest, Ethiopia to the north, Somalia to the east, Uganda to the west, Tanzania to the south, and the Indian Ocean to the southeast. Its geography, climate and population vary widely, ranging from cold snow-capped mountaintops (Batian, Nelion and Point Lenana on Mount Kenya) with vast surrounding forests, wildlife and fertile agricultural regions to temperate climates in western and rift valley counties and further on to dry less fertile arid and semi-arid areas and absolute deserts (Chalbi Desert and Nyiri Desert).",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1963%2F12%2F12%2Fthumbs%2F79-Flag_of_Kenya.svg_600x600.jpeg?alt=media&token=5b2fa0c9-f50f-478b-9368-4f6fc1f08a77",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1963%2F12%2F12%2F79-Flag_of_Kenya.svg.png?alt=media&token=d32cda1e-981f-4ca1-a60b-2fdcf8d00d33",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Kenya",
    },
    {
      id: 80,
      date: "12 Jul 1979",
      time: 0,
      type: 0,
      title: "Kiribati Independence Day",
      description:
        "Kiribati (/ˌkɪrɪˈbæs/ i KIRR-ih-BASS),[7] officially the Republic of Kiribati (Gilbertese: [Ribaberiki] Kiribati),[8][9][10] is an island country in the Micronesia subregion of Oceania in the central Pacific Ocean. Its permanent population is over 119,000 as of the 2020 census, with more than half living on Tarawa atoll. The state comprises 32 atolls and one remote raised coral island, Banaba. Its total land area is 811 km2 (313 sq mi)[11] dispersed over 3,441,810 km2 (1,328,890 sq mi) of ocean.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1979%2F07%2F12%2Fthumbs%2F80-Flag_of_Kiribati.svg_600x600.jpeg?alt=media&token=ed70ac8b-6a87-4915-a8a8-9e4df87912b8",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1979%2F07%2F12%2F80-Flag_of_Kiribati.svg.png?alt=media&token=82ca5fa5-7cca-4f7d-91cc-8827f0c37579",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Kiribati",
    },
    {
      id: 81,
      date: "17 Feb 2008",
      time: 0,
      type: 0,
      title: "Kosovo Independence Day",
      description:
        "Kosovo (Albanian: Kosova [kɔˈsɔva]; Serbian Cyrillic: Косово [kôsoʋo]), officially the Republic of Kosovo (Albanian: Republika e Kosovës; Serbian: Република Косово, romanized: Republika Kosovo), is a country in Southeast Europe with partial diplomatic recognition. Kosovo lies landlocked in the centre of the Balkans, bordered by Serbia to the north and east, North Macedonia to the southeast, Albania to the southwest, and Montenegro to the west. Most of central Kosovo is dominated by the vast plains and fields of Metohija and the Kosovo field. The Accursed Mountains and Šar Mountains rise in the southwest and southeast, respectively. Its capital and largest city is Pristina.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2008%2F02%2F17%2Fthumbs%2F81-Flag_of_Kosovo.svg_600x600.jpeg?alt=media&token=41435dce-f232-4f38-828b-9a61e2413361",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2008%2F02%2F17%2F81-Flag_of_Kosovo.svg.png?alt=media&token=39bc2771-4f17-44b9-a45e-665300a4e6b0",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Kosovo",
    },
    {
      id: 82,
      date: "31 Aug 1991",
      time: 0,
      type: 0,
      title: "Kyrgystan Independence Day",
      description:
        "Kyrgyzstan,[a] officially the Kyrgyz Republic,[b][10] is a landlocked country in Central Asia, lying in the Tian Shan and Pamir mountain ranges. Bishkek is the capital and largest city of the country. Kyrgyzstan is bordered by Kazakhstan to the north, Uzbekistan to the west, Tajikistan to the south, and China to the east and southeast.[11][12][13] Ethnic Kyrgyz make up the majority of the country's seven million people, followed by significant minorities of Uzbeks and Russians.[14]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1991%2F08%2F31%2Fthumbs%2F82-Flag_of_Kyrgyzstan.svg_600x600.jpeg?alt=media&token=4afb5ba3-f2a5-4aa7-83f3-8fa4ef35f9c9",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1991%2F08%2F31%2F82-Flag_of_Kyrgyzstan.svg.png?alt=media&token=f9fcf9c7-3410-4b32-b821-6f52d78b0def",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Kyrgyzstan",
    },
    {
      id: 83,
      date: "2 Dec 1975",
      time: 0,
      type: 0,
      title: "Laos National Day",
      description:
        "Laos (/ˈlɑːoʊs/ i[9][d]), officially the Lao People's Democratic Republic (Lao PDR or LPDR),[e] is a landlocked country in Southeast Asia. At the heart of the Indochinese Peninsula, Laos is bordered by Myanmar and China to the northwest, Vietnam to the east, Cambodia to the southeast, and Thailand to the west and southwest.[12] Its capital and largest city is Vientiane.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1975%2F12%2F02%2Fthumbs%2F83-Flag_of_Laos.svg_600x600.jpeg?alt=media&token=9abd49ca-feb9-4901-abfb-233feb98fdca",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1975%2F12%2F02%2F83-Flag_of_Laos.svg.png?alt=media&token=dc5e7f21-0001-4e7a-9569-0ec10ba5adf9",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Laos",
    },
    {
      id: 84,
      date: "18 Nov 1918",
      time: 0,
      type: 0,
      title: "Proclamation Day of the Republic of Latvia",
      description:
        "Latvia (/ˈlætviə/ i LAT-vee-ə, sometimes /ˈlɑːtviə/ LAHT-vee-ə; Latvian: Latvija Latvian pronunciation: [ˈlatvija]; Latgalian: Latveja; Livonian: Lețmō), officially the Republic of Latvia[14] (Latvian: Latvijas Republika, Latgalian: Latvejas Republika, Livonian: Lețmō Vabāmō), is a country in the Baltic region of Northern Europe. It is one of the Baltic states. It borders Estonia to the north, Lithuania to the south, Russia to the east, and Belarus to the southeast and shares a maritime border with Sweden to the west. Latvia covers an area of 64,589 km2 (24,938 sq mi), with a population of 1.9 million. The country has a temperate seasonal climate.[15] Its capital and largest city is Riga. Latvians belong to the ethno-linguistic group of the Balts and speak Latvian, one of the only two[a] surviving Baltic languages. Russians are the most prominent minority in the country, at almost a quarter of the population.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1918%2F11%2F18%2Fthumbs%2F84-Flag_of_Latvia.svg_600x600.jpeg?alt=media&token=0a66bbe4-8ed6-4d30-9cd6-a6acfd872c3d",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1918%2F11%2F18%2F84-Flag_of_Latvia.svg.png?alt=media&token=19e5cc1a-f187-4525-a049-5ac322e11052",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Latvia",
    },
    {
      id: 85,
      date: "22 Nov 1943",
      time: 0,
      type: 0,
      title: "Lebanese Independence Day",
      description:
        "Lebanon (/ˈlɛbənɒn, -nən/ i LEB-ə-non, -⁠nən; Arabic: لُبْنَان Lubnān [lɪbˈneːn]; French: Liban), officially the Republic of Lebanon[b] (الجمهورية اللبنانية al-Jumhūrīyah al-Lubnānīyah; République libanaise), is a country in West Asia. It is bordered by Syria to the north and east, by Israel to the south, and by the Mediterranean Sea to the west. Cyprus lies a short distance away from the country's Mediterranean coastline. Lebanon's location at the crossroads of the Mediterranean Basin, between Europe and the Middle East, has contributed to the country's rich history and shaped a unique cultural identity denoted by religious diversity.[17] Located in the Levant, the country has a population of more than five million people and covers an area of 10,452 square kilometres (4,036 sq mi), making it the second-smallest Asian country. The capital and largest city is Beirut, followed by Tripoli and Jounieh. While Arabic is the official language, French is also recognized in a formal capacity; Lebanese Arabic is the country's vernacular, though French and English play a relatively significant role in everyday life,[18] with Modern Standard Arabic being limited to news and government matters.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1943%2F11%2F22%2Fthumbs%2F85-Flag_of_Lebanon.svg_600x600.jpeg?alt=media&token=934f834f-b75b-41cf-a832-1c08513a84a5",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1943%2F11%2F22%2F85-Flag_of_Lebanon.svg.png?alt=media&token=5ef77d36-7037-44f9-88c7-ca0bdc7ee04b",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Lebanon",
    },
    {
      id: 86,
      date: "4 Oct 1966",
      time: 0,
      type: 0,
      title: "Lesotho Independence Day",
      description:
        "Lesotho (/lɪˈsuːtuː/ i lih-SOO-too,[6][7] Sotho pronunciation: [lɪˈsʊːtʰʊ]), formally the Kingdom of Lesotho, is a landlocked country in Southern Africa. As an enclave of South Africa, with which it shares a 1,106 km border,[8] it is the only sovereign enclave in the world outside of the Italian peninsula. It is situated in the Maloti Mountains and contains the highest peak in Southern Africa.[9] It has an area of over 30,000 km2 (11,600 sq mi) and has",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1966%2F10%2F04%2Fthumbs%2F86-Flag_of_Lesotho.svg_600x600.jpeg?alt=media&token=4143b635-a950-46ec-933e-3c2706500633",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1966%2F10%2F04%2F86-Flag_of_Lesotho.svg.png?alt=media&token=4b3447fc-ba47-4ef8-82cb-5066aafa8fc0",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Lesotho",
    },
    {
      id: 87,
      date: "24 Dec 1951",
      time: 0,
      type: 0,
      title: "Libyan Indpendence",
      description:
        "Libya (/ˈlɪbiə/ i LIB-ee-ə; Arabic: ليبيا, romanized: Lībiyā, pronounced [liː.bi.jæː]), officially the State of Libya (Arabic: دولة ليبيا, romanized: Dawlat Lībiyā),[7][8][9][10] is a country in the Maghreb region of North Africa. It is bordered by the Mediterranean Sea to the north, Egypt to the east, Sudan to the southeast, Chad to the south, Niger to the southwest, Algeria to the west, and Tunisia to the northwest. Libya comprises three historical regions: Tripolitania, Fezzan, and Cyrenaica. With an area of almost 1.8 million km2 (700,000 sq mi), it is the fourth-largest country in Africa and the Arab world, and the 16th-largest in the world.[11] The country's official religion is Islam, with 96.6% of the Libyan population being Sunni Muslims. The official language of Libya is Arabic, with vernacular Libyan Arabic being spoken most widely. The majority of Libya's population is Arab.[12] The largest city and capital, Tripoli, is located in north-western Libya and contains over a million of Libya's seven million people.[13]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1951%2F12%2F24%2Fthumbs%2F87-Flag_of_Libya.svg_600x600.jpeg?alt=media&token=06a471ea-ccbe-463b-9f92-cceda99febf9",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1951%2F12%2F24%2F87-Flag_of_Libya.svg.png?alt=media&token=a0ee69f3-733a-4b74-92f5-1bed84d6ab07",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Libya",
    },
    {
      id: 88,
      date: "16 Feb 1918",
      time: 0,
      type: 0,
      title: "Lithuanian State Reestablishment Day",
      description:
        "Lithuania (/ˌlɪθjuˈeɪniə/ i LITH-yoo-AYN-ee-ə;[13] Lithuanian: Lietuva [lʲɪɛtʊˈvɐ]), officially the Republic of Lithuania (Lithuanian: Lietuvos Respublika [lʲɪɛtʊˈvoːs rʲɛsˈpʊblʲɪkɐ]), is a country in the Baltic region of Europe.[a] It is one of three Baltic states and lies on the eastern shore of the Baltic Sea. Lithuania shares land borders with Latvia to the north, Belarus to the east and south, Poland to the south, and Russia to the southwest.[b] It has a maritime border with Sweden to the west on the Baltic Sea. Lithuania covers an area of 65,300 km2 (25,200 sq mi), with a population of 2.86 million. Its capital and largest city is Vilnius; other major cities are Kaunas, Klaipėda, Šiauliai and Panevėžys. Lithuanians belong to the ethno-linguistic group of the Balts and speak Lithuanian, one of only a few living Baltic languages.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1918%2F02%2F16%2Fthumbs%2F88-Flag_of_Lithuania.svg_600x600.jpeg?alt=media&token=0c121779-6973-4658-aa2c-9cd58a1c634d",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1918%2F02%2F16%2F88-Flag_of_Lithuania.svg.png?alt=media&token=8039a4d0-ad31-4d26-a5cf-bb7034cb3b44",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Lithuania",
    },
    {
      id: 89,
      date: "26 Jun 1960",
      time: 0,
      type: 0,
      title: "Madagascar Independence Day",
      description:
        "Madagascar, officially the Republic of Madagascar,[a] is an island country lying off the southeastern coast of Africa. It is the world's fourth largest island, the second-largest island country and the 46th largest country in the world.[14] Its capital and largest city is Antananarivo.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F06%2F26%2Fthumbs%2F89-Flag_of_Madagascar.svg_600x600.jpeg?alt=media&token=7636d47c-bcf6-4a72-b213-4bb6df6a9f2e",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F06%2F26%2F89-Flag_of_Madagascar.svg.png?alt=media&token=e8cd4a8e-e8f7-4f97-ba88-a47682297732",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Madagascar",
    },
    {
      id: 90,
      date: "1 Jul 1976",
      time: 0,
      type: 0,
      title: "Madeira Day",
      description:
        "Madeira (/məˈdɪərə/, /məˈdɛərə/,[3][4][5] Portuguese: [mɐˈðɐjɾɐ]), officially the Autonomous Region of Madeira (Portuguese: Região Autónoma da Madeira), is one of two autonomous regions of Portugal, the other being the Azores. It is an archipelago situated in the North Atlantic Ocean, in a region known as Macaronesia, just under 400 kilometres (250 mi) to the north of the Canary Islands and 520 kilometres (320 mi) west of the Kingdom of Morocco.[6][7] Madeira is geologically located on the African Tectonic Plate, although it is culturally, politically and ethnically associated with Europe, with its population predominantly descended from original Portuguese settlers.[8][9][10] Its population was 251,060 in 2021. The capital of Madeira is Funchal, which is located on the main island's south coast.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1976%2F07%2F01%2Fthumbs%2F90-Flag_of_Madeira.svg_600x600.jpeg?alt=media&token=0ad94714-d15a-4ede-9f8f-301c850dd1a1",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1976%2F07%2F01%2F90-Flag_of_Madeira.svg.png?alt=media&token=75331657-2101-4759-ba92-31cb9135f357",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Madeira",
    },
    {
      id: 91,
      date: "6 Jul 1966",
      time: 0,
      type: 0,
      title: "Malawi Independence Day",
      description:
        "Malawi (/məˈlɔːwi, məˈlɑːwi, ˈmæləwi/; Chichewa pronunciation: [maláβi] or [maláwi]; Tumbuka: Malaŵi),[9] officially the Republic of Malawi, is a landlocked country in Southeastern Africa that was formerly known as Nyasaland. It is bordered by Zambia to the west, Tanzania to the north and northeast, and Mozambique to the east, south and southwest. Malawi spans over 118,484 km2 (45,747 sq mi) and has an estimated population of 19,431,566 (as of January 2021).[10] Malawi's capital (and largest city) is Lilongwe. Its second-largest is Blantyre, its third-largest is Mzuzu and its fourth-largest is its former capital, Zomba. The name Malawi comes from the Maravi, an old name for the Chewa people who inhabit the area. The country is nicknamed - The Warm Heart of Africa - because of the friendliness of its people.[11]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1966%2F07%2F06%2Fthumbs%2F91-Flag_of_Malawi.svg_600x600.jpeg?alt=media&token=0a6a7d3e-bce7-4190-8237-b62326b2f0e9",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1966%2F07%2F06%2F91-Flag_of_Malawi.svg.png?alt=media&token=53183542-1322-429f-bef3-08edd72d4fc1",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Malawi",
    },
    {
      id: 92,
      date: "31 Aug 1957",
      time: 0,
      type: 0,
      title: "Malaysia Hari Kebangsaan (National Day)",
      description:
        "Malaysia (/məˈleɪziə, -ʒə/ i mə-LAY-zee-ə, -⁠zhə; Malay: [malɛjsia]) is a country in Southeast Asia. The federal constitutional monarchy consists of thirteen states and three federal territories, separated by the South China Sea into two regions: Peninsular Malaysia and Borneo's East Malaysia. Peninsular Malaysia shares a land and maritime border with Thailand and maritime borders with Singapore, Vietnam, and Indonesia. East Malaysia shares land and maritime borders with Brunei and Indonesia, as well as a maritime border with the Philippines and Vietnam. Kuala Lumpur is the national capital, the country's largest city, and the seat of the legislative branch of the federal government. Putrajaya is the country's administrative centre, which represents the seat of both the executive branch (the Cabinet and federal ministries and agencies) and the judicial branch of the federal government. With a population of over 33 million, The country is the world's 43rd-most populous country. The southernmost point of continental Eurasia is Tanjung Piai. Located in the tropics, the country is one of 17 megadiverse countries and home to numerous endemic species.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1957%2F08%2F31%2Fthumbs%2F92-Flag_of_Malaysia.svg_600x600.jpeg?alt=media&token=f4e6aa6d-8ab5-415a-87ff-6d0af1cd855c",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1957%2F08%2F31%2F92-Flag_of_Malaysia.svg.png?alt=media&token=c7caff1a-1ebb-42d1-93ab-4846cfba78ea",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Malaysia",
    },
    {
      id: 93,
      date: "16 Sep 1963",
      time: 0,
      type: 0,
      title: "Malaysia Day",
      description:
        "A picture of the 2011 simultaneous Malaysia Day and Hari Merdeka celebrations that was held at the Dataran Merdeka in Kuala Lumpur.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1963%2F09%2F16%2Fthumbs%2F93-Hari_Malaysia_celebration_in_2011_600x600.jpeg?alt=media&token=9a995c20-764a-4a0c-a6e1-570f7e86c417",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1963%2F09%2F16%2F93-Hari_Malaysia_celebration_in_2011.jpeg?alt=media&token=8471ebc0-d091-4335-830c-f000eeacd1a2",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Malaysia_Day",
    },
    {
      id: 94,
      date: "22 Sep 1960",
      time: 0,
      type: 0,
      title: "Mali Independence Day",
      description:
        "Mali[b] (/ˈmɑːli/ i; French pronunciation: ​[mali]), officially the Republic of Mali,[c] is a landlocked country in West Africa. Mali is the eighth-largest country in Africa, with an area of over 1,241,238 square kilometres (479,245 sq mi). The country is bordered on the north by Algeria, on the east by Niger, on the northwest by Mauritania, on the south by Burkina Faso and Côte d'Ivoire, and on the west by Guinea and Senegal. The population of Mali is 21.9 million.[10][11] 67% of its population was estimated to be under the age of 25 in 2017.[12] Its capital and largest city is Bamako. It has 13 official languages, of which Bambara is the most spoken one.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F09%2F22%2Fthumbs%2F94-Flag_of_Mali.svg_600x600.jpeg?alt=media&token=5d157787-d733-4b19-a978-474837afe247",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F09%2F22%2F94-Flag_of_Mali.svg.png?alt=media&token=c86a8760-82a0-4a16-a1d3-8592d6599d99",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Mali",
    },
    {
      id: 95,
      date: "21 Sep 1964",
      time: 0,
      type: 0,
      title: "Malta Independence Day",
      description:
        "Malta (/ˈmɒltə/ i MOL-tə, /ˈmɔːltə/ MAWL-tə, Maltese: [ˈmɐːltɐ]), officially the Republic of Malta (Maltese: Repubblika ta' Malta [rɛˈpʊbːlɪkɐ tɐ ˈmɐːltɐ]), is an island country in Southern Europe, located in the Mediterranean Sea. It consists of an archipelago between Italy and Libya.[13] It lies 80 km (50 mi) south of Sicily (Italy), 284 km (176 mi) east of Tunisia,[14] and 333 km (207 mi) north of Libya.[15] The official languages are Maltese and English, and 66% of the population is at least conversant in Italian.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1964%2F09%2F21%2Fthumbs%2F95-Flag_of_Malta.svg_600x600.jpeg?alt=media&token=deae32f8-3d82-4359-9441-ad09c9e822bb",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1964%2F09%2F21%2F95-Flag_of_Malta.svg.png?alt=media&token=34b59ccb-7fb6-4a1a-babb-7a951417a67f",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Malta",
    },
    {
      id: 96,
      date: "1 May 1979",
      time: 0,
      type: 0,
      title: "Marshall Islands Constitution Day",
      description:
        "The Marshall Islands (Marshallese: Ṃajeḷ),[9] officially the Republic of the Marshall Islands (Marshallese: Aolepān Aorōkin Ṃajeḷ),[note 1] is an island country near Kiribati in the Pacific Ocean, slightly west of the International Date Line and just north of the equator. Geographically, the country is part of the larger island group of Micronesia.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1979%2F05%2F01%2Fthumbs%2F96-Flag_of_the_Marshall_Islands.svg_600x600.jpeg?alt=media&token=4f9d924c-95db-4888-8f5f-e59d4e451051",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1979%2F05%2F01%2F96-Flag_of_the_Marshall_Islands.svg.png?alt=media&token=4888d76c-813a-492f-a900-d72107ae82ff",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Marshall_Islands",
    },
    {
      id: 97,
      date: "28 Nov 1960",
      time: 0,
      type: 0,
      title: "Mauritania Independence Day",
      description:
        "Mauritania,[a] officially the Islamic Republic of Mauritania (Arabic: الجمهورية الإسلامية الموريتانية), is a sovereign country in Northwest Africa. It is bordered by the Atlantic Ocean to the west, Western Sahara to the north and northwest, Algeria to the northeast, Mali to the east and southeast, and Senegal to the southwest. Mauritania is the 11th-largest country in Africa and the 28th-largest in the world, and 90% of its territory is situated in the Sahara. Most of its population of 4.4 million lives in the temperate south of the country, with roughly one-third",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F11%2F28%2Fthumbs%2F97-Flag_of_Mauritania.svg_600x600.jpeg?alt=media&token=9c6c9cb4-6173-444c-bb2a-4866f2e839ee",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F11%2F28%2F97-Flag_of_Mauritania.svg.png?alt=media&token=f7e5e99b-3927-4c0d-a1da-150f7c958cce",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Mauritania",
    },
    {
      id: 98,
      date: "12 Mar 1968",
      time: 0,
      type: 0,
      title: "Mauritius Independence Day",
      description:
        "Mauritius (/məˈrɪʃ(i)əs, mɔː-/ i mər-ISH-(ee-)əs, mor-; French: Maurice [mɔʁis, moʁis] i; Mauritian Creole: Moris [moʁis]), officially the Republic of Mauritius (French: République de Maurice; Mauritian Creole: Repiblik Moris), is an Indian Ocean island country, approximately 2,000 kilometres (1,100 nautical miles) off the southeastern coast of East Africa, east of Madagascar. It includes the main island (also called Mauritius), as well as Rodrigues, Agaléga and St. Brandon.[11][12] The islands of Mauritius and Rodrigues, along with nearby Réunion (a French overseas department), are part of the Mascarene Islands. The main island of Mauritius, where most of the population is concentrated, hosts the capital and largest city, Port Louis. The country spans 2,040 square kilometres (790 sq mi) and has an exclusive economic zone covering 2,300,000 square kilometres (670,000 square nautical miles).[13] Mauritius is one of the most densely populated countries in the world.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1968%2F03%2F12%2Fthumbs%2F98-Flag_of_Mauritius.svg_600x600.jpeg?alt=media&token=3d7a60c9-122a-45ad-99f9-8874dc4c1409",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1968%2F03%2F12%2F98-Flag_of_Mauritius.svg.png?alt=media&token=cf5718ee-259e-4361-8f80-c0ec0f299def",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Mauritius",
    },
    {
      id: 99,
      date: "3 Nov 1979",
      time: 0,
      type: 0,
      title: "Micronesia Independence Day",
      description:
        "The Federated States of Micronesia (/ˌmaɪkroʊˈniːʒə/ i; abbreviated FSM), or simply Micronesia, is an island country in Micronesia, a subregion of Oceania. It consists of four states—from west to east, Yap, Chuuk, Pohnpei and Kosrae—that are spread across the western Pacific. Together, the states comprise around 607 islands (a combined land area of approximately 702 km2 or 271 sq mi) that cover a longitudinal distance of almost 2,700 km (1,700 mi) just north of the equator. They lie northeast of Indonesia and Papua New Guinea, south of Guam and the Marianas, west of Nauru and the Marshall Islands, east of Palau and the Philippines, about 2,900 km (1,800 mi) north of eastern Australia, 3,400 km (2,100 mi) southeast of Japan, and some 4,",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1979%2F11%2F03%2Fthumbs%2F99-Flag_of_the_Federated_States_of_Micronesia.svg_600x600.jpeg?alt=media&token=2d9742a2-996d-48fa-9236-f433741f2290",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1979%2F11%2F03%2F99-Flag_of_the_Federated_States_of_Micronesia.svg.png?alt=media&token=7bb36444-23be-4e4a-8714-63cebc7fcafa",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Federated_States_of_Micronesia",
    },
    {
      id: 100,
      date: "27 Aug 1991",
      time: 0,
      type: 0,
      title: "Moldova Independence Day",
      description:
        "Moldova (/mɒlˈdoʊvə/ i mol-DOH-və, sometimes UK: /ˈmɒldəvə/ MOL-də-və;[13][14][15] Romanian pronunciation: [molˈdova]), officially the Republic of Moldova (Romanian: Republica Moldova), is a landlocked country in Eastern Europe, on the northeastern corner of the Balkans.[16] The country spans a total of 33,483 km2 (13,067 sq mi) and has a population of approximately 2.5 million as of January 2023.[17] Moldova is bordered by Romania to the west and Ukraine to the north, east, and south.[18] The unrecognised breakaway state of Transnistria lies across the Dniester river on the country's eastern border with Ukraine. Moldova is a unitary parliamentary representative democratic republic with its capital in Chișinău, the country's largest city and main cultural and commercial centre.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1991%2F08%2F27%2Fthumbs%2F100-Flag_of_Moldova.svg_600x600.jpeg?alt=media&token=5f263a43-ffae-4c2e-9636-d4ce1f069e6c",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1991%2F08%2F27%2F100-Flag_of_Moldova.svg.png?alt=media&token=b44b5b43-cbfb-40e6-820f-5baa4890df06",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Moldova",
    },
    {
      id: 101,
      date: "29 Dec 1911",
      time: 0,
      type: 0,
      title: "Mongolia Independence Day",
      description:
        "Mongolia[c] (/mɒŋˈɡoʊliə/ i mong-GOH-lee-ə) is a landlocked country in East Asia, bordered by Russia to the north and China to the south. It covers an area of 1,564,116 square kilometres (603,909 square miles), with a population of just 3.3 million, making it the world's most sparsely populated sovereign state. Mongolia is the world's largest landlocked country that does not border a closed sea, and much of its area is covered by grassy steppe, with mountains to the north and west and the Gobi Desert to the south. Ulaanbaatar, the capital and largest city, is home to roughly half of the country's population.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1911%2F12%2F29%2Fthumbs%2F101-Flag_of_Mongolia.svg_600x600.jpeg?alt=media&token=c1396ef5-ba94-4950-b817-89684a61cdc0",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1911%2F12%2F29%2F101-Flag_of_Mongolia.svg.png?alt=media&token=e5078a16-0d98-41a7-88c9-0f4336ea5343",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Mongolia",
    },
    {
      id: 102,
      date: "26 Nov 1924",
      time: 0,
      type: 0,
      title: "Mongolian People's Republic",
      description:
        "The Mongolian People's Republic (MPR; Mongolian: Бүгд Найрамдах Монгол Ард Улс, БНМАУ, Bügd Nairamdakh Mongol Ard Uls) was a socialist state which existed from 1924 to 1992, located in the historical region of Outer Mongolia in East Asia. Until 1990, it was a one-party state ruled by the Mongolian People's Revolutionary Party, and maintained close political and economic ties with the Soviet Union, as part of the Eastern Bloc. Socialist collectivization, industrialization, and urbanization transformed Mongolia's agrarian, nomadic economy of the 1920s into a developing, agricultural-industrial economy by the late 1980s.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1924%2F11%2F26%2Fthumbs%2F102-Flag_of_the_Mongolian_People's_Republic_(1945%E2%80%931992).svg_600x600.jpeg?alt=media&token=5a1b4362-aa35-48f0-925f-d9a57f5a3615",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1924%2F11%2F26%2F102-Flag_of_the_Mongolian_People's_Republic_(1945%E2%80%931992).svg.png?alt=media&token=44461030-7a73-438b-a1e7-4a8cbe42969d",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Mongolian_People%27s_Republic",
    },
    {
      id: 103,
      date: "21 May 2006",
      time: 0,
      type: 0,
      title: "Montenegro Independence Day",
      description:
        "Montenegro (/ˌmɒntɪˈniːɡroʊ, -ˈneɪɡroʊ, -ˈnɛɡroʊ/ i MON-tih-NEEG-roh, -⁠NAYG-roh, -⁠NEG-roh;[9] Montenegrin: Crna Gora,[a][b] Црна Гора,[c] lit. 'Black Mountain')[10][11] is a country in Southeast Europe.[12] It is bordered by Bosnia and Herzegovina to the north, Serbia to the northeast, Kosovo to the east, Albania to the southeast, and Croatia and the Adriatic Sea to the northwest with a coastline of 293.5 km.[13] Podgorica (Cyrillic: Подгорица) is the country's capital and its largest city, it covers 10.4% of Montenegro's territory of 13,812 square kilometres (5,333 sq mi), and is home to roughly 31% of its total population of 621,000.[14] Cetinje (Cyrillic: Цетиње) is the former royal capital and cultural centre of Montenegro and is the location of several national institutions, including the official residence of the President of Montenegro.[15]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2006%2F05%2F21%2Fthumbs%2F103-Flag_of_Montenegro.svg_600x600.jpeg?alt=media&token=1ce4d40a-ad33-4ddb-9daf-3b5b0961d9df",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2006%2F05%2F21%2F103-Flag_of_Montenegro.svg.png?alt=media&token=97c01074-961b-456c-96c2-678081bf03ad",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Montenegro",
    },
    {
      id: 104,
      date: "25 Jun 1975",
      time: 0,
      type: 0,
      title: "Mozambique Independence Day",
      description:
        "Mozambique (/ˌmoʊzæmˈbiːk/; Portuguese: Moçambique, pronounced [musɐ̃ˈbikɨ]; Chichewa: Mozambiki; Swahili: Msumbiji; Tsonga: Muzambhiki), officially the Republic of Mozambique (República de Moçambique, pronounced [ʁɛˈpuβlikɐ ðɨ musɐ̃ˈbikɨ]), is a country located in southeastern Africa bordered by the Indian Ocean to the east, Tanzania to the north, Malawi and Zambia to the northwest, Zimbabwe to the west, and Eswatini and South Africa to the southwest. The sovereign state is separated from the Comoros, Mayotte and Madagascar",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1975%2F06%2F25%2Fthumbs%2F104-Flag_of_Mozambique.svg_600x600.jpeg?alt=media&token=081fd584-69b2-4155-84f5-11c7f74f465d",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1975%2F06%2F25%2F104-Flag_of_Mozambique.svg.png?alt=media&token=21d851ad-736f-4c33-9bd5-718bb541ad19",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Mozambique",
    },
    {
      id: 105,
      date: "21 Mar 1990",
      time: 0,
      type: 0,
      title: "Namibia Independence Day",
      description:
        "Namibia (/nəˈmɪbiə/ i, /næˈ-/),[15][16] officially the Republic of Namibia, is a country in Southern Africa. Its western border is the Atlantic Ocean. It shares land borders with Zambia and Angola to the north, Botswana to the east and South Africa to the south and east. Although it does not border Zimbabwe, less than 200 metres (660 feet) of the Botswanan right bank of the Zambezi River separates the two countries. Namibia gained independence from South Africa on 21 March 1990, following the Namibian War of Independence. Its capital and largest city is Windhoek. Namibia is a member state of the United Nations (UN), the Southern African Development Community (SADC), the African Union (AU) and the Commonwealth of Nations.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1990%2F03%2F21%2Fthumbs%2F105-Flag_of_Namibia.svg_600x600.jpeg?alt=media&token=e6a7c537-9162-4588-84a3-859c43bfb230",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1990%2F03%2F21%2F105-Flag_of_Namibia.svg.png?alt=media&token=d1241b44-dc2f-41eb-9019-0b82d3aa17a9",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Namibia",
    },
    {
      id: 106,
      date: "31 Jan 1968",
      time: 0,
      type: 0,
      title: "Nauru Independence Day",
      description:
        "Nauru (/nɑːˈuːruː/ nah-OO-roo[11] or /ˈnaʊruː/ NOW-roo;[12] Nauruan: Naoero), officially the Republic of Nauru (Nauruan: Repubrikin Naoero) and formerly known as Pleasant Island, is an island country and microstate in Micronesia, part of Oceania in the Central Pacific. Its nearest neighbour is Banaba of Kiribati, about 300 km (190 mi) to the east.[13] It lies northwest of Tuvalu, 1,300 km (810 mi) northeast of Solomon Islands,[14] east-northeast of Papua New Guinea, southeast of the Federated States of Micronesia and south of the Marshall Islands. With an area of only 21 km2 (8.1 sq mi), Nauru is the third-smallest country in the world behind Vatican City and Monaco, making it the smallest republic and island nation. Its population of about 10,800 is the world's second-smallest (not including colonies or overseas territories) after Vatican City. Settled by people from Micronesia circa 1000 BCE, Nauru was annexed and claimed as a colony by the German Empire in the late 19th century. After World War I, Nauru became a League of Nations mandate administered by Australia, New Zealand, and the United Kingdom. During World War II, Nauru was occupied by Japanese troops, and was bypassed by the Allied advance across the Pacific. ",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1968%2F01%2F31%2Fthumbs%2F106-Flag_of_Nauru.svg_600x600.jpeg?alt=media&token=d5ef0dde-043c-41c0-9f67-840f780e220e",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1968%2F01%2F31%2F106-Flag_of_Nauru.svg.png?alt=media&token=6104b574-0d56-4143-a19e-9ffb9536f522",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Nauru",
    },
    {
      id: 107,
      date: "19 Sep 2015",
      time: 0,
      type: 0,
      title: "Nepal Constitution Day",
      description:
        "Nepal (English: /nɪˈpɔːl/;[14] Nepali: नेपाल [nepal]), officially the Federal Democratic Republic of Nepal (Nepali: सङ्घीय लोकतान्त्रिक गणतन्त्र नेपाल), is a landlocked country in South Asia. It is mainly situated in the Himalayas, but also includes parts of the Indo-Gangetic Plain. It borders the Tibet Autonomous Region of China to the north, and India in the south, east, and west, while it is narrowly separated from Bangladesh by the Siliguri Corridor, and from Bhutan by the Indian state of Sikkim. Nepal has a diverse geography, including fertile plains, subalpine forested hills, and eight of the world's ten tallest mountains, including Mount Everest, the highest point on Earth. Kathmandu is the nation's capital and the largest city. Nepal is a multi-ethnic, multi-lingual, multi-religious and multi-cultural state, with Nepali as the official language.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2015%2F09%2F19%2Fthumbs%2F107-Flag_of_Nepal.svg_600x600.jpeg?alt=media&token=4d5645b3-0082-4b29-a6f5-ae9e178d1e53",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2015%2F09%2F19%2F107-Flag_of_Nepal.svg.png?alt=media&token=d22a1972-ff6e-4736-95cd-fe8d672722a3",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Nepal",
    },
    {
      id: 108,
      date: "5 May 1945",
      time: 0,
      type: 0,
      title: "Kingdom of the Netherlands Liberation Day",
      description:
        "The Kingdom of the Netherlands (Dutch: Koninkrijk der Nederlanden, pronounced [ˈkoːnɪŋkrɛik dɛr ˈneːdərlɑndə(n)] i),[g] commonly known as simply the Netherlands,[h] consists of the entire area in which the monarch of the Netherlands functions as head of state. The realm is not a federation; it is a collection of states and territories united under its monarch. 98% of its territory and population is in Western Europe; it also includes several small West Indian island territories in the Caribbean (in the Leeward Islands and Leeward Antilles groups).",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1945%2F05%2F05%2Fthumbs%2F108-Flag_of_the_Netherlands.svg_600x600.jpeg?alt=media&token=401ae776-b821-49fc-9740-824223aa4eb6",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1945%2F05%2F05%2F108-Flag_of_the_Netherlands.svg.png?alt=media&token=6812095d-3ae6-482d-8e5f-c8aa32a3ee82",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Kingdom_of_the_Netherlands",
    },
    {
      id: 109,
      date: "18 Dec 1958",
      time: 0,
      type: 0,
      title: "Niger Republic Day",
      description:
        "Niger or the Niger[13][14] (/niːˈʒɛər, ˈnaɪdʒər/ nee-ZHAIR, NY-jər,[15][16] French: [niʒɛʁ]),[a] officially the Republic of the Niger[13][14] (French: République du Niger; Hausa: Jamhuriyar Nijar), is a landlocked country in West Africa. It is a unitary state bordered by Libya to the northeast, Chad to the east, Nigeria to the south, Benin and Burkina Faso to the southwest, Mali to the west, and Algeria to the northwest. It covers a land area of almost 1,270,000 km2 (490,000 sq mi), making it the largest landlocked country in West Africa. Over 80% of its land area lies in the Sahara. Its predominantly Muslim population of about 25 million[17][18] live mostly in clusters in the south and west of the country. The capital Niamey is located in Niger's southwest corner.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1958%2F12%2F18%2Fthumbs%2F109-Flag_of_Niger.svg_600x600.jpeg?alt=media&token=5395e6f8-a208-4b36-a87e-3fc52c6340a4",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1958%2F12%2F18%2F109-Flag_of_Niger.svg.png?alt=media&token=50ac621d-88bb-4513-bf43-b5e3f14fe944",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Niger",
    },
    {
      id: 110,
      date: "1 Oct 1960",
      time: 0,
      type: 0,
      title: "Nigeria Independence Day",
      description:
        "Nigeria (/naɪˈdʒɪəriə/ i ny-JEER-ee-ə),[a] officially the Federal Republic of Nigeria, is a country in West Africa.[11] It is situated between the Sahel to the north and the Gulf of Guinea to the south in the Atlantic Ocean. It covers an area of 923,769 square kilometres (356,669 sq mi), and with a population of over 230 million, it is the most populous country in Africa, and the world's sixth-most populous country. Nigeria borders Niger in the north, Chad in the northeast, Cameroon in the east, and Benin in the west. Nigeria is a federal republic comprising 36 states and the Federal Capital Territory, where the capital, Abuja, is located. The largest city in Nigeria is Lagos, one of the largest metropolitan areas in the world and the largest in Africa.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F10%2F01%2Fthumbs%2F110-Flag_of_Nigeria.svg_600x600.jpeg?alt=media&token=022945a7-7e02-4c7b-92e9-6fe543188527",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F10%2F01%2F110-Flag_of_Nigeria.svg.png?alt=media&token=a9285693-0e2d-45d3-9552-d22e0a99d66e",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Nigeria",
    },
    {
      id: 111,
      date: "9 Sep 1948",
      time: 0,
      type: 0,
      title: "North Korea Day of the Foundation of the Republic",
      description:
        "North Korea,[c] officially the Democratic People's Republic of Korea (DPRK),[d] is a country in East Asia. It constitutes the northern half of the Korean Peninsula and borders China and Russia to the north at the Yalu (Amnok) and Tumen rivers, and South Korea to the south at the Korean Demilitarized Zone. North Korea's border with South Korea is a disputed border as both countries claim the entirety of the Korean Peninsula. The country's western border is formed by the Yellow Sea, while its eastern border is defined by the Sea of Japan. North Korea, like its southern counterpart, claims to be the legitimate government of the entire peninsula and adjacent islands. Pyongyang is the capital and largest city.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1948%2F09%2F09%2Fthumbs%2F111-Flag_of_North_Korea.svg_600x600.jpeg?alt=media&token=588363d0-be6e-49d4-a5be-11c055ceefdb",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1948%2F09%2F09%2F111-Flag_of_North_Korea.svg.png?alt=media&token=cdacc889-8b1f-4ff5-878d-b7d19da72a3b",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/North_Korea",
    },
    {
      id: 112,
      date: "2 Aug 1903",
      time: 0,
      type: 0,
      title: "North Macedonia Republic Day",
      description:
        "Republic Day or the Day of the Republic or Ilinden (Macedonian: Ден на Републиката, Den na Republikata, Илинден) is a major national holiday of North Macedonia. It is celebrated on 2 August,[1] which is also a major religious holiday – Ilinden (Macedonian: Илинден; St. Elijah day; the day is reckoned as 20 July according to the Julian Calendar). It commemorates two major events in the establishment of the statehood of the country which took place on this date: The Ilinden Uprising of 1903 which was organized by the Internal Macedonian Revolutionary Organization against the Ottoman Empire, and during which a short-lived Kruševo Republic was proclaimed, and The First Assembly of ASNOM of 1944, during World War II in Yugoslav Macedonia, which laid the foundation of the SR Macedonia. In Photo: The Macedonium monument in Kruševo commemorating the Ilinden Uprising of 1903",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1903%2F08%2F02%2Fthumbs%2F112-North-Macedonia-Republic-Day_600x600.jpeg?alt=media&token=dce8e4cd-a10f-4f94-8694-33ff80dcd23a",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1903%2F08%2F02%2F112-North-Macedonia-Republic-Day.jpeg?alt=media&token=b74f9de1-6acd-4014-a2cb-31b31f7757e6",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Republic_Day_(North_Macedonia)",
    },
    {
      id: 113,
      date: "8 Sep 1991",
      time: 0,
      type: 0,
      title: "North Macedonia Independence Day",
      description:
        "North Macedonia (/ˌmæsəˈdoʊniə/ MASS-ə-DOH-nee-ə),[c] officially the Republic of North Macedonia,[d] is a landlocked country in Southeast Europe. It shares land borders with Kosovo[e] to the northwest, Serbia to the north, Bulgaria to the east, Greece to the south, and Albania to the west.[8] It constitutes approximately the northern third of the larger geographical region of Macedonia. Skopje, the capital and largest city, is home to a quarter of the country's 1.83 million people. The majority of the residents are ethnic Macedonians, a South Slavic people. Albanians form a significant minority at around 25%, followed by Turks, Romani, Serbs, Bosniaks, Aromanians and a few other minorities.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1991%2F09%2F08%2Fthumbs%2F113-Flag_of_North_Macedonia.svg_600x600.jpeg?alt=media&token=d3f02435-3a74-4612-ad66-5077b81aec67",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1991%2F09%2F08%2F113-Flag_of_North_Macedonia.svg.png?alt=media&token=cd27eee8-37f6-459a-a3e7-82877ec89952",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/North_Macedonia",
    },
    {
      id: 114,
      date: "8 Jan 1978",
      time: 0,
      type: 0,
      title: "Northern Mariana Islands Commonwealth Day",
      description:
        "The Northern Mariana Islands, officially the Commonwealth of the Northern Mariana Islands[b] (CNMI; Chamorro: Sankattan Siha Na Islas Mariånas; Carolinian: Commonwealth Téél Falúw kka Efáng llól Marianas), is an unincorporated territory and commonwealth of the United States consisting of 14 islands in the northwestern Pacific Ocean.[10] The CNMI includes the 14 northernmost islands in the Mariana Archipelago; the southernmost island, Guam, is a separate U.S. territory. The Northern Mariana Islands were listed by the United Nations as a non-self governing territory until 1990.[11]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1978%2F01%2F08%2Fthumbs%2F114-Flag_of_the_Northern_Mariana_Islands.svg_600x600.jpeg?alt=media&token=ee606294-6e75-4388-a55d-d2fa115b7ed6",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1978%2F01%2F08%2F114-Flag_of_the_Northern_Mariana_Islands.svg.png?alt=media&token=87166799-f083-4fcf-b6a9-5cbaacd23681",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Northern_Mariana_Islands",
    },
    {
      id: 115,
      date: "14 Aug 1947",
      time: 0,
      type: 0,
      title: "Pakistan Independence Day",
      description:
        "Pakistan (Urdu: پَاکِسْتَان [ˈpaːkɪstaːn]),[d] officially the Islamic Republic of Pakistan (ISO: اِسْلامی جَمْہُورِیَہ پَاکِسْتَان, islāmi jamhūriyāh pākistān), is a country in South Asia. It is the world's fifth-most populous country, with a population of 241.5 million people, and has the world's largest Muslim population as of year 2023.[10] Islamabad is the nation's capital, while Karachi is its largest city and financial centre, followed by Lahore, Faisalabad, Multan, Peshawer and Hyderabad as other major cities. Pakistan is the 33rd-largest country in the world by area and the second-largest in South Asia, spanning 881,913 square kilometres (340,509 square miles). It has a 1,046-kilometre (650-mile) coastline along the Arabian Sea and Gulf of Oman in the south, and is bordered by India to the east, Afghanistan to the west, Iran to the southwest, and China to the northeast. It is separated narrowly from Tajikistan by Afghanistan's Wakhan Corridor in the north, and also shares a maritime border with Oman.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1947%2F08%2F14%2Fthumbs%2F115-Flag_of_Pakistan.svg_600x600.jpeg?alt=media&token=0975470f-8386-4ea7-987a-3c08821f66fa",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1947%2F08%2F14%2F115-Flag_of_Pakistan.svg.png?alt=media&token=c3bce01a-f6b2-4d1b-8dc8-f1e2033e44e1",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Pakistan",
    },
    {
      id: 116,
      date: "1 Oct 1994",
      time: 0,
      type: 0,
      title: "Palau Independence Day",
      description:
        "Palau[a] (officially the Republic of Palau[b][6] and historically Belau, Palaos or Pelew) is an island country in the Micronesia subregion of Oceania in the western Pacific. The republic consists of approximately 340 islands and connects the western chain of the Caroline Islands with parts of the Federated States of Micronesia. It has a total area of 466 square kilometers (180 sq mi), making it one of the smallest countries in the world.[7] The most populous island is Koror, home to the country's most populous city of the same name. The capital Ngerulmud is located on the nearby island of Babeldaob, in Melekeok State. Palau shares maritime boundaries with international waters to the north, the Federated States of Micro",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1994%2F10%2F01%2Fthumbs%2F116-Flag_of_Palau.svg_600x600.jpeg?alt=media&token=f5db5314-67ba-4ef9-b637-ae6dcf45436b",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1994%2F10%2F01%2F116-Flag_of_Palau.svg.png?alt=media&token=57f706cc-91f3-46ca-8967-bea4eae30b65",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Palau",
    },
    {
      id: 117,
      date: "15 Nov 1988",
      time: 0,
      type: 0,
      title: "Palestine Independence Day",
      description:
        "Palestine (Arabic: فلسطين, romanized: Filasṭīn, pronounced [fɪ.las.tˤiːn]), officially the State of Palestine[a] (دولة فلسطين, Dawlat Filasṭīn), is a state located in the Southern Levant region of West Asia. Officially governed by the Palestine Liberation Organization (PLO), it claims the West Bank (including East Jerusalem) and the Gaza Strip as its territory, though the entirety of that territory has been under Israeli occupation since the 1967 Six-Day War.[5][18] As a result of the Oslo Accords of 1993–1995, the West Bank is currently divided into 165 Palestinian enclaves that are under partial Palestinian National Authority (PNA) rule; the remainder, including 200 Israeli settlements, is under full Israeli control. The Gaza Strip has been ruled by the militant Islamic group",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1988%2F11%2F15%2Fthumbs%2F117-Flag_of_Palestine.svg_600x600.jpeg?alt=media&token=ee843f9e-b70c-4e77-b5cf-85168812b3e7",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1988%2F11%2F15%2F117-Flag_of_Palestine.svg.png?alt=media&token=635a6287-046d-445b-92d7-79d4b78b3549",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/State_of_Palestine",
    },
    {
      id: 118,
      date: "3 Nov 1903",
      time: 0,
      type: 0,
      title: "Panama Independence Day",
      description:
        "Panama (/ˈpænəmɑː/ i PAN-ə-mah, /pænəˈmɑː/ pan-ə-MAH; Spanish: Panamá IPA: [panaˈma] i), officially the Republic of Panama (Spanish: República de Panamá), is a transcontinental country in Central America, spanning the southern tip of North America into the northern part of South America. It is bordered by Costa Rica to the west, Colombia to the southeast, the Caribbean Sea to the north, and the Pacific Ocean to the south. Its capital and largest city is Panama City, whose metropolitan area is home to nearly half the country's over 4 million inhabitants.[9][10]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1903%2F11%2F03%2Fthumbs%2F118-Flag_of_Panama.svg_600x600.jpeg?alt=media&token=e184c0b5-8b20-469b-92ca-d36aa1a3bc12",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1903%2F11%2F03%2F118-Flag_of_Panama.svg.png?alt=media&token=56249adf-dfa4-47f6-8919-454f95b17e92",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Panama",
    },
    {
      id: 119,
      date: "16 Sep 1975",
      time: 0,
      type: 0,
      title: "Papua New Guinea Independence Day",
      description:
        "Papua New Guinea (abbreviated PNG; /ˈpæp(j)uə ... ˈɡɪni, ˈpɑː-/ i, also US: /ˈpɑːpwə-, ˈpɑːp(j)ə-/[12]), officially[13] the Independent State of Papua New Guinea (Tok Pisin: Independen Stet bilong Papua Niugini; Hiri Motu: Independen Stet bilong Papua Niu Gini), is a country in Oceania that comprises the eastern half of the island of New Guinea and its offshore islands in Melanesia (a region of the southwestern Pacific Ocean north of Australia). Its capital, located along its southeastern coast, is Port Moresby. The country is the world's third largest island country, with an area of 462,840 km2 (178,700 sq mi).[14]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1975%2F09%2F16%2Fthumbs%2F119-Flag_of_Papua_New_Guinea.svg_600x600.jpeg?alt=media&token=27c85685-6eb7-4ef0-9426-43ccc84b9a69",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1975%2F09%2F16%2F119-Flag_of_Papua_New_Guinea.svg.png?alt=media&token=2184d159-a9d2-400f-8b29-dc935f2786a2",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Papua_New_Guinea",
    },
    {
      id: 120,
      date: "11 Nov 1918",
      time: 0,
      type: 0,
      title: "Poland Independence Day",
      description:
        "Poland,[a] officially the Republic of Poland,[b] is a country in Central Europe. It is divided into 16 administrative provinces called voivodeships, covering an area of 313,931 km2 (121,209 sq mi). Poland has a population of over 38 million and is the fifth-most populous member state of the European Union. Warsaw is the nation's capital and largest metropolis.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1918%2F11%2F11%2Fthumbs%2F120-Flag_of_Poland.svg_600x600.jpeg?alt=media&token=be9b738f-731c-4545-99b9-5d8f7c99eb10",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1918%2F11%2F11%2F120-Flag_of_Poland.svg.png?alt=media&token=4643a602-d77c-445a-957c-3f6570c500fe",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Poland",
    },
    {
      id: 121,
      date: "1 Dec 1918",
      time: 0,
      type: 0,
      title: "Romania Great Union Day",
      description:
        "Romania (/roʊˈmeɪniə/ i roh-MAY-nee-ə; Romanian: România [romɨˈni.a] i) is a country in Central, Eastern and Southeast Europe.[12][13] It borders Ukraine to the north, Hungary to the west, Serbia to the southwest, Bulgaria to the south, Moldova to the east, and the Black Sea to the southeast. It has a predominantly temperate-continental climate, and an area of 238,397 km2 (92,046 sq mi), with a population of 19 million people (2023). Romania is the twelfth-largest country in Europe and the sixth-most populous member state of the European Union. Its capital and largest city is Bucharest, followed by Iași, Cluj-Napoca, Timișoara, Constanța, Craiova, Brașov, and Galați.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1918%2F12%2F01%2Fthumbs%2F121-Flag_of_Romania.svg_600x600.jpeg?alt=media&token=fa8808de-fb58-4628-a0a2-08bbc19d9715",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1918%2F12%2F01%2F121-Flag_of_Romania.svg.png?alt=media&token=6ac395ad-d998-4168-bedf-d0929919c587",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Romania",
    },
    {
      id: 122,
      date: "12 Jun 1990",
      time: 0,
      type: 0,
      title: "Russia Day",
      description:
        "Russia (Russian: Россия, romanized: Rossiya, [rɐˈsʲijə]), or the Russian Federation,[b] is a country spanning Eastern Europe and Northern Asia. It is the largest country in the world by area, extends across eleven time zones, and shares land boundaries with fourteen countries.[c] It is the world's ninth-most populous country and Europe's most populous country. The country's capital and largest city is Moscow. Saint Petersburg is Russia's cultural centre and second-largest city. Other major urban areas in the country include Novosibirsk, Yekaterinburg, Nizhny Novgorod, Chelyabinsk, Krasnoyarsk, and Kazan.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1990%2F06%2F12%2Fthumbs%2F122-Flag_of_Russia.svg_600x600.jpeg?alt=media&token=434cd860-10df-4581-8fcf-4d258c0f18ae",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1990%2F06%2F12%2F122-Flag_of_Russia.svg.png?alt=media&token=6275e312-cd89-438f-9e3e-13c1d67c55db",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Russia",
    },
    {
      id: 123,
      date: "1 Jul 1962",
      time: 0,
      type: 0,
      title: "Rwanda Independence Day",
      description:
        "Rwanda (/ruˈɑːndə/ i roo-AHN-də or /ruːˈændə/ roo-AN-də; Kinyarwanda: u Rwanda [u.ɾɡwaː.nda] i), officially the Republic of Rwanda,[13] is a landlocked country in the Great Rift Valley of Central Africa, where the African Great Lakes region and Southeast Africa converge. Located a few degrees south of the Equator, Rwanda is bordered by Uganda, Tanzania, Burundi, and the Democratic Republic of the Congo. It is highly elevated, giving it the soubriquet - land of a thousand hills, with its geography dominated by mountains in the west and savanna to the southeast, with numerous lakes throughout the country. The climate is temperate to subtropical, with two rainy seasons and two dry seasons each year. It is the most densely populated mainland African country; among countries larger than 10,000 km2, it is the fifth most densely populated country in the world. Its capital and largest city is Kigali.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1962%2F07%2F01%2Fthumbs%2F123-Flag_of_Rwanda.svg_600x600.jpeg?alt=media&token=b24c2b4a-ca33-4892-b140-6e8c4eed97da",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1962%2F07%2F01%2F123-Flag_of_Rwanda.svg.png?alt=media&token=b13d506d-8e03-4295-addb-f3cee19c4f42",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Rwanda",
    },
    {
      id: 124,
      date: "31 Aug 1963",
      time: 0,
      type: 0,
      title: "Sabah Day (Malaysia)",
      description:
        "Sabah (Malay pronunciation: [saˈbah]) is a state of Malaysia located on the northern portion of Borneo, in the region of East Malaysia. Sabah has land borders with the Malaysian territory of Sarawak to the southwest and Indonesia's North Kalimantan province to the south. The Federal Territory of Labuan is an island just off Sabah's west coast. Sabah shares maritime borders with Vietnam to the west and the Philippines to the north and east. Kota Kinabalu is the state capital and the economic centre of the state, and the seat of the Sabah State government. ",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2023%2F08%2F31%2Fthumbs%2F124-Flag_of_Sabah.svg_600x600.jpeg?alt=media&token=06bbebb6-dec0-4698-83ad-ca9677b4140b",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2023%2F08%2F31%2F124-Flag_of_Sabah.svg.png?alt=media&token=98ffa652-fd0a-4df0-85de-30fa4bfe0476",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Sabah",
    },
    {
      id: 125,
      date: "19 Sep 1983",
      time: 0,
      type: 0,
      title: "Saint Kitts and Nevis Independence Day",
      description:
        "Saint Kitts and Nevis (/-ˈkɪts ... ˈniːvɪs/ i), officially the Federation of Saint Christopher and Nevis,[7] is an island country and microstate consisting of the two islands of Saint Kitts and Nevis, both located in the West Indies, in the Leeward Islands chain of the Lesser Antilles. With 261 square kilometres (101 sq mi) of territory, and roughly 50,000 inhabitants, it is the smallest sovereign state in the Western Hemisphere, in both area and population, as well as the world's smallest sovereign federation.[1] The country is a Commonwealth realm, with Charles III as King and head of state.[1][8] It is the smallest sovereign state in North America.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1983%2F09%2F19%2Fthumbs%2F125-Flag_of_Saint_Kitts_and_Nevis.svg_600x600.jpeg?alt=media&token=9a917bb6-6bc0-49ef-852e-3ff56b6b2f88",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1983%2F09%2F19%2F125-Flag_of_Saint_Kitts_and_Nevis.svg.png?alt=media&token=9106c379-1cf5-47e6-acf4-5316ca94b6bc",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Saint_Kitts_and_Nevis",
    },
    {
      id: 126,
      date: "22 Feb 1979",
      time: 0,
      type: 0,
      title: "Saint Lucia Independence Day",
      description:
        "Saint Lucia (/ˈluːʃə/ LOO-shə; Saint Lucian Creole French: Sent Lisi, French: Sainte-Lucie) is a constitutional monarchy and an island country of the West Indies in the eastern Caribbean.[9] The island was previously called Iouanalao and later Hewanorra, names given by the native Arawaks and Caribs (respectively), two Amerindian peoples.[10] Part of the Windward Islands of the Lesser Antilles, it is located north/northeast of the island of Saint Vincent, northwest of Barbados and south of Martinique. It covers a land area of 617 km2 (238 square miles) with an estimated population of over 180,000 people as of 2022. The nation's capital and largest city is Castries.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1979%2F02%2F22%2Fthumbs%2F126-Flag_of_Saint_Lucia.svg_600x600.jpeg?alt=media&token=276997a6-53aa-4fea-b653-65e85589cfec",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1979%2F02%2F22%2F126-Flag_of_Saint_Lucia.svg.png?alt=media&token=3cd4a568-afdf-42c1-9109-dab3949c3a8d",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Saint_Lucia",
    },
    {
      id: 127,
      date: "27 Oct 1979",
      time: 0,
      type: 0,
      title: "Saint Vincent and the Grenadines Independence Day",
      description:
        "Saint Vincent and the Grenadines (/ˌɡrɛnəˈdiːnz/ i GREH-nə-DEENZ) is an island country in the Caribbean. It is located in the southeast Windward Islands of the Lesser Antilles, which lie in the West Indies, at the southern end of the eastern border of the Caribbean Sea, where the latter meets the Atlantic Ocean.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1979%2F10%2F27%2Fthumbs%2F127-Flag_of_Saint_Vincent_and_the_Grenadines.svg_600x600.jpeg?alt=media&token=f95c50ca-2a8e-40d5-9dbc-edd6569b443e",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1979%2F10%2F27%2F127-Flag_of_Saint_Vincent_and_the_Grenadines.svg.png?alt=media&token=fc694569-35ec-4ef2-a0fe-2e8abcb74233",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Saint_Vincent_and_the_Grenadines",
    },
    {
      id: 128,
      date: "1 Jun 1962",
      time: 0,
      type: 0,
      title: "Samoa Independence Day",
      description:
        "Samoa,[note 1] officially the Independent State of Samoa[note 2] and until 1997 known as Western Samoa (Samoan: Sāmoa i Sisifo), is a Polynesian island country consisting of two main islands (Savai'i and Upolu); two smaller, inhabited islands (Manono and Apolima); and several smaller, uninhabited islands, including the Aleipata Islands (Nu'utele, Nu'ulua, Fanuatapu and Namua). Samoa is located 64 km (40 mi) west of American Samoa, 889 km (552 mi) northeast of Tonga (closest foreign country), 1,152 km (716 mi) northeast of Fiji, 483 km (300 mi) east of Wallis and Futuna, 1,151 km (715 mi) southeast of Tuvalu, 519 km (322 mi) south of Tokelau, 4,190 km (2,600 mi) southwest of Hawaii, and 610 km (380 mi) northwest of Niue. The capital and largest city is Apia. The Lapita people discovered and settled the Samoan Islands around 3,500 years ago. They developed a Samoan language and Samoan cultural identity.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1962%2F06%2F01%2Fthumbs%2F128-Flag_of_Samoa.svg_600x600.jpeg?alt=media&token=f3ad757f-7e62-41fb-ad64-21fd4d9484e7",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1962%2F06%2F01%2F128-Flag_of_Samoa.svg.png?alt=media&token=28ea7f78-ab85-40c8-80f6-d1e7c4cc31ae",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Samoa",
    },
    {
      id: 129,
      date: "10 May 1945",
      time: 0,
      type: 0,
      title: "Sark (British Islands) Liberation Day",
      description:
        "Sark (Sercquiais: Sèr or Cerq) is a part of the Channel Islands in the southwestern English Channel, off the coast of Normandy, France. It is a royal fief, which forms part of the Bailiwick of Guernsey, with its own set of laws based on Norman law and its own parliament. It has a population of about 500.[4] Sark (including the nearby island of Brecqhou) has an area of 2.10 square miles (5.44 km2).[5] Little Sark is a peninsula joined by a natural but high and very narrow isthmus to the rest of Sark Island.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1945%2F05%2F10%2Fthumbs%2F129-Flag_of_Sark_(bordered).svg_600x600.jpeg?alt=media&token=bbc63502-cde4-41b4-9c42-ad4d55c2d2ba",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1945%2F05%2F10%2F129-Flag_of_Sark_(bordered).svg.png?alt=media&token=f9892745-3416-45bc-9254-561b513f1bb8",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Sark",
    },
    {
      id: 130,
      date: "23 Sep 1932",
      time: 0,
      type: 0,
      title: "Saudi National Day",
      description:
        "Saudi Arabia,[d] officially the Kingdom of Saudi Arabia (KSA),[e] is a country in West Asia. It covers the bulk of the Arabian Peninsula, and has a land area of about 2150000 km2 (830000 sq mi), making it the fifth-largest country in Asia, the second-largest in the Arab world, and the largest in West Asia and the Middle East. It is bordered by the Red Sea to the west; Jordan, Iraq, and Kuwait to the north; the Persian Gulf, Qatar and the United Arab Emirates to the east; Oman to the southeast; and Yemen to the south. Bahrain is an island country off its east coast. The Gulf of Aqaba in the northwest separates Saudi Arabia from Egypt and Israel. Saudi Arabia is the only country with a coastline along both the Red Sea and the Persian Gulf, and most of its terrain consists of arid desert, lowland, steppe, and mountains. Saudi Arabia's capital and largest city is Riyadh; the kingdom also is the location of Islam's two holiest cities of Mecca and Medina.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1932%2F09%2F23%2Fthumbs%2F130-Flag_of_Saudi_Arabia.svg_600x600.jpeg?alt=media&token=2c5bd4c8-b4d4-421f-bc3d-f6e081cdceb1",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1932%2F09%2F23%2F130-Flag_of_Saudi_Arabia.svg.png?alt=media&token=ff07d854-1219-4ad0-8631-592a2db2c1b6",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Saudi_Arabia",
    },
    {
      id: 131,
      date: "4 Apr 1960",
      time: 0,
      type: 0,
      title: "Senegal Independence Day",
      description:
        "Senegal,[f] officially the Republic of Senegal,[g] is a country in West Africa, on the Atlantic Ocean coastline. Senegal is bordered by Mauritania to the north, Mali to the east, Guinea to the southeast and Guinea-Bissau to the southwest. Senegal nearly surrounds the Gambia, a country occupying a narrow sliver of land along the banks of the Gambia River, which separates Senegal's southern region of Casamance from the rest of the country. Senegal also shares a maritime border with Cape Verde. Senegal's economic and political capital is Dakar.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F04%2F04%2Fthumbs%2F131-Flag_of_Senegal.svg_600x600.jpeg?alt=media&token=dff811be-7377-44e9-88f9-963833ad4134",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F04%2F04%2F131-Flag_of_Senegal.svg.png?alt=media&token=89f18e49-ccef-4e8c-b97a-1ea9634dfa15",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Senegal",
    },
    {
      id: 132,
      date: "18 Jun 1993",
      time: 0,
      type: 0,
      title: "Seychelles National Day",
      description:
        "Seychelles (/seɪˈʃɛlz/ i, /-ˈʃɛl, ˈseɪʃɛl(z)/;[8][9] French: [sɛʃɛl][10][11][12] or [seʃɛl][13]), officially the Republic of Seychelles (French: République des Seychelles; Creole: La Repiblik Sesel), is an island country and archipelagic state consisting of 155 islands (as per the Constitution) in the Indian Ocean. Its capital and largest city, Victoria, is 1,500 kilometres (800 nautical miles) east of mainland Africa. Nearby island countries and territories include the Comoros, Madagascar, Mauritius, and the French overseas departments of Mayotte and Réunion to the south; and Maldives and the Chagos Archipelago (administered by the United Kingdom as the British Indian Ocean Territory) to the east. Seychelles is the smallest country in Africa as well as the least populated sovereign African country, with an estimated population of 100,600 in 2022.[4]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1993%2F06%2F18%2Fthumbs%2F132-Flag_of_Seychelles.svg_600x600.jpeg?alt=media&token=93a1dc8a-2699-4b5c-be92-d2b8fb778a4f",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1993%2F06%2F18%2F132-Flag_of_Seychelles.svg.png?alt=media&token=59127c0f-9b25-4e9f-b2dd-090d7256ebfc",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Seychelles",
    },
    {
      id: 133,
      date: "27 Apr 1961",
      time: 0,
      type: 0,
      title: "Sierra Leone Republic Day",
      description:
        "Sierra Leone,[note 1] officially the Republic of Sierra Leone, is a country on the southwest coast of West Africa. It shares its southeastern border with Liberia, and the northern half of the nation is surrounded by Guinea. Covering a total area of 71,740 km2 (27,699 sq mi),[11] Sierra Leone has a tropical climate, with diverse environments ranging from savanna to rainforests. The country has a population of 7,092,113 as of the 2015 census.[12] Freetown is the capital and largest city. The country is divided into five administrative regions, which are subdivided into 16 districts.[13][14]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1961%2F04%2F27%2Fthumbs%2F133-Flag_of_Sierra_Leone.svg_600x600.jpeg?alt=media&token=a8f8b067-748b-4151-a6b5-a5f9f21d9b3d",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1961%2F04%2F27%2F133-Flag_of_Sierra_Leone.svg.png?alt=media&token=8350ecda-6b1a-4c0d-9833-1698dc2db051",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Sierra_Leone",
    },
    {
      id: 134,
      date: "9 Aug 1965",
      time: 0,
      type: 0,
      title: "Singapore National Day",
      description:
        "Singapore (/ˈsɪŋ(ɡ)əpɔːr/ i), officially the Republic of Singapore, is an island country and city-state in maritime Southeast Asia. It is located about one degree of latitude (137 kilometres or 85 miles) north of the equator, off the southern tip of the Malay Peninsula, bordering the Strait of Malacca to the west, the Singapore Strait to the south, the South China Sea to the east, and the Straits of Johor to the north. The country's territory comprises one main island, 63 satellite islands and islets, and one outlying islet; the combined area of these has increased by 25% since the country's independence as a result of extensive land reclamation projects. It has the second highest population density of any country in the world, although there are numerous green and recreational spaces as a result of urban planning. With a multicultural population and in recognition of the cultural identities of the major ethnic groups within the nation, Singapore has four official languages – English, Malay, Mandarin, and Tamil. English is the lingua franca, with its exclusive use in numerous public services. Multi-racialism is enshrined in the constitution and continues to shape national policies in education, housing, and politics.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1965%2F08%2F09%2Fthumbs%2F134-Flag_of_Singapore.svg_600x600.jpeg?alt=media&token=dd954dd4-4559-4530-96f3-4ef46a08f698",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1965%2F08%2F09%2F134-Flag_of_Singapore.svg.png?alt=media&token=7072221c-de8b-4ec3-a55c-7bef802f80bf",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Singapore",
    },
    {
      id: 135,
      date: "1 Jan 1993",
      time: 0,
      type: 0,
      title: "Day of the Establishment of the Slovak Republic",
      description:
        "Day of the Establishment of the Slovak Republic, this day commemorates when Czechoslovakia split into the Czech Republic and Slovakia in 1993",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1993%2F01%2F01%2Fthumbs%2F135-Flag_of_Slovakia.svg_600x600.jpeg?alt=media&token=e83ad5a6-6ca0-444d-af11-5177e18d18d1",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1993%2F01%2F01%2F135-Flag_of_Slovakia.svg.png?alt=media&token=29683b8a-d12a-4fc2-8c25-e0c45b2b5064",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Slovakia",
    },
    {
      id: 136,
      date: "25 Jun 1991",
      time: 0,
      type: 0,
      title: "Slovenia Statehood Day",
      description:
        "Slovenia (/sloʊˈviːniə, slə-/ i[10][11] sloh-VEE-nee-ə; Slovene: Slovenija [slɔˈʋèːnija]),[12] officially the Republic of Slovenia (Slovene: Republika Slovenijai,[13] abbr.: RS[14]), is a country in southern Central Europe.[15]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1991%2F06%2F25%2Fthumbs%2F136-Flag_of_Slovenia.svg_600x600.jpeg?alt=media&token=3552b601-25df-49d0-b93f-cf02a6b203fa",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1991%2F06%2F25%2F136-Flag_of_Slovenia.svg.png?alt=media&token=14468ad9-58e1-4022-97eb-ccdaf600ec2b",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Slovenia",
    },
    {
      id: 137,
      date: "7 Jul 1978",
      time: 0,
      type: 0,
      title: "Solomon Islands Independence Day",
      description:
        "Solomon Islands[9][a] is a country consisting of six major islands and over 900 smaller islands in Melanesia, part of Oceania, to the northeast of Australia. It is directly adjacent to Papua New Guinea to the northwest, Australia to the southwest, New Caledonia and Vanuatu to the southeast, Fiji, Wallis and Futuna and Tuvalu to the east, Nauru and the Federated States of Micronesia to the north. It has a land area of 29,000 square kilometres (11,000 sq mi),[11] and a population of approximately 700,000.[12] Its capital, Honiara, is located on the largest island, Guadalcanal. The country takes its name from the wider area of the Solomon Islands (archipelago), which is a collection of Melanesian islands that also includes the Autonomous Region of Bougainville (currently a part of Papua New Guinea), but excludes the Santa Cruz Islands.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1978%2F07%2F07%2Fthumbs%2F137-Flag_of_the_Solomon_Islands.svg_600x600.jpeg?alt=media&token=90a22799-7d39-4611-9279-d91b803b4b00",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1978%2F07%2F07%2F137-Flag_of_the_Solomon_Islands.svg.png?alt=media&token=639f5283-5b97-4512-b43a-ef3873af3b74",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Solomon_Islands",
    },
    {
      id: 138,
      date: "1 Jul 1960",
      time: 0,
      type: 0,
      title: "Somalia Independence Day",
      description:
        "Somalia,[b] officially the Federal Republic of Somalia[12] (Somali: Jamhuuriyadda Federaalka Soomaaliya; Arabic: جمهورية الصومال الفيدرالية), is a country in the Horn of Africa. The country is bordered by Ethiopia to the west, Djibouti[13] to the northwest, the Gulf of Aden to the north, the Indian Ocean to the east, and Kenya to the southwest. Somalia has the longest coastline on Africa's mainland.[14]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F07%2F01%2Fthumbs%2F138-Flag_of_Somalia.svg_600x600.jpeg?alt=media&token=7eb63d23-63c5-4276-873a-9a5f58a098b2",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F07%2F01%2F138-Flag_of_Somalia.svg.png?alt=media&token=18c31381-22bd-4f55-b2e6-db27486468ab",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Somalia",
    },
    {
      id: 139,
      date: "27 Apr 1994",
      time: 0,
      type: 0,
      title: "South Africa Freedom Day",
      description:
        "South Africa, officially the Republic of South Africa, is the southernmost country in Africa. It is bounded to the south by 2,798 kilometres (1,739 mi) of coastline that stretches along the South Atlantic and Indian Oceans;[14][15][16] to the north by the neighbouring countries of Namibia, Botswana, and Zimbabwe; and to the east and northeast by Mozambique and Eswatini. ",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1994%2F04%2F27%2Fthumbs%2F139-Flag_of_South_Africa.svg_600x600.jpeg?alt=media&token=5cfba37d-62a2-44ac-9276-d63b5f44e443",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1994%2F04%2F27%2F139-Flag_of_South_Africa.svg.png?alt=media&token=4413b63a-1d5a-41f9-9f36-cbeb2620b58e",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/South_Africa",
    },
    {
      id: 140,
      date: "1 Mar 1919",
      time: 0,
      type: 0,
      title: "South Korea Declaration of Independence",
      description:
        "South Korea,[a] officially the Republic of Korea (ROK),[b] is a country in East Asia. It constitutes the southern part of the Korean Peninsula and borders North Korea along the Korean Demilitarized Zone.[c] The country's western border is formed by the Yellow Sea, and its eastern border is defined by the Sea of Japan. South Korea claims to be the sole legitimate government of the entire peninsula and adjacent islands. It has a population of 51.96 million, of which roughly half live in the Seoul Capital Area, the fourth most populous metropolitan area in the world. Other major cities include Incheon, Busan, and Daegu.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1919%2F03%2F01%2Fthumbs%2F140-Flag_of_South_Korea.svg_600x600.jpeg?alt=media&token=cf66fab0-5ea2-434d-828c-cb62dfc08c02",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1919%2F03%2F01%2F140-Flag_of_South_Korea.svg.png?alt=media&token=be8eaf41-21be-4ad3-bb36-5423aa6bce31",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/South_Korea",
    },
    {
      id: 141,
      date: "9 Jul 2011",
      time: 0,
      type: 0,
      title: "South Sudan Independence Day",
      description:
        "South Sudan (/suːˈdɑːn, -ˈdæn/), officially the Republic of South Sudan, is a landlocked country in East Africa. It is bordered by Ethiopia, Sudan, the Central African Republic, the Democratic Republic of the Congo, Uganda, and Kenya. Its population was estimated at 11,088,796 in 2023.[17] Juba is the capital and largest city.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2011%2F07%2F09%2Fthumbs%2F141-Flag_of_South_Sudan.svg_600x600.jpeg?alt=media&token=fe8c3b11-fb52-49eb-a424-5a9062374bb1",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2011%2F07%2F09%2F141-Flag_of_South_Sudan.svg.png?alt=media&token=49b5952d-5d9d-49b8-bca8-4e9ecf371c34",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/South_Sudan",
    },
    {
      id: 142,
      date: "4 Feb 1948",
      time: 0,
      type: 0,
      title: "Sri Lanka Independence Day",
      description:
        "Sri Lanka (UK: /sri ˈlæŋkə, ʃriː -/, US: /- ˈlɑːŋkə/ i; Sinhala: ශ්‍රී ලංකා, romanized: Śrī Laṅkā (IPA: [ʃriː laŋkaː]); Tamil: இலங்கை, romanized: Ilaṅkai (IPA: [ilaŋɡaj])), formerly known as Ceylon and officially the Democratic Socialist Republic of Sri Lanka, is an island country in South Asia. It lies in the Indian Ocean, southwest of the Bay of Bengal, separated from the Indian peninsula by the Gulf of Mannar and the Palk Strait. Sri Lanka shares a maritime border with the Maldives in the south-west and India in the north-west.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1948%2F02%2F04%2Fthumbs%2F142-Flag_of_Sri_Lanka.svg_600x600.jpeg?alt=media&token=830096d5-a390-465b-8ae8-522ce8b9cd61",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1948%2F02%2F04%2F142-Flag_of_Sri_Lanka.svg.png?alt=media&token=9b068e35-b2d6-4bb0-a61e-54e337320004",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Sri_Lanka",
    },
    {
      id: 143,
      date: "1 Jan 1956",
      time: 0,
      type: 0,
      title: "Sudan Independence Day",
      description:
        "Sudan (English: /suːˈdɑːn/ i soo-DAHN or /suːˈdæn/ soo-DAN; Arabic: السودان, romanized: Sūdān), officially the Republic of the Sudan (Arabic: جمهورية السودان, romanized: Jumhūriyyat as-Sūdān), is a country in Northeast Africa. It borders the Central African Republic to the southwest, Chad to the west, Egypt to the north, Eritrea to the northeast, Ethiopia to the southeast, Libya to the northwest, South Sudan to the south, and the Red Sea. It has a population of 45.7 million people as of 2022[12] and occupies 1,886,068 square kilometres (728,215 square miles), making it Africa's third-largest country by area and the third-largest by area in the Arab League. It was the largest country by area in Africa and the Arab League until the secession of South Sudan in 2011;[13] since then both titles have been held by Algeria. Its capital city is Khartoum, and its most populous city is Omdurman (part of the metropolitan area of Khartoum).",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1956%2F01%2F01%2Fthumbs%2F143-Flag_of_Sudan.svg_600x600.jpeg?alt=media&token=129b403f-ceda-4393-8a63-870730fc485a",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1956%2F01%2F01%2F143-Flag_of_Sudan.svg.png?alt=media&token=16ffe267-3ca5-4b7d-8598-6cdf0acae345",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Sudan",
    },
    {
      id: 144,
      date: "25 Nov 1975",
      time: 0,
      type: 0,
      title: "Suriname Independence Day",
      description:
        "Suriname (/ˈsʊərɪnæm, -nɑːm/ i SOOR-ih-NAM, -⁠NAHM), officially the Republic of Suriname (Dutch: Republiek Suriname [reːpyˌblik ˌsyːriˈnaːmə], Sranan Tongo: Ripoliku Sranan[citation needed] [ripoliku sranaŋ]), is a country in northern South America. It is bordered by the Atlantic Ocean to the north, French Guiana to the east, Guyana to the west, and Brazil to the south. At under 165,000 square kilometers (64,000 square miles), it is the smallest country in South America.[a]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1975%2F11%2F25%2Fthumbs%2F144-Flag_of_Suriname.svg_600x600.jpeg?alt=media&token=e6a97695-6ad0-4317-81bd-8afae8191826",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1975%2F11%2F25%2F144-Flag_of_Suriname.svg.png?alt=media&token=9fa94bc0-2454-40f4-a6cb-4b9ffd4d102f",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Suriname",
    },
    {
      id: 145,
      date: "17 Apr 1946",
      time: 0,
      type: 0,
      title: "Syria Evacuation Day",
      description:
        "Syria (Arabic: سُورِيَا or سُورِيَة, romanized: Sūriyā), officially the Syrian Arab Republic (Arabic: الجمهورية العربية السورية, romanized: al-Jumhūrīyah al-ʻArabīyah as-Sūrīyah), is a West Asian country located in the Eastern Mediterranean and the Levant. ",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1946%2F04%2F17%2Fthumbs%2F145-Flag_of_Syria.svg_600x600.jpeg?alt=media&token=bc967fb9-bbe0-4e06-8264-ecf611497d95",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1946%2F04%2F17%2F145-Flag_of_Syria.svg.png?alt=media&token=ec28a3ba-ca6b-4fa8-befe-bd86dbf34d0d",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Syria",
    },
    {
      id: 146,
      date: "9 Sep 1991",
      time: 0,
      type: 0,
      title: "Tajikistan Independence Day",
      description:
        "Tajikistan,[c][d] officially the Republic of Tajikistan,[e] is a landlocked country in Central Asia. It has an area of 142,326 km2 (54,952 sq mi) and an estimated population of 9,750,065 people.[8] Dushanbe is the country's capital and largest city. It is bordered by Afghanistan to the south, Uzbekistan to the west, Kyrgyzstan to the north, China to the east and is separated narrowly from Pakistan by Afghanistan's Wakhan Corridor. Tajiks form the ethnic majority in the country and the historical Tajik homeland lies in present-day Tajikistan as well as parts of Afghanistan and Uzbekistan.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1991%2F09%2F09%2Fthumbs%2F146-Flag_of_Tajikistan.svg_600x600.jpeg?alt=media&token=484bf9b1-2842-487c-a114-2507b5f61d3d",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1991%2F09%2F09%2F146-Flag_of_Tajikistan.svg.png?alt=media&token=9adf4792-c1dc-4198-baa5-0d9132632d6d",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Tajikistan",
    },
    {
      id: 147,
      date: "9 Dec 1961",
      time: 0,
      type: 0,
      title: "Tanzania Independence Day",
      description:
        "Tanzania (/ˌtænzəˈniːə/ TAN-zə-NEE-ə;[8][9][b] Swahili: [tanzaˈni.a]), officially the United Republic of Tanzania (Swahili: Jamhuri ya Muungano wa Tanzania), is a country in East Africa within the African Great Lakes region. It borders Uganda to the north; Kenya to the northeast; the Indian Ocean to the east; Mozambique and Malawi to the south; Zambia to the southwest; and Rwanda, Burundi, and the Democratic Republic of the Congo to the west. Mount Kilimanjaro, Africa's highest mountain, is in northeastern Tanzania. According to the 2022 national census, Tanzania has a population of nearly 62 million,[10] making it the most populous country located entirely south of the equator.[11]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1961%2F12%2F09%2Fthumbs%2F147-Flag_of_Tanzania.svg_600x600.jpeg?alt=media&token=9b29c581-370e-46e4-abdb-96d0ed3e8f00",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1961%2F12%2F09%2F147-Flag_of_Tanzania.svg.png?alt=media&token=b3b99440-b879-40cc-9f05-ce111fbcf7d4",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Tanzania",
    },
    {
      id: 148,
      date: "30 Aug 1990",
      time: 0,
      type: 0,
      title: "Tatarstan (Russia) Declaration of Independence",
      description:
        "Tatarstan (Tatar: Татарстан; Russian: Татарстан), officially the Republic of Tatarstan,[note 1] sometimes also called Tataria,[note 2] is a republic of the Russian Federation, located in Eastern Europe. It is a part of the Volga Federal District; and its capital and largest city is Kazan, an important cultural centre in Russia. The region's main source of wealth is oil with a strong petrochemical industry.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1990%2F08%2F30%2Fthumbs%2F148Flag_of_Tatarstan.svg_600x600.jpeg?alt=media&token=340524c4-fff5-403d-9b5f-38737fdadb31",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1990%2F08%2F30%2F148Flag_of_Tatarstan.svg.png?alt=media&token=4361dcf0-fe91-4f23-b45b-bb746d9649cc",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Tatarstan",
    },
    {
      id: 149,
      date: "5 Dec 1927",
      time: 0,
      type: 0,
      title: "Thailand Day",
      description: "Birthday of Late King Bhumibol Adulyadej in 1927",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1927%2F12%2F05%2Fthumbs%2F149-Flag_of_Thailand.svg_600x600.jpeg?alt=media&token=347aebe3-6d70-4beb-bd9a-75aeeaa32435",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1927%2F12%2F05%2F149-Flag_of_Thailand.svg.png?alt=media&token=8386eeaf-dcc8-48a3-ac0b-4842ce1a4551",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Thailand",
    },
    {
      id: 150,
      date: "27 Apr 1960",
      time: 0,
      type: 0,
      title: "Togo Independence Day",
      description:
        "Togo (/ˈtoʊɡoʊ/ i TOH-goh), officially the Togolese Republic (French: République togolaise), is a country in West Africa. It is bordered by Ghana to the west, Benin to the east and Burkina Faso to the north.[9] It is one of the least developed countries and extends south to the Gulf of Guinea, where its capital, Lomé, is located.[9] It is a small, tropical country, which covers 56,785 square kilometres (22,000 square miles) and has a population of approximately 8 million,[10] and has a width of less than 115 km (71 mi) between Ghana and its eastern neighbor Benin.[11][12]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F04%2F27%2Fthumbs%2F150-Flag_of_Togo.svg_600x600.jpeg?alt=media&token=d678413c-d9cd-41b7-b3b2-ec74b1140c26",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F04%2F27%2F150-Flag_of_Togo.svg.png?alt=media&token=a18897de-ee1a-4e27-938f-2d8b6c3bdd85",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Togo",
    },
    {
      id: 151,
      date: "2 Sep 1990",
      time: 0,
      type: 0,
      title: "Transnistria Independence Day",
      description:
        "Transnistria, officially the Pridnestrovian Moldavian Republic (PMR),[c] is an unrecognised state internationally considered to be part of Moldova. Transnistria controls most of the narrow strip of land between the Dniester river and the Moldovan–Ukrainian border, as well as some land on the other side of the river's bank. Its capital and largest city is Tiraspol. ",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1990%2F09%2F02%2Fthumbs%2F151-Flag_of_Transnistria_(state).svg_600x600.jpeg?alt=media&token=31fa6b13-0670-41d9-8dac-55660fb29068",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1990%2F09%2F02%2F151-Flag_of_Transnistria_(state).svg.png?alt=media&token=52e23380-99a0-41f8-87f0-86e3eb3c2308",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Transnistria",
    },
    {
      id: 152,
      date: "31 Aug 1962",
      time: 0,
      type: 0,
      title: "Trinidad and Tobago Independence Day",
      description:
        "Trinidad and Tobago (/ˈtrɪnɪdæd ... təˈbeɪɡoʊ/ i, /- toʊ-/, TRIH-nih-dad ... tə-BAY-goh, -⁠ toh-), officially the Republic of Trinidad and Tobago, is the southernmost island country in the Caribbean. Consisting of the main islands Trinidad and Tobago and numerous much smaller islands, it is situated 11 kilometres (6.8 miles) off the coast of northeastern Venezuela and 130 kilometres (81 miles) south of Grenada.[10] It shares maritime boundaries with Barbados to the east, Grenada to the northwest, Saint Vincent and the Grenadines to the north and Venezuela to the south and west.[11][12] Trinidad and Tobago is generally considered to be part of the West Indies. The island country's capital is Port of Spain, while its largest and most populous city is San Fernando.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1962%2F08%2F31%2Fthumbs%2F152-Flag_of_Trinidad_and_Tobago.svg_600x600.jpeg?alt=media&token=b7a60f85-7f49-467b-86b9-3e08a7fe0914",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1962%2F08%2F31%2F152-Flag_of_Trinidad_and_Tobago.svg.png?alt=media&token=c3adb075-b6a9-4627-a237-b24eae15ca2a",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Trinidad_and_Tobago",
    },
    {
      id: 153,
      date: "20 Mar 1956",
      time: 0,
      type: 0,
      title: "Tunisia Declaration of Independence",
      description:
        "Tunisia,[a] officially the Republic of Tunisia,[b][19] is the northernmost country in Africa. It is a part of the Maghreb region of North Africa, bordered by Algeria to the west and southwest, Libya to the southeast, and the Mediterranean Sea to the north and east. ",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1956%2F03%2F20%2Fthumbs%2F153-Flag_of_Tunisia.svg_600x600.jpeg?alt=media&token=2c631555-4de5-4de9-b1ba-fbf229953f03",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1956%2F03%2F20%2F153-Flag_of_Tunisia.svg.png?alt=media&token=89bcca82-02a1-4be9-8018-bfbe246cecbd",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Tunisia",
    },
    {
      id: 154,
      date: "29 Oct 1923",
      time: 0,
      type: 0,
      title: "Turkey Republic Day",
      description:
        "Turkey (Turkish: Türkiye, pronounced [ˈtyɾcije]), officially the Republic of Türkiye (Turkish: Türkiye Cumhuriyeti [ˈtyɾcije dʒumˈhuːɾijeti] i), is a transcontinental country located at the juncture of Southeast Europe and West Asia. It is mainly on the Anatolian Peninsula in West Asia, with a small portion called East Thrace on the Balkan Peninsula in Southeast Europe.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1923%2F10%2F29%2Fthumbs%2F154-Flag_of_Turkey.svg_600x600.jpeg?alt=media&token=dbffe261-e511-4d7c-ad9d-eec86f500341",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1923%2F10%2F29%2F154-Flag_of_Turkey.svg.png?alt=media&token=d6ed0f4c-c9bf-4681-af1f-723d156ac03a",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Turkey",
    },
    {
      id: 155,
      date: "27 Sep 1991",
      time: 0,
      type: 0,
      title: "Turkmenistan Independence Day",
      description:
        "Turkmenistan (/tɜːrkˈmɛnɪstæn/ i or /ˌtɜːrkmɛnɪˈstɑːn/ i; Turkmen: Türkmenistan, pronounced [tʏɾkmønʏˈθːɑːn][14]) is a landlocked country in Central Asia bordered by Kazakhstan to the northwest, Uzbekistan to the north, east and northeast, Afghanistan to the southeast, Iran to the south and southwest and the Caspian Sea to the west.[15] Ashgabat is the capital and largest city.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1991%2F09%2F27%2Fthumbs%2F155-Flag_of_Turkmenistan.svg_600x600.jpeg?alt=media&token=68e62651-4937-4ac9-bdab-9a35d0f8b244",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1991%2F09%2F27%2F155-Flag_of_Turkmenistan.svg.png?alt=media&token=cd9ea92c-08cf-4686-868b-caa148cc5660",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Turkmenistan",
    },
    {
      id: 156,
      date: "30 Aug 1976",
      time: 0,
      type: 0,
      title: "Turks and Caicos Islands Constitution Day",
      description:
        "The Turks and Caicos Islands (abbreviated TCI;[7] /tɜːrks/ and /ˈkeɪkəs, -koʊs, -kɒs/) are a British Overseas Territory consisting of the larger Caicos Islands and smaller Turks Islands, two groups of tropical islands in the Lucayan Archipelago of the Atlantic Ocean and northern West Indies.[8] They are known primarily for tourism and as an offshore financial centre. The resident population in July 2021 was put at 57,196, making it the third-largest of the British overseas territories by population.[7]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1976%2F08%2F30%2Fthumbs%2F156-Flag_of_the_Turks_and_Caicos_Islands.svg_600x600.jpeg?alt=media&token=541e4fa0-ef2e-4b9d-8016-1e56b9266d71",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1976%2F08%2F30%2F156-Flag_of_the_Turks_and_Caicos_Islands.svg.png?alt=media&token=08f717bb-ba2c-494c-b8c5-f87dab4bc402",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Turks_and_Caicos_Islands",
    },
    {
      id: 157,
      date: "1 Oct 1975",
      time: 0,
      type: 0,
      title: "Tuvalu Independence Day",
      description:
        "Tuvalu (/tuːˈvɑːluː/ i too-VAH-loo),[6] formerly known as the Ellice Islands, is an island country in the Polynesian subregion of Oceania in the Pacific Ocean. Its islands are situated about midway between Hawaii and Australia.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1975%2F10%2F01%2Fthumbs%2F157-Flag_of_Tuvalu.svg_600x600.jpeg?alt=media&token=903596e5-9813-4994-97d0-4fc29a7d54c7",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1975%2F10%2F01%2F157-Flag_of_Tuvalu.svg.png?alt=media&token=815a718e-f39e-4320-ab43-75032de31b9e",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Tuvalu",
    },
    {
      id: 158,
      date: "9 Oct 1962",
      time: 0,
      type: 0,
      title: "Uganda Independence Day",
      description:
        "Uganda, officially the Republic of Uganda, is a landlocked country in East Africa. The country is bordered to the east by Kenya, to the north by South Sudan, to the west by the Democratic Republic of the Congo, to the south-west by Rwanda, and to the south by Tanzania.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1962%2F10%2F09%2Fthumbs%2F158-Flag_of_Uganda.svg_600x600.jpeg?alt=media&token=768c85f2-6878-49e9-a9af-1c13e0a7b2c0",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1962%2F10%2F09%2F158-Flag_of_Uganda.svg.png?alt=media&token=a63c9b2f-7690-420a-bb9f-2e40ac5d43aa",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Uganda",
    },
    {
      id: 159,
      date: "24 Aug 1991",
      time: 0,
      type: 0,
      title: "Ukraine Independence Day",
      description:
        "Ukraine (/juːˈkreɪn/ i yoo-KRAYN; Ukrainian: Україна, romanized: Ukraina, pronounced [ʊkrɐˈjinɐ] i) is a country in Eastern Europe. It is the second-largest European country after Russia, which borders it to the east and northeast.[a][11] It also borders Belarus to the north; Poland, Slovakia, and Hungary to the west; and Romania and Moldova[b] to the southwest; with a coastline along the Black Sea and the Sea of Azov to the south and southeast.[c] Kyiv is the nation's capital and largest city, followed by Kharkiv, Dnipro and Odesa. Ukraine's official language is Ukrainian; Russian is also widely spoken, especially in the east and south.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1991%2F08%2F24%2Fthumbs%2F159-Flag_of_Ukraine.svg_600x600.jpeg?alt=media&token=5723bf9c-691a-4a68-a8ee-9d9bb689300a",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1991%2F08%2F24%2F159-Flag_of_Ukraine.svg.png?alt=media&token=390a88f0-b264-4ba4-a880-9fd56b17b783",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Ukraine",
    },
    {
      id: 160,
      date: "2 Dec 1971",
      time: 0,
      type: 0,
      title: "United Arab Emirates National Day",
      description:
        "The United Arab Emirates (UAE; Arabic: الإمارات العربية المتحدة al-ʾImārāt al-ʿArabiyah al-Muttaḥidah[note 2]) is a country in West Asia. It is located at the eastern end of the Arabian Peninsula and shares borders with Oman and Saudi Arabia, while having maritime borders in the Persian Gulf with Qatar and Iran. Abu Dhabi is the nation's capital, while Dubai, the most populous city, is an international hub.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1971%2F12%2F02%2Fthumbs%2F160-Flag_of_the_United_Arab_Emirates.svg_600x600.jpeg?alt=media&token=1c711494-bef3-4ef8-bed2-9912fb5cd380",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1971%2F12%2F02%2F160-Flag_of_the_United_Arab_Emirates.svg.png?alt=media&token=4079b0a4-0565-4eca-b15d-7f9173d46806",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/United_Arab_Emirates",
    },
    {
      id: 161,
      date: "31 Mar 1917",
      time: 0,
      type: 0,
      title: "United States Virgin Islands (United States) Transfer Day",
      description:
        "The United States Virgin Islands,[note 2] officially the Virgin Islands of the United States, are a group of Caribbean islands and an unincorporated and organized territory of the United States.[7] The islands are geographically part of the Virgin Islands archipelago and are located in the Leeward Islands of the Lesser Antilles.[8]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1917%2F03%2F31%2Fthumbs%2F161-Flag_of_the_United_States_Virgin_Islands.svg_600x600.jpeg?alt=media&token=37d6ce48-a04a-442e-9a49-3bf64b9a9890",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1917%2F03%2F31%2F161-Flag_of_the_United_States_Virgin_Islands.svg.png?alt=media&token=7fddd2f4-2a96-44be-a9a1-a92c00b3a2c5",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/United_States_Virgin_Islands",
    },
    {
      id: 162,
      date: "1 Sep 1991",
      time: 0,
      type: 0,
      title: "Uzbekistan Independence Day",
      description:
        "Uzbekistan[a] (UK: /ʊzˌbɛkɪˈstɑːn, ʌz-, -ˈstæn/, US: /ʊzˈbɛkɪstæn, -stɑːn/ i;[10][11] officially the Republic of Uzbekistan[b], is a doubly landlocked country located in Central Asia. It is surrounded by five landlocked countries: Kazakhstan to the north; Kyrgyzstan to the northeast; Tajikistan to the southeast; Afghanistan to the south; and Turkmenistan to the southwest. Its capital and largest city is Tashkent.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1991%2F09%2F01%2Fthumbs%2F162-Flag_of_Uzbekistan.svg_600x600.jpeg?alt=media&token=6931bfd2-584c-4e71-84b5-310262e869df",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1991%2F09%2F01%2F162-Flag_of_Uzbekistan.svg.png?alt=media&token=f50cd38e-121e-4d51-af11-cf120024d40b",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Uzbekistan",
    },
    {
      id: 163,
      date: "30 Jul 1980",
      time: 0,
      type: 0,
      title: "Vanuatu Independence Day",
      description:
        "Vanuatu (English: /ˌvɑːnuˈɑːtuː/ i VAH-noo-AH-too or /vænˈwɑːtuː/ van-WAH-too; Bislama and French pronunciation [vanuatu]), officially the Republic of Vanuatu (French: République de Vanuatu; Bislama: Ripablik blong Vanuatu), is an island country in Melanesia, located in the South Pacific Ocean. The archipelago, which is of volcanic origin, is 1,750 km (1,090 mi) east of northern Australia, 540 km (340 mi) northeast of New Caledonia, east of New Guinea, southeast of Solomon Islands, and west of Fiji.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1980%2F07%2F30%2Fthumbs%2F163-Flag_of_Vanuatu.svg_600x600.jpeg?alt=media&token=384cad42-5b72-461b-b775-d3b75becedec",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1980%2F07%2F30%2F163-Flag_of_Vanuatu.svg.png?alt=media&token=9d6dc622-463a-425e-bc33-f94858b54eaf",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Vanuatu",
    },
    {
      id: 164,
      date: "11 Feb 1929",
      time: 0,
      type: 0,
      title: "Foundation of Vatican City",
      description:
        "Vatican City (/ˈvætɪkən/ i), officially the Vatican City State (Italian: Stato della Città del Vaticano;[f] Latin: Status Civitatis Vaticanæ),[g][h] is a landlocked independent country,[15][16] city-state, microstate, and enclave within Rome, Italy.[17][18] It became independent from Italy in 1929 with the Lateran Treaty, and it is a distinct territory under full ownership, exclusive dominion, and sovereign authority and jurisdiction of the Holy See, itself a sovereign entity under international law, which maintains the city state's temporal power and governance, diplomatic, and spiritual independence.[i][19]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1929%2F02%2F11%2Fthumbs%2F164-Flag_of_Vatican_City_State_-_2023_version.svg_600x600.jpeg?alt=media&token=5bfc88ee-f66d-4f55-84a4-4ecbab654df0",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1929%2F02%2F11%2F164-Flag_of_Vatican_City_State_-_2023_version.svg.png?alt=media&token=0ece0f8a-ae68-4544-9e2b-ec76b5e778bd",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Vatican_City",
    },
    {
      id: 165,
      date: "2 Sep 1945",
      time: 0,
      type: 0,
      title: "Vietnam National Day",
      description:
        "Vietnam[b] (Vietnamese: Việt Nam, [vîət nāːm] i), officially the Socialist Republic of Vietnam (SRV),[c] is a country at the eastern edge of mainland Southeast Asia, with an area of 331,212 square kilometres (127,882 sq mi) and a population of over 100 million, making it the world's fifteenth-most populous country. Vietnam shares land borders with China to the north, and Laos and Cambodia to the west. It shares maritime borders with Thailand through the Gulf of Thailand, and the Philippines, Indonesia, and Malaysia through the South China Sea. Its capital is Hanoi and its largest city is Ho Chi Minh City (commonly referred to by its former name, Saigon).",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1945%2F09%2F02%2Fthumbs%2F165-Flag_of_Vietnam.svg_600x600.jpeg?alt=media&token=f5b59077-ff4b-43bb-b90f-22d974135df1",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1945%2F09%2F02%2F165-Flag_of_Vietnam.svg.png?alt=media&token=c80e76d7-2af6-4fa7-9f3a-6931e1d8d31a",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Vietnam",
    },
    {
      id: 166,
      date: "22 May 1990",
      time: 0,
      type: 0,
      title: "Yemen Unity Day",
      description:
        "Yemen (/ˈjɛmən/ i; Arabic: ٱلْيَمَن, romanized: al-Yaman), officially the Republic of Yemen,[a] is a country in West Asia.[11] It is located in the southern end of the Arabian Peninsula, and borders Saudi Arabia to the north and Oman to the northeast",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1990%2F05%2F22%2Fthumbs%2F166-Flag_of_Yemen.svg_600x600.jpeg?alt=media&token=f31374b7-b9ab-4c9c-b5ab-93963c276c02",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1990%2F05%2F22%2F166-Flag_of_Yemen.svg.png?alt=media&token=8c044ee0-01de-4ce2-a8c7-a0e2b18b4899",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Yemen",
    },
    {
      id: 167,
      date: "24 Oct 1964",
      time: 0,
      type: 0,
      title: "Zambia Independence Day",
      description:
        "Zambia (/ˈzæmbiə, ˈzɑːm-/), officially the Republic of Zambia (Bemba: Icalo ca Zambia; Tonga: Cisi ca Zambia; Tumbuka: Chalo cha Zambia Lozi: Naha ya Zambia; Nyanja: Dziko la Zambia), is a landlocked country at the crossroads of Central, Southern and East Africa.[8]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1964%2F10%2F24%2Fthumbs%2F167-Flag_of_Zambia.svg_600x600.jpeg?alt=media&token=6c2b8349-f7bb-48ec-b56c-c2bb91f160c7",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1964%2F10%2F24%2F167-Flag_of_Zambia.svg.png?alt=media&token=545f55ac-0be2-4140-9891-95cb8d13dfbf",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Zambia",
    },
    {
      id: 168,
      date: "18 Apr 1980",
      time: 0,
      type: 0,
      title: "Zimbabwe Independence Day",
      description:
        "Zimbabwe (/zɪmˈbɑːbweɪ, -wi/), officially the Republic of Zimbabwe, is a landlocked country in Southern Africa, between the Zambezi and Limpopo Rivers, bordered by South Africa to the south, Botswana to the southwest, Zambia to the north, and Mozambique to the east. The capital and largest city is Harare, and the second largest is Bulawayo.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1980%2F04%2F18%2Fthumbs%2F168-Flag_of_Zimbabwe.svg_600x600.jpeg?alt=media&token=a4eaaddc-8f79-4b22-a3f7-8c457d1ec790",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1980%2F04%2F18%2F168-Flag_of_Zimbabwe.svg.png?alt=media&token=91fd75b7-3733-48b2-8208-633ec7c5c4c2",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Zimbabwe",
    },
    {
      id: 1,
      date: "1 Jun 1926",
      time: 0,
      type: 1,
      title: "Marilyn Monroe",
      description: "American actress and model",
      thumbnail: "",
      photo: "",
      imgsource: "",
      link: "https://www.biography.com/actors/marilyn-monroe",
    },
    {
      id: 2,
      date: "8 Feb 1931",
      time: 0,
      type: 1,
      title: "James Dean",
      description: "American actor",
      thumbnail: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1931%2F02%2F08%2F02-James-Dean.jpeg?alt=media&token=4c56997d-5c40-4b9f-9925-8ca38f5999c7",
      imgsource: "https://jamesdean.com/photos/",
      link: "https://jamesdean.com/",
    },
    {
      id: 3,
      date: "9 Aug 1963",
      time: 0,
      type: 1,
      title: "Whitney Houston",
      description: "American singer and actress",
      thumbnail: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1963%2F08%2F09%2F03-Whitney-Houston.jpeg?alt=media&token=bd1c6f50-218f-44cd-8577-d8238540446f",
      imgsource:
        "https://www.whitneyhouston.com/photo_gallery/music-video-photos/",
      link: "https://www.whitneyhouston.com/",
    },
    {
      id: 4,
      date: "26 Nov 1939",
      time: 0,
      type: 1,
      title: "Tina Turner",
      description: "American singer and songwriter",
      thumbnail: "",
      photo: "",
      imgsource: "",
      link: "",
    },
    {
      id: 5,
      date: "4 Apr 1979",
      time: 0,
      type: 1,
      title: "Heath Ledger",
      description: "Australian actor",
      thumbnail: "",
      photo: "",
      imgsource: "",
      link: "",
    },
    {
      id: 6,
      date: "1 Feb 1968",
      time: 0,
      type: 1,
      title: "Lisa Marie Presley",
      description: "American singer-songwriter",
      thumbnail: "",
      photo: "",
      imgsource: "",
      link: "",
    },
    {
      id: 7,
      date: "23 Aug 1970",
      time: 0,
      type: 1,
      title: "River Phoenix",
      description: "American actor and musician",
      thumbnail: "",
      photo: "",
      imgsource: "",
      link: "",
    },
    {
      id: 8,
      date: "12 Sep 1973",
      time: 0,
      type: 1,
      title: "Paul Walker",
      description: "American actor",
      thumbnail: "",
      photo: "",
      imgsource: "",
      link: "",
    },
    {
      id: 9,
      date: "14 Sep 1983",
      time: 0,
      type: 1,
      title: "Amy Winehouse",
      description: "English singer and songwriter",
      thumbnail: "",
      photo: "",
      imgsource: "",
      link: "",
    },
    {
      id: 10,
      date: "7 Jun 1958",
      time: 0,
      type: 1,
      title: "Prince",
      description: "American singer-songwriter and musician",
      thumbnail: "",
      photo: "",
      imgsource: "",
      link: "",
    },
    {
      id: 11,
      date: "29 Nov 1976",
      time: 0,
      type: 1,
      title: "Chadwick Boseman",
      description: "American actor",
      thumbnail: "",
      photo: "",
      imgsource: "",
      link: "",
    },
    {
      id: 12,
      date: "8 Jan 1947",
      time: 0,
      type: 1,
      title: "David Bowie",
      description: "English singer-songwriter and musician",
      thumbnail: "",
      photo: "",
      imgsource: "",
      link: "",
    },
    {
      id: 13,
      date: "22 Jul 1934",
      time: 0,
      type: 1,
      title: "Louise Fetcher",
      description: "American actress",
      thumbnail: "",
      photo: "",
      imgsource: "",
      link: "",
    },
    {
      id: 14,
      date: "28 Nov 1967",
      time: 0,
      type: 1,
      title: "Anna Nicole Smith",
      description: "American model and actress",
      thumbnail: "",
      photo: "",
      imgsource: "",
      link: "",
    },
    {
      id: 15,
      date: "11 May 1982",
      time: 0,
      type: 1,
      title: "Cory Monteith",
      description: "Canadian actor and musician",
      thumbnail: "",
      photo: "",
      imgsource: "",
      link: "",
    },
    {
      id: 16,
      date: "21 Jul 1951",
      time: 0,
      type: 1,
      title: "Robin Williams",
      description: "American actor and comedian",
      thumbnail: "",
      photo: "",
      imgsource: "",
      link: "",
    },
    {
      id: 17,
      date: "13 Jun 1932",
      time: 0,
      type: 1,
      title: "Bob McGrath",
      description: "American actor and musician",
      thumbnail: "",
      photo: "",
      imgsource: "",
      link: "",
    },
    {
      id: 18,
      date: "11 Oct 1966",
      time: 0,
      type: 1,
      title: "Luke Perry",
      description: "American actor",
      thumbnail: "",
      photo: "",
      imgsource: "",
      link: "",
    },
    {
      id: 19,
      date: "21 Oct 1956",
      time: 0,
      type: 1,
      title: "Carrie Fisher",
      description: "American actress and writer",
      thumbnail: "",
      photo: "",
      imgsource: "",
      link: "",
    },
    {
      id: 20,
      date: "29 Aug 1958",
      time: 0,
      type: 1,
      title: "Michael Jackson",
      description: "American singer, songwriter, dancer and philanthropist",
      thumbnail: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1958%2F08%2F29%2F20-Michael-Jackson.jpeg?alt=media&token=7cfd9444-1ffe-4a95-accc-060879bd63c9",
      imgsource:
        "https://www.facebook.com/photo.php?fbid=843856860438381&set=pb.100044422239922.-2207520000&type=3",
      link: "https://www.michaeljackson.com/",
    },
    {
      id: 21,
      date: "27 Mar 1969",
      time: 0,
      type: 1,
      title: "Mariah Carey",
      description: "American singer, songwriter and record producer",
      thumbnail: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1969%2F03%2F27%2F21-Mariah-Carey.png?alt=media&token=db78ebfc-c0a3-4dc3-98ca-4a75869dd6f6",
      imgsource: "https://mariahcarey.com/",
      link: "https://mariahcarey.com/",
    },
    {
      id: 22,
      date: "13 Dec 1989",
      time: 0,
      type: 1,
      title: "Taylor Swift",
      description: "American singer-songwriter",
      thumbnail: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1989%2F12%2F13%2F22-Taylor-Swift.jpeg?alt=media&token=a6da946f-5e39-408f-a035-50761cd28fed",
      imgsource:
        "https://www.facebook.com/photo.php?fbid=844944980330655&set=pb.100044454818615.-2207520000&type=3",
      link: "https://www.taylorswift.com/",
    },
    {
      id: 23,
      date: "17 Feb 1991",
      time: 0,
      type: 1,
      title: "Ed Sheeran",
      description: "English singer-songwriter",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1991%2F02%2F17%2Fthumbs%2F23-Ed-Sheeran_600x600.jpeg?alt=media&token=ec89509c-03a4-404e-9316-ee8554b5f9f9",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1991%2F02%2F17%2F23-Ed-Sheeran.jpeg?alt=media&token=d86b8ffc-22e1-447a-aa9b-21181bc30861",
      imgsource:
        "https://www.facebook.com/EdSheeranMusic/photos/pb.100044477407527.-2207520000/10156184784544486/?type=3",
      link: "https://www.edsheeran.com/",
    },
    {
      id: 24,
      date: "9 Apr 1990",
      time: 0,
      type: 1,
      title: "Kristen Stewart",
      description:
        "American actress known for her role as Bella Swan in the -Twilight - film series.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1990%2F04%2F09%2Fthumbs%2F24-Kristen_Stewart_at_Berlinale_2023_600x600.jpeg?alt=media&token=51fdc3dc-374d-4b97-977e-375f0c7cc521",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1990%2F04%2F09%2F24-Kristen_Stewart_at_Berlinale_2023.jpeg?alt=media&token=b9122f0d-40d0-481a-a9d4-8a373be2dceb",
      imgsource:
        "https://en.wikipedia.org/wiki/Kristen_Stewart#/media/File:Kristen_Stewart_at_Berlinale_2023.jpg",
      link: "https://en.wikipedia.org/wiki/Kristen_Stewart",
    },
    {
      id: 1,
      date: "8 Aug 2023",
      time: 0,
      type: 0,
      title: "Hawaii Wildfire 2023",
      description:
        "Maui faced the threat of destructive wildfires. 📷 People watch as smoke and flames fill the air on Front Street in downtown Lahaina on August 8. Alan Dickar via AP",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2023%2F08%2F08%2Fthumbs%2F01-Hawaii-Wildfire_600x600.jpeg?alt=media&token=7489487c-3fab-4e88-8e00-abe633741900",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2023%2F08%2F08%2F01-Hawaii-Wildfire.jpeg?alt=media&token=53dc3581-79b0-456f-8aba-3c111b37d45b",
      imgsource:
        "https://edition.cnn.com/interactive/2023/08/hawaii-wildfires-timeline-maui-lahaina-dg/index.html",
      link: "https://edition.cnn.com/interactive/2023/08/hawaii-wildfires-timeline-maui-lahaina-dg/index.html",
    },
    {
      id: 2,
      date: "30 Jun 2016",
      time: 0,
      type: 0,
      title: "Rodrigo Duterte became the 16th Philippine President",
      description:
        "Rodrigo Duterte began his presidency in the Philippines on June 30, 2016. He was elected as the 16th President of the Philippines in the 2016 presidential election and served a single six-year term as allowed by the Philippine Constitution. His presidency lasted until June 30, 2022.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2016%2F06%2F30%2Fthumbs%2F02-PH-16thPresident-Rodrigo-Duterte_600x600.jpeg?alt=media&token=17eee556-d887-4f3b-9c6b-76419e2160f7",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2016%2F06%2F30%2F02-PH-16thPresident-Rodrigo-Duterte.jpeg?alt=media&token=7181b5e7-6cf9-4634-a493-c8bb3acc9dc6",
      imgsource:
        "https://en.wikipedia.org/wiki/Rodrigo_Duterte#/media/File:President_Rodrigo_Duterte_portrait_(cropped).jpg",
      link: "https://en.wikipedia.org/wiki/Rodrigo_Duterte",
    },
    {
      id: 3,
      date: "23 Mar 2015",
      time: 0,
      type: 0,
      title: "Death of Singapore's founding father Mr Lee Kuan Yew",
      description:
        "Lee Kuan Yew, the founding Prime Minister of Singapore, passed away on March 23, 2015. He played a significant role in the development and transformation of Singapore into a prosperous and modern city-state. ",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2015%2F03%2F23%2Fthumbs%2F03-Death-Lee-Kuan-Yew_600x600.jpeg?alt=media&token=cd97883f-7725-48dc-9f2d-a043a1614f21",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2015%2F03%2F23%2F03-Death-Lee-Kuan-Yew.png?alt=media&token=88305174-b37a-4b43-88a8-06de85a6a249",
      imgsource:
        "https://mothership.sg/2015/03/lee-kuan-yew-91-has-passed-away/",
      link: "https://www.straitstimes.com/singapore/singapores-founding-father-mr-lee-kuan-yew-dies-aged-91-at-318am-on-monday",
    },
    {
      id: 4,
      date: "29 Oct 2022",
      time: 0,
      type: 0,
      title: "South Korea Crowd Crush ",
      description:
        "A crowd crush happened during a Halloween festivities at Itaewon, South Korea. 📷 Partygoers, many in Halloween costumes, leave the scene of the crowd crush. PHOTO: REUTERS",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2022%2F10%2F29%2Fthumbs%2F04-Seoul-Crowd-Crush_600x600.jpeg?alt=media&token=c3f3fab7-ba33-438e-8cb7-0b29401fa2b0",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2022%2F10%2F29%2F04-Seoul-Crowd-Crush.jpeg?alt=media&token=b53dd6da-05a5-4782-b29f-11ea26021e2b",
      imgsource:
        "https://www.straitstimes.com/multimedia/graphics/2022/10/south-korea-crowd-crush-halloween/index.html?shell",
      link: "https://www.channelnewsasia.com/interactives/seoul-crowd-crush-itaewon-halloween-tragedy-3035276",
    },
    {
      id: 5,
      date: "11 Mar 2020",
      time: 0,
      type: 0,
      title: "COVID-19 Pandemic",
      description:
        "COVID-19, which is caused by the novel coronavirus SARS-CoV-2, was first identified in late 2019 in Wuhan, China, and it quickly spread to become a global pandemic. World Health Organization (WHO) declares COVID-19 a Pandemic on 11 March 2020. ",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2020%2F03%2F11%2Fthumbs%2F05-Coronavirus_600x600.jpeg?alt=media&token=d2e4180e-b697-4e9f-a7c9-222eb4d7344e",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2020%2F03%2F11%2F05-Coronavirus.jpg?alt=media&token=1040525e-7612-448c-b82c-a494b15c38cc",
      imgsource: "https://www.who.int/health-topics/coronavirus#tab=tab_1",
      link: "https://www.ajmc.com/view/a-timeline-of-covid19-developments-in-2020",
    },
    {
      id: 6,
      date: "28 Oct 2022",
      time: 0,
      type: 0,
      title: "Elon Musk buys Twitter",
      description:
        "Elon Musk's acquisition of Twitter for $44 billion has made him the company's majority shareholder.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2022%2F10%2F28%2Fthumbs%2F06-Twitter-Elon-Musk_600x600.jpeg?alt=media&token=70788fb0-83f5-4c49-947b-b4022acaaecf",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2022%2F10%2F28%2F06-Twitter-Elon-Musk.png?alt=media&token=458524be-8455-4913-867e-516669b992eb",
      imgsource:
        "https://www.nbcnews.com/business/business-news/twitter-elon-musk-timeline-what-happened-so-far-rcna57532",
      link: "https://www.nbcnews.com/business/business-news/twitter-elon-musk-timeline-what-happened-so-far-rcna57532",
    },
    {
      id: 7,
      date: "15 Nov 2022",
      time: 0,
      type: 0,
      title: "Day of Eight Billion",
      description: "The world population crossed 8 billion.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2022%2F11%2F15%2Fthumbs%2F07-UN-8Billion_600x600.jpeg?alt=media&token=78342a9d-8c41-4e8a-82e5-f6bbba125bb1",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2022%2F11%2F15%2F07-UN-8Billion.jpeg?alt=media&token=ce09da87-38f9-4ccd-9309-c00040fdd286",
      imgsource: "https://www.un.org/en/dayof8billion",
      link: "https://www.un.org/en/dayof8billion",
    },
    {
      id: 8,
      date: "8 Sep 2022",
      time: 0,
      type: 0,
      title: "Death of Queen Elizabeth II",
      description: "Queen Elizabeth II has died at Balmoral aged 96. ",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2022%2F09%2F08%2Fthumbs%2F08-Death-of-Queen-Elizabeth_600x600.jpeg?alt=media&token=f4ca2973-0b52-4f55-9b5b-2c6bd2b1bd9b",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2022%2F09%2F08%2F08-Death-of-Queen-Elizabeth.jpg?alt=media&token=2f6bfbbd-e893-4247-ba8a-786f0470581e",
      imgsource: "https://www.bbc.com/news/uk-61585886",
      link: "https://www.bbc.com/news/uk-61585886",
    },
    {
      id: 9,
      date: "20 Jun 2021",
      time: 0,
      type: 0,
      title: "Joe Biden becomes 46th US President",
      description:
        "Joseph Robinette Biden Jr. becomes the U.S. 46th President and assumed office on this date. ",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2021%2F06%2F20%2Fthumbs%2F09-Joe-Biden_600x600.jpeg?alt=media&token=7df3b5bc-100b-4721-a214-072d587873a3",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2021%2F06%2F20%2F09-Joe-Biden.jpeg?alt=media&token=25c8f869-3c12-4b2b-80c9-5617b05990d8",
      imgsource:
        "https://en.wikipedia.org/wiki/Joe_Biden#/media/File:Joe_Biden_presidential_portrait.jpg",
      link: "https://en.wikipedia.org/wiki/Joe_Biden",
    },
    {
      id: 10,
      date: "31 Jan 2020",
      time: 0,
      type: 0,
      title: "Britain leaves the European Union",
      description:
        "The UK's EU ambassador Tim Barrow hands the written notification to the Council's Secretary General, Jeppe Tranholm-Mikkelsen",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2020%2F01%2F31%2Fthumbs%2F10-Brexit_600x600.jpeg?alt=media&token=3247cb3b-45b0-4ad8-b92a-15f06da153b3",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2020%2F01%2F31%2F10-Brexit.jpeg?alt=media&token=799266e3-f358-414e-81c0-5dd1728097aa",
      imgsource:
        "https://www.consilium.europa.eu/en/policies/eu-relations-with-the-united-kingdom/the-eu-uk-withdrawal-agreement/timeline-eu-uk-withdrawal-agreement/",
      link: "https://www.consilium.europa.eu/en/policies/eu-relations-with-the-united-kingdom/the-eu-uk-withdrawal-agreement/timeline-eu-uk-withdrawal-agreement/",
    },
    {
      id: 11,
      date: "6 May 2023",
      time: 0,
      type: 0,
      title: "The Coronation of Charles III and Camilla",
      description: "King Charles and Queen Camilla. 📷 GETTY (2)",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2023%2F05%2F06%2Fthumbs%2F11-crowns-Coronation-of-King-Charles-III_600x600.jpeg?alt=media&token=8c69987b-27b6-418a-9215-b7c0a9300898",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2023%2F05%2F06%2F11-crowns-Coronation-of-King-Charles-III.jpeg?alt=media&token=5b4f6360-3b90-42e1-b4ed-b26ef8180f8e",
      imgsource:
        "https://people.com/royals/king-charles-queen-camilla-crowned-coronation-best-photos-exact-moment/",
      link: "https://www.royal.uk/news-and-activity/2023-07-05/scotland-marks-the-coronation-of-king-charles-iii-and-queen-camilla",
    },
    {
      id: 12,
      date: "19 May 2018",
      time: 0,
      type: 0,
      title: "The Wedding of Prince Harry and Ms. Megan Markle",
      description:
        "The wedding of Prince Harry and Meghan Markle took place on May 19, 2018. The ceremony was held at St. George's Chapel in Windsor Castle, United Kingdom. Prince Harry, who is the younger son of Prince Charles and Princess Diana, and Meghan Markle, an American actress known for her role in the television series - Suits, exchanged their vows in a widely-watched and celebrated royal wedding. Meghan Markle became the Duchess of Sussex upon her marriage to Prince Harry. 📷 AARON CHOWN/AFP",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2018%2F05%2F18%2Fthumbs%2F12-Wedding-Prince-Harry-Meghan-Markle_600x600.jpeg?alt=media&token=856b7370-980c-46ac-b4eb-e2400845a41b",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2018%2F05%2F18%2F12-Wedding-Prince-Harry-Meghan-Markle.jpeg?alt=media&token=3048e46d-a96f-4db2-a222-012547cc8c99",
      imgsource:
        "https://people.com/royals/prince-harry-meghan-markle-wedding-details/",
      link: "https://www.royal.uk/royalwedding",
    },
    {
      id: 13,
      date: "29 Apr 2011",
      time: 0,
      type: 0,
      title: "The Wedding of Prince William and Ms. Catherine Middleton",
      description:
        "Prince William and Catherine Middleton, now known as the Duke and Duchess of Cambridge, were married on April 29, 2011, in a widely-watched royal wedding ceremony. 📷 Press Association",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2011%2F04%2F29%2Fthumbs%2F13-Wedding-Prince-William-Kate-Middleton_600x600.jpeg?alt=media&token=9ce251df-15c7-41b7-b83b-2c41686de6d2",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2011%2F04%2F29%2F13-Wedding-Prince-William-Kate-Middleton.jpeg?alt=media&token=fa5fb783-8f20-44f6-b8fd-938a370ca385",
      imgsource:
        "https://www.westminster-abbey.org/history/explore-our-history/the-wedding-of-william-and-kate",
      link: "https://www.royal.uk/wedding-prince-william-and-miss-catherine-middleton",
    },
    {
      id: 14,
      date: "6 Oct 2010",
      time: 0,
      type: 0,
      title: "Instagram launch in iOS App",
      description:
        "Instagram was created by Kevin Systrom and Mike Krieger and quickly gained popularity as a photo and video-sharing social media platform. Since its launch, Instagram has undergone significant growth and introduced various features, including Stories, IGTV, and more, making it one of the most widely used social media platforms globally.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2010%2F10%2F06%2Fthumbs%2F14-Instragram_600x600.jpeg?alt=media&token=f5ed4de7-485f-4aaf-87e9-14bda96ba733",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2010%2F10%2F06%2F14-Instragram.png?alt=media&token=79f4ecba-d845-4514-b1a7-c8dc0b39b201",
      imgsource: "https://www.instagram.com/",
      link: "https://www.investopedia.com/articles/investing/102615/story-instagram-rise-1-photo0sharing-app.asp",
    },
    {
      id: 15,
      date: "21 Apr 2011",
      time: 0,
      type: 0,
      title: "Zoom was founded",
      description:
        "Zoom is a popular video conferencing and online meeting platform that allows users to conduct virtual meetings, webinars, and other collaborative activities over the internet. It was developed by Zoom Video Communications, Inc. and gained widespread popularity, particularly during the COVID-19 pandemic when remote work and virtual meetings became essential.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2011%2F04%2F21%2Fthumbs%2F15-Zoom-Logo_600x600.jpeg?alt=media&token=87370dcb-3bcd-438d-83be-e75f658e2546",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2011%2F04%2F21%2F15-Zoom-Logo.jpeg?alt=media&token=504fcd8f-cba2-4289-a601-aa6516360773",
      imgsource: "https://zoom.us/",
      link: "https://en.wikipedia.org/wiki/Zoom_Video_Communications",
    },
    {
      id: 16,
      date: "29 Jun 2007",
      time: 0,
      type: 0,
      title: "First iPhone (1st Generation) Release",
      description:
        "Apple released the first-generation iPhone on June 29, 2007, revolutionizing the smartphone industry with its touchscreen interface and app ecosystem.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2007%2F06%2F29%2Fthumbs%2F16-iPhone-1st-Gen-Release_600x600.jpeg?alt=media&token=47086b74-19a9-44a3-a142-849879ae6c4c",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2007%2F06%2F29%2F16-iPhone-1st-Gen-Release.jpeg?alt=media&token=5f69cadd-3369-4526-baa2-1e988ff9eb8f",
      imgsource:
        "https://www.computerworld.com/article/3692531/evolution-of-apple-iphone.html#:~:text=After%20months%20of%20rumors%20and,It%20offered%20a%203.5%2Din.",
      link: "https://en.wikipedia.org/wiki/IPhone",
    },
    {
      id: 17,
      date: "20 Jan 2009",
      time: 0,
      type: 0,
      title: "First Presidential Inauguration of Barach Obama",
      description:
        "Barack Obama was inaugurated as the 44th President of the United States, becoming the first African American to hold the office.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2009%2F01%2F20%2Fthumbs%2F17-Oathtaking-President_Barack_Obama_600x600.jpeg?alt=media&token=3e88a6fb-4caf-4b38-bbf4-baa5036bdb5e",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2009%2F01%2F20%2F17-Oathtaking-President_Barack_Obama.jpeg?alt=media&token=4c68dd56-fd55-43b3-bb86-55eb80761906",
      imgsource: "https://en.wikipedia.org/wiki/Barack_Obama",
      link: "https://en.wikipedia.org/wiki/First_inauguration_of_Barack_Obama",
    },
    {
      id: 18,
      date: "18 Dec 2009",
      time: 0,
      type: 0,
      title: "Avatar movie release ",
      description:
        "James Cameron's science fiction film - Avatar - was released in December 2009, becoming a box office success and introducing new 3D filmmaking technologies.  ",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2009%2F12%2F18%2Fthumbs%2F18-Avatar_(2009_film)_poster_600x600.jpeg?alt=media&token=d2745a64-08cf-47b3-a08a-4050e803df95",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2009%2F12%2F18%2F18-Avatar_(2009_film)_poster.jpeg?alt=media&token=7aaa22ee-afdb-4c8c-8c0e-93f7d40e4d9c",
      imgsource:
        "https://en.wikipedia.org/wiki/Avatar_(2009_film)#/media/File:Avatar_(2009_film)_poster.jpg",
      link: "https://en.wikipedia.org/wiki/Avatar_(2009_film)",
    },
    {
      id: 19,
      date: "2 Apr 2005",
      time: 0,
      type: 0,
      title: "Pope John Paul II dies",
      description:
        "Pope John Paul II, whose birth name was Karol Józef Wojtyła, passed away on April 2, 2005, at the age of 84. The cause of his death was attributed to septic shock and multiple organ failure. Pope John Paul II had a long history of health issues, including Parkinson's disease, which had affected him for many years. In the final years of his life, his health deteriorated significantly, and he suffered from various ailments, including respiratory problems and heart issues. These health problems ultimately led to his death in 2005.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2005%2F04%2F02%2Fthumbs%2F19-Pope-John-Paul-II_600x600.jpeg?alt=media&token=0a875d8f-cbdc-4747-a402-7d98a945c94d",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2005%2F04%2F02%2F19-Pope-John-Paul-II.jpeg?alt=media&token=8ec407f9-f0fa-43aa-acf7-5a2206d2241e",
      imgsource:
        "https://en.wikipedia.org/wiki/Pope_John_Paul_II#/media/File:Ritratto_di_papa_Giovanni_Paolo_II_(1984_%E2%80%93_edited).jpg",
      link: "https://www.vatican.va/content/john-paul-ii/en/biografia/documents/hf_jp-ii_spe_20190722_biografia.html",
    },
    {
      id: 20,
      date: "14 Feb 2005",
      time: 0,
      type: 0,
      title: "YouTube is founded",
      description:
        "YouTube, the popular video-sharing platform, was founded in February 2005. It quickly became a revolutionary platform for sharing and viewing online videos.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2005%2F02%2F14%2Fthumbs%2F20-YouTube-New_600x600.jpeg?alt=media&token=91db01c5-d719-4cc1-958a-8daaa4424aad",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2005%2F02%2F14%2F20-YouTube-New.png?alt=media&token=f4d87835-e3fc-4cd4-830e-d1de60f1eef2",
      imgsource: "https://www.youtube.com/",
      link: "https://en.wikipedia.org/wiki/YouTube",
    },
    {
      id: 21,
      date: "20 Jan 2001",
      time: 0,
      type: 0,
      title: "First Presidential Inauguration of George W. Bush",
      description:
        "George W. Bush was elected as the 43rd President of the United States after a controversial election that included a recount in Florida and a Supreme Court decision.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2001%2F01%2F20%2Fthumbs%2F21-George-W-Bush_600x600.jpeg?alt=media&token=1a03dd0a-b56c-4e42-bb81-49c7aee84805",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F2001%2F01%2F20%2F21-George-W-Bush.jpeg?alt=media&token=fa45e5ff-8eba-4b3e-9455-159359221623",
      imgsource:
        "https://en.wikipedia.org/wiki/George_W._Bush#/media/File:George-W-Bush.jpeg",
      link: "https://en.wikipedia.org/wiki/First_inauguration_of_George_W._Bush",
    },
    {
      id: 22,
      date: "10 May 1994",
      time: 0,
      type: 0,
      title: "Nelson Mandela's Inauguration",
      description:
        "Nelson Mandela was elected as the South Africa's first black president. Mandela's presidential inauguration, on May 10, 1994, was a historic event symbolizing the end of apartheid and the beginning of a new era in South Africa.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1994%2F05%2F10%2Fthumbs%2F22-Nelson_Mandela_1994_600x600.jpeg?alt=media&token=7f7fd3ff-d704-4d6a-b847-6f110bbfcb06",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1994%2F05%2F10%2F22-Nelson_Mandela_1994.jpeg?alt=media&token=e96fee92-fd95-4a9b-95db-19d73eca9b5f",
      imgsource:
        "https://en.wikipedia.org/wiki/Nelson_Mandela#/media/File:Nelson_Mandela_1994.jpg",
      link: "https://en.wikipedia.org/wiki/Presidency_of_Nelson_Mandela",
    },
    {
      id: 23,
      date: "25 Jun 1993",
      time: 0,
      type: 0,
      title: "Kim Campbell Becomes Canada's First Female Prime Minister",
      description:
        "Kim Campbell became the first woman to serve as Canada's Prime Minister when she assumed office on June 25, 1993, although her time in office was brief.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1993%2F06%2F25%2Fthumbs%2F23-Kim_Campbell_600x600.jpeg?alt=media&token=048ab577-f9a9-47ff-8206-e0db6edf61b4",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1993%2F06%2F25%2F23-Kim_Campbell.jpeg?alt=media&token=123b89c9-496f-473a-9091-549b8409e915",
      imgsource:
        "https://en.wikipedia.org/wiki/Kim_Campbell#/media/File:Kim_Campbell.jpg",
      link: "https://en.wikipedia.org/wiki/Kim_Campbell",
    },
    {
      id: 24,
      date: "11 Jun 1993",
      time: 0,
      type: 0,
      title: "Jurassic Park Release",
      description:
        "The blockbuster film - Jurassic Park - directed by Steven Spielberg and based on Michael Crichton's novel, was released in 1993, showcasing groundbreaking special effects.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1993%2F06%2F11%2Fthumbs%2F24-Jurassic_Park_poster_600x600.jpeg?alt=media&token=86aec1cf-3f67-4193-b144-2bf020467d1d",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1993%2F06%2F11%2F24-Jurassic_Park_poster.jpeg?alt=media&token=19a7a9b3-cfbf-4cfe-a89f-c9c2648351c6",
      imgsource:
        "https://en.wikipedia.org/wiki/Jurassic_Park_(film)#/media/File:Jurassic_Park_poster.jpg",
      link: "https://en.wikipedia.org/wiki/Jurassic_Park_(film)",
    },
    {
      id: 25,
      date: "16 Aug 1992",
      time: 0,
      type: 0,
      title: "Hurricane Andrew",
      description:
        "Hurricane Andrew struck Florida and Louisiana in August 1992, causing extensive damage and becoming one of the costliest natural disasters in U.S. history at the time.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1992%2F08%2F16%2Fthumbs%2F25-Andrew_1992-08-23_1231Z_(Cropped)_600x600.jpeg?alt=media&token=89703848-b208-4e66-b947-3d82757281d9",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1992%2F08%2F16%2F25-Andrew_1992-08-23_1231Z_(Cropped).png?alt=media&token=513b4bb3-3a41-4b27-81cd-82e23d0b7a74",
      imgsource:
        "https://en.wikipedia.org/wiki/Hurricane_Andrew#/media/File:Andrew_1992-08-23_1231Z_(Cropped).png",
      link: "https://en.wikipedia.org/wiki/Hurricane_Andrew",
    },
    {
      id: 26,
      date: "20 Jan 1993",
      time: 0,
      type: 0,
      title: "Bill Clinton Elected President",
      description:
        "In the United States, Bill Clinton was elected as the 42nd President, defeating incumbent President George H.W. Bush and independent candidate Ross Perot.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1993%2F01%2F20%2Fthumbs%2F26-Bill_Clinton_600x600.jpeg?alt=media&token=5503215f-b115-4a7e-84a8-38f5e64b6246",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1993%2F01%2F20%2F26-Bill_Clinton.jpeg?alt=media&token=13c2dcd5-2ea7-499e-90a9-ea1657676ecb",
      imgsource:
        "https://en.wikipedia.org/wiki/Bill_Clinton#/media/File:Bill_Clinton.jpg",
      link: "https://en.wikipedia.org/wiki/Bill_Clinton",
    },
    {
      id: 27,
      date: "25 Jul 1992",
      time: 0,
      type: 0,
      title: "Barcelona Olympics",
      description:
        "The 1992 Summer Olympics, officially known as the Games of the XXV Olympiad, were held in Barcelona, Spain, bringing together athletes from around the world.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1992%2F07%2F25%2Fthumbs%2F27-Barcelona-Olypmics_600x600.jpeg?alt=media&token=8d1bac26-9a92-43c5-a4e9-268720e63881",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1992%2F07%2F25%2F27-Barcelona-Olypmics.png?alt=media&token=1e986523-12fe-4087-9425-e121c68f4821",
      imgsource:
        "https://en.wikipedia.org/wiki/1992_Summer_Olympics#/media/File:1992_Summer_Olympics_logo.svg",
      link: "https://en.wikipedia.org/wiki/1992_Summer_Olympics",
    },
    {
      id: 28,
      date: "12 Jun 1991",
      time: 0,
      type: 0,
      title: "Mount Pinatubo Eruption",
      description:
        "Mount Pinatubo in the Philippines began showing signs of eruption activity in April 1991. The volcano's eruption escalated over the following months, with first eruption occuring on June 12, 1991 and major eruption occurring on June 15, 1991. This eruption was one of the largest volcanic eruptions of the 20th century and had significant environmental and climatic impacts.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1991%2F06%2F12%2Fthumbs%2F28-Pinatubo_Ausbruch_1991_600x600.jpeg?alt=media&token=43791c9e-393f-437b-95d0-0f1bc3449ade",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1991%2F06%2F12%2F28-Pinatubo_Ausbruch_1991.jpeg?alt=media&token=a83cc9f5-118c-40c0-b9fe-f114eb454124",
      imgsource:
        "https://en.wikipedia.org/wiki/1991_eruption_of_Mount_Pinatubo#/media/File:Pinatubo_Ausbruch_1991.jpg",
      link: "https://en.wikipedia.org/wiki/1991_eruption_of_Mount_Pinatubo",
    },
    {
      id: 29,
      date: "22 Dec 1990",
      time: 0,
      type: 0,
      title: "Lech Wałęsa Becomes President of Poland",
      description:
        "Lech Wałęsa, the leader of the Solidarity trade union movement, was elected as President of Poland in December 1990.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1990%2F12%2F22%2Fthumbs%2F29-Lech_Wa%C5%82e%CC%A8sa_(2019)%2C_FORUM_2000%2C_Prague_(2)_600x600.jpeg?alt=media&token=1661d7d0-c7de-4612-81ee-a04925f8b099",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1990%2F12%2F22%2F29-Lech_Wa%C5%82e%CC%A8sa_(2019)%2C_FORUM_2000%2C_Prague_(2).jpeg?alt=media&token=a4c686d7-ca5f-4e3c-b58d-7da6b0f7f767",
      imgsource:
        "https://en.wikipedia.org/wiki/Lech_Wa%C5%82%C4%99sa#/media/File:Lech_Wa%C5%82%C4%99sa_(2019),_FORUM_2000,_Prague_(2).jpg",
      link: "https://en.wikipedia.org/wiki/Lech_Wa%C5%82%C4%99sa",
    },
    {
      id: 30,
      date: "16 Nov 1990",
      time: 0,
      type: 0,
      title: "Home Alone movie release",
      description:
        "Home Alone is a classic comedy film directed by Chris Columbus and written by John Hughes. It was released in 1990 and has since become a beloved holiday movie. The film stars Macaulay Culkin as Kevin McCallister, a young boy who is accidentally left behind when his family goes on vacation for Christmas.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1990%2F11%2F16%2Fthumbs%2F30-Home_alone_poster_600x600.jpeg?alt=media&token=1b066c3d-0a9e-4d3f-81fa-3e961ae8ca91",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1990%2F11%2F16%2F30-Home_alone_poster.jpeg?alt=media&token=5c276522-6bec-4a64-98f8-ea9e47b8b9ad",
      imgsource:
        "https://en.wikipedia.org/wiki/Home_Alone#/media/File:Home_alone_poster.jpg",
      link: "https://en.wikipedia.org/wiki/Home_Alone",
    },
    {
      id: 31,
      date: "9 Nov 1989",
      time: 0,
      type: 0,
      title: "Fall of Berlin Wall",
      description:
        "On November 9, 1989, the Berlin Wall, which had divided East and West Berlin for nearly 30 years, was opened, leading to the eventual reunification of Germany.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1989%2F11%2F9%2Fthumbs%2F31-Berlin-Wall_600x600.jpeg?alt=media&token=0d5facb5-75d4-450e-85fb-95522e00a06b",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1989%2F11%2F9%2F31-Berlin-Wall.jpeg?alt=media&token=fe163646-73fc-4479-93ac-059f7af036d3",
      imgsource:
        "https://en.wikipedia.org/wiki/Berlin_Wall#/media/File:Berlinermauer.jpg",
      link: "https://en.wikipedia.org/wiki/Berlin_Wall",
    },
    {
      id: 32,
      date: "24 Mar 1989",
      time: 0,
      type: 0,
      title: "Exxon Valdez Oil Spill",
      description:
        "The Exxon Valdez oil tanker ran aground in Alaska's Prince William Sound, resulting in one of the largest oil spills in U.S. history.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1989%2F03%2F24%2Fthumbs%2F32-Exxon-Valdez-Oil-Spill_600x600.jpeg?alt=media&token=8e23f398-4fbb-4e80-b150-be2f31b1f0f8",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1989%2F03%2F24%2F32-Exxon-Valdez-Oil-Spill.jpeg?alt=media&token=fafb2bce-725d-465b-8348-0a78eb7957ae",
      imgsource:
        "https://en.wikipedia.org/wiki/Exxon_Valdez_oil_spill#/media/File:Exval.jpeg",
      link: "https://en.wikipedia.org/wiki/Exxon_Valdez_oil_spill",
    },
    {
      id: 33,
      date: "21 Dec 1988",
      time: 0,
      type: 0,
      title: "Pan Am Flight 103 Bombing",
      description:
        "Pan Am Flight 103 was bombed over Lockerbie, Scotland, killing all 270 people on board. It was one of the deadliest terrorist attacks in history.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1988%2F12%2F21%2Fthumbs%2F33-Pan_Am_Flight_103._Crashed_Lockerbie%2C_Scotland%2C_21_December_1988_600x600.jpeg?alt=media&token=015fde8f-8d75-40f8-9fc6-80c4b09421dd",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1988%2F12%2F21%2F33-Pan_Am_Flight_103._Crashed_Lockerbie%2C_Scotland%2C_21_December_1988.jpg?alt=media&token=c866a015-9a1c-458f-ab8f-9216347cf1ee",
      imgsource:
        "https://en.wikipedia.org/wiki/Pan_Am_Flight_103#/media/File:Pan_Am_Flight_103._Crashed_Lockerbie,_Scotland,_21_December_1988.jpg",
      link: "https://en.wikipedia.org/wiki/Pan_Am_Flight_103",
    },
    {
      id: 34,
      date: "17 Sep 1988",
      time: 0,
      type: 0,
      title: "Olympic Summer Games in Seoul",
      description:
        "The 1988 Summer Olympics were held in Seoul, South Korea, showcasing athletes from around the world.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1988%2F09%2F17%2Fthumbs%2F34-Seoul-Olympics_600x600.jpeg?alt=media&token=bcc7261d-c073-40b7-9c04-d9055d606201",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1988%2F09%2F17%2F34-Seoul-Olympics.png?alt=media&token=23d600c5-2cf9-4a1d-a2bd-febc78dfbfa6",
      imgsource:
        "https://en.wikipedia.org/wiki/1988_Summer_Olympics#/media/File:1988_Summer_Olympics_logo.svg",
      link: "https://en.wikipedia.org/wiki/1988_Summer_Olympics",
    },
    {
      id: 35,
      date: "13 Feb 1988",
      time: 0,
      type: 0,
      title: "1988 Winter Olympics",
      description:
        "The 1988 Winter Olympics were held in Calgary, Canada, featuring various winter sports.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1988%2F02%2F13%2Fthumbs%2F35-Calgary-Olympics_600x600.jpeg?alt=media&token=cbc58f73-d4fd-4f76-bfaa-207f7561c8c7",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1988%2F02%2F13%2F35-Calgary-Olympics.png?alt=media&token=45b03ae1-3b29-4880-b057-eceb799ade01",
      imgsource:
        "https://en.wikipedia.org/wiki/1988_Winter_Olympics#/media/File:1988_Winter_Olympics_logo.svg",
      link: "https://en.wikipedia.org/wiki/1988_Winter_Olympics",
    },
    {
      id: 36,
      date: "11 Mar 1985",
      time: 0,
      type: 0,
      title: "Mikhail Gorbachev Becomes Soviet Leader",
      description:
        "In March 1985, Mikhail Gorbachev became the General Secretary of the Communist Party of the Soviet Union, initiating a series of reforms, including perestroika (economic restructuring) and glasnost (political openness), which would lead to significant changes in the USSR.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1985%2F03%2F11%2Fthumbs%2F36-RIAN_archive_850809_General_Secretary_of_the_CPSU_CC_M._Gorbachev_(cropped)_600x600.jpeg?alt=media&token=35b56937-a65f-48b8-9720-5535d8f5b884",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1985%2F03%2F11%2F36-RIAN_archive_850809_General_Secretary_of_the_CPSU_CC_M._Gorbachev_(cropped).jpg?alt=media&token=d58e4bb3-b10d-4e35-a3c1-37af8792623c",
      imgsource:
        "https://en.wikipedia.org/wiki/Mikhail_Gorbachev#/media/File:RIAN_archive_850809_General_Secretary_of_the_CPSU_CC_M._Gorbachev_(cropped).jpg",
      link: "https://en.wikipedia.org/wiki/Mikhail_Gorbachev",
    },
    {
      id: 37,
      date: "7 Oct 1985",
      time: 0,
      type: 0,
      title: "Achille Lauro Hijacking",
      description:
        "The Italian cruise ship Achille Lauro was hijacked by Palestinian militants, resulting in the death of one passenger, Leon Klinghoffer.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1985%2F10%2F07%2Fthumbs%2F37-Achille39_600x600.jpeg?alt=media&token=2fe3fb6c-c8f0-44af-9e83-4aecadd8021e",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1985%2F10%2F07%2F37-Achille39.jpeg?alt=media&token=a4e98837-68d8-4925-b1f0-d07f40b64dc2",
      imgsource:
        "https://en.wikipedia.org/wiki/Achille_Lauro_hijacking#/media/File:Achille39.jpg",
      link: "https://en.wikipedia.org/wiki/Achille_Lauro_hijacking",
    },
    {
      id: 38,
      date: "3 Jul 1985",
      time: 0,
      type: 0,
      title: "Back to the Future movie released",
      description:
        "Back to the Future is a classic science fiction film released in 1985. It was directed by Robert Zemeckis and written by Robert Zemeckis and Bob Gale. The film blends elements of science fiction, comedy, and adventure.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1985%2F07%2F03%2Fthumbs%2F38-Back_to_the_Future_600x600.jpeg?alt=media&token=fdbeee03-612e-4af2-a092-50fa4107f862",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1985%2F07%2F03%2F38-Back_to_the_Future.jpeg?alt=media&token=ca2c200f-eb32-4e2e-8cb1-f6c8ab5c92f0",
      imgsource:
        "https://en.wikipedia.org/wiki/Back_to_the_Future#/media/File:Back_to_the_Future.jpg",
      link: "https://en.wikipedia.org/wiki/Back_to_the_Future",
    },
    {
      id: 39,
      date: "5 Aug 1993",
      time: 0,
      type: 0,
      title: "Magic: The Gathering game released",
      description:
        "This day marked a significant moment in the world of gaming and pop culture with the release of - Magic: The Gathering - a collectible card game created by mathematician Richard Garfield and published by Wizards of the Coast. The game introduced a unique blend of strategy, fantasy, and collectibility that captured the imaginations of players worldwide.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1993%2F08%2F05%2Fthumbs%2F39-Magic_the_gathering-card_back_600x600.jpeg?alt=media&token=da0a0c5b-ba6e-4206-b3b2-612189427037",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1993%2F08%2F05%2F39-Magic_the_gathering-card_back.jpeg?alt=media&token=42e2d1bb-3efa-4d70-94de-50f067227a24",
      imgsource:
        "https://en.wikipedia.org/wiki/Magic:_The_Gathering#/media/File:Magic_the_gathering-card_back.jpg",
      link: "https://en.wikipedia.org/wiki/Magic:_The_Gathering",
    },
    {
      id: 40,
      date: "31 Oct 1984",
      time: 0,
      type: 0,
      title: "Indira Gandhi Assassination",
      description:
        "Indian Prime Minister Indira Gandhi was assassinated by two of her bodyguards in New Delhi, leading to widespread anti-Sikh riots in India.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1984%2F10%2F31%2Fthumbs%2F40-Indira_Gandhi_official_portrait_600x600.jpeg?alt=media&token=350a6243-d7cd-45d3-8dfb-4b97249fe655",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1984%2F10%2F31%2F40-Indira_Gandhi_official_portrait.png?alt=media&token=6cf5db67-2415-4b12-b31a-b9a9c1eeb424",
      imgsource:
        "https://en.wikipedia.org/wiki/Indira_Gandhi#/media/File:Indira_Gandhi_official_portrait.png",
      link: "https://en.wikipedia.org/wiki/Indira_Gandhi",
    },
    {
      id: 41,
      date: "24 Jan 1984",
      time: 0,
      type: 0,
      title: "Apple Macintosh Computer",
      description:
        "Apple Inc. introduced the Macintosh (The Mac) computer, featuring a graphical user interface and a mouse, revolutionizing personal computing. 📷 Steve Jobs with the original Macintosh, January 1984, photographed by Bernard Gotfryd[a]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1984%2F01%2F24%2Fthumbs%2F41-Steve_Jobs_and_Macintosh_computer%2C_January_1984%2C_by_Bernard_Gotfryd_-_edited_600x600.jpeg?alt=media&token=3c014070-3a88-47e2-a412-675984f8bb8e",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1984%2F01%2F24%2F41-Steve_Jobs_and_Macintosh_computer%2C_January_1984%2C_by_Bernard_Gotfryd_-_edited.jpeg?alt=media&token=a7e35497-92d2-40a5-9dd8-686c82f19304",
      imgsource:
        "https://en.wikipedia.org/wiki/Mac_(computer)#/media/File:Steve_Jobs_and_Macintosh_computer,_January_1984,_by_Bernard_Gotfryd_-_edited.jpg",
      link: "https://en.wikipedia.org/wiki/Mac_(computer)",
    },
    {
      id: 42,
      date: "30 Aug 1984",
      time: 0,
      type: 0,
      title: "Space Shuttle Discovery's Maiden Flight",
      description:
        "Space Shuttle Discovery's maiden flight was a significant event in the history of space exploration. Discovery was the third orbiter in NASA's Space Shuttle program, and its maiden flight was known as STS-41-D. 📷 The crew assigned to the STS-41D mission included (seated left to right) Richard M. (Mike) Mullane, mission specialist; Steven A. Hawley, mission specialist; Henry W. Hartsfield, commander; and Michael L. (Mike) Coats, pilot. Standing in the rear are Charles D. Walker, payload specialist; and Judith A. (Judy) Resnik, mission specialist. Launched aboard the Space Shuttle Discovery August 30, 1984 at 8:41:50 am (EDT), the STS-41D mission deployed three satellites: the Satellite Business System SBS-D; the SYCOM IV-2 (also known as LEASAT-2); and the TELSTAR.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1984%2F08%2F30%2Fthumbs%2F42-Space-Shuttle-Maiden_600x600.jpeg?alt=media&token=e790a311-3a46-4f59-be9e-0ba5b49ca0a3",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1984%2F08%2F30%2F42-Space-Shuttle-Maiden.jpeg?alt=media&token=90b6329f-62bd-464f-a3b5-4af91c1f80ed",
      imgsource:
        "https://en.wikipedia.org/wiki/STS-41-D#/media/File:STS-41-D_crew.jpg",
      link: "https://en.wikipedia.org/wiki/STS-41-D",
    },
    {
      id: 43,
      date: "2 Dec 1984",
      time: 0,
      type: 0,
      title: "Bhopal Gas Tragedy",
      description:
        "A deadly gas leak occurred at the Union Carbide pesticide plant in Bhopal, India, resulting in one of the worst industrial disasters in history. 📷 Exterior view of the Union Carbide pesticide factory in Bhopal, India, scene of the world's worst industrial disaster in 1985. Interior shot of the factory before it was stripped of some of its most valuable components and subsequently abandoned to the elements. The factory remains ruined and abandoned and approximately 350 metric tonnes of toxic waste are stored there above ground. Many thousands of tonnes of toxic waste were also buried, in an untreated state, on the site. Rainwater continues to run through this buried waste as well as the remains of the abandoned solar evaporation ponds. This leaches toxic chemicals into the local groundwater aquifer, which serves as the primary water supply for thousands of people.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1984%2F12%2F02%2Fthumbs%2F43-Union_Carbide_pesticide_factory%2C_Bhopal%2C_India%2C_1985_600x600.jpeg?alt=media&token=ff265627-4651-4148-83f3-5d280a4e7a0c",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1984%2F12%2F02%2F43-Union_Carbide_pesticide_factory%2C_Bhopal%2C_India%2C_1985.jpeg?alt=media&token=afb21935-549d-4167-af99-eee23333b84b",
      imgsource:
        "https://en.wikipedia.org/wiki/Bhopal_disaster#/media/File:Union_Carbide_pesticide_factory,_Bhopal,_India,_1985.jpg",
      link: "https://en.wikipedia.org/wiki/Bhopal_disaster",
    },
    {
      id: 44,
      date: "18 Jun 1983",
      time: 0,
      type: 0,
      title: "Sally Ride Becomes First American Woman in Space",
      description:
        "Sally Ride became the first American woman to travel into space when she flew aboard the Space Shuttle Challenger on STS-7.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1983%2F06%2F18%2Fthumbs%2F44-Sally-Ride_600x600.jpeg?alt=media&token=1a59144f-ceeb-4f45-b161-9ec82ff8edca",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1983%2F06%2F18%2F44-Sally-Ride.jpeg?alt=media&token=a4716703-a9b0-4cff-8313-ff3cdddc49fc",
      imgsource:
        "https://en.wikipedia.org/wiki/Sally_Ride#/media/File:Sally_Ride_(1984).jpg",
      link: "https://en.wikipedia.org/wiki/Sally_Ride",
    },
    {
      id: 45,
      date: "18 Apr 1983",
      time: 0,
      type: 0,
      title: "U.S. Embassy Bombing in Beirut",
      description:
        "A suicide bombing targeted the U.S. Embassy in Beirut, Lebanon, killing 63 people, including 17 Americans.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1983%2F04%2F18%2Fthumbs%2F45-Beirutembassy_600x600.jpeg?alt=media&token=018b9855-22a0-4cfa-a585-edecd17f6d8d",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1983%2F04%2F18%2F45-Beirutembassy.jpeg?alt=media&token=a92f867c-f59b-43e9-95fd-6a72ace461bc",
      imgsource:
        "https://en.wikipedia.org/wiki/1983_US_embassy_bombing_in_Beirut#/media/File:Beirutembassy.jpg",
      link: "https://en.wikipedia.org/wiki/1983_US_embassy_bombing_in_Beirut",
    },
    {
      id: 46,
      date: "21 Sep 1983",
      time: 0,
      type: 0,
      title: "Motorola DynaTAC 8000X",
      description:
        "The Motorola DynaTAC 8000X, commonly known as the - brick phone - became the first commercially available handheld mobile phone.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1983%2F09%2F21%2Fthumbs%2F46-DynaTAC8000X_600x600.jpeg?alt=media&token=dec682aa-29ef-4745-aea7-8afc01467e29",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1983%2F09%2F21%2F46-DynaTAC8000X.png?alt=media&token=cc6bdd9d-f71b-488e-b945-fcce71ba5ba3",
      imgsource:
        "https://en.wikipedia.org/wiki/Motorola_DynaTAC#/media/File:DynaTAC8000X.jpg",
      link: "https://en.wikipedia.org/wiki/Motorola_DynaTAC",
    },
    {
      id: 47,
      date: "3 Apr 1973",
      time: 0,
      type: 0,
      title: "First Mobile Phone demo",
      description:
        "Martin Cooper of Motorola, shown here in a 2007 reenactment, made the first publicized handheld mobile phone call on a prototype DynaTAC model on 3 April 1973.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1973%2F04%2F03%2Fthumbs%2F47-Martin-Cooper-Phone_600x600.jpeg?alt=media&token=ff836425-d3e1-4ad2-8e14-3507e8f7fe83",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1973%2F04%2F03%2F47-Martin-Cooper-Phone.jpeg?alt=media&token=d5477d28-b8f6-4ffb-b15a-3adeab443c88",
      imgsource:
        "https://en.wikipedia.org/wiki/Mobile_phone#/media/File:2007Computex_e21Forum-MartinCooper.jpg",
      link: "https://en.wikipedia.org/wiki/Mobile_phone",
    },
    {
      id: 48,
      date: "18 May 1980",
      time: 0,
      type: 0,
      title: "Eruption of Mount St. Helens",
      description:
        "📷 On May 18, 1980, at 8:32 a.m. Pacific Daylight Time, a magnitude 5.1 earthquake shook Mount St. Helens. The bulge and surrounding area slid away in a gigantic rockslide and debris avalanche, releasing pressure, and triggering a major pumice and ash eruption of the volcano. Thirteen-hundred feet (400 meters) of the peak collapsed or blew outwards. As a result, 24 square miles (62 square kilometers) of valley was filled by a debris avalanche, 250 square miles (650 square kilometers) of recreation, timber, and private lands were damaged by a lateral blast, and an estimated 200 million cubic yards (150 million cubic meters) of material was deposited directly by lahars (volcanic mudflows) into the river channels. Fifty-seven people were killed or are still missing. USGS Photograph taken on May 18, 1980, by Austin Post.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1980%2F05%2F18%2Fthumbs%2F48-Mt.Helens-Eruption_600x600.jpeg?alt=media&token=739ced3d-7f20-44f0-86c1-fdb9c988ca7e",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1980%2F05%2F18%2F48-Mt.Helens-Eruption.jpeg?alt=media&token=cfea4cac-eb6d-4983-9ed7-14ef98944413",
      imgsource:
        "https://en.wikipedia.org/wiki/1980_eruption_of_Mount_St._Helens#/media/File:MSH80_eruption_mount_st_helens_05-18-80-dramatic-edit.jpg",
      link: "https://en.wikipedia.org/wiki/1980_eruption_of_Mount_St._Helens",
    },
    {
      id: 49,
      date: "5 Mar 1982",
      time: 0,
      type: 0,
      title: "John Belushi's Death",
      description:
        "Actor and comedian John Belushi died of a drug overdose at the age of 33. 📷 John Belushi at the 32nd Annual Radio and Television Correspondents Association Dinner - NARA - 30805929",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1982%2F03%2F05%2Fthumbs%2F49-John_Belushi_at_the_32nd_Annual_Radio_and_Television_Correspondents_Association_Dinner_-_NARA_-_30805929_600x600.jpeg?alt=media&token=27c94051-2fd2-4548-a374-1efbfd4a7ed8",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1982%2F03%2F05%2F49-John_Belushi_at_the_32nd_Annual_Radio_and_Television_Correspondents_Association_Dinner_-_NARA_-_30805929.jpeg?alt=media&token=4d7439d4-ddd9-413d-8f33-35cd837ba9f6",
      imgsource:
        "https://en.wikipedia.org/wiki/John_Belushi#/media/File:John_Belushi_at_the_32nd_Annual_Radio_and_Television_Correspondents_Association_Dinner_-_NARA_-_30805929.jpg",
      link: "https://en.wikipedia.org/wiki/John_Belushi",
    },
    {
      id: 50,
      date: "20 Jan 1981",
      time: 0,
      type: 0,
      title: "Inauguration of Ronald Reagan",
      description:
        "Ronald Reagan was inaugurated as the 40th President of the United States, with George H.W. Bush as Vice President.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1981%2F01%2F20%2Fthumbs%2F50-Official_Portrait_of_President_Reagan_1981_600x600.jpeg?alt=media&token=97f25145-e7e1-402e-affd-474b603e9139",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1981%2F01%2F20%2F50-Official_Portrait_of_President_Reagan_1981.jpeg?alt=media&token=7a60c413-baae-4364-9bd8-dfe8a6d835f1",
      imgsource:
        "https://en.wikipedia.org/wiki/Ronald_Reagan#/media/File:Official_Portrait_of_President_Reagan_1981.jpg",
      link: "https://en.wikipedia.org/wiki/First_inauguration_of_Ronald_Reagan",
    },
    {
      id: 51,
      date: "12 Aug 1981",
      time: 0,
      type: 0,
      title: "Release of the IBM PC",
      description:
        "IBM introduced its first personal computer, the IBM PC. It played a significant role in popularizing personal computing. 📷 IBM Personal Computer On display at the Musée Bolo, EPFL, Lausanne.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1981%2F08%2F12%2Fthumbs%2F51-IBM-PC_600x600.jpeg?alt=media&token=f7f2bfde-66a3-49dd-8965-d0bc19277cca",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1981%2F08%2F12%2F51-IBM-PC.png?alt=media&token=5d85da47-c497-489d-b183-bf8d2e121490",
      imgsource:
        "https://en.wikipedia.org/wiki/IBM_Personal_Computer#/media/File:IBM_PC-IMG_7271_(transparent).png",
      link: "https://en.wikipedia.org/wiki/IBM_Personal_Computer#",
    },
    {
      id: 52,
      date: "29 Jul 1981",
      time: 0,
      type: 0,
      title: "Prince Charles and Lady Diana's Wedding",
      description:
        "Prince Charles of Wales married Lady Diana Spencer in a lavish royal wedding watched by millions worldwide.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1981%2F07%2F29%2Fthumbs%2F52-Wedding_of_Charles%2C_Prince_of_Wales%2C_and_Lady_Diana_Spencer_photo_600x600.jpeg?alt=media&token=28c8124f-99ce-4cc2-8b77-a928ce54344d",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1981%2F07%2F29%2F52-Wedding_of_Charles%2C_Prince_of_Wales%2C_and_Lady_Diana_Spencer_photo.png?alt=media&token=f28234d1-3e2a-4d1e-a745-cd3849eb93ae",
      imgsource:
        "https://en.wikipedia.org/wiki/Wedding_of_Prince_Charles_and_Lady_Diana_Spencer#/media/File:Wedding_of_Charles,_Prince_of_Wales,_and_Lady_Diana_Spencer_photo.PNG",
      link: "https://en.wikipedia.org/wiki/Wedding_of_Prince_Charles_and_Lady_Diana_Spencer",
    },
    {
      id: 53,
      date: "8 Dec 1980",
      time: 0,
      type: 0,
      title: "Assassination of John Lennon",
      description:
        "John Lennon, the legendary musician and former member of The Beatles, was shot and killed in New York City.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1980%2F12%2F08%2Fthumbs%2F53-John-Lennon_600x600.jpeg?alt=media&token=373c4c3c-5fbf-4177-aad6-1758a9632ab9",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1980%2F12%2F08%2F53-John-Lennon.jpeg?alt=media&token=1f7c2e2f-712d-4f6f-8e98-930c16666533",
      imgsource:
        "https://en.wikipedia.org/wiki/John_Lennon#/media/File:John_Lennon_1969_(cropped).jpg",
      link: "https://en.wikipedia.org/wiki/John_Lennon",
    },
    {
      id: 54,
      date: "19 Jul 1980",
      time: 0,
      type: 0,
      title: "1980 Summer Olympics in Moscow",
      description:
        "📷 “Emblem of XXII Olympic Games”. Emblem of XXII Olympic Games in Moscow.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1980%2F07%2F19%2Fthumbs%2F54-Olympics-Moscow_600x600.jpeg?alt=media&token=cd88111a-9a43-477f-ab07-b8136ebccff9",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1980%2F07%2F19%2F54-Olympics-Moscow.png?alt=media&token=e8b1f466-a14b-4b5d-9300-fbc05aaf0e29",
      imgsource:
        "https://en.wikipedia.org/wiki/1980_Summer_Olympics#/media/File:Emblem_of_the_1980_Summer_Olympics.svg",
      link: "https://en.wikipedia.org/wiki/1980_Summer_Olympics",
    },
    {
      id: 55,
      date: "1 Jul 1979",
      time: 0,
      type: 0,
      title: "Sony Walkman Introduced",
      description:
        "Sony introduced the portable cassette player known as the Walkman, revolutionizing the way people listened to music on the go. 📷 Original 1979 Sony Walkman TPS-L2",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1979%2F07%2F01%2Fthumbs%2F55-Sony-Walkman-1979_600x600.jpeg?alt=media&token=ae5cb021-b7bb-4a5d-87ca-041b0544ae19",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1979%2F07%2F01%2F55-Sony-Walkman-1979.jpeg?alt=media&token=73d13b58-b77a-4f93-9192-424f1ac2dc75",
      imgsource:
        "https://en.wikipedia.org/wiki/Walkman#/media/File:Original_Sony_Walkman_TPS-L2.JPG",
      link: "https://en.wikipedia.org/wiki/Walkman",
    },
    {
      id: 56,
      date: "4 May 1979",
      time: 0,
      type: 0,
      title: "Margaret Thatcher Becomes UK Prime Minister",
      description:
        "Margaret Thatcher became the Prime Minister of the United Kingdom in May 1979, becoming the country's first female Prime Minister.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1979%2F05%2F04%2Fthumbs%2F56-UK-PM-Thatcher_600x600.jpeg?alt=media&token=260bd17f-469d-42b6-bff1-b1be69b02946",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1979%2F05%2F04%2F56-UK-PM-Thatcher.jpeg?alt=media&token=db2963e7-2b70-48a1-9d0e-edede13be69c",
      imgsource:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Premier_Thatcher%2C_Bestanddeelnr_932-7045.jpg/440px-Premier_Thatcher%2C_Bestanddeelnr_932-7045.jpg",
      link: "https://en.wikipedia.org/wiki/Premiership_of_Margaret_Thatcher",
    },
    {
      id: 57,
      date: "15 Aug 1979",
      time: 0,
      type: 0,
      title: "The Film - Apocalypse Now - Released",
      description:
        "Apocalypse Now is a war film directed by Francis Ford Coppola and inspired by Joseph Conrad's novel - Heart of Darkness - was released in 1979.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1979%2F08%2F15%2Fthumbs%2F57-Apocalypse_Now_poster_600x600.jpeg?alt=media&token=42b515e1-d2d5-4a72-8322-d04ff38828da",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1979%2F08%2F15%2F57-Apocalypse_Now_poster.jpeg?alt=media&token=dbc3300c-924d-4622-bfc5-ce5650cb2dff",
      imgsource:
        "https://upload.wikimedia.org/wikipedia/en/c/c2/Apocalypse_Now_poster.jpg",
      link: "https://en.wikipedia.org/wiki/Apocalypse_Now",
    },
    {
      id: 58,
      date: "26 Aug 1978",
      time: 0,
      type: 0,
      title: "Pope John Paul I's Short Papacy",
      description:
        "Pope John Paul I became the Pope of the Roman Catholic Church in August 1978 but sadly passed away after only 33 days in office.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1978%2F08%2F26%2Fthumbs%2F58-Pope-John-Paul-I_600x600.jpeg?alt=media&token=dfa4e7c6-837f-4247-91a6-8fd17fea94f5",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1978%2F08%2F26%2F58-Pope-John-Paul-I.jpeg?alt=media&token=4a1acb29-9d18-49a7-95bf-e50c8de093b3",
      imgsource:
        "https://en.wikipedia.org/wiki/Pope_John_Paul_I#/media/File:Ioannes_Paulus_I,_by_Fotografia_Felici,_1978_(cropped).jpg",
      link: "https://en.wikipedia.org/wiki/Pope_John_Paul_I",
    },
    {
      id: 59,
      date: "25 Jul 1978",
      time: 0,
      type: 0,
      title: "Louise Brown: First Test-Tube Baby ",
      description:
        "Louise Brown, the world's first - test-tube baby - conceived through in vitro fertilization (IVF), was born on July 25, 1978, in the United Kingdom. 📷 Book of Louise Brown - Louise Brown: My Life as the World's First Test-Tube Baby https://www.amazon.sg/Louise-Brown-Worlds-First-Test-Tube/dp/1909446084",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1978%2F07%2F25%2Fthumbs%2F59-Louise-Brown_600x600.jpeg?alt=media&token=4fdcc430-8115-47fc-9c32-524ce3ffa042",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1978%2F07%2F25%2F59-Louise-Brown.jpeg?alt=media&token=cb238019-93d1-446e-97b8-2d59c37bf1cc",
      imgsource:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTv1HD9sOp87S01G8hHiESLjnXS2yQElEOQnYiojFRHOWJr7I9g",
      link: "https://en.wikipedia.org/wiki/Louise_Brown",
    },
    {
      id: 60,
      date: "18 Nov 1978",
      time: 0,
      type: 0,
      title: "Jonestown Massacre",
      description:
        "In November 1978, the Jonestown Massacre occurred in Guyana when over 900 members of the People's Temple cult, led by Jim Jones, died in a mass murder-suicide event. 📷 The entrance to Jonestown.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1978%2F11%2F18%2Fthumbs%2F60-Jonestown_entrance_600x600.jpeg?alt=media&token=ec123b18-756c-4b8a-9425-7c3c9d653374",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1978%2F11%2F18%2F60-Jonestown_entrance.jpeg?alt=media&token=0853ea4f-d500-4893-98cc-52b4089a4e94",
      imgsource:
        "https://en.wikipedia.org/wiki/Jonestown#/media/File:Jonestown_entrance.jpg",
      link: "https://en.wikipedia.org/wiki/Jonestown",
    },
    {
      id: 61,
      date: "16 Jun 1978",
      time: 0,
      type: 0,
      title: "The Film - Grease - Released",
      description:
        "Grease is a musical film starring John Travolta and Olivia Newton-John, was released in 1978 and became a cultural phenomenon.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1978%2F06%2F16%2Fthumbs%2F61-Grease-poster_600x600.jpeg?alt=media&token=0d5d0955-d5bf-4853-9ca8-4c0e440b96c4",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1978%2F06%2F16%2F61-Grease-poster.jpeg?alt=media&token=4cce37e9-d0f4-41a9-95fc-ce306b47e5c0",
      imgsource:
        "https://en.wikipedia.org/wiki/Grease_(film)#/media/File:Grease_ver2.jpg",
      link: "https://en.wikipedia.org/wiki/Grease_(film)",
    },
    {
      id: 62,
      date: "20 Jan 1977",
      time: 0,
      type: 0,
      title: "Jimmy Carter Inaugurated as President",
      description:
        "Jimmy Carter was inaugurated as the 39th President of the United States, succeeding Gerald Ford.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1977%2F01%2F20%2Fthumbs%2F62-JimmyCarterPortrait2_600x600.jpeg?alt=media&token=de9056a5-519d-424c-88ea-8b204305fb74",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1977%2F01%2F20%2F62-JimmyCarterPortrait2.jpeg?alt=media&token=049ea89d-84b9-49af-9272-e6635857fa74",
      imgsource:
        "https://en.wikipedia.org/wiki/Jimmy_Carter#/media/File:JimmyCarterPortrait2.jpg",
      link: "https://en.wikipedia.org/wiki/Jimmy_Carter",
    },
    {
      id: 63,
      date: "10 Jun 1977",
      time: 0,
      type: 0,
      title: "The Apple II Released",
      description:
        "Apple Computer released the Apple II and was first sold on June 10, 1977. Apple II is one of the earliest personal computers, which played a significant role in the development of the PC industry. 📷 Apple II computer. On display at the Musée Bolo, EPFL, Lausanne. Standard Apple II logo from 1977 onwards. Photoshop done by ClueCog",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1977%2F06%2F10%2Fthumbs%2F63-Apple-II-Series_600x600.jpeg?alt=media&token=820a26c9-7d84-4090-bf9e-c01a56411b5a",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1977%2F06%2F10%2F63-Apple-II-Series.png?alt=media&token=03566db6-4208-491e-83b1-29521f911b0d",
      imgsource:
        "https://en.wikipedia.org/wiki/Apple_II_series#/media/File:Apple_II.png",
      link: "https://en.wikipedia.org/wiki/Apple_II_series",
    },
    {
      id: 64,
      date: "25 May 1977",
      time: 0,
      type: 0,
      title: "Star Wars Released",
      description:
        "Star Wars is a groundbreaking science fiction film directed by George Lucas. It follows the epic journey of Luke Skywalker, a young farm boy, as he joins forces with Princess Leia, Han Solo, and Jedi Knight Obi-Wan Kenobi to combat the tyrannical Galactic Empire and its villainous leader, Darth Vader. The film captivated audiences with its innovative special effects, memorable characters, and timeless story of good versus evil. ",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1977%2F05%2F25%2Fthumbs%2F64-StarWarsMoviePoster1977_600x600.jpeg?alt=media&token=2bbcd289-5684-4298-8c8f-1287d7f050eb",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1977%2F05%2F25%2F64-StarWarsMoviePoster1977.jpeg?alt=media&token=fdc19589-fc8a-4047-afa8-3c796bd26511",
      imgsource:
        "https://en.wikipedia.org/wiki/Star_Wars_(film)#/media/File:StarWarsMoviePoster1977.jpg",
      link: "https://en.wikipedia.org/wiki/Star_Wars_(film)",
    },
    {
      id: 65,
      date: "16 Aug 1977",
      time: 0,
      type: 0,
      title: "Elvis Presley's Death",
      description:
        "Legendary musician Elvis Presley passed away due to a heart attack. He was found unresponsive on the bathroom floor at his Graceland mansion in Memphis, Tennessee, on August 16, 1977. Despite efforts to revive him, he was pronounced dead at the age of 42. 📷 A photograph promoting the film Jailhouse Rock depicts singer Elvis Presley.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1977%2F08%2F16%2Fthumbs%2F65-Elvis_Presley_promoting_Jailhouse_Rock_600x600.jpeg?alt=media&token=915c9e24-2773-4cfc-bab4-8a28fd620b49",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1977%2F08%2F16%2F65-Elvis_Presley_promoting_Jailhouse_Rock.jpeg?alt=media&token=cb8b8ddf-5e56-4c13-8513-f3d5c9536b1a",
      imgsource:
        "https://en.wikipedia.org/wiki/Elvis_Presley#/media/File:Elvis_Presley_promoting_Jailhouse_Rock.jpg",
      link: "https://en.wikipedia.org/wiki/Elvis_Presley",
    },
    {
      id: 66,
      date: "1 Apr 1976",
      time: 0,
      type: 0,
      title: "Apple Computer Founded",
      description:
        "Apple Computer, Inc. was founded by Steve Jobs, Steve Wozniak, and Ronald Wayne on April 1, 1976, marking the birth of the Apple brand. 📷 Original logo featured Sir Isaac Newton sitting under an apple tree with an apple about to fall on his head. This logo was designed by Ronald Wayne, one of the co-founders.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1976%2F04%2F01%2Fthumbs%2F66-apple-logo-1_600x600.jpeg?alt=media&token=e6d5e0f3-c08f-4e3f-8157-5ccd7ec49ea0",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1976%2F04%2F01%2F66-apple-logo-1.png?alt=media&token=f697c3c7-d1c5-4b3f-a286-a83f69ae9c69",
      imgsource: "https://www.crowdspring.com/blog/apple-logo/",
      link: "https://en.wikipedia.org/wiki/Apple_Inc.",
    },
    {
      id: 67,
      date: "28 Jul 1976",
      time: 0,
      type: 0,
      title: "Tangshan Earthquake",
      description:
        "A devastating earthquake struck Tangshan, China, on July 28, 1976, causing one of the deadliest natural disasters in history with significant loss of life.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1976%2F07%2F28%2Fthumbs%2F67-Tangshan_earthquake_600x600.jpeg?alt=media&token=d48c8cf3-53e5-4a98-a421-5eb1a11c72e4",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1976%2F07%2F28%2F67-Tangshan_earthquake.jpeg?alt=media&token=e9550b7c-8a5d-4da1-b0c5-c614882d98d9",
      imgsource:
        "https://en.wikipedia.org/wiki/1976_Tangshan_earthquake#/media/File:Tangshan_earthquake.jpg",
      link: "https://en.wikipedia.org/wiki/1976_Tangshan_earthquake",
    },
    {
      id: 68,
      date: "20 Jul 1976",
      time: 0,
      type: 0,
      title: "Viking 1 landing on Mars",
      description:
        "NASA's Viking 1 was a spacecraft that successfully landed on Mars on July 20, 1976. It conducted experiments to search for signs of life on the Martian surface and transmitted valuable data back to Earth. Viking 1 marked a significant milestone in planetary exploration, providing key insights into the Red Planet.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1976%2F07%2F20%2Fthumbs%2F68-Viking_spacecraft_600x600.jpeg?alt=media&token=053e27af-f8f5-46ce-9b0a-6c5ac50df8c3",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1976%2F07%2F20%2F68-Viking_spacecraft.jpeg?alt=media&token=35237e45-e8a3-463c-a764-87b5232d6039",
      imgsource:
        "https://en.wikipedia.org/wiki/Viking_1#/media/File:Viking_spacecraft.jpg",
      link: "https://en.wikipedia.org/wiki/Viking_1",
    },
    {
      id: 69,
      date: "4 Apr 1975",
      time: 0,
      type: 0,
      title: "Microsoft Founded",
      description:
        "Microsoft Corporation was founded by Bill Gates and Paul Allen on April 4, 1975, marking the beginning of one of the world's most influential technology companies. 📷 Building 92 at Microsoft Corporation headquarters in Redmond, Washington. Photographed by user Coolcaesar on 30 May 2016.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1975%2F04%2F04%2Fthumbs%2F69-Microsoft-Founded_600x600.jpeg?alt=media&token=4364e37b-0ddb-423f-8a3b-a6a41ad82897",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1975%2F04%2F04%2F69-Microsoft-Founded.png?alt=media&token=17487f3a-db36-4ddb-bc87-2e603b333a3a",
      imgsource:
        "https://en.wikipedia.org/wiki/Microsoft#/media/File:Building92microsoft.jpg",
      link: "https://en.wikipedia.org/wiki/Microsoft",
    },
    {
      id: 70,
      date: "1 Oct 1975",
      time: 0,
      type: 0,
      title: "Thrilla in Manila Boxing Match",
      description:
        "Muhammad Ali and Joe Frazier faced off in a historic boxing match known as the - Thrilla in Manila - with Ali emerging as the winner. ",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1975%2F10%2F01%2Fthumbs%2F70-Thrilla-in-Manila-Joe-Frazier-vs-Muhammad-Ali_600x600.jpeg?alt=media&token=65d542f6-0fc0-4ac3-8a4a-9d1c27e59180",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1975%2F10%2F01%2F70-Thrilla-in-Manila-Joe-Frazier-vs-Muhammad-Ali.jpeg?alt=media&token=6a40d8c2-2e47-49c8-acd4-afa02442e997",
      imgsource:
        "https://www.thefightcity.com/the-thrilla-in-manila-muhammad-ali-joe-frazier-ali-vs-frazier-george-foreman-boxing-history/",
      link: "https://en.wikipedia.org/wiki/Thrilla_in_Manila",
    },
    {
      id: 71,
      date: "20 Jun 1975",
      time: 0,
      type: 0,
      title: "Jaws movie released",
      description:
        "Jaws movie released in 1975 was directed by Steven Spielberg. Jaws is a thriller film that revolves around a small seaside town terrorized by a great white shark. The film's suspenseful storytelling and iconic score by John Williams made it a blockbuster hit, setting the standard for the summer blockbuster genre.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1975%2F06%2F20%2Fthumbs%2F71-JAWS_Movie_poster_600x600.jpeg?alt=media&token=99d81674-e8c1-4365-bd1b-1e5b726d7c6d",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1975%2F06%2F20%2F71-JAWS_Movie_poster.jpeg?alt=media&token=e26a86ba-bcfe-4377-bd78-55974d1fe858",
      imgsource:
        "https://en.wikipedia.org/wiki/Jaws_(film)#/media/File:JAWS_Movie_poster.jpg",
      link: "https://en.wikipedia.org/wiki/Jaws_(film)",
    },
    {
      id: 72,
      date: "18 May 1974",
      time: 0,
      type: 0,
      title: "India's First Nuclear Test",
      description:
        "Operation Smiling Buddha, known as Pokhran-I, was India's first successful nuclear weapons test conducted on May 18, 1974, at the Pokhran Test Range. The test established India as a nuclear-armed nation. It was conducted underground and had a significant impact on regional and global nuclear policies, raising concerns and reactions worldwide. 📷 Prime Minister Indira Gandhi visits the site of India’s first nuclear test at Pokhran in Rajasthan, in 1974.(HT File Photo)",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1974%2F05%2F18%2Fthumbs%2F72-Operation-Smiling-Buddha-Site-Visit_600x600.jpeg?alt=media&token=da9f7099-165f-4634-b3d9-8f1588e4146d",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1974%2F05%2F18%2F72-Operation-Smiling-Buddha-Site-Visit.webp?alt=media&token=baf23cf5-ac74-4bcb-9b89-2b986a85fb73",
      imgsource:
        "https://images.hindustantimes.com/img/2021/05/18/550x309/conducted-nuclear-explosion-htphoto-nuclear-visiting-peaceful_cb17dd84-80ba-11e7-b63c-9d281adafd5e_1621301698383.jpg",
      link: "https://www.hindustantimes.com/india-news/smiling-buddha-how-india-successfully-conducted-first-nuclear-test-in-pokhran-101621301524390.html",
    },
    {
      id: 73,
      date: "30 Oct 1974",
      time: 0,
      type: 0,
      title: "Rumble in the Jungle Boxing Match",
      description:
        "Rumble in the Jungle was a historic boxing match held in Kinshasa, Zaire (now the Democratic Republic of Congo), on October 30, 1974. Muhammad Ali defeated George Foreman, the reigning heavyweight champion, using a tactical approach known as the - rope-a-dope, reclaiming the title in one of the greatest upsets in boxing history.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1974%2F10%2F30%2Fthumbs%2F73-The_Rumble_in_the_Jungle_poster_600x600.jpeg?alt=media&token=f73fe835-e146-403b-af88-61b7dc11dfc5",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1974%2F10%2F30%2F73-The_Rumble_in_the_Jungle_poster.jpeg?alt=media&token=9a2bbd44-681c-491b-b1aa-c3d395af800d",
      imgsource:
        "https://en.wikipedia.org/wiki/The_Rumble_in_the_Jungle#/media/File:The_Rumble_in_the_Jungle_poster.jpg",
      link: "https://en.wikipedia.org/wiki/The_Rumble_in_the_Jungle",
    },
    {
      id: 74,
      date: "6 Oct 1973",
      time: 0,
      type: 0,
      title: "Yom Kippur War",
      description:
        "Egypt and Syria launched a surprise attack on Israel, marking the start of the Yom Kippur War, also known as the Ramadan War. The conflict had significant implications for the Middle East. 📷 Yom_Kippur_War_Montage",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1973%2F10%2F06%2Fthumbs%2F74-Yom_Kippur_War_Montage_600x600.jpeg?alt=media&token=c606d896-0f6e-4d38-93db-acf251ea5959",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1973%2F10%2F06%2F74-Yom_Kippur_War_Montage.png?alt=media&token=51a354cb-8fe7-4b50-ae57-57bb977862b8",
      imgsource:
        "https://en.wikipedia.org/wiki/Yom_Kippur_War#/media/File:Yom_Kippur_War_Montage.png",
      link: "https://en.wikipedia.org/wiki/Yom_Kippur_War",
    },
    {
      id: 75,
      date: "8 Apr 1973",
      time: 0,
      type: 0,
      title: "Pablo Picasso's Death",
      description:
        "The renowned artist Pablo Picasso passed away in April 1973, leaving behind a legacy of groundbreaking art. One of his most renowned works is -Guernica - completed in 1937. Guernica is a powerful and emotionally charged mural-sized painting that depicts the horrors of war and the suffering caused by the bombing of the town of Guernica during the Spanish Civil War. It is considered a masterpiece of modern art and a powerful anti-war symbol.📷 Portrait photograph of Pablo Picasso, 1908",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1973%2F04%2F08%2Fthumbs%2F75-Portrait_de_Picasso%2C_1908_600x600.jpeg?alt=media&token=34830549-94eb-403e-bf85-f3c50adc83ed",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1973%2F04%2F08%2F75-Portrait_de_Picasso%2C_1908.jpeg?alt=media&token=3b3a42cf-db2b-42e7-928f-6685d8c2251f",
      imgsource:
        "https://en.wikipedia.org/wiki/Pablo_Picasso#/media/File:Portrait_de_Picasso,_1908.jpg",
      link: "https://en.wikipedia.org/wiki/Pablo_Picasso",
    },
    {
      id: 76,
      date: "20 Oct 1973",
      time: 0,
      type: 0,
      title: "The Sydney Opera House Opens",
      description:
        "The iconic Sydney Opera House in Australia officially opened in October 1973, becoming a symbol of the city and a masterpiece of modern architecture.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1973%2F10%2F20%2Fthumbs%2F76-Sydney_Australia._(21339175489)_600x600.jpeg?alt=media&token=9e4662b6-a84c-4405-b9f0-9798f2bcf1b7",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1973%2F10%2F20%2F76-Sydney_Australia._(21339175489).jpg?alt=media&token=fd96838d-4b99-471e-88c2-388ffb6e6e49",
      imgsource:
        "https://en.wikipedia.org/wiki/Sydney_Opera_House#/media/File:Sydney_Australia._(21339175489).jpg",
      link: "https://en.wikipedia.org/wiki/Sydney_Opera_House",
    },
    {
      id: 77,
      date: "5 Sep 1972",
      time: 0,
      type: 0,
      title: "Munich Olympics Massacre",
      description:
        "During the 1972 Summer Olympics in Munich, Germany, a terrorist group called Black September took 11 members of the Israeli Olympic team hostage. The crisis ended tragically with the deaths of all hostages. 📷 Front view of the Israeli apartment at the 1972 Olympic Games in Munich, where the athletes were held hostage during the Munich massacre.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1972%2F09%2F05%2Fthumbs%2F77-MunichIsraeliOlympicFront_600x600.jpeg?alt=media&token=e7c5c927-6dc0-4079-b13c-c683473461bc",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1972%2F09%2F05%2F77-MunichIsraeliOlympicFront.jpeg?alt=media&token=d3d161d9-24d4-474f-b180-41f3e4568ca1",
      imgsource:
        "https://en.wikipedia.org/wiki/Munich_massacre#/media/File:MunichIsraeliOlympicFront.jpg",
      link: "https://en.wikipedia.org/wiki/Munich_massacre",
    },
    {
      id: 78,
      date: "19 Dec 1972",
      time: 0,
      type: 0,
      title: "Apollo 17 Moon Mission",
      description:
        "Apollo 17, the final manned mission of NASA's Apollo program, successfully landed on the moon in December 1972, with astronauts Eugene Cernan and Harrison Schmitt conducting lunar exploration. 📷 Eugene Cernan on the Moon during the Apollo 17 mission. Cropped version of Image AS17-134-20378.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1972%2F12%2F19%2Fthumbs%2F78-Eugene_Cernan_at_the_LM%2C_Apollo_17_600x600.jpeg?alt=media&token=a3df13dd-27d6-49a6-b12c-6f6a235d7ade",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1972%2F12%2F19%2F78-Eugene_Cernan_at_the_LM%2C_Apollo_17.jpeg?alt=media&token=78e854a8-7f00-41b2-8a82-cbfbb9be7603",
      imgsource:
        "https://en.wikipedia.org/wiki/Apollo_17#/media/File:Eugene_Cernan_at_the_LM,_Apollo_17.jpg",
      link: "https://en.wikipedia.org/wiki/Apollo_17",
    },
    {
      id: 79,
      date: "18 Oct 1972",
      time: 0,
      type: 0,
      title: "The Clean Water Act",
      description:
        "The U.S. Clean Water Act was enacted in 1972, aimed at regulating water pollution and protecting water quality. 📷 Obverse of the Great Seal of the United States.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1972%2F10%2F18%2Fthumbs%2F79-Great-Seal-of-the-US_600x600.jpeg?alt=media&token=9970afda-da8c-4541-9cca-ef4fecfbc70b",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1972%2F10%2F18%2F79-Great-Seal-of-the-US.png?alt=media&token=0eb054ff-6405-47b1-aec6-cbd6009a50b2",
      imgsource:
        "https://en.wikipedia.org/wiki/Clean_Water_Act#/media/File:Great_Seal_of_the_United_States_(obverse).svg",
      link: "https://en.wikipedia.org/wiki/Clean_Water_Act",
    },
    {
      id: 80,
      date: "9 Feb 1971",
      time: 0,
      type: 0,
      title: "Apollo 14 Moon Mission",
      description:
        "NASA's Apollo 14 mission, launched in January 1971, successfully landed astronauts Alan Shepard and Edgar Mitchell on the moon, marking the third manned lunar landing. 📷 Apollo 14, Alan B. Shepard Jr. on Lunar surface.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1971%2F02%2F09%2Fthumbs%2F80-Apollo_14_Shepard_600x600.jpeg?alt=media&token=9644ba75-3fc0-45ad-a679-4bf6c2b849ea",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1971%2F02%2F09%2F80-Apollo_14_Shepard.jpeg?alt=media&token=6421aecc-e179-4ec5-81c1-842c62198e32",
      imgsource:
        "https://en.wikipedia.org/wiki/Apollo_14#/media/File:Apollo_14_Shepard.jpg",
      link: "https://en.wikipedia.org/wiki/Apollo_14",
    },
    {
      id: 81,
      date: "1 Oct 1971",
      time: 0,
      type: 0,
      title: "Walt Disney World Opens",
      description:
        "Walt Disney World Resort, located in Orlando, Florida, opened on October 1, 1971. It is a world-renowned theme park and entertainment complex, featuring multiple theme parks, resorts, and attractions. Walt Disney World has become a beloved destination, attracting millions of visitors from around the globe. 📷 Cinderella Castle in the Magic Kingdom at Walt Disney World.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1971%2F10%2F01%2Fthumbs%2F81-Walt-Disney-World_600x600.jpeg?alt=media&token=d602c234-d3a0-4f20-89c8-a1ebd28bd8e2",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1971%2F10%2F01%2F81-Walt-Disney-World.png?alt=media&token=c5844c2d-a2a8-459c-83d2-1c9b7bb15af8",
      imgsource:
        "https://en.wikipedia.org/wiki/Walt_Disney_World#/media/File:Cinderella_Castle.jpg",
      link: "https://en.wikipedia.org/wiki/Walt_Disney_World",
    },
    {
      id: 82,
      date: "5 Apr 1971",
      time: 0,
      type: 0,
      title: "Mount Etna Eruption",
      description:
        "Mount Etna, one of Europe's most active volcanoes, experienced a significant eruption in 1971.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1971%2F04%2F05%2Fthumbs%2F82-Mt-Etna-Eruption-1971StuBW_01_600x600.jpeg?alt=media&token=a945141f-e00c-4fda-9cfd-631ec853a5da",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1971%2F04%2F05%2F82-Mt-Etna-Eruption-1971StuBW_01.jpeg?alt=media&token=ea8cd534-9154-4715-893f-179896a165c0",
      imgsource: "https://www.italysvolcanoes.com/ETNA_1971.html",
      link: "https://en.wikipedia.org/wiki/Mount_Etna",
    },
    {
      id: 83,
      date: "4 May 1970",
      time: 0,
      type: 0,
      title: "Kent State Shootings",
      description:
        "the Ohio National Guard opened fire on student protesters at Kent State University, resulting in the deaths of four students and widespread protests against the Vietnam War. 📷 Mary Ann Vecchio gestures and screams as she kneels by the body of a student, Jeffrey Miller, lying face down on the campus of Kent State University, in Kent, Ohio. On publication, the image was retouched to remove the fencepost above Vecchio's head.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1970%2F05%2F04%2Fthumbs%2F83-Kent_State_massacre_600x600.jpeg?alt=media&token=9e66517b-0119-46f3-b3d9-8aef77d41562",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1970%2F05%2F04%2F83-Kent_State_massacre.jpeg?alt=media&token=0404e148-2eee-402d-96f6-178198ca19d8",
      imgsource:
        "https://en.wikipedia.org/wiki/Kent_State_shootings#/media/File:Kent_State_massacre.jpg",
      link: "https://en.wikipedia.org/wiki/Kent_State_shootings",
    },
    {
      id: 84,
      date: "22 Apr 1970",
      time: 0,
      type: 0,
      title: "First Earth Day",
      description:
        "The first Earth Day was celebrated on April 22, 1970, marking the beginning of the modern environmental movement. 📷 The Earth flag is not an official flag, since there is no official governing body over Earth. The flag holds a photo transfer of a NASA image of the Earth on a dark blue background. It has been associated with Earth Day. ",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1970%2F04%2F22%2Fthumbs%2F84-Earth_flag_PD_600x600.jpeg?alt=media&token=11174c33-39fa-454e-bde2-8e4d866b094e",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1970%2F04%2F22%2F84-Earth_flag_PD.jpeg?alt=media&token=8828885d-4234-4ead-b249-36b82a0932c1",
      imgsource:
        "https://en.wikipedia.org/wiki/Earth_Day#/media/File:Earth_flag_PD.jpg",
      link: "https://www.earthday.org/history/",
    },
    {
      id: 85,
      date: "17 Apr 1970",
      time: 0,
      type: 0,
      title: "Apollo 13 Mission",
      description:
        "NASA's Apollo 13 mission encountered a critical malfunction in April 1970 but managed to return safely to Earth, demonstrating the importance of teamwork and ingenuity. 📷 Logo Apollo 13 This is the insignia of the Apollo 13 lunar landing mission.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1970%2F04%2F17%2Fthumbs%2F85-Apollo-XIII-Mission_600x600.jpeg?alt=media&token=5f982215-9648-4789-8c1e-7f9bdd2d5392",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1970%2F04%2F17%2F85-Apollo-XIII-Mission.png?alt=media&token=12082f0f-09a5-45b7-b2ff-0f2ac58ae5b5",
      imgsource:
        "https://en.wikipedia.org/wiki/Apollo_13#/media/File:Apollo_13-insignia.png",
      link: "https://en.wikipedia.org/wiki/Apollo_13",
    },
    {
      id: 86,
      date: "20 Jul 1969",
      time: 0,
      type: 0,
      title: "Apollo 11 Moon Landing",
      description:
        "NASA's Apollo 11 mission, commanded by Neil Armstrong, landed astronauts Neil Armstrong and Buzz Aldrin on the moon. Armstrong became the first human to set foot on the lunar surface, uttering the famous words - That's one small step for man, one giant leap for mankind. 📷 Buzz Aldrin on the Moon in a photograph taken by Neil Armstrong, who can be seen in the visor reflection along with Earth,[1] the Lunar Module Eagle, and the U.S. flag",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1969%2F07%2F20%2Fthumbs%2F86-Aldrin_Apollo_11_original_600x600.jpeg?alt=media&token=f7653e89-826e-434f-a39f-24d51c773c3c",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1969%2F07%2F20%2F86-Aldrin_Apollo_11_original.jpeg?alt=media&token=e1379a28-d810-4e87-a581-edb5bd9b4dfa",
      imgsource:
        "https://en.wikipedia.org/wiki/Apollo_11#/media/File:Aldrin_Apollo_11_original.jpg",
      link: "https://en.wikipedia.org/wiki/Apollo_11",
    },
    {
      id: 87,
      date: "15 Aug 1969",
      time: 0,
      type: 0,
      title: "1st Woodstock Festival",
      description:
        "The Woodstock Music & Art Fair took place from August 15 to 18, 1969, in upstate New York. It became a symbol of the counterculture movement, attracting hundreds of thousands of young people for a weekend of music and peace. 📷 An advertisement poster for the w:Woodstock Festival, 1969",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1969%2F08%2F15%2Fthumbs%2F87-Woodstock_poster_600x600.jpeg?alt=media&token=05d03456-ecae-4539-a286-4e999eeb3ae7",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1969%2F08%2F15%2F87-Woodstock_poster.jpeg?alt=media&token=6e24b0fd-6862-4dcb-ae04-129d8d0f32db",
      imgsource:
        "https://en.wikipedia.org/wiki/Woodstock#/media/File:Woodstock_poster.jpg",
      link: "https://en.wikipedia.org/wiki/Woodstock",
    },
    {
      id: 88,
      date: "10 Nov 1969",
      time: 0,
      type: 0,
      title: "Sesame Street Premieres",
      description:
        "Sesame Street is a highly acclaimed and long-running children's television program created by Joan Ganz Cooney and Lloyd Morrisett. It first premiered on November 10, 1969, in the United States. The show is known for its innovative and educational approach to teaching children fundamental skills, including reading, math, and social interactions, through entertaining and engaging content.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1969%2F11%2F10%2Fthumbs%2F88-Sesame-Street-Poster_600x600.jpeg?alt=media&token=240b7b7d-70a0-44f6-90a0-66c6740b31d2",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1969%2F11%2F10%2F88-Sesame-Street-Poster.jpeg?alt=media&token=13001ad9-c54b-4803-a8d0-42ef0a653a83",
      imgsource:
        "https://static.wikia.nocookie.net/international-entertainment-project/images/d/d6/Sesame_Street_-_poster_%28English%29.jpg/revision/latest?cb=20220205163031",
      link: "https://en.wikipedia.org/wiki/History_of_Sesame_Street",
    },
    {
      id: 89,
      date: "4 Apr 1968",
      time: 0,
      type: 0,
      title: "Assassination of Martin Luther King Jr.",
      description:
        "Civil rights leader Martin Luther King Jr. was assassinated in Memphis, Tennessee, on April 4, 1968, sparking protests and riots across the United States.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1968%2F04%2F04%2Fthumbs%2F89-Martin_Luther_King%2C_Jr._600x600.jpeg?alt=media&token=b04b096a-fbce-43c3-91f2-818ea9eb7091",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1968%2F04%2F04%2F89-Martin_Luther_King%2C_Jr..jpg?alt=media&token=bc5f0f3b-8fef-465b-9c19-b6927583c6c6",
      imgsource:
        "https://en.wikipedia.org/wiki/Martin_Luther_King_Jr.#/media/File:Martin_Luther_King,_Jr..jpg",
      link: "https://en.wikipedia.org/wiki/Martin_Luther_King_Jr.",
    },
    {
      id: 90,
      date: "6 Jun 1968",
      time: 0,
      type: 0,
      title: "Assassination of Robert F. Kennedy",
      description:
        "U.S. Senator Robert F. Kennedy, a candidate for the Democratic presidential nomination, was assassinated in Los Angeles on June 5, 1968, following a campaign event.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1968%2F06%2F06%2Fthumbs%2F90-Bobby_Kennedy_-_restored_600x600.jpeg?alt=media&token=74a6320d-09c9-41c9-b9e0-7d13fdc1bcd8",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1968%2F06%2F06%2F90-Bobby_Kennedy_-_restored.jpeg?alt=media&token=e374e378-c207-4857-9376-5e98cbac4a4b",
      imgsource:
        "https://en.wikipedia.org/wiki/Robert_F._Kennedy#/media/File:Bobby_Kennedy_-_restored.jpg",
      link: "https://en.wikipedia.org/wiki/Robert_F._Kennedy",
    },
    {
      id: 91,
      date: "12 Oct 1968",
      time: 0,
      type: 0,
      title: "Mexico City Olympics",
      description:
        "The 1968 Summer Olympics were held in Mexico City, Mexico, and were marked by notable moments, including American sprinters Tommie Smith and John Carlos raising their fists in a Black Power salute during the medal ceremony.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1968%2F10%2F12%2Fthumbs%2F91-Mexico-Olympics-68_600x600.jpeg?alt=media&token=52bb2baa-241e-4149-a6d7-e3d79e215fc9",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1968%2F10%2F12%2F91-Mexico-Olympics-68.png?alt=media&token=fc8e4eca-dc63-4807-b803-7300b3bde001",
      imgsource:
        "https://en.wikipedia.org/wiki/1968_Summer_Olympics#/media/File:1968_Mexico_emblem.svg",
      link: "https://en.wikipedia.org/wiki/1968_Summer_Olympics",
    },
    {
      id: 92,
      date: "21 Dec 1968",
      time: 0,
      type: 0,
      title: "Apollo 8 Moon Mission",
      description:
        "NASA's Apollo 8 mission, in December 1968, became the first manned spacecraft to orbit the moon and provided iconic images of Earthrise from lunar orbit. 📷 Taken by Apollo 8 crewmember Bill Anders on December 24, 1968, at mission time 075:49:07 [8] (16:40 UTC), while in orbit around the Moon, showing the Earth rising for the third time above the lunar horizon. ",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1968%2F12%2F21%2Fthumbs%2F92-NASA-Apollo8-Dec24-Earthrise_600x600.jpeg?alt=media&token=049c9b4f-0206-477e-aaa3-729c59a0f44e",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1968%2F12%2F21%2F92-NASA-Apollo8-Dec24-Earthrise.jpeg?alt=media&token=6158b1a9-0e7a-4892-82ab-77a27c7530ff",
      imgsource:
        "https://en.wikipedia.org/wiki/Apollo_8#/media/File:NASA-Apollo8-Dec24-Earthrise.jpg",
      link: "https://en.wikipedia.org/wiki/Apollo_8",
    },
    {
      id: 93,
      date: "3 Dec 1967",
      time: 0,
      type: 0,
      title: "First Heart Transplant",
      description:
        "Dr. Christiaan Barnard performed the world's first successful human heart transplant in South Africa in 1967. 📷 The heart surgeon Christiaan Barnard, sitting on a sofa; he is in Italy to participate in the international congress on the origins of sudden death and resuscitation therapies. Florence (Italy), January 1969.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1967%2F12%2F03%2Fthumbs%2F93-Christiaan_Barnard_1969_600x600.jpeg?alt=media&token=d9836ab2-a23d-4750-a70f-3442c618bb1b",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1967%2F12%2F03%2F93-Christiaan_Barnard_1969.jpeg?alt=media&token=0d8d0837-5b8c-4568-9d33-43beaee35acf",
      imgsource:
        "https://en.wikipedia.org/wiki/Christiaan_Barnard#/media/File:Christiaan_Barnard_1969.jpg",
      link: "https://en.wikipedia.org/wiki/Christiaan_Barnard",
    },
    {
      id: 94,
      date: "14 Jun 1967",
      time: 0,
      type: 0,
      title: "Mariner 5 to Venus",
      description:
        "NASA's Mariner 5 spacecraft conducted a successful flyby of Venus, providing valuable data about the planet's atmosphere.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1967%2F06%2F14%2Fthumbs%2F94-Mariner_5_600x600.jpeg?alt=media&token=1863e45a-9b4e-4eb8-a7fc-1627abb85d31",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1967%2F06%2F14%2F94-Mariner_5.jpeg?alt=media&token=7960d671-a233-4f33-81f8-56d762d2ebd2",
      imgsource:
        "https://en.wikipedia.org/wiki/Mariner_5#/media/File:Mariner_5.jpg",
      link: "https://en.wikipedia.org/wiki/Mariner_5",
    },
    {
      id: 95,
      date: "27 Jan 1967",
      time: 0,
      type: 0,
      title: "Apollo I Tragedy",
      description:
        "A cabin fire during a pre-launch test killed the crew of Apollo 1: Virgil 'Gus' Grissom, Edward H. White II, and Roger B. Chaffee. 📷 Astronauts (left to right) Gus Grissom, Ed White, and Roger Chaffee, pose in front of Launch Complex 34 which is housing their Saturn 1 launch vehicle. The astronauts died ten days later in a fire on the launch pad.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1967%2F01%2F27%2Fthumbs%2F95-Apollo1-Crew_01_600x600.jpeg?alt=media&token=3c692aba-e2af-4231-828d-f5499820329f",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1967%2F01%2F27%2F95-Apollo1-Crew_01.jpeg?alt=media&token=1b10ea2a-e649-4bfd-9f5e-2c81ffbc8a63",
      imgsource:
        "https://en.wikipedia.org/wiki/Apollo_1#/media/File:Apollo1-Crew_01.jpg",
      link: "https://en.wikipedia.org/wiki/Apollo_1",
    },
    {
      id: 96,
      date: "8 Sep 1966",
      time: 0,
      type: 0,
      title: "Star Trek Debuts",
      description:
        "The popular science fiction television series - Star Trek - made its debut, creating a lasting cultural legacy. Star Trek is a science fiction TV series exploring space adventures and human values. 📷 The logo of the television show Star Trek: The Original Series",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1966%2F09%2F08%2Fthumbs%2F96-Star-Trek_600x600.jpeg?alt=media&token=4d399288-5c85-44fa-918d-adb702538dd5",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1966%2F09%2F08%2F96-Star-Trek.png?alt=media&token=e387d340-814d-496f-a585-7af42087f175",
      imgsource:
        "https://en.wikipedia.org/wiki/Star_Trek#/media/File:Star_Trek_TOS_logo.svg",
      link: "https://en.wikipedia.org/wiki/Star_Trek#Awards_and_honors",
    },
    {
      id: 97,
      date: "11 Jul 1966",
      time: 0,
      type: 0,
      title: "World Cup Victory for England",
      description:
        "England won the 1966 FIFA World Cup, defeating West Germany in the final, which was held in London. 📷 This is the logo for 1966 FIFA World Cup.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1966%2F07%2F11%2Fthumbs%2F97-1966_FIFA_World_Cup_600x600.jpeg?alt=media&token=3de2400b-0f10-4762-9326-76946a2a5232",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1966%2F07%2F11%2F97-1966_FIFA_World_Cup.png?alt=media&token=de7cb41b-00bf-45d3-b1bb-7d28a59c3ae2",
      imgsource:
        "https://en.wikipedia.org/wiki/1966_FIFA_World_Cup#/media/File:1966_FIFA_World_Cup.png",
      link: "https://en.wikipedia.org/wiki/1966_FIFA_World_Cup",
    },
    {
      id: 98,
      date: "21 Oct 1966",
      time: 0,
      type: 0,
      title: "Aberfan Disaster",
      description:
        "A catastrophic coal mining disaster occurred in Aberfan, Wales, leading to the deaths of 144 people, including 116 children, when a slag heap collapsed onto a school. 📷 Aerial shot showing the extent of the devastation in the Aberfan disaster",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1966%2F10%2F21%2Fthumbs%2F98-Aberfan_disaster%2C_October_1966_600x600.jpeg?alt=media&token=07d4360b-c736-4813-841f-584d086fbf52",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1966%2F10%2F21%2F98-Aberfan_disaster%2C_October_1966.jpeg?alt=media&token=a9487905-44b2-422b-9b69-104197fc6de4",
      imgsource:
        "https://en.wikipedia.org/wiki/Aberfan_disaster#/media/File:Aberfan_disaster,_October_1966.jpg",
      link: "https://en.wikipedia.org/wiki/Aberfan_disaster",
    },
    {
      id: 99,
      date: "21 Feb 1965",
      time: 0,
      type: 0,
      title: "Malcolm X Assassination",
      description:
        "Civil rights leader Malcolm X was assassinated in New York City in February 1965.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1965%2F02%2F21%2Fthumbs%2F99-Malcolm_X_NYWTS_2_600x600.jpeg?alt=media&token=62b7e069-0c7c-4816-b593-523c4a6ddf8c",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1965%2F02%2F21%2F99-Malcolm_X_NYWTS_2.jpeg?alt=media&token=7d0fa364-f890-4a16-8fa3-0b95b07a4e31",
      imgsource:
        "https://en.wikipedia.org/wiki/Malcolm_X#/media/File:Malcolm_X_NYWTS_2.jpg",
      link: "https://en.wikipedia.org/wiki/Malcolm_X",
    },
    {
      id: 100,
      date: "2 Mar 1965",
      time: 0,
      type: 0,
      title: "The Sound of Music",
      description:
        "The Sound of Music is a 1965 musical film about a governess in Austria and her impact on a widowed captain's family. 📷 Poster with an illustration of actress Julie Andrews dancing in the mountains",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1965%2F03%2F02%2Fthumbs%2F100-Sound_of_music_600x600.jpeg?alt=media&token=dbaa5ca2-43c2-4d9c-a5aa-6fdbf13dd520",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1965%2F03%2F02%2F100-Sound_of_music.jpeg?alt=media&token=0c589568-4ffd-4cb8-af79-df21fcd81362",
      imgsource:
        "https://en.wikipedia.org/wiki/The_Sound_of_Music_(film)#/media/File:Sound_of_music.jpg",
      link: "https://en.wikipedia.org/wiki/The_Sound_of_Music_(film)",
    },
    {
      id: 101,
      date: "6 Aug 1965",
      time: 0,
      type: 0,
      title: "Civit Rights Act of 1965",
      description:
        "President Lyndon B. Johnson signed the Civil Rights Act of 1965 into law, prohibiting racial discrimination in voting and ending racial segregation in schools, workplaces, and public facilities.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1965%2F08%2F06%2Fthumbs%2F101-Great_Seal_of_the_United_States_(obverse).svg_600x600.jpeg?alt=media&token=a0b563de-8114-4e54-abd4-da6d48f13a0d",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1965%2F08%2F06%2F101-Great_Seal_of_the_United_States_(obverse).svg.jpg?alt=media&token=3f70bbc7-c1c0-4e48-831e-d9a3cc271378",
      imgsource:
        "https://en.wikipedia.org/wiki/Voting_Rights_Act_of_1965#/media/File:Great_Seal_of_the_United_States_(obverse).svg",
      link: "https://en.wikipedia.org/wiki/Voting_Rights_Act_of_1965",
    },
    {
      id: 102,
      date: "10 Jul 1964",
      time: 0,
      type: 0,
      title: "The Beatles' Album - A Hard Day's Night",
      description:
        "The Beatles released their album - A Hard Day's Night - featuring the title track and other iconic songs.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1964%2F07%2F10%2Fthumbs%2F102-HardDayUK_600x600.jpeg?alt=media&token=8a6c6fe2-19d7-498b-af32-8d2115744b3a",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1964%2F07%2F10%2F102-HardDayUK.jpeg?alt=media&token=0ec58007-3112-4826-8b70-88a819efb66a",
      imgsource:
        "https://en.wikipedia.org/wiki/A_Hard_Day%27s_Night_(album)#/media/File:HardDayUK.jpg",
      link: "https://en.wikipedia.org/wiki/A_Hard_Day%27s_Night_(album)",
    },
    {
      id: 103,
      date: "25 Feb 1964",
      time: 0,
      type: 0,
      title: "Muhammad Ali Becomes Champion",
      description:
        "Muhammad Ali (then known as Cassius Clay) won the world heavyweight boxing championship by defeating Sonny Liston in February 1964. 📷 Bust photographic portrait of Muhammad Ali in 1967. World Journal Tribune photo by Ira Rosenberg.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1964%2F02%2F25%2Fthumbs%2F103-Muhammad_Ali_NYWTS_600x600.jpeg?alt=media&token=78aea712-d2d3-4aae-b8aa-a0e5f4e1d413",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1964%2F02%2F25%2F103-Muhammad_Ali_NYWTS.jpeg?alt=media&token=8c2f7386-aa0e-40be-9311-17c2aad21de3",
      imgsource:
        "https://en.wikipedia.org/wiki/Muhammad_Ali#/media/File:Muhammad_Ali_NYWTS.jpg",
      link: "https://en.wikipedia.org/wiki/Muhammad_Ali",
    },
    {
      id: 104,
      date: "10 Oct 1964",
      time: 0,
      type: 0,
      title: "Tokyo Summer Olympics 1964",
      description:
        "The Summer Olympics were held in Tokyo, Japan, marking the first time the event was held in Asia.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1964%2F10%2F10%2Fthumbs%2F104-Tokyo-Olympics-1964_600x600.jpeg?alt=media&token=7dbcd5a9-8ed8-4580-bea3-1895870b381c",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1964%2F10%2F10%2F104-Tokyo-Olympics-1964.png?alt=media&token=91511d97-bcad-4fb2-876f-3cd466e4475e",
      imgsource:
        "https://en.wikipedia.org/wiki/1964_Summer_Olympics#/media/File:Tokyo_1964_Summer_Olympics_logo.svg",
      link: "https://en.wikipedia.org/wiki/1964_Summer_Olympics",
    },
    {
      id: 105,
      date: "22 Nov 1963",
      time: 0,
      type: 0,
      title: "Assassination of President John F. Kennedy",
      description:
        "President John F. Kennedy was assassinated in Dallas, Texas, on November 22, 1963, a tragedy that had a profound impact on the United States and the world. 📷 John F. Kennedy, photograph in the Oval Office. ",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1964%2F11%2F22%2Fthumbs%2F105-John_F._Kennedy%2C_White_House_color_photo_portrait_600x600.jpeg?alt=media&token=668942dd-0602-4847-a7ee-6988d7f92cff",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1964%2F11%2F22%2F105-John_F._Kennedy%2C_White_House_color_photo_portrait.jpg?alt=media&token=7e619a11-0a54-42c4-a126-cdc23ae44890",
      imgsource:
        "https://en.wikipedia.org/wiki/John_F._Kennedy#/media/File:John_F._Kennedy,_White_House_color_photo_portrait.jpg",
      link: "https://en.wikipedia.org/wiki/John_F._Kennedy",
    },
    {
      id: 106,
      date: "28 Aug 1963",
      time: 0,
      type: 0,
      title: "March on Washington",
      description:
        "The March on Washington for Jobs and Freedom took place on August 28, 1963, where Dr. Martin Luther King Jr. delivered his iconic - I Have a Dream - speech. 📷 Hundreds of thousands descended on Washington, D.C.'s, Lincoln Memorial Aug. 28, 1963.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1963%2F08%2F28%2Fthumbs%2F106-IhaveadreamMarines_600x600.jpeg?alt=media&token=50d8194e-fba4-472b-962e-ade8f876406a",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1963%2F08%2F28%2F106-IhaveadreamMarines.jpeg?alt=media&token=515e9c60-d5eb-4710-a5b8-40929337f072",
      imgsource:
        "https://en.wikipedia.org/wiki/March_on_Washington_for_Jobs_and_Freedom#/media/File:IhaveadreamMarines.jpg",
      link: "https://en.wikipedia.org/wiki/March_on_Washington_for_Jobs_and_Freedom",
    },
    {
      id: 107,
      date: "3 Jun 1963",
      time: 0,
      type: 0,
      title: "Pope John XXIII's Death",
      description:
        "Pope John XXIII, who convened the Second Vatican Council, died in June 1963.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1963%2F06%2F03%2Fthumbs%2F107-Pope-John-XXIII-Ioannes_XXIII%2C_by_De_Agostini%2C_1958%E2%80%931963_600x600.jpeg?alt=media&token=30d051a4-9525-4337-a676-be704f883f2c",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1963%2F06%2F03%2F107-Pope-John-XXIII-Ioannes_XXIII%2C_by_De_Agostini%2C_1958%E2%80%931963.jpeg?alt=media&token=0a7da9a5-291b-4c95-8fca-3d6c7539aa69",
      imgsource:
        "https://en.wikipedia.org/wiki/Pope_John_XXIII#/media/File:Ioannes_XXIII,_by_De_Agostini,_1958%E2%80%931963.jpg",
      link: "https://en.wikipedia.org/wiki/Pope_John_XXIII",
    },
    {
      id: 108,
      date: "12 Jul 1962",
      time: 0,
      type: 0,
      title: "The Rolling Stones' Debut",
      description:
        "he British rock band The Rolling Stones released their debut album in 1964. 📷 The Rolling Stones at Marcus Amphitheater in Milwaukee, USA, performing at Summerfest festival on June 23, 2015.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1962%2F07%2F12%2Fthumbs%2F108-The_Rolling_Stones_Summerfest_in_Milwaukee_-_2015_600x600.jpeg?alt=media&token=e61554c6-1383-422a-b163-73d9a9f7314e",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1962%2F07%2F12%2F108-The_Rolling_Stones_Summerfest_in_Milwaukee_-_2015.jpeg?alt=media&token=e178c74b-ff68-4e20-8df6-c89223f7f776",
      imgsource:
        "https://en.wikipedia.org/wiki/The_Rolling_Stones#/media/File:The_Rolling_Stones_Summerfest_in_Milwaukee_-_2015.jpg",
      link: "https://en.wikipedia.org/wiki/The_Rolling_Stones",
    },
    {
      id: 109,
      date: "4 Aug 1962",
      time: 0,
      type: 0,
      title: "Marilyn Monroe's Death",
      description:
        "Iconic actress Marilyn Monroe died on August 5, 1962, under circumstances that have remained the subject of speculation. 📷 Photo of actress in a late 1953 issue of Modern Screen. A search revealed that this was taken for 1954 film River of No Return.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1962%2F08%2F04%2Fthumbs%2F109-Monroecirca1953_600x600.jpeg?alt=media&token=2c1fb0f4-ddd8-48cf-9908-c74cc10fa6ec",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1962%2F08%2F04%2F109-Monroecirca1953.jpeg?alt=media&token=40af2d6b-18e1-4899-889a-b8ee54f38842",
      imgsource:
        "https://en.wikipedia.org/wiki/Marilyn_Monroe#/media/File:Monroecirca1953.jpg",
      link: "https://en.wikipedia.org/wiki/Marilyn_Monroe",
    },
    {
      id: 110,
      date: "10 Jul 1962",
      time: 0,
      type: 0,
      title: "Telstar Satellite",
      description:
        "The Telstar 1 communications satellite was launched, enabling live television broadcasts and telephone calls between the United States and Europe. 📷 Telstar satellite-CnAM 35181-IMG 5408-gradient",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1962%2F07%2F10%2Fthumbs%2F110-1920px-Telstar_satellite-CnAM_35181-IMG_5408-gradient_600x600.jpeg?alt=media&token=7413b3dc-f8a9-4215-a94a-e47e496f88c0",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1962%2F07%2F10%2F110-1920px-Telstar_satellite-CnAM_35181-IMG_5408-gradient.jpeg?alt=media&token=84209400-f2d9-4181-90c4-ea2ed6ef0090",
      imgsource:
        "https://en.wikipedia.org/wiki/Telstar#/media/File:Telstar_satellite-CnAM_35181-IMG_5408-gradient.jpg",
      link: "https://en.wikipedia.org/wiki/Telstar",
    },
    {
      id: 111,
      date: "12 Apr 1961",
      time: 0,
      type: 0,
      title: "First Human in Space",
      description:
        "Soviet cosmonaut Yuri Gagarin's historic spaceflight marked a major achievement in the space race. 📷 Launch of Vostok 1",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1961%2F04%2F12%2Fthumbs%2F111-Vostok1_big_600x600.jpeg?alt=media&token=e3e628ce-24e1-45c0-b34e-40a11ecfb0e0",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1961%2F04%2F12%2F111-Vostok1_big.gif?alt=media&token=f5af3f03-42fe-4cd6-a78c-ceac634fd955",
      imgsource:
        "https://en.wikipedia.org/wiki/Vostok_1#/media/File:Vostok1_big.gif",
      link: "https://en.wikipedia.org/wiki/Vostok_1",
    },
    {
      id: 112,
      date: "13 Aug 1961",
      time: 0,
      type: 0,
      title: "Berlin Wall Construction",
      description:
        "The construction of the Berlin Wall began in August 1961, dividing East and West Berlin and symbolizing the Cold War division of Germany. 📷 East German construction workers building the Berlin Wall, 20 November 1961",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1961%2F08%2F13%2Fthumbs%2F112-Berlin_Wall_1961-11-20_600x600.jpeg?alt=media&token=ea750be5-6f38-431f-aba7-b267a28f57a4",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1961%2F08%2F13%2F112-Berlin_Wall_1961-11-20.jpeg?alt=media&token=6483c6ae-89c3-42e3-90f0-a6fa847cbb8b",
      imgsource:
        "https://en.wikipedia.org/wiki/Berlin_Wall#/media/File:Berlin_Wall_1961-11-20.jpg",
      link: "https://en.wikipedia.org/wiki/Berlin_Wall",
    },
    {
      id: 113,
      date: "20 Jan 1961",
      time: 0,
      type: 0,
      title: "Inauguration of President John F. Kennedy",
      description:
        "John F. Kennedy was inaugurated as the 35th President of the United States on January 20, 1961, delivering his famous inaugural address.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1961%2F01%2F20%2Fthumbs%2F113-Jfk_inauguration_600x600.jpeg?alt=media&token=a7b8cd01-ed86-4f39-83ba-9548872a45e7",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1961%2F01%2F20%2F113-Jfk_inauguration.jpeg?alt=media&token=a0168cbb-32b5-4d7b-9d5e-3cb5d68a7713",
      imgsource:
        "https://en.wikipedia.org/wiki/Inauguration_of_John_F._Kennedy#/media/File:Jfk_inauguration.jpg",
      link: "https://en.wikipedia.org/wiki/Inauguration_of_John_F._Kennedy",
    },
    {
      id: 114,
      date: "14 Sep 1960",
      time: 0,
      type: 0,
      title: "OPEC Founded",
      description:
        "The Organization of the Petroleum Exporting Countries (OPEC) was founded by five major oil-producing countries: Iran, Iraq, Kuwait, Saudi Arabai and Venezuela. 📷 Flag of OPEC",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F09%2F14%2Fthumbs%2F114-Flag_of_OPEC.svg_600x600.jpeg?alt=media&token=58d41669-2d1b-4b27-9ff7-5aebec16b5b5",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F09%2F14%2F114-Flag_of_OPEC.svg.png?alt=media&token=4ecbfe04-ffa3-4c30-8971-cc7566f84beb",
      imgsource:
        "https://en.wikipedia.org/wiki/OPEC#/media/File:Flag_of_OPEC.svg",
      link: "https://en.wikipedia.org/wiki/OPEC",
    },
    {
      id: 115,
      date: "22 May 1960",
      time: 0,
      type: 0,
      title: "Chilean Earthquake",
      description:
        "One of the most powerful earthquakes in recorded history, with a magnitude of 9.5, struck Chile, causing widespread destruction. 📷 Earthquake damage to good quality, wood-frame houses in Valdivia, Chile, 1960.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F05%2F22%2Fthumbs%2F115-Valdivia_after_earthquake%2C_1960_600x600.jpeg?alt=media&token=fbdb4a8e-25eb-4ed1-b2d2-d722322f7b29",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F05%2F22%2F115-Valdivia_after_earthquake%2C_1960.jpeg?alt=media&token=a1c7011c-f571-4913-9e7a-33516cd68925",
      imgsource:
        "https://en.wikipedia.org/wiki/1960_Valdivia_earthquake#/media/File:Valdivia_after_earthquake,_1960.jpg",
      link: "https://en.wikipedia.org/wiki/1960_Valdivia_earthquake",
    },
    {
      id: 116,
      date: "18 Sep 1960",
      time: 0,
      type: 0,
      title: "1st 1960 Summer Paralympics",
      description:
        "The first Paralympic Games were held in Rome, Italy, in 1960. These games were organized as a parallel event to the 1960 Summer Olympics and were specifically designed for athletes with disabilities. Since then, the Paralympic Games have become a major international multi-sport event, with athletes from around the world competing in various sports adapted to their impairments. The Paralympics typically follow the Olympic Games and continue to promote inclusivity and excellence in sports for athletes with disabilities.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F09%2F18%2Fthumbs%2F116-ISMG_1960_%26_1964_600x600.jpeg?alt=media&token=bddf6a8f-3de7-4cbc-9498-e0aa6720cee0",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1960%2F09%2F18%2F116-ISMG_1960_%26_1964.jpeg?alt=media&token=c67ba34a-0eb0-474e-80c5-42b8f16494f2",
      imgsource:
        "https://en.wikipedia.org/wiki/1960_Summer_Paralympics#/media/File:ISMG_1960_&_1964.jpg",
      link: "https://en.wikipedia.org/wiki/1960_Summer_Paralympics",
    },
    {
      id: 117,
      date: "2 Jan 1959",
      time: 0,
      type: 0,
      title: "Luna 1 Mission",
      description:
        "The Soviet spacecraft Luna 1 became the first human-made object to reach the moon, although it did not land on the lunar surface. 📷  Interplanetary station Luna 1. Interplanetary station Luna 1 exhibited in the 'Kosmos' pavilion of the Exhibition of Achievements of National Economy of the USSR (VDNKh).",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1959%2F01%2F02%2Fthumbs%2F117-RIAN_archive_510848_Interplanetary_station_Luna_1_-_blacked_600x600.jpeg?alt=media&token=90f328b7-3fab-4a0d-a35d-982fcf251166",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1959%2F01%2F02%2F117-RIAN_archive_510848_Interplanetary_station_Luna_1_-_blacked.jpeg?alt=media&token=e85aaae1-ca62-45e7-9fe1-d3161ee104ef",
      imgsource:
        "https://en.wikipedia.org/wiki/Luna_1#/media/File:RIAN_archive_510848_Interplanetary_station_Luna_1_-_blacked.jpg",
      link: "https://en.wikipedia.org/wiki/Luna_1",
    },
    {
      id: 118,
      date: "9 Mar 1959",
      time: 0,
      type: 0,
      title: "Barbie Doll Debuts",
      description:
        "The Barbie doll was introduced by Mattel, becoming an iconic toy for generations. 📷 The first Barbie doll was introduced in both blonde and brunette on March 9, 1959.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1959%2F03%2F09%2Fthumbs%2F118-MattelBarbieno1br_600x600.jpeg?alt=media&token=249ca7d3-1a64-41bf-b203-caad179bb41f",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1959%2F03%2F09%2F118-MattelBarbieno1br.jpeg?alt=media&token=c06439fa-fa38-46d1-aa56-5e3b5c0da2a5",
      imgsource:
        "https://en.wikipedia.org/wiki/Barbie#/media/File:MattelBarbieno1br.jpg",
      link: "https://en.wikipedia.org/wiki/Barbie",
    },
    {
      id: 119,
      date: "25 Apr 1959",
      time: 0,
      type: 0,
      title: "Opening of the St. Lawrence Seaway",
      description:
        "The St. Lawrence Seaway, connecting the Great Lakes to the Atlantic Ocean, officially opened for navigation.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1959%2F04%2F25%2Fthumbs%2F119-diberville_600x600.jpeg?alt=media&token=f60d6e5b-9fef-4dba-bcd5-b16d5af968c7",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1959%2F04%2F25%2F119-diberville.jpeg?alt=media&token=0d8bf01f-1453-4410-96e9-8a311787b6eb",
      imgsource:
        "https://greatlakes-seaway.com/wp-content/themes/slsmc/seaway-timeline-page/images/diberville.jpg",
      link: "https://greatlakes-seaway.com/en/the-seaway/",
    },
    {
      id: 120,
      date: "29 Jul 1958",
      time: 0,
      type: 0,
      title: "NASA Established",
      description:
        "The National Aeronautics and Space Administration (NASA) was established in the United States, marking the beginning of the space race.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1958%2F07%2F29%2Fthumbs%2F120-NASA-Logo_600x600.jpeg?alt=media&token=08b8f379-a27c-41ea-953c-7a404d5dc174",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1958%2F07%2F29%2F120-NASA-Logo.png?alt=media&token=55c841be-676b-4e01-ba35-413e1f84fc53",
      imgsource:
        "https://www.nasa.gov/sites/all/themes/custom/nasatwo/images/nasa-logo.svg",
      link: "https://www.nasa.gov/feature/65-years-ago-the-national-aeronautics-and-space-act-of-1958-creates-nasa",
    },
    {
      id: 121,
      date: "1 Feb 1958",
      time: 0,
      type: 0,
      title: "Explorer I Satellite",
      description:
        "First Successful American Satellite. The United States successfully launched its first satellite, Explorer 1, marking the beginning of its space program. ",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1958%2F02%2F01%2Fthumbs%2F121-Explorer1_600x600.jpeg?alt=media&token=9e40ed19-9d6b-43ab-b4d5-c69679567e24",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1958%2F02%2F01%2F121-Explorer1.jpeg?alt=media&token=6f4986cd-492f-4c9f-b092-b6cba6031f97",
      imgsource:
        "https://en.wikipedia.org/wiki/Explorer_1#/media/File:Explorer1.jpg",
      link: "https://en.wikipedia.org/wiki/Explorer_1",
    },
    {
      id: 122,
      date: "16 Jun 1958",
      time: 0,
      type: 0,
      title: "The Hula Hoop Craze",
      description:
        "The Hula Hoop was first introduced and advertised by the Wham-O toy company in 1958. It quickly became a sensation, and the earliest advertisements for the Hula Hoop date back to that year when it gained widespread popularity as a toy and fitness craze.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1958%2F06%2F16%2Fthumbs%2F122-Girl_twirling_Hula_Hoop%2C_1958_600x600.jpeg?alt=media&token=1cd99d67-54f7-4153-9586-5f092599d78c",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1958%2F06%2F16%2F122-Girl_twirling_Hula_Hoop%2C_1958.jpeg?alt=media&token=f986429c-ecba-4f32-918d-008603ededae",
      imgsource:
        "https://upload.wikimedia.org/wikipedia/commons/9/98/Girl_twirling_Hula_Hoop%2C_1958.jpg",
      link: "https://en.wikipedia.org/wiki/Hula_hoop",
    },
    {
      id: 123,
      date: "4 Oct 1957",
      time: 0,
      type: 0,
      title: "Sputnik I",
      description:
        "Sputnik I was the first artificial satellite, launched by the Soviet Union in 1957, marking the start of the space age.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1957%2F10%2F04%2Fthumbs%2F123-Sputnik_1_satellite_model_600x600.jpeg?alt=media&token=2d0be1a0-651f-423d-8ab8-dbf629382475",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1957%2F10%2F04%2F123-Sputnik_1_satellite_model.png?alt=media&token=2a996a72-8a06-4dcd-be55-9247c27472b5",
      imgsource:
        "https://en.wikipedia.org/wiki/Sputnik_1#/media/File:Sputnik_1_satellite_model.png",
      link: "https://en.wikipedia.org/wiki/Sputnik_1",
    },
    {
      id: 124,
      date: "1 Nov 1957",
      time: 0,
      type: 0,
      title: "Mackinac Bridge Opens",
      description:
        "The Mackinac Bridge, connecting Michigan's Upper and Lower Peninsulas, opened to traffic, becoming one of the longest suspension bridges in the world.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1957%2F11%2F01%2Fthumbs%2F124-Mackinac_Bridge_from_the_air4_600x600.jpeg?alt=media&token=8d921f38-594a-4e41-ba43-fbbd18eb7a68",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1957%2F11%2F01%2F124-Mackinac_Bridge_from_the_air4.jpeg?alt=media&token=2abeb9f1-1f11-4a24-a0f9-166f90fe5378",
      imgsource:
        "https://en.wikipedia.org/wiki/Mackinac_Bridge#/media/File:Mackinac_Bridge_from_the_air4.jpg",
      link: "https://en.wikipedia.org/wiki/Mackinac_Bridge",
    },
    {
      id: 125,
      date: "4 Sep 1957",
      time: 0,
      type: 0,
      title: "Little Rock Nine",
      description:
        "Nine African American students, known as the - Little Rock Nine - were escorted by federal troops to attend Little Rock Central High School in Arkansas, desegregating the school. 📷 A photograph of Elizabeth Eckford attempting to enter Little Rock School on 4th September, 1957. The girl shouting is Hazel Massery. See Little Rock Nine for context.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1957%2F09%2F04%2Fthumbs%2F125-Little_Rock_Desegregation_1957_600x600.jpeg?alt=media&token=65e659a9-eb56-4866-8124-9fada65f36f9",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1957%2F09%2F04%2F125-Little_Rock_Desegregation_1957.jpeg?alt=media&token=67c8628b-9664-44f0-b02a-2903d5f18da2",
      imgsource:
        "https://en.wikipedia.org/wiki/Little_Rock_Nine#/media/File:Little_Rock_Desegregation_1957.jpg",
      link: "https://en.wikipedia.org/wiki/Little_Rock_Nine",
    },
    {
      id: 126,
      date: "22 Nov 1956",
      time: 0,
      type: 0,
      title: "Olympic Games 1956 in Melbourne",
      description:
        "The Summer Olympics were held in Melbourne, Australia, and the Winter Olympics took place in Cortina d'Ampezzo, Italy.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1956%2F11%2F22%2Fthumbs%2F126-Summer-Olympics-Melbourne_600x600.jpeg?alt=media&token=c0c4bd54-4b4a-4b10-8533-4135fa5118d3",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1956%2F11%2F22%2F126-Summer-Olympics-Melbourne.png?alt=media&token=5098c7ee-e0a4-4246-a2b4-e9fd5943ad59",
      imgsource:
        "https://en.wikipedia.org/wiki/1956_Summer_Olympics#/media/File:1956_Summer_Olympics_logo.svg",
      link: "https://en.wikipedia.org/wiki/1956_Summer_Olympics",
    },
    {
      id: 127,
      date: "19 Apr 1956",
      time: 0,
      type: 0,
      title: "Wedding of Rainier III, Prince of Monaco, and Grace Kelly",
      description:
        "The wedding of Rainier III, Prince of Monaco, and Grace Kelly in 1956 was a glamorous and iconic royal event, uniting Hollywood and European royalty.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1956%2F04%2F19%2Fthumbs%2F127-grace_kelly_mariage_1626_jpeg_4397_600x600.jpeg?alt=media&token=23408867-0395-49ee-9662-20e1f518cce7",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1956%2F04%2F19%2F127-grace_kelly_mariage_1626_jpeg_4397.jpeg?alt=media&token=c6e4a77a-e8e7-40ee-940b-e57d4a875483",
      imgsource:
        "https://www.vogue.fr/wedding/inspiration/diaporama/grace-kellys-wedding-to-prince-rainier-iii-in-9-vintage-shots/53682",
      link: "https://en.wikipedia.org/wiki/Wedding_of_Rainier_III,_Prince_of_Monaco,_and_Grace_Kelly",
    },
    {
      id: 128,
      date: "23 Oct 1956",
      time: 0,
      type: 0,
      title: "Hungarian Revolution of 1956",
      description:
        "The Hungarian Revolution of 1956 took place from October 23, 1956, to November 4, 1956. It was a brief but intense period of political and social upheaval in Hungary, marked by protests, demonstrations, and armed conflict, as Hungarians sought greater political freedom and independence from Soviet control. The revolution was ultimately suppressed by Soviet military intervention.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1956%2F10%2F23%2Fthumbs%2F128-Hole_in_flag_-_Budapest_1956_600x600.jpeg?alt=media&token=9588af79-59e4-4d82-8db3-5f1e0772863e",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1956%2F10%2F23%2F128-Hole_in_flag_-_Budapest_1956.jpeg?alt=media&token=b668d3f1-b9ab-438c-9b16-42afead98f4b",
      imgsource:
        "https://en.wikipedia.org/wiki/Hungarian_Revolution_of_1956#/media/File:Hole_in_flag_-_Budapest_1956.jpg",
      link: "https://en.wikipedia.org/wiki/Hungarian_Revolution_of_1956",
    },
    {
      id: 129,
      date: "18 Apr 1955",
      time: 0,
      type: 0,
      title: "Albert Einstein's Death",
      description:
        "The renowned physicist Albert Einstein passed away in Princeton, New Jersey, leaving behind a lasting legacy in science. 📷 Albert Einstein during a lecture in Vienna in 1921",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1955%2F04%2F18%2Fthumbs%2F129-Einstein_1921_by_F_Schmutzer_-_restoration_600x600.jpeg?alt=media&token=86eec585-d358-4b13-983a-1c06a0be345c",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1955%2F04%2F18%2F129-Einstein_1921_by_F_Schmutzer_-_restoration.jpeg?alt=media&token=bbe38140-de6c-45d4-bae3-4812a9631506",
      imgsource:
        "https://en.wikipedia.org/wiki/Albert_Einstein#/media/File:Einstein_1921_by_F_Schmutzer_-_restoration.jpg",
      link: "https://en.wikipedia.org/wiki/Albert_Einstein",
    },
    {
      id: 130,
      date: "17 Jul 1955",
      time: 0,
      type: 0,
      title: "Disneyland Opens",
      description:
        "Disneyland, the famous theme park in Anaheim, California, opened its doors to the public. 📷 Sleeping Beauty Castle in 2019 after refurbishment",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1955%2F07%2F17%2Fthumbs%2F130-Disneyland_600x600.jpeg?alt=media&token=08d06884-cc98-4ec3-b789-a7ef367e7ed3",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1955%2F07%2F17%2F130-Disneyland.png?alt=media&token=bc51580b-d5f2-4aa4-9794-a5dab17858bc",
      imgsource:
        "https://en.wikipedia.org/wiki/Disneyland#/media/File:Sleeping_Beauty_Castle_2019.jpg",
      link: "https://en.wikipedia.org/wiki/Disneyland",
    },
    {
      id: 131,
      date: "29 Sep 1955",
      time: 0,
      type: 0,
      title: "CERN Established",
      description:
        "The European Organization for Nuclear Research (CERN) was established, becoming a leading center for particle physics research.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1955%2F09%2F29%2Fthumbs%2F131-CERN-Logo_600x600.jpeg?alt=media&token=c05b4a36-7047-468f-8eab-61b538f45669",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1955%2F09%2F29%2F131-CERN-Logo.png?alt=media&token=8a069ecf-01e2-4dba-b120-ef64315f65e2",
      imgsource: "https://en.wikipedia.org/wiki/CERN#/media/File:CERN_logo.svg",
      link: "https://en.wikipedia.org/wiki/CERN",
    },
    {
      id: 132,
      date: "5 Oct 1954",
      time: 0,
      type: 0,
      title: "Hurricane Hazel",
      description:
        "Hurricane Hazel was one of the most destructive hurricanes of the 1954 Atlantic hurricane season. Before reaching the United States, Hurricane Hazel struck Haiti and the Dominican Republic, causing extensive damage and loss of life. Thousands of people were killed in these countries. 📷 15 UTC map of October 1954 showing Hurricane Hazel and the meteorological set-up.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1954%2F10%2F05%2Fthumbs%2F132-Hazel_600x600.jpeg?alt=media&token=22204296-fb13-4d3e-9472-cd93c1fdfbcf",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1954%2F10%2F05%2F132-Hazel.gif?alt=media&token=69421996-c4c7-4110-8ff3-0d350dea2165",
      imgsource:
        "https://en.wikipedia.org/wiki/Hurricane_Hazel#/media/File:Hazel.gif",
      link: "https://en.wikipedia.org/wiki/Hurricane_Hazel",
    },
    {
      id: 133,
      date: "8 Sep 1954",
      time: 0,
      type: 0,
      title: "SEATO Established",
      description:
        "SEATO, or the Southeast Asia Treaty Organization, was an international organization established on September 8, 1954. Its primary purpose was to provide collective defense and prevent the spread of communism in Southeast Asia during the Cold War. The organization was created in response to concerns about communist expansion in the region following the Korean War and the First Indochina War.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1954%2F09%2F08%2Fthumbs%2F133-Flag_of_SEATO.svg_600x600.jpeg?alt=media&token=caf18856-45ff-4d35-a62b-657220e777ac",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1954%2F09%2F08%2F133-Flag_of_SEATO.svg.png?alt=media&token=5d41a654-7d7c-40b9-8170-a61761020555",
      imgsource:
        "https://en.wikipedia.org/wiki/Southeast_Asia_Treaty_Organization#/media/File:Flag_of_SEATO.svg",
      link: "https://en.wikipedia.org/wiki/Southeast_Asia_Treaty_Organization",
    },
    {
      id: 134,
      date: "29 Jul 1954",
      time: 0,
      type: 0,
      title: "The Lord of the Rings",
      description:
        "J.R.R. Tolkien's 'The Fellowship of the Ring,' the first book in 'The Lord of the Rings' trilogy, was published.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1954%2F07%2F29%2Fthumbs%2F134-First_Single_Volume_Edition_of_The_Lord_of_the_Rings_600x600.jpeg?alt=media&token=81083dbe-9c4e-46c2-b98a-98b1be8f9390",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1954%2F07%2F29%2F134-First_Single_Volume_Edition_of_The_Lord_of_the_Rings.jpg?alt=media&token=543a8611-3e39-4e44-8a51-1697bc10cc92",
      imgsource:
        "https://en.wikipedia.org/wiki/The_Lord_of_the_Rings#/media/File:First_Single_Volume_Edition_of_The_Lord_of_the_Rings.gif",
      link: "https://en.wikipedia.org/wiki/The_Lord_of_the_Rings",
    },
    {
      id: 135,
      date: "2 Jun 1953",
      time: 0,
      type: 0,
      title: "Queen Elizabeth II's Coronation",
      description:
        "📷 Coronation portrait of Queen Elizabeth II, June 1953, London, England.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1953%2F06%2F02%2Fthumbs%2F135-Queen_Elizabeth_II_on_her_Coronation_Day_600x600.jpeg?alt=media&token=191de92f-bbff-4bfe-9139-74d6727a6ab4",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1953%2F06%2F02%2F135-Queen_Elizabeth_II_on_her_Coronation_Day.jpeg?alt=media&token=490ca150-ad74-4215-a6e3-31da0ecabd0b",
      imgsource:
        "https://en.wikipedia.org/wiki/Coronation_of_Elizabeth_II#/media/File:Queen_Elizabeth_II_on_her_Coronation_Day.jpg",
      link: "https://en.wikipedia.org/wiki/Coronation_of_Elizabeth_II",
    },
    {
      id: 136,
      date: "5 Mar 1953",
      time: 0,
      type: 0,
      title: "Joseph Stalin's Death",
      description:
        "Soviet leader Joseph Stalin died, leading to a period of political upheaval and changes in the Soviet Union.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1953%2F03%2F05%2Fthumbs%2F156-Joseph_Stalin%2C_1950_(cropped)_600x600.jpeg?alt=media&token=8e61187f-a4ed-4897-9605-b62aaa2953b5",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1953%2F03%2F05%2F156-Joseph_Stalin%2C_1950_(cropped).jpeg?alt=media&token=b53fa285-19ae-44d7-88e5-d390438e7d3b",
      imgsource:
        "https://en.wikipedia.org/wiki/Joseph_Stalin#/media/File:Joseph_Stalin,_1950_(cropped).jpg",
      link: "https://en.wikipedia.org/wiki/Joseph_Stalin",
    },
    {
      id: 137,
      date: "29 May 1953",
      time: 0,
      type: 0,
      title: "First climbers of Mt. Everest",
      description:
        "Sir Edmund Hillary of New Zealand and Tenzing Norgay, a Sherpa of Nepal, were the first climbers to reach the summit of Mount Everest on May 29, 1953. They successfully ascended via the South Col route, a historic achievement in mountaineering.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1953%2F05%2F29%2Fthumbs%2F137-Tenzing_and_Hillary_600x600.jpeg?alt=media&token=06bd7206-49c0-4cc2-9967-675658c8b20e",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1953%2F05%2F29%2F137-Tenzing_and_Hillary.jpeg?alt=media&token=fb0ec655-3543-4d47-9b19-3805832adce3",
      imgsource:
        "https://en.wikipedia.org/wiki/Edmund_Hillary#/media/File:Tenzing_and_Hillary.jpg",
      link: "https://en.wikipedia.org/wiki/Tenzing_Norgay",
    },
    {
      id: 138,
      date: "20 Jan 1953",
      time: 0,
      type: 0,
      title: "First inauguration of Dwight D. Eisenhower",
      description:
        "Dwight D. Eisenhower was a prominent American military and political figure. He was elected as the 34th President of the United States.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1953%2F01%2F20%2Fthumbs%2F138-Dwight_D._Eisenhower%2C_official_photo_portrait%2C_May_29%2C_1959_600x600.jpeg?alt=media&token=ccb9dca3-f346-40a9-87c4-5f60c9396aee",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1953%2F01%2F20%2F138-Dwight_D._Eisenhower%2C_official_photo_portrait%2C_May_29%2C_1959.jpg?alt=media&token=ffcfe0fe-ec51-4d34-bf97-560028d8e699",
      imgsource:
        "https://en.wikipedia.org/wiki/Dwight_D._Eisenhower#/media/File:Dwight_D._Eisenhower,_official_photo_portrait,_May_29,_1959.jpg",
      link: "https://en.wikipedia.org/wiki/First_inauguration_of_Dwight_D._Eisenhower",
    },
    {
      id: 139,
      date: "19 Jul 1952",
      time: 0,
      type: 0,
      title: "1952 Summer Olympics",
      description:
        "The Summer Olympics were held in Helsinki, Finland. 📷 This is the logo for 1952 Summer Olympics. Further details: Helsinki 1952 Summer Olympics Olympics logo. Used to illustrate the Olympic Games.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1952%2F07%2F19%2Fthumbs%2F139-1952-Helenski-Olympics_600x600.jpeg?alt=media&token=0ff88167-1f34-4ea3-acb6-23461ff3e416",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1952%2F07%2F19%2F139-1952-Helenski-Olympics.png?alt=media&token=621ddbfe-c509-4fc0-87e4-eb9fdd3f36f6",
      imgsource:
        "https://en.wikipedia.org/wiki/1952_Summer_Olympics#/media/File:1952_Summer_Olympics_logo.svg",
      link: "https://en.wikipedia.org/wiki/1952_Summer_Olympics",
    },
    {
      id: 140,
      date: "5 Dec 1952",
      time: 0,
      type: 0,
      title: "The Great Smog of London",
      description:
        "In December 1952, London experienced a deadly smog event known as the - Great Smog. It resulted in thousands of deaths due to air pollution and played a role in prompting environmental legislation. 📷 Nelson's Column during the Great Smog of 1952 Original description: Nelson's Column in December. Foggy Day in December 1952.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1952%2F12%2F05%2Fthumbs%2F140Nelson's_Column_during_the_Great_Smog_of_1952_600x600.jpeg?alt=media&token=43bd965f-2dc5-4629-893a-5819690b5266",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1952%2F12%2F05%2F140Nelson's_Column_during_the_Great_Smog_of_1952.jpeg?alt=media&token=0dcdc523-e67c-43a9-82bd-7efb13fda2ff",
      imgsource:
        "https://en.wikipedia.org/wiki/Great_Smog_of_London#/media/File:Nelson's_Column_during_the_Great_Smog_of_1952.jpg",
      link: "https://en.wikipedia.org/wiki/Great_Smog_of_London",
    },
    {
      id: 141,
      date: "23 Sep 1952",
      time: 0,
      type: 0,
      title: "Rocky Marciano vs. Jersey Joe Walcott First Fight",
      description:
        "Rocky Marciano defeated Jersey Joe Walcott to become the World Heavyweight Boxing Champion.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1952%2F09%2F23%2Fthumbs%2F141-Rocky-Marciano_600x600.jpeg?alt=media&token=48c2cbd3-4970-4f43-8d4e-c1ed8739e8ed",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1952%2F09%2F23%2F141-Rocky-Marciano.jpeg?alt=media&token=59379832-0ccc-4e90-b3b6-e40113c16172",
      imgsource:
        "https://www.firstpost.com/sports/on-this-day-in-1952-rocky-marciano-became-world-heavyweight-champion-with-a-ko-to-jersey-joe-walcott-watch-11316501.html",
      link: "https://en.wikipedia.org/wiki/Rocky_Marciano_vs._Jersey_Joe_Walcott",
    },
    {
      id: 142,
      date: "31 Mar 1951",
      time: 0,
      type: 0,
      title: "First UNIVAC Computer",
      description:
        "The UNIVAC I (Universal Automatic Computer), one of the first commercially produced computers, was delivered to the United States Census Bureau. 📷 A UNIVAC I at the United States Census Bureau in 1951",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1951%2F03%2F31%2Fthumbs%2F142-Univac_I_Census_dedication_600x600.jpeg?alt=media&token=7c0b9228-35b4-4931-b7da-ac5b85a6f49f",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1951%2F03%2F31%2F142-Univac_I_Census_dedication.jpeg?alt=media&token=26614d60-e721-4fbb-b00d-991603e1e2cc",
      imgsource:
        "https://en.wikipedia.org/wiki/UNIVAC_I#/media/File:Univac_I_Census_dedication.jpg",
      link: "https://en.wikipedia.org/wiki/UNIVAC_I",
    },
    {
      id: 143,
      date: "18 Apr 1951",
      time: 0,
      type: 0,
      title: "Treaty of Paris (1951)",
      description:
        "This treaty established the European Coal and Steel Community (ECSC), an early step toward European integration, with Belgium, France, Italy, Luxembourg, the Netherlands, and West Germany as signatories.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1951%2F04%2F18%2Fthumbs%2F143-1951_CECA_ECSC_600x600.jpeg?alt=media&token=37575952-a8b0-4d5a-9d49-ae246c5c40ed",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1951%2F04%2F18%2F143-1951_CECA_ECSC.jpeg?alt=media&token=c2226e5b-d1d9-4d75-a374-fc362a4a9cfc",
      imgsource:
        "https://en.wikipedia.org/wiki/Treaty_of_Paris_(1951)#/media/File:1951_CECA_ECSC.jpg",
      link: "https://en.wikipedia.org/wiki/Treaty_of_Paris_(1951)",
    },
    {
      id: 144,
      date: "16 Jul 1951",
      time: 0,
      type: 0,
      title: "The Catcher in the Rye",
      description:
        "J.D. Salinger's novel 'The Catcher in the Rye' was published, becoming a classic of American literature.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1951%2F07%2F16%2Fthumbs%2F144-The_Catcher_in_the_Rye_(1951%2C_first_edition_cover)_600x600.jpeg?alt=media&token=a2752a92-7de3-4195-9445-cbd6adb1b660",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1951%2F07%2F16%2F144-The_Catcher_in_the_Rye_(1951%2C_first_edition_cover).jpeg?alt=media&token=57059b0a-6dcb-4449-af89-9e7407117625",
      imgsource:
        "https://en.wikipedia.org/wiki/The_Catcher_in_the_Rye#/media/File:The_Catcher_in_the_Rye_(1951,_first_edition_cover).jpg",
      link: "https://en.wikipedia.org/wiki/The_Catcher_in_the_Rye",
    },
    {
      id: 145,
      date: "4 Mar 1950",
      time: 0,
      type: 0,
      title: "Disney's 'Cinderella' Released",
      description:
        "Walt Disney's animated film 'Cinderella' was released, becoming a classic in the Disney canon.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1950%2F03%2F04%2Fthumbs%2F145-Cinderella_(Official_1950_Film_Poster)_600x600.jpeg?alt=media&token=482ea8ba-07b7-445f-8d4e-a33befee2463",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1950%2F03%2F04%2F145-Cinderella_(Official_1950_Film_Poster).png?alt=media&token=a85e553e-c41d-40dc-9362-cabb7406566e",
      imgsource:
        "https://en.wikipedia.org/wiki/Cinderella_(1950_film)#/media/File:Cinderella_(Official_1950_Film_Poster).png",
      link: "https://en.wikipedia.org/wiki/Cinderella_(1950_film)",
    },
    {
      id: 146,
      date: "2 Oct 1950",
      time: 0,
      type: 0,
      title: "First Peanuts Comic Strip",
      description:
        "Charles M. Schulz's 'Peanuts' comic strip, featuring characters like Charlie Brown and Snoopy, was first published.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1950%2F10%2F02%2Fthumbs%2F146-Peanuts-Gang_600x600.jpeg?alt=media&token=52aade67-c475-443a-8f35-388ab175b93b",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1950%2F10%2F02%2F146-Peanuts-Gang.png?alt=media&token=aee700dc-2be7-40d5-b829-deca0f5cfc62",
      imgsource:
        "https://en.wikipedia.org/wiki/Peanuts#/media/File:Peanuts_gang.png",
      link: "https://en.wikipedia.org/wiki/Peanuts",
    },
    {
      id: 147,
      date: "25 Jun 1950",
      time: 0,
      type: 0,
      title: "Korean War Begins",
      description:
        "The Korean War was a conflict that began on June 25, 1950, when North Korean forces, supported by the Soviet Union and China, invaded South Korea, which was supported by the United Nations and the United States. The war marked a significant early escalation of the Cold War, with North Korea, a communist regime, seeking to unify the Korean Peninsula under its rule, while South Korea, a democratic republic, aimed to maintain its independence. 📷 A column of troops and armor of the 1st Marine Division move through communist Chinese lines during their successful breakout from the Chosin Reservoir in North Korea. ",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1950%2F06%2F25%2Fthumbs%2F147-1920px-Chosin_600x600.jpeg?alt=media&token=def1f3d0-a5c7-4851-bc52-1b16bb2659c8",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1950%2F06%2F25%2F147-1920px-Chosin.jpeg?alt=media&token=44f70f01-2b68-46d0-9e99-0ee4b552d0c7",
      imgsource:
        "https://en.wikipedia.org/wiki/Korean_War#/media/File:Chosin.jpg",
      link: "https://en.wikipedia.org/wiki/Korean_War",
    },
    {
      id: 148,
      date: "4 Apr 1949",
      time: 0,
      type: 0,
      title: "Formation of NATO",
      description:
        "On April 4, 1949, the North Atlantic Treaty Organization (NATO) was established. It was a military alliance of Western democracies, including the United States, Canada, and various European nations, designed to provide collective defense against potential aggression, primarily from the Soviet Union.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1949%2F04%2F04%2Fthumbs%2F148-Flag_of_NATO.svg_600x600.jpeg?alt=media&token=897a62be-8525-47e7-a0b5-592320e627d8",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1949%2F04%2F04%2F148-Flag_of_NATO.svg.png?alt=media&token=34864c69-312c-4127-85c4-9cb3d8623057",
      imgsource:
        "https://en.wikipedia.org/wiki/NATO#/media/File:Flag_of_NATO.svg",
      link: "https://en.wikipedia.org/wiki/NATO",
    },
    {
      id: 149,
      date: "29 Aug 1949",
      time: 0,
      type: 0,
      title: "Soviet Atomic Bomb",
      description:
        "The Soviet Union conducted its first successful nuclear weapons test, becoming the second nation (after the United States) to possess atomic weapons. 📷 Screenshot of the First ever Soviet nuclear test in 1949, RDS-1",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1949%2F08%2F29%2Fthumbs%2F149-Soviet-Atomic-Bomb_600x600.jpeg?alt=media&token=c8c6224f-3ec0-42ba-a38d-1bc6c065c864",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1949%2F08%2F29%2F149-Soviet-Atomic-Bomb.png?alt=media&token=5b66e34e-6035-4f68-b9cb-376d18685ff3",
      imgsource:
        "https://en.wikipedia.org/wiki/Soviet_atomic_bomb_project#/media/File:Soviet_Nuclear_Bomb_Test_1949_RDS1.gif",
      link: "https://en.wikipedia.org/wiki/Soviet_atomic_bomb_project",
    },
    {
      id: 150,
      date: "8 Jun 1949",
      time: 0,
      type: 0,
      title: "George Orwell's '1984'",
      description:
        "George Orwell's dystopian novel 'Nineteen Eighty-Four' (commonly known as '1984') was published, depicting a totalitarian future.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1949%2F06%2F24%2Fthumbs%2F151-1984_first_edition_cover_600x600.jpeg?alt=media&token=63d1d7a1-b621-4daf-a82e-12a28e54fa14",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1949%2F06%2F24%2F151-1984_first_edition_cover.jpeg?alt=media&token=09df985b-4cc0-47c6-8d68-8e0a43a6c7f8",
      imgsource:
        "https://en.wikipedia.org/wiki/Nineteen_Eighty-Four#/media/File:1984_first_edition_cover.jpg",
      link: "https://en.wikipedia.org/wiki/Nineteen_Eighty-Four",
    },
    {
      id: 151,
      date: "24 Jun 1948",
      time: 0,
      type: 0,
      title: "Berlin Blockage",
      description:
        "The Soviet Union imposed a blockade on West Berlin in 1948, which continued into 1949. The Berlin Airlift, a massive humanitarian and logistical effort led by the United States and its allies, supplied West Berlin by air, averting a potential crisis. 📷 Berliners watching a C-54 land at Berlin Tempelhof Airport, 1948.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1948%2F06%2F24%2Fthumbs%2F151-C-54landingattemplehof_600x600.jpeg?alt=media&token=d49f4115-26a0-4473-a705-c8d262e22c18",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1948%2F06%2F24%2F151-C-54landingattemplehof.jpeg?alt=media&token=8f264e30-d275-449b-aaa4-e22c86deee2b",
      imgsource:
        "https://en.wikipedia.org/wiki/Berlin_Blockade#/media/File:C-54landingattemplehof.jpg",
      link: "https://en.wikipedia.org/wiki/Berlin_Blockade",
    },
    {
      id: 152,
      date: "30 Jan 1948",
      time: 0,
      type: 0,
      title: "Gandhi Assassination",
      description:
        "Mahatma Gandhi, the leader of India's nonviolent independence movement, was assassinated on January 30, 1948, in New Delhi by Nathuram Godse, a Hindu nationalist who disagreed with his ideology. 📷 Studio photograph of Mahatma Gandhi, London, 1931.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1948%2F01%2F30%2Fthumbs%2F152-Mahatma-Gandhi%2C_studio%2C_1931_600x600.jpeg?alt=media&token=3c0d4cb9-ac1f-405b-a017-5d71ab20a18e",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1948%2F01%2F30%2F152-Mahatma-Gandhi%2C_studio%2C_1931.jpeg?alt=media&token=f16dfd4c-67c6-408d-8c73-73a169a56d0f",
      imgsource:
        "https://en.wikipedia.org/wiki/Mahatma_Gandhi#/media/File:Mahatma-Gandhi,_studio,_1931.jpg",
      link: "https://en.wikipedia.org/wiki/Assassination_of_Mahatma_Gandhi",
    },
    {
      id: 153,
      date: "26 Nov 1948",
      time: 0,
      type: 0,
      title: "Polaroid Land Camera",
      description:
        "📷 Polaroid Land Camera Model 95, the first commercially available instant camera",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1948%2F11%2F26%2Fthumbs%2F153-Polaroid_Land_Camera_Model_95_-_MIT_Museum_-_DSC03766_600x600.jpeg?alt=media&token=f005ca6d-2b7a-4d82-ab02-623c5f89d872",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1948%2F11%2F26%2F153-Polaroid_Land_Camera_Model_95_-_MIT_Museum_-_DSC03766.jpeg?alt=media&token=dd8eddb0-81d6-4217-b971-a670df1a30ec",
      imgsource:
        "https://en.wikipedia.org/wiki/Land_Camera#/media/File:Polaroid_Land_Camera_Model_95_-_MIT_Museum_-_DSC03766.JPG",
      link: "https://en.wikipedia.org/wiki/Land_Camera",
    },
    {
      id: 154,
      date: "7 Apr 1948",
      time: 0,
      type: 0,
      title: "Foundation of the World Health Organization (WHO)",
      description:
        "The World Health Organization (WHO) was established on April 7, 1948, as a specialized agency of the United Nations focused on global public health.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1948%2F04%2F07%2Fthumbs%2F153-WHO_600x600.jpeg?alt=media&token=127148f9-3685-42a5-8874-bb419cf2d489",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1948%2F04%2F07%2F153-WHO.png?alt=media&token=a1c30858-503e-491f-9ef6-6af3d1947e79",
      imgsource:
        "https://en.wikipedia.org/wiki/World_Health_Organization#/media/File:World_Health_Organisation_headquarters,_Geneva,_north_and_west_sides.jpg",
      link: "https://www.who.int/about/history#",
    },
    {
      id: 155,
      date: "12 Mar 1947",
      time: 0,
      type: 0,
      title: "The Truman Doctrine",
      description:
        "In a speech to Congress in March 1947, U.S. President Harry S. Truman outlined the Truman Doctrine, which pledged U.S. support for countries threatened by communism, setting the stage for the Cold War.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1947%2F03%2F12%2Fthumbs%2F154-truman-harry_600x600.jpeg?alt=media&token=d8dc7ac1-520b-413c-a3c2-79c2bb5f3636",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1947%2F03%2F12%2F154-truman-harry.jpeg?alt=media&token=88faf8ec-9ed4-4850-ae26-fc8b82d0ed13",
      imgsource:
        "https://history.state.gov/milestones/1945-1952/truman-doctrine#",
      link: "https://history.state.gov/milestones/1945-1952/truman-doctrine#",
    },
    {
      id: 156,
      date: "18 Sep 1947",
      time: 0,
      type: 0,
      title: "Creation of the CIA",
      description:
        "The Central Intelligence Agency (CIA) was established in the United States to oversee intelligence and counterintelligence activities.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1947%2F09%2F18%2Fthumbs%2F155-CIA-Seal_600x600.jpeg?alt=media&token=2c39264a-61cb-4708-ad0b-d2c725a5f1b9",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1947%2F09%2F18%2F155-CIA-Seal.png?alt=media&token=5c68b7ae-d0bb-42f6-aa15-c1c19307659e",
      imgsource:
        "https://en.wikipedia.org/wiki/Central_Intelligence_Agency#/media/File:Seal_of_the_Central_Intelligence_Agency.svg",
      link: "https://www.cia.gov/legacy/cia-history/#",
    },
    {
      id: 157,
      date: "8 Jul 1947",
      time: 0,
      type: 0,
      title: "Roswell UFO Incident",
      description:
        "In July 1947, there were reports of a 'flying disc' crash near Roswell, New Mexico, which sparked conspiracy theories and speculation about UFOs. 📷 July 8, 1947, issue of the Roswell Daily Record, announcing the 'capture' of a 'flying saucer'",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1947%2F07%2F08%2Fthumbs%2F157-RoswellDailyRecordJuly8%2C1947_600x600.jpeg?alt=media&token=19f43097-f04a-40b3-844e-47bbd4892706",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1947%2F07%2F08%2F157-RoswellDailyRecordJuly8%2C1947.jpeg?alt=media&token=9112b3ab-69dc-46d9-9c9c-5a8713bb5516",
      imgsource:
        "https://en.wikipedia.org/wiki/Roswell_incident#/media/File:RoswellDailyRecordJuly8,1947.jpg",
      link: "https://en.wikipedia.org/wiki/Roswell_incident",
    },
    {
      id: 158,
      date: "6 Jun 1946",
      time: 0,
      type: 0,
      title: "The NBA is Formed",
      description:
        "The National Basketball Association (NBA) was formed in the United States, merging the National Basketball League (NBL) and the Basketball Association of America (BAA).",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1946%2F06%2F06%2Fthumbs%2F158-NBA_600x600.jpeg?alt=media&token=53add069-ac58-48d8-a22c-9ff852f026d1",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1946%2F06%2F06%2F158-NBA.png?alt=media&token=6877b6cd-2ffe-40b1-8a38-abe58e598684",
      imgsource:
        "https://en.wikipedia.org/wiki/National_Basketball_Association#/media/File:National_Basketball_Association_logo.svg",
      link: "https://en.wikipedia.org/wiki/National_Basketball_Association",
    },
    {
      id: 159,
      date: "10 Jan 1946",
      time: 0,
      type: 0,
      title: "First General Assembly of the United Nation",
      description:
        "The United Nations General Assembly held its first meeting in London in 1946, addressing issues related to post-war reconstruction and international cooperation.📷 United Nations General Assembly hall in New York City.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1946%2F01%2F10%2Fthumbs%2F159-UN_General_Assembly_hall_600x600.jpeg?alt=media&token=ed5db35b-0206-4cbc-beb2-fb760f9bd3ba",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1946%2F01%2F10%2F159-UN_General_Assembly_hall.jpeg?alt=media&token=19aa6668-e675-46ff-91f8-db43eb8df5e3",
      imgsource:
        "https://en.wikipedia.org/wiki/United_Nations_General_Assembly#/media/File:UN_General_Assembly_hall.jpg",
      link: "https://en.wikipedia.org/wiki/United_Nations_General_Assembly",
    },
    {
      id: 160,
      date: "5 Mar 1946",
      time: 0,
      type: 0,
      title: "Iron Curtain Speech",
      description:
        "British Prime Minister Winston Churchill delivered his famous 'Iron Curtain' speech in Fulton, Missouri, warning of the division of Europe between Western and Eastern blocs. ",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1946%2F03%2F05%2Fthumbs%2F160-Lead%20Image%20-%20KB%20M-3_600x600.jpeg?alt=media&token=41440e01-21f8-48f3-97c0-ec3878cc9924",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1946%2F03%2F05%2F160-Lead%20Image%20-%20KB%20M-3.jpeg?alt=media&token=10143f6c-8893-46de-860c-ad98c69d9d69",
      imgsource:
        "https://www.nationalww2museum.org/war/articles/winston-churchills-iron-curtain-speech-march-5-1946",
      link: "https://winstonchurchill.org/resources/speeches/1946-1963-elder-statesman/the-sinews-of-peace/",
    },
    {
      id: 161,
      date: "24 Oct 1945",
      time: 0,
      type: 0,
      title: "United Nations Established",
      description:
        "The United Nations (UN) is an international organization founded in 1945 after World War II. Its purpose is to promote peace, cooperation, and diplomacy among nations, prevent conflicts, provide humanitarian assistance, and address global challenges, such as poverty, disease, and climate change, through multilateral diplomacy and cooperation. The UN was established to prevent future world wars and foster international collaboration.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1945%2F10%2F24%2Fthumbs%2F161-Flag_of_the_United_Nations.svg_600x600.jpeg?alt=media&token=962dcfdb-fa3f-4687-8ebe-3d8459e37169",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1945%2F10%2F24%2F161-Flag_of_the_United_Nations.svg.png?alt=media&token=fdfdb2b9-a51c-42f8-9833-8e9016def334",
      imgsource:
        "https://en.wikipedia.org/wiki/United_Nations#/media/File:Flag_of_the_United_Nations.svg",
      link: "https://www.un.org/en/about-us",
    },
    {
      id: 162,
      date: "20 Nov 1945",
      time: 0,
      type: 0,
      title: "Nuremberg Trials",
      description:
        "The Nuremberg Trials began in November 1945, prosecuting Nazi leaders for war crimes, crimes against humanity, and other atrocities committed during World War II. 📷 View from above of the judges' bench at the International Military Tribunal in Nurembe",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1945%2F11%2F20%2Fthumbs%2F162-Color_photograph_of_judges'_bench_at_IMT_600x600.jpeg?alt=media&token=04c6da13-7cbc-4067-af4a-e51297bb7e8f",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1945%2F11%2F20%2F162-Color_photograph_of_judges'_bench_at_IMT.jpeg?alt=media&token=c97361ea-6dc4-475f-8e9f-e7c3c2c9cd2c",
      imgsource:
        "https://en.wikipedia.org/wiki/Nuremberg_trials#/media/File:Color_photograph_of_judges'_bench_at_IMT.jpg",
      link: "https://en.wikipedia.org/wiki/Nuremberg_trials",
    },
    {
      id: 163,
      date: "2 Sep 1945",
      time: 0,
      type: 0,
      title: "End of World War II",
      description:
        "World War II (1939-1945) was a global conflict involving major powers, marked by military campaigns, genocide, and the use of atomic bombs. Germany surrendered on May 8, 1945, marking the end of the war in Europe. Japan officially surrendered on September 2, 1945, aboard the USS Missouri in Tokyo Bay, bringing an end to World War II. 📷 For documentary purposes the German Federal Archive often retained the original image captions, which may be erroneous, biased, obsolete or politically extreme. German Stuka dive bombers over the Eastern Front, World War II. A destroyed city is seen in the foreground (below the Junkers Ju 87s)",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1945%2F09%2F02%2Fthumbs%2F163-Bundesarchiv_Bild_101I-646-5188-17%2C_Flugzeuge_Junkers_Ju_87_600x600.jpeg?alt=media&token=8182b64a-f039-4168-b698-c29f034a0ff9",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1945%2F09%2F02%2F163-Bundesarchiv_Bild_101I-646-5188-17%2C_Flugzeuge_Junkers_Ju_87.jpeg?alt=media&token=26d3d4af-d96b-4406-8322-fb3c0900f82c",
      imgsource:
        "https://en.wikipedia.org/wiki/World_War_II#/media/File:Bundesarchiv_Bild_101I-646-5188-17,_Flugzeuge_Junkers_Ju_87.jpg",
      link: "https://en.wikipedia.org/wiki/World_War_II",
    },
    {
      id: 164,
      date: "6 Jun 1944",
      time: 0,
      type: 0,
      title: "D-Day",
      description:
        "Allied forces launched the Normandy Invasion, known as D-Day, to liberate Western Europe from Nazi occupation. It was a pivotal moment in World War II. 📷 A LCVP (Landing Craft, Vehicle, Personnel) from the U.S. Coast Guard-manned USS Samuel Chase disembarks troops of Company A, 16th Infantry, 1st Infantry Division (the Big Red One) wading onto the Fox Green section of Omaha Beach (Calvados, Basse-Normandie, France) on the morning of June 6, 1944. American soldiers encountered the newly formed German 352nd Division when landing. During the initial landing two-thirds of Company E became casualties.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1944%2F06%2F06%2Fthumbs%2F164-Into_the_Jaws_of_Death_23-0455M_edit_600x600.jpeg?alt=media&token=4289f6a9-8ea4-41a8-a93d-d99de83b2af5",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1944%2F06%2F06%2F164-Into_the_Jaws_of_Death_23-0455M_edit.jpeg?alt=media&token=20237177-bde9-44e2-9d5d-dcdee53471a3",
      imgsource:
        "https://en.wikipedia.org/wiki/Normandy_landings#/media/File:Into_the_Jaws_of_Death_23-0455M_edit.jpg",
      link: "https://en.wikipedia.org/wiki/Normandy_landings",
    },
    {
      id: 165,
      date: "17 Sep 1944",
      time: 0,
      type: 0,
      title: "Operation Market Garden",
      description:
        "Allied forces launched Operation Market Garden in September 1944, an unsuccessful attempt to seize key bridges in the Netherlands. 📷 Parachutes open overhead as waves of paratroops land in Holland during operations by the 1st Allied Airborne Army.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1944%2F09%2F17%2Fthumbs%2F165-Waves_of_paratroops_land_in_Holland_600x600.jpeg?alt=media&token=98fb3b78-2199-4a4b-84ea-ed8c882a0e9e",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1944%2F09%2F17%2F165-Waves_of_paratroops_land_in_Holland.jpeg?alt=media&token=c676be91-8c3d-4102-a31d-639fde20076d",
      imgsource:
        "https://en.wikipedia.org/wiki/Operation_Market_Garden#/media/File:Waves_of_paratroops_land_in_Holland.jpg",
      link: "https://en.wikipedia.org/wiki/Operation_Market_Garden",
    },
    {
      id: 166,
      date: "21 Aug 1944",
      time: 0,
      type: 0,
      title: "Dumbarton Oaks Conference",
      description:
        "The Dumbarton Oaks Conference laid the groundwork for the establishment of the United Nations, with discussions on the structure of the future organization. 📷 Participants of the conference",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1944%2F08%2F21%2Fthumbs%2F166-Washington_conversations_on_international_organization%2C_Dumbarton_Oaks%2C_Washington%2C_D.C.%2C_USA_(August_1944)_600x600.jpeg?alt=media&token=ca64e3cc-6b9b-4edf-be97-944a8762322d",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1944%2F08%2F21%2F166-Washington_conversations_on_international_organization%2C_Dumbarton_Oaks%2C_Washington%2C_D.C.%2C_USA_(August_1944).jpg?alt=media&token=d9639ba7-78ff-4e69-8c04-9e50b748af90",
      imgsource:
        "https://en.wikipedia.org/wiki/Dumbarton_Oaks_Conference#/media/File:Washington_conversations_on_international_organization,_Dumbarton_Oaks,_Washington,_D.C.,_USA_(August_1944).jpg",
      link: "https://en.wikipedia.org/wiki/Dumbarton_Oaks_Conference",
    },
    {
      id: 167,
      date: "16 Dec 1944",
      time: 0,
      type: 0,
      title: "Battle of the Bulge",
      description:
        "The Battle of the Bulge was a major German offensive in the Ardennes region of Belgium and Luxembourg in December 1944, but it was eventually repelled by Allied forces. 📷 American soldiers of the 117th Infantry Regiment, Tennessee National Guard, part of the 30th Infantry Division, move past a destroyed American M5A1 'Stuart' tank on their march to recapture the town of St. Vith during the Battle of the Bulge, January 1945.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1944%2F12%2F16%2Fthumbs%2F167-117th_Infantry_North_Carolina_NG_at_St._Vith_1945_600x600.jpeg?alt=media&token=80f4c8ee-8583-475c-b17c-ac9ba3e22ae5",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1944%2F12%2F16%2F167-117th_Infantry_North_Carolina_NG_at_St._Vith_1945.jpg?alt=media&token=6d9f442e-4118-4433-a6fe-d17c43952a08",
      imgsource:
        "https://en.wikipedia.org/wiki/Battle_of_the_Bulge#/media/File:117th_Infantry_North_Carolina_NG_at_St._Vith_1945.jpg",
      link: "https://en.wikipedia.org/wiki/Battle_of_the_Bulge",
    },
    {
      id: 168,
      date: "3 Jun 1943",
      time: 0,
      type: 0,
      title: "Zoot Suit Riots",
      description:
        "The Zoot Suit Riots occurred in Los Angeles, USA, primarily involving clashes between servicemen and Mexican American youth known as 'pachucos' over the wearing of zoot suits. 📷 Victims of the Zoot Suit Riots, where groups of servicemen scoured the streets in Los Angeles looking for and attacking individuals wearing zoot suits (June, 1943). The servicemen blamed the Mexican American pachucos for numerous unprovoked assaults on their colleagues.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1943%2F06%2F03%2Fthumbs%2F168-Victims_of_the_Zoot_Suit_Riots_600x600.jpeg?alt=media&token=2c28be11-a2a0-43e6-9f2e-d195b301fd3a",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1943%2F06%2F03%2F168-Victims_of_the_Zoot_Suit_Riots.jpeg?alt=media&token=862d0395-1d52-4264-8e9f-f90a3939afff",
      imgsource:
        "https://en.wikipedia.org/wiki/Zoot_Suit_Riots#/media/File:Victims_of_the_Zoot_Suit_Riots.jpg",
      link: "https://en.wikipedia.org/wiki/Zoot_Suit_Riots",
    },
    {
      id: 169,
      date: "24 Jul 1943",
      time: 0,
      type: 0,
      title: "Operation Gomorrah",
      description:
        "Allied bombing campaigns, including the firebombing of Hamburg, caused widespread destruction in German cities. 📷 A Royal Air Force Lancaster bomber over Hamburg during Operation Gomorrah. Note the flares surrounding the bomber’s silhouette. ",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1943%2F07%2F24%2Fthumbs%2F169-Picture2_0_600x600.jpeg?alt=media&token=9ce6f234-6bdf-4325-874d-cd1be161fc53",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1943%2F07%2F24%2F169-Picture2_0.png?alt=media&token=260a168b-63bd-48d7-bb17-4ad13816e2d1",
      imgsource:
        "https://www.nationalww2museum.org/war/articles/operation-gomorrah-first-firestorms",
      link: "https://en.wikipedia.org/wiki/Bombing_of_Hamburg_in_World_War_II",
    },
    {
      id: 170,
      date: "5 Jul 1943",
      time: 0,
      type: 0,
      title: "Battle of Kursk",
      description:
        "The Battle of Kursk was a pivotal battle on the Eastern Front of World War II that took place from July 5 to August 23, 1943. It was fought between the Soviet Union and Nazi Germany. ",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1943%2F07%2F05%2Fthumbs%2F170-featured-image_11-19-15_600x600.jpeg?alt=media&token=23080221-74c8-40c1-959b-93edb4ec94c5",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1943%2F07%2F05%2F170-featured-image_11-19-15.png?alt=media&token=3b27e109-37e8-402f-92fc-4e57911e7a6d",
      imgsource:
        "https://warfarehistorynetwork.com/article/the-kursk-battle-the-eastern-fronts-turning-point/",
      link: "https://en.wikipedia.org/wiki/Battle_of_Kursk",
    },
    {
      id: 171,
      date: "23 Aug 1942",
      time: 0,
      type: 0,
      title: "Battle of Stalingrad",
      description:
        "The Battle of Stalingrad, fought from August 1942 to February 1943, was one of the most significant and brutal battles of World War II. TThe Battle of Stalingrad is often seen as a turning point in World War II, as it halted the German advance into the Soviet Union and marked the beginning of the Soviet push westward toward Germany.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1942%2F08%2F23%2Fthumbs%2F171-%D0%A4%D0%BE%D0%BD%D1%82%D0%B0%D0%BD_%C2%AB%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B8%D0%B8%CC%86_%D1%85%D0%BE%D1%80%D0%BE%D0%B2%D0%BE%D0%B4%C2%BB_600x600.jpeg?alt=media&token=a21825d1-cee1-4352-8806-575a40b1a395",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1942%2F08%2F23%2F171-%D0%A4%D0%BE%D0%BD%D1%82%D0%B0%D0%BD_%C2%AB%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B8%D0%B8%CC%86_%D1%85%D0%BE%D1%80%D0%BE%D0%B2%D0%BE%D0%B4%C2%BB.jpeg?alt=media&token=e2b98f66-516a-4b9f-9a71-38670c290000",
      imgsource:
        "https://en.wikipedia.org/wiki/Battle_of_Stalingrad#/media/File:%D0%A4%D0%BE%D0%BD%D1%82%D0%B0%D0%BD_%C2%AB%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B8%D0%B9_%D1%85%D0%BE%D1%80%D0%BE%D0%B2%D0%BE%D0%B4%C2%BB.jpg",
      link: "https://en.wikipedia.org/wiki/Battle_of_Stalingrad",
    },
    {
      id: 172,
      date: "9 Apr 1942",
      time: 0,
      type: 0,
      title: "The Bataan Death March",
      description:
        "The Bataan Death March took place in April 1942, following the surrender of American and Filipino forces to the Japanese during the Battle of Bataan. It was part of Japan's campaign to capture the Philippines. It is remembered as one of the most infamous war crimes committed by the Japanese during World War II. It is a symbol of the brutality and inhumanity of war. 📷 Prisoners photographed during the march. They have their hands tied behind their backs. They are (left to right): Pvt Samuel Stenzler (d. May 1942); Pvt Frank Spears (killed June 1945); Capt John McDonnell Gallagher who died shortly after this picture was taken 9 April 1942",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1942%2F04%2F09%2Fthumbs%2F172-lossy-page1-1920px-The_March_of_Death._Along_the_March_(on_which)_these_prisoners_were_photographed%2C_they_have_their_hands_tied_behind..._-_NARA_-_532548.tif_600x600.jpeg?alt=media&token=e22f6b88-9699-4845-867f-c265173c25ce",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1942%2F04%2F09%2F172-lossy-page1-1920px-The_March_of_Death._Along_the_March_(on_which)_these_prisoners_were_photographed%2C_they_have_their_hands_tied_behind..._-_NARA_-_532548.tif.jpg?alt=media&token=06cde439-fad4-4baa-90d4-b98a0f0cbb19",
      imgsource:
        "https://en.wikipedia.org/wiki/Bataan_Death_March#/media/File:The_March_of_Death._Along_the_March_(on_which)_these_prisoners_were_photographed,_they_have_their_hands_tied_behind..._-_NARA_-_532548.tif",
      link: "https://en.wikipedia.org/wiki/Bataan_Death_March",
    },
    {
      id: 173,
      date: "9 Mar 1942",
      time: 0,
      type: 0,
      title: "Alaska Highway",
      description:
        "The construction of the Alaska Highway, a vital supply route during the war, was completed. 📷 Alaska Highway between Fort Nelson and Watson Lake",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1942%2F03%2F09%2Fthumbs%2F173-AlaskaHWY_600x600.jpeg?alt=media&token=bd1293d7-e76d-4600-acf2-ce5a605b2de2",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1942%2F03%2F09%2F173-AlaskaHWY.jpeg?alt=media&token=6bebc7c7-2330-4b9b-bd0b-b2696d643b65",
      imgsource:
        "https://en.wikipedia.org/wiki/Alaska_Highway#/media/File:AlaskaHWY.JPG",
      link: "https://en.wikipedia.org/wiki/Alaska_Highway",
    },
    {
      id: 174,
      date: "7 Dec 1941",
      time: 0,
      type: 0,
      title: "Attack on Pearl Harbor",
      description:
        "The Japanese Imperial Navy launched a surprise attack on the U.S. naval base at Pearl Harbor, Hawaii. This event led to the United States' entry into World War II. 📷 Photograph taken from a Japanese plane during the torpedo attack on ships moored on both sides of Ford Island shortly after the beginning of the Pearl Harbor attack. ",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1941%2F12%2F07%2Fthumbs%2F174-1920px-Attack_on_Pearl_Harbor_Japanese_planes_view_600x600.jpeg?alt=media&token=463c9b39-b5d8-4108-925a-9a806b863937",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1941%2F12%2F07%2F174-1920px-Attack_on_Pearl_Harbor_Japanese_planes_view.jpeg?alt=media&token=632d82a7-c295-4ea6-9cf7-d0f403bba07d",
      imgsource:
        "https://en.wikipedia.org/wiki/Attack_on_Pearl_Harbor#/media/File:Attack_on_Pearl_Harbor_Japanese_planes_view.jpg",
      link: "https://en.wikipedia.org/wiki/Attack_on_Pearl_Harbor",
    },
    {
      id: 175,
      date: "8 Sep 1941",
      time: 0,
      type: 0,
      title: "Siege of Leningrad",
      description:
        "The Siege of Leningrad, lasting from 1941 to 1944, was a brutal and prolonged Nazi German blockade of the Soviet city of Leningrad (now St. Petersburg). It resulted in immense suffering, as the city's residents endured starvation, extreme cold, and constant bombardment. The siege is one of the deadliest in history, with hundreds of thousands of civilian deaths.  📷 The fire of anti-aircraft guns deployed in the neighborhood of St. Isaac's cathedral during the defense of Leningrad (now called St. Petersburg, its pre-Soviet name) in 1941.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1941%2F09%2F08%2Fthumbs%2F175-Anti_aircraft_Leningrad_1941_600x600.jpeg?alt=media&token=d397bcf1-d485-4341-a2d8-d2ca1cca3edb",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1941%2F09%2F08%2F175-Anti_aircraft_Leningrad_1941.jpeg?alt=media&token=a8dee6b2-965e-4553-ac4e-458adaf500ab",
      imgsource:
        "https://en.wikipedia.org/wiki/Siege_of_Leningrad#/media/File:Anti_aircraft_Leningrad_1941.JPG",
      link: "https://en.wikipedia.org/wiki/Siege_of_Leningrad",
    },
    {
      id: 176,
      date: "30 Sep 1941",
      time: 0,
      type: 0,
      title: "Battle of Moscow",
      description:
        "The German advance on Moscow was halted by Soviet forces in a significant battle on the Eastern Front. 📷 Soviet anti-aircraft gunners on the roof of the Hotel Moskva",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1941%2F09%2F30%2Fthumbs%2F176-RIAN_archive_887721_Defense_of_Moscow_600x600.jpeg?alt=media&token=132c6425-e76e-4d10-868e-4471d7db1c51",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1941%2F09%2F30%2F176-RIAN_archive_887721_Defense_of_Moscow.jpeg?alt=media&token=50243a70-fb76-4578-aeb8-b636dc6814ea",
      imgsource:
        "https://en.wikipedia.org/wiki/Battle_of_Moscow#/media/File:RIAN_archive_887721_Defense_of_Moscow.jpg",
      link: "https://en.wikipedia.org/wiki/Battle_of_Moscow",
    },
    {
      id: 177,
      date: "26 May 1940",
      time: 0,
      type: 0,
      title: "Dunkirk Evacuation",
      description:
        "Operation Dynamo, also known as the Dunkirk Evacuation, saw the evacuation of over 330,000 British and Allied troops from the beaches of Dunkirk, France, back to Britain. 📷 Dunkirk 26-29 May 1940 British troops line up on the beach at Dunkirk to await evacuation.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1940%2F05%2F26%2Fthumbs%2F177-1920px-Dunkirk_26-29_May_1940_NYP68075_600x600.jpeg?alt=media&token=26d08195-4955-41db-8b65-9c51e8cd5f3c",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1940%2F05%2F26%2F177-1920px-Dunkirk_26-29_May_1940_NYP68075.jpeg?alt=media&token=e6142288-6b5d-4f79-ab52-55373b8cb9a5",
      imgsource:
        "https://en.wikipedia.org/wiki/Dunkirk_evacuation#/media/File:Dunkirk_26-29_May_1940_NYP68075.jpg",
      link: "https://en.wikipedia.org/wiki/Dunkirk_evacuation",
    },
    {
      id: 178,
      date: "3 Apr 1940",
      time: 0,
      type: 0,
      title: "Katyn Massacre",
      description:
        "Thousands of Polish military officers and intellectuals were massacred by Soviet forces in the Katyn Forest, a war crime that strained Soviet-Polish relations. 📷 Photo from 1943 exhumation of mass grave of Polish officers killed by NKVD in Katyń Forest in 1940. Inside of one of largest grave.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1940%2F04%2F03%2Fthumbs%2F178-Katyn_Massacre_-_Mass_Graves_2_600x600.jpeg?alt=media&token=71c062e8-02e4-4da4-8601-78d132311181",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1940%2F04%2F03%2F178-Katyn_Massacre_-_Mass_Graves_2.jpeg?alt=media&token=564a83e4-7be4-4db3-aac1-940b25025226",
      imgsource:
        "https://en.wikipedia.org/wiki/Katyn_massacre#/media/File:Katyn_Massacre_-_Mass_Graves_2.jpg",
      link: "https://en.wikipedia.org/wiki/Katyn_massacre",
    },
    {
      id: 179,
      date: "13 Nov 1940",
      time: 0,
      type: 0,
      title: "Disney's 'Fantasia'",
      description:
        "Walt Disney's animated film 'Fantasia' was released, featuring classical music and innovative animation. 📷 This is a poster for Fantasia. The poster art copyright is believed to belong to Disney Enterprises, Inc.. Further details: Original poster for Fantasia.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1940%2F11%2F13%2Fthumbs%2F179-Fantasia-poster-1940_600x600.jpeg?alt=media&token=fe5bcde8-50d3-4ffa-a2b4-af37ecdeb6a0",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1940%2F11%2F13%2F179-Fantasia-poster-1940.jpeg?alt=media&token=483aed77-0dd1-49a6-b531-3f5c02896da7",
      imgsource:
        "https://en.wikipedia.org/wiki/Fantasia_(1940_film)#/media/File:Fantasia-poster-1940.jpg",
      link: "https://en.wikipedia.org/wiki/Fantasia_(1940_film)",
    },
    {
      id: 180,
      date: "23 Aug 1939",
      time: 0,
      type: 0,
      title: "Molotov-Ribbentrop Pact",
      description:
        "Nazi Germany and the Soviet Union signed the Molotov-Ribbentrop Pact, a non-aggression treaty that included a secret protocol dividing Eastern Europe into spheres of influence. 📷 Stalin and Ribbentrop shaking hands after the signing of the pact in the Kremlin",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1939%2F08%2F23%2Fthumbs%2F180-Bundesarchiv_Bild_183-H27337%2C_Moskau%2C_Stalin_und_Ribbentrop_im_Kreml_600x600.jpeg?alt=media&token=e757b3a2-6599-414b-940b-277cc8c5876c",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1939%2F08%2F23%2F180-Bundesarchiv_Bild_183-H27337%2C_Moskau%2C_Stalin_und_Ribbentrop_im_Kreml.jpeg?alt=media&token=587dfa8b-1440-4d22-a6d1-d305fd1a15d5",
      imgsource:
        "https://en.wikipedia.org/wiki/Molotov%E2%80%93Ribbentrop_Pact#/media/File:Bundesarchiv_Bild_183-H27337,_Moskau,_Stalin_und_Ribbentrop_im_Kreml.jpg",
      link: "https://en.wikipedia.org/wiki/Molotov%E2%80%93Ribbentrop_Pact",
    },
    {
      id: 181,
      date: "1 Sep 1939",
      time: 0,
      type: 0,
      title: "Outbreak of World War II",
      description:
        "World War II officially began on September 1, 1939, when Nazi Germany, under the leadership of Adolf Hitler, invaded Poland. This invasion prompted Britain and France to declare war on Germany, marking the start of a global conflict. 📷 Soldiers of the German Wehrmacht tearing down the border crossing into Poland, 1 September 1939",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1939%2F09%2F01%2Fthumbs%2F181-Danzig_Police_at_Polish_Border_(1939-09-01)_600x600.jpeg?alt=media&token=1ac0e31d-e75b-4ca3-8eec-f284604cdf52",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1939%2F09%2F01%2F181-Danzig_Police_at_Polish_Border_(1939-09-01).jpeg?alt=media&token=5be3c3bb-15a2-456d-a255-d01bb9aee22a",
      imgsource:
        "https://en.wikipedia.org/wiki/World_War_II#/media/File:Danzig_Police_at_Polish_Border_(1939-09-01).jpg",
      link: "https://en.wikipedia.org/wiki/World_War_II",
    },
    {
      id: 182,
      date: "30 Nov 1939",
      time: 0,
      type: 0,
      title: "Winter War 1939",
      description:
        "The Winter War (1939-1940) was a conflict where the Soviet Union invaded Finland, resulting in Finnish resistance and territorial concessions. 📷 A Finnish Maxim M/09-21 machine gun crew during the Winter War",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1939%2F11%2F30%2Fthumbs%2F182-A_Finnish_Maxim_M-32_machine_gun_nest_during_the_Winter_War_600x600.jpeg?alt=media&token=c23cb1a1-2532-4b81-a638-edbd0e923eec",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1939%2F11%2F30%2F182-A_Finnish_Maxim_M-32_machine_gun_nest_during_the_Winter_War.jpeg?alt=media&token=327e8fbc-f8e1-48dc-a978-4628370d21c8",
      imgsource:
        "https://en.wikipedia.org/wiki/Winter_War#/media/File:A_Finnish_Maxim_M-32_machine_gun_nest_during_the_Winter_War.jpg",
      link: "https://en.wikipedia.org/wiki/Winter_War",
    },
    {
      id: 183,
      date: "9 Nov 1938",
      time: 0,
      type: 0,
      title: "Kristallnacht",
      description:
        "In November, the anti-Semitic pogrom known as Kristallnacht occurred in Nazi Germany, resulting in widespread violence against Jews and the destruction of Jewish businesses and synagogues. 📷 Interior view of the destroyed Fasanenstrasse Synagogue, Berlin, burned on Kristallnacht; November Pogroms.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1938%2F11%2F09%2Fthumbs%2F183-Interior_view_of_the_destroyed_Fasanenstrasse_Synagogue%2C_Berlin_600x600.jpeg?alt=media&token=82f5b1de-5e14-4e3f-8972-ea426e96ae83",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1938%2F11%2F09%2F183-Interior_view_of_the_destroyed_Fasanenstrasse_Synagogue%2C_Berlin.jpeg?alt=media&token=40b1df57-3410-4eec-8460-2ddfe8ccfc0e",
      imgsource:
        "https://en.wikipedia.org/wiki/Kristallnacht#/media/File:Interior_view_of_the_destroyed_Fasanenstrasse_Synagogue,_Berlin.jpg",
      link: "https://en.wikipedia.org/wiki/Kristallnacht",
    },
    {
      id: 184,
      date: "2 Dec 1938",
      time: 0,
      type: 0,
      title: "KinderTransport",
      description:
        "Kindertransport was a program, took place in 1938-1939, that rescued nearly 10,000 predominantly Jewish children from Nazi-occupied Europe to safety in Britain. The first Kindertransport arrived at Harwich, Great Britain, on December 2, 1938. 📷 Young refugees of the first Kindertransport after their arrival at Harwich, Essex, in the early morning of 2 December 1938",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1938%2F12%2F02%2Fthumbs%2F184-Bundesarchiv_Bild_183-1987-0928-501%2C_England%2C_Ju%CC%88dische_Flu%CC%88chtlingskinder_600x600.jpeg?alt=media&token=9fa383ea-95d4-4e3c-aba6-e309d917f39e",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1938%2F12%2F02%2F184-Bundesarchiv_Bild_183-1987-0928-501%2C_England%2C_Ju%CC%88dische_Flu%CC%88chtlingskinder.jpeg?alt=media&token=115dfda0-7373-4b34-af87-fcbe2ff79317",
      imgsource:
        "https://en.wikipedia.org/wiki/Kindertransport#/media/File:Bundesarchiv_Bild_183-1987-0928-501,_England,_J%C3%BCdische_Fl%C3%BCchtlingskinder.jpg",
      link: "https://en.wikipedia.org/wiki/Kindertransport",
    },
    {
      id: 185,
      date: "26 Dec 1933",
      time: 0,
      type: 0,
      title: "Montevideo Convention",
      description:
        "The comic book character Superman made his first appearance in Action Comics #1, created by Jerry Siegel and Joe Shuster. 📷 Superman in Action Comics #1000 (April 2018)",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1938%2F04%2F18%2Fthumbs%2F185-Supermanflying_600x600.jpeg?alt=media&token=167163a2-64f9-4321-8c54-91775058e826",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1938%2F04%2F18%2F185-Supermanflying.png?alt=media&token=a26f700a-0c86-47d1-ad80-ef569de6e2ef",
      imgsource:
        "https://en.wikipedia.org/wiki/Superman#/media/File:Supermanflying.png",
      link: "https://en.wikipedia.org/wiki/Superman",
    },
    {
      id: 186,
      date: "2 Jul 1937",
      time: 0,
      type: 0,
      title: "Amelia Earhart's Disappearance",
      description:
        "Pioneering aviator Amelia Earhart disappeared while attempting to circumnavigate the globe, and her fate remains a mystery. 📷 Amelia Earhart standing under nose of her Lockheed Model 10-E Electra. Gelatin silver print, 1937. National Portrait Gallery, Smithsonian Institution; gift of George R. Rinhart, in memory of Joan Rinhart",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1937%2F07%2F02%2Fthumbs%2F186-Amelia_Earhart_standing_under_nose_of_her_Lockheed_Model_10-E_Electra%2C_small_600x600.jpeg?alt=media&token=6db9814f-c1a1-44ba-a644-c121d26e1610",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1937%2F07%2F02%2F186-Amelia_Earhart_standing_under_nose_of_her_Lockheed_Model_10-E_Electra%2C_small.jpeg?alt=media&token=bbd2c337-78c2-47b4-8e54-36f32ad61624",
      imgsource:
        "https://en.wikipedia.org/wiki/Amelia_Earhart#/media/File:Amelia_Earhart_standing_under_nose_of_her_Lockheed_Model_10-E_Electra,_small.jpg",
      link: "https://en.wikipedia.org/wiki/Amelia_Earhart",
    },
    {
      id: 187,
      date: "27 May 1937",
      time: 0,
      type: 0,
      title: "Golden Gate Bridge",
      description:
        "The Golden Gate Bridge in San Francisco, California, was completed and opened to vehicular traffic, becoming an iconic symbol of the city. 📷 View from the Marin Headlands, 2007",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1937%2F05%2F27%2Fthumbs%2F187-GoldenGateBridge-001_600x600.jpeg?alt=media&token=2033027b-daa2-4315-823d-84dee59852aa",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1937%2F05%2F27%2F187-GoldenGateBridge-001.jpeg?alt=media&token=eb46dc6b-3777-465d-8f82-e826f28f9a0d",
      imgsource:
        "https://en.wikipedia.org/wiki/Golden_Gate_Bridge#/media/File:GoldenGateBridge-001.jpg",
      link: "",
    },
    {
      id: 188,
      date: "7 Jul 1937",
      time: 0,
      type: 0,
      title: "Japanese Invasion of China",
      description:
        "The Second Sino-Japanese War continued, with Japan launching a full-scale invasion of China. The conflict would later become part of World War II. 📷 The Imperial Japanese Navy (IJN) Special Naval Landing Forces troops in gas masks prepare for an advance in the rubble of Shanghai, China.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1937%2F07%2F07%2Fthumbs%2F188-Japanese_Special_Naval_Landing_Forces_in_Battle_of_Shanghai_1937_600x600.jpeg?alt=media&token=bb1987b1-4276-4d7b-9022-746f41ef05dd",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1937%2F07%2F07%2F188-Japanese_Special_Naval_Landing_Forces_in_Battle_of_Shanghai_1937.jpeg?alt=media&token=723a9220-51f1-4159-8f57-e9238b49bcf3",
      imgsource:
        "https://en.wikipedia.org/wiki/Second_Sino-Japanese_War#/media/File:Japanese_Special_Naval_Landing_Forces_in_Battle_of_Shanghai_1937.jpg",
      link: "https://en.wikipedia.org/wiki/Second_Sino-Japanese_War",
    },
    {
      id: 189,
      date: "6 May 1937",
      time: 0,
      type: 0,
      title: "Hindenburg Disaster",
      description:
        "The German passenger airship LZ 129 Hindenburg caught fire while attempting to land in Lakehurst, New Jersey, resulting in a catastrophic disaster and 36 fatalities. 📷 Zeppelin the Hindenburg on fire at the mooring mast of Lakehurst (United States of America) 6 May 1937. Ballast water is thrown down. Exit airships.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1937%2F05%2F06%2Fthumbs%2F189-Hindenburg_disaster_600x600.jpeg?alt=media&token=97cf9251-e304-4170-a14a-6b6475222f4b",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1937%2F05%2F06%2F189-Hindenburg_disaster.jpeg?alt=media&token=b81199ec-d53d-46a6-bcb5-532cdcf611a2",
      imgsource:
        "https://en.wikipedia.org/wiki/Hindenburg_disaster#/media/File:Hindenburg_disaster.jpg",
      link: "https://en.wikipedia.org/wiki/Hindenburg_disaster",
    },
    {
      id: 190,
      date: "13 Dec 1937",
      time: 0,
      type: 0,
      title: "Nanjing Massacre",
      description:
        "During the Second Sino-Japanese War, the Japanese Imperial Army captured Nanjing (Nanking), China, and committed widespread atrocities against the city's civilian population. 📷 Bodies of victims along Qinhuai River out of Nanjing's west gate during Nanjing Massacre.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1937%2F12%2F13%2Fthumbs%2F190-Nanking_bodies_1937_600x600.jpeg?alt=media&token=3e8bb43a-c335-4ff4-8d40-b6faf9e08e56",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1937%2F12%2F13%2F190-Nanking_bodies_1937.jpeg?alt=media&token=4372ff81-f5a9-4c3d-b70b-b23f69d84e94",
      imgsource:
        "https://en.wikipedia.org/wiki/Nanjing_Massacre#/media/File:Nanking_bodies_1937.jpg",
      link: "https://en.wikipedia.org/wiki/Nanjing_Massacre",
    },
    {
      id: 191,
      date: "21 Dec 1937",
      time: 0,
      type: 0,
      title: "Disney's 'Snow White and the Seven Dwarfs'",
      description:
        "The first full-length animated feature film, 'Snow White and the Seven Dwarfs,' was released by Walt Disney Productions and premiered at the Carthay Circle Theatre in Los Angeles, California on December 21, 1937. 📷 Snow White 1937 poster",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1937%2F12%2F21%2Fthumbs%2F191-Snow_White_1937_poster_600x600.jpeg?alt=media&token=fa220ea2-02d5-4977-bbb1-592bdf0907b7",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1937%2F12%2F21%2F191-Snow_White_1937_poster.png?alt=media&token=ab4eb61e-1dfc-4e5b-8b21-1419cfd48335",
      imgsource:
        "https://en.wikipedia.org/wiki/Snow_White_and_the_Seven_Dwarfs_(1937_film)#/media/File:Snow_White_1937_poster.png",
      link: "https://en.wikipedia.org/wiki/Snow_White_and_the_Seven_Dwarfs_(1937_film)#/media/File:Snow_White_1937_poster.png",
    },
    {
      id: 192,
      date: "1 Aug 1936",
      time: 0,
      type: 0,
      title: "Berlin Olympics 1936",
      description:
        "The Summer Olympics, officially known as the Games of the XI Olympiad, were held in Berlin, Germany. These Olympics were notable for their propaganda and the prominence of Nazi symbols. 📷 This is the logo owned by International Olympic Committee for 1936 Summer Olympics.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1936%2F08%2F01%2Fthumbs%2F192-Berlin-Olympics-1939_600x600.jpeg?alt=media&token=b24c8783-8963-4886-9ea7-348ade72af10",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1936%2F08%2F01%2F192-Berlin-Olympics-1939.png?alt=media&token=07e511b4-4d41-4b24-859f-991a964b82d7",
      imgsource:
        "https://en.wikipedia.org/wiki/1936_Summer_Olympics#/media/File:1936_Summer_Olympics_logo.svg",
      link: "https://en.wikipedia.org/wiki/1936_Summer_Olympics",
    },
    {
      id: 193,
      date: "30 Jun 1936",
      time: 0,
      type: 0,
      title: "Gone with the Wind'",
      description:
        "Gone with the Wind' is a novel written by American author Margaret Mitchell. It was first published by Macmillan Publishers in 1936. The novel quickly became a bestseller and went on to win the Pulitzer Prize for Fiction in 1937. It remains one of the most popular and enduring works of American literature, exploring themes of love, survival, and the American South during the Civil War and Reconstruction eras. 📷 This is the front cover art for the book Gone with the Wind (novel). The book cover art copyright is believed to belong to Macmillan Publishers.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1936%2F06%2F30%2Fthumbs%2F193-Gone_with_the_Wind_cover_600x600.jpeg?alt=media&token=e9f18f91-513b-4362-a5fd-a3f0ef3850cc",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1936%2F06%2F30%2F193-Gone_with_the_Wind_cover.jpeg?alt=media&token=f20435f5-4a32-42ae-95f0-2e55ce208065",
      imgsource:
        "https://en.wikipedia.org/wiki/Gone_with_the_Wind_(novel)#/media/File:Gone_with_the_Wind_cover.jpg",
      link: "https://en.wikipedia.org/wiki/Gone_with_the_Wind_(novel)",
    },
    {
      id: 194,
      date: "18 Sep 1936",
      time: 0,
      type: 0,
      title: "International Brigades",
      description:
        "International volunteers from various countries, known as the International Brigades, joined the Spanish Republic in the fight against the Nationalists. 📷 Emblem of the International Brigades, Spanish Civil War",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1936%2F09%2F18%2Fthumbs%2F194-Emblem_of_the_International_Brigades.svg_600x600.jpeg?alt=media&token=3a21e472-e42e-46f7-ab5a-abc26b35775a",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1936%2F09%2F18%2F194-Emblem_of_the_International_Brigades.svg.png?alt=media&token=f3f47358-3389-443e-8986-7d8363ecff4d",
      imgsource:
        "https://en.wikipedia.org/wiki/International_Brigades#/media/File:Emblem_of_the_International_Brigades.svg",
      link: "https://en.wikipedia.org/wiki/International_Brigades",
    },
    {
      id: 195,
      date: "15 Sep 1935",
      time: 0,
      type: 0,
      title: "Nuremberg Laws",
      description:
        "Nazi Germany introduced the Nuremberg Laws, which institutionalized anti-Semitic policies and defined who was considered Jewish, leading to discrimination and persecution. 📷 Title page of the German government gazette Reichsgesetzblatt issue proclaiming the laws, published on 16 September 1935 (RGBl. I No. 100)",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1935%2F09%2F15%2Fthumbs%2F195-RGBL_I_1935_S_1145_600x600.jpeg?alt=media&token=4085e2fb-8853-4847-8813-b07308bcf030",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1935%2F09%2F15%2F195-RGBL_I_1935_S_1145.jpeg?alt=media&token=30192c5f-785d-411c-bff8-a31b0c4f9a2c",
      imgsource:
        "https://en.wikipedia.org/wiki/Nuremberg_Laws#/media/File:RGBL_I_1935_S_1145.jpg",
      link: "https://en.wikipedia.org/wiki/Nuremberg_Laws",
    },
    {
      id: 196,
      date: "17 Dec 1935",
      time: 0,
      type: 0,
      title: "First Flight of the DC-3",
      description:
        "The Douglas DC-3, a groundbreaking commercial aircraft, made its maiden flight, revolutionizing air travel. 📷 A DC-3 operated in period Scandinavian Airlines colours by Flygande Veteraner flying over Lidingö, Sweden, in 1989",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1935%2F12%2F17%2Fthumbs%2F196-Douglas_DC-3%2C_SE-CFP_600x600.jpeg?alt=media&token=61621f6f-2512-4e98-829b-1fdac85f0a35",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1935%2F12%2F17%2F196-Douglas_DC-3%2C_SE-CFP.jpeg?alt=media&token=70f16f76-5fd0-486e-acc1-adc318a71b68",
      imgsource:
        "https://en.wikipedia.org/wiki/Douglas_DC-3#/media/File:Douglas_DC-3,_SE-CFP.jpg",
      link: "https://en.wikipedia.org/wiki/Douglas_DC-3",
    },
    {
      id: 197,
      date: "3 Oct 1935",
      time: 0,
      type: 0,
      title: "Italian Invasion of Ethiopia",
      description:
        "In October, Italian forces, under Benito Mussolini, invaded Ethiopia, marking the beginning of the Second Italo-Ethiopian War. 📷 Italian artillery in Tembien",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1935%2F10%2F03%2Fthumbs%2F197-AO-Etiopia-1936-A-artiglieria-nel-Tembien_600x600.jpeg?alt=media&token=953fd199-856b-41f0-ba77-a07419c4e44e",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1935%2F10%2F03%2F197-AO-Etiopia-1936-A-artiglieria-nel-Tembien.jpeg?alt=media&token=f8430bd7-141b-4214-91f7-f766893d2978",
      imgsource:
        "https://en.wikipedia.org/wiki/Second_Italo-Ethiopian_War#/media/File:AO-Etiopia-1936-A-artiglieria-nel-Tembien.jpg",
      link: "https://en.wikipedia.org/wiki/Second_Italo-Ethiopian_War",
    },
    {
      id: 198,
      date: "11 Aug 1934",
      time: 0,
      type: 0,
      title: "Alcatraz ",
      description:
        "Alcatraz Island in San Francisco Bay became a federal prison, housing some of America's most notorious criminals. 📷 The prison sits atop Alcatraz Island. Alcatraz Island by Don Ramey Logan",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1934%2F08%2F11%2Fthumbs%2F198-Alcatraz_Island_photo_Don_Ramey_Logan_600x600.jpeg?alt=media&token=bb12c728-87ed-40e6-aa74-afffd547b780",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1934%2F08%2F11%2F198-Alcatraz_Island_photo_Don_Ramey_Logan.jpeg?alt=media&token=3b7c26fc-8134-43e9-9208-5dc2253415c7",
      imgsource:
        "https://en.wikipedia.org/wiki/Alcatraz_Federal_Penitentiary#/media/File:Alcatraz_Island_photo_Don_Ramey_Logan.jpg",
      link: "https://en.wikipedia.org/wiki/Alcatraz_Federal_Penitentiary",
    },
    {
      id: 199,
      date: "23 May 1934",
      time: 0,
      type: 0,
      title: "Bonnie and Clyde",
      description:
        "The notorious American criminals Bonnie Parker and Clyde Barrow were killed in a police ambush in Bienville Parish, Louisiana. 📷 Bonnie Parker and Clyde Barrow, sometime between 1932 and 1934, when their exploits in Arkansas included murder, robbery, and kidnapping. ",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1934%2F05%2F23%2Fthumbs%2F199-Bonnieclyde_f_600x600.jpeg?alt=media&token=17e018e9-dd08-46a3-b5e0-c2c9be0ef463",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1934%2F05%2F23%2F199-Bonnieclyde_f.jpeg?alt=media&token=d1fe478a-49a3-406a-afcb-96a300f5915e",
      imgsource:
        "https://en.wikipedia.org/wiki/Bonnie_and_Clyde#/media/File:Bonnieclyde_f.jpg",
      link: "",
    },
    {
      id: 200,
      date: "26 Dec 1933",
      time: 0,
      type: 0,
      title: "Montevideo Convention",
      description:
        "The Montevideo Convention on the Rights and Duties of States was signed, defining the criteria for statehood. 📷 Parties to the Montevideo Convention   Parties   Signatories   Other Organization of American States members.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1933%2F12%2F26%2Fthumbs%2F200-Montevideo_Convention_parties.svg_600x600.jpeg?alt=media&token=c06464a4-f66b-4307-ba12-05033ac7abea",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1933%2F12%2F26%2F200-Montevideo_Convention_parties.svg.png?alt=media&token=ad760fa8-40fb-409d-8c96-9e345e0ec448",
      imgsource:
        "https://en.wikipedia.org/wiki/Montevideo_Convention#/media/File:Montevideo_Convention_parties.svg",
      link: "https://en.wikipedia.org/wiki/Montevideo_Convention",
    },
    {
      id: 201,
      date: "27 Feb 1933",
      time: 0,
      type: 0,
      title: "Reichstag Fire",
      description:
        "The Reichstag building in Berlin, Germany, was set on fire, which the Nazis used as a pretext to suppress political opposition and consolidate power. 📷 Firefighters struggle to extinguish the fire.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1933%2F02%2F27%2Fthumbs%2F201-Reichstagsbrand_600x600.jpeg?alt=media&token=a4adeed5-81bc-4d28-86ed-d31f4aa2b725",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1933%2F02%2F27%2F201-Reichstagsbrand.jpeg?alt=media&token=285e45ef-e949-49a6-85ea-8356d66138cd",
      imgsource:
        "https://en.wikipedia.org/wiki/Reichstag_fire#/media/File:Reichstagsbrand.jpg",
      link: "https://en.wikipedia.org/wiki/Reichstag_fire",
    },
    {
      id: 202,
      date: "4 Mar 1933",
      time: 0,
      type: 0,
      title: "First inauguration of Franklin D. Roosevelt",
      description:
        "Franklin D. Roosevelt was inaugurated as the 32nd President of the United States, delivering his famous 'The only thing we have to fear is fear itself' speech.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1933%2F03%2F04%2Fthumbs%2F202-Flickr_-_USCapitol_-_Franklin_D._Roosevelt's_First_Inauguration_600x600.jpeg?alt=media&token=a3761182-026d-48ec-ba69-8459320a7600",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1933%2F03%2F04%2F202-Flickr_-_USCapitol_-_Franklin_D._Roosevelt's_First_Inauguration.jpg?alt=media&token=95f6aeec-4857-4d25-982a-aba283af2e88",
      imgsource:
        "https://en.wikipedia.org/wiki/First_inauguration_of_Franklin_D._Roosevelt#/media/File:Flickr_-_USCapitol_-_Franklin_D._Roosevelt's_First_Inauguration.jpg",
      link: "https://en.wikipedia.org/wiki/First_inauguration_of_Franklin_D._Roosevelt",
    },
    {
      id: 203,
      date: "8 Jul 1932",
      time: 0,
      type: 0,
      title: "Bonus Army March",
      description:
        "Thousands of World War I veterans, known as the Bonus Army, marched on Washington, D.C., to demand early payment of their war bonuses. 📷 Bonus Army marchers (left) clash with the police.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1932%2F07%2F08%2Fthumbs%2F203-1920px-Bonus_marchers_05510_2004_001_a_600x600.jpeg?alt=media&token=d9682308-bb59-458b-9d35-37772645c87e",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1932%2F07%2F08%2F203-1920px-Bonus_marchers_05510_2004_001_a.gif?alt=media&token=fd794b0b-fb8c-4248-8149-f3f305709312",
      imgsource:
        "https://en.wikipedia.org/wiki/Bonus_Army#/media/File:Bonus_marchers_05510_2004_001_a.gif",
      link: "https://en.wikipedia.org/wiki/Bonus_Army",
    },
    {
      id: 204,
      date: "1 Mar 1932",
      time: 0,
      type: 0,
      title: "Lindbergh Baby Kidnapping",
      description:
        "The infant son of famous aviator Charles Lindbergh, Charles Lindbergh Jr., was kidnapped from his home in New Jersey, leading to a high-profile investigation. 📷 Lindbergh baby poster",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1932%2F03%2F01%2Fthumbs%2F204-Lindbergh_baby_poster_600x600.jpeg?alt=media&token=5177ceb6-e4d1-4ab5-bdd7-9d7b1fd121bb",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1932%2F03%2F01%2F204-Lindbergh_baby_poster.jpeg?alt=media&token=0aabc520-0e56-4f38-bd90-9b3e8c7977d0",
      imgsource:
        "https://en.wikipedia.org/wiki/Lindbergh_kidnapping#/media/File:Lindbergh_baby_poster.jpg",
      link: "https://en.wikipedia.org/wiki/Lindbergh_kidnapping",
    },
    {
      id: 205,
      date: "30 Jul 1932",
      time: 0,
      type: 0,
      title: "Olympic Games in Los Angeles",
      description:
        "The Summer Olympics were held in Los Angeles, California, with athletes from around the world participating. 📷 Emblem of the 1932 Summer Olympics",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1932%2F07%2F30%2Fthumbs%2F205-1932-Olympics-Los-Angeles_600x600.jpeg?alt=media&token=d6ee3ca3-ec18-42d8-b91c-78c255a58799",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1932%2F07%2F30%2F205-1932-Olympics-Los-Angeles.png?alt=media&token=827c42dd-5699-493d-ae1c-aa3e5e51b6e5",
      imgsource:
        "https://en.wikipedia.org/wiki/1932_Summer_Olympics#/media/File:1932_Summer_Olympics_logo.svg",
      link: "https://en.wikipedia.org/wiki/1932_Summer_Olympics",
    },
    {
      id: 206,
      date: "1 May 1931",
      time: 0,
      type: 0,
      title: "The Empire State Building",
      description:
        "The Empire State Building in New York City was completed and became the tallest building in the world. 📷 Aerial view in 2012",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1931%2F05%2F01%2Fthumbs%2F206-Empire_State_Building_(aerial_view)_600x600.jpeg?alt=media&token=17bf24c8-3123-4df1-abc1-0a009cf910c2",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1931%2F05%2F01%2F206-Empire_State_Building_(aerial_view).jpeg?alt=media&token=dc7e2578-e80c-489e-84e9-887f965edc8f",
      imgsource:
        "https://en.wikipedia.org/wiki/Empire_State_Building#/media/File:Empire_State_Building_(aerial_view).jpg",
      link: "https://www.esbnyc.com/",
    },
    {
      id: 207,
      date: "18 Sep 1931",
      time: 0,
      type: 0,
      title: "Japan Invades Manchuria",
      description:
        "Japanese forces invaded Manchuria, marking the beginning of Japanese expansion in East Asia and leading to condemnation by the international community. 📷 Japanese troops marching into Mukden on September 18, 1931",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1931%2F09%2F18%2Fthumbs%2F207-Japanese_troops_entering_Tsitsihar_600x600.jpeg?alt=media&token=c6492d0c-e05c-4d01-a002-04be0a69dec8",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1931%2F09%2F18%2F207-Japanese_troops_entering_Tsitsihar.jpeg?alt=media&token=72bedbf8-b3a5-4204-9e68-cc3df745d83a",
      imgsource:
        "https://en.wikipedia.org/wiki/Japanese_invasion_of_Manchuria#/media/File:Japanese_troops_entering_Tsitsihar.jpg",
      link: "https://en.wikipedia.org/wiki/Japanese_invasion_of_Manchuria",
    },
    {
      id: 208,
      date: "18 Oct 1931",
      time: 0,
      type: 0,
      title: "Al Capone Conviction",
      description:
        "American gangster Al Capone was convicted of tax evasion and sentenced to prison. 📷 Screenshot from FBI official website. ",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1931%2F10%2F18%2Fthumbs%2F208-Al-Capone-Screenshot_600x600.jpeg?alt=media&token=d6388efa-e7e1-40ca-9e29-b3d518a418e8",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1931%2F10%2F18%2F208-Al-Capone-Screenshot.png?alt=media&token=4c6ddbb8-73f9-4898-b8ae-f368b2573169",
      imgsource:
        "https://www.fbi.gov/image-repository/capone-mug-shot-1939.jpg/@@images/image/high",
      link: "https://www.fbi.gov/history/famous-cases/al-capone",
    },
    {
      id: 209,
      date: "13 Jul 1930",
      time: 0,
      type: 0,
      title: "First FIFA World Cup",
      description:
        "The inaugural FIFA World Cup soccer tournament was held in Uruguay, with the host nation winning the championship.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1930%2F07%2F13%2Fthumbs%2F209-Uruguay_1930_World_Cup_600x600.jpeg?alt=media&token=8230efaa-50f1-4b30-96e5-f2ca4781f800",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1930%2F07%2F13%2F209-Uruguay_1930_World_Cup.jpeg?alt=media&token=66b25aa2-d5d0-4282-a36e-8df2dd73b2fa",
      imgsource:
        "https://en.wikipedia.org/wiki/1930_FIFA_World_Cup#/media/File:Uruguay_1930_World_Cup.jpg",
      link: "https://en.wikipedia.org/wiki/1930_FIFA_World_Cup",
    },
    {
      id: 210,
      date: "27 May 1930",
      time: 0,
      type: 0,
      title: "The Chrysler Building",
      description:
        "Construction of the Chrysler Building in New York City was completed, making it one of the iconic skyscrapers of the era.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1930%2F05%2F27%2Fthumbs%2F210-building-1-scaled_600x600.jpeg?alt=media&token=f54cd3bb-dcb1-4fcf-8a3d-c703ba4df84c",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1930%2F05%2F27%2F210-building-1-scaled.jpeg?alt=media&token=112041b8-ad08-452f-925d-f0432b53fab5",
      imgsource:
        "https://chryslerbuilding.com/wp-content/uploads/2020/05/building-1-scaled.jpg",
      link: "https://chryslerbuilding.com/history/",
    },
    {
      id: 211,
      date: "18 Feb 1930",
      time: 0,
      type: 0,
      title: "Pluto Discovered",
      description:
        "Pluto, formerly considered the ninth planet in the solar system, was discovered by American astronomer Clyde Tombaugh. 📷 Northern hemisphere of Pluto in true color, taken by NASA's New Horizons probe in 2015[a]",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1930%2F02%2F18%2Fthumbs%2F211-Pluto_in_True_Color_-_High-Res_600x600.jpeg?alt=media&token=3718a7a9-18a8-41f6-a089-9a7b5c536da3",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1930%2F02%2F18%2F211-Pluto_in_True_Color_-_High-Res.jpeg?alt=media&token=3fac9479-03df-4c83-a6ba-48183612e500",
      imgsource:
        "https://en.wikipedia.org/wiki/Pluto#/media/File:Pluto_in_True_Color_-_High-Res.jpg",
      link: "https://solarsystem.nasa.gov/planets/dwarf-planets/pluto/in-depth/",
    },
    {
      id: 212,
      date: "16 May 1929",
      time: 0,
      type: 0,
      title: "First Academy Awards",
      description:
        "The first Academy Awards, also known as the Oscars, were held in Hollywood, honoring outstanding achievements in the film industry. 📷 The first Academy Awards ceremony (pictured) was held at the Hollywood Roosevelt Hotel",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1929%2F05%2F16%2Fthumbs%2F212-1stOscars_1929_600x600.jpeg?alt=media&token=69e5cdbf-2e57-4440-bc88-6672ac3e1aef",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1929%2F05%2F16%2F212-1stOscars_1929.jpeg?alt=media&token=7d1de930-5003-425b-b732-f0284d767d2e",
      imgsource:
        "https://en.wikipedia.org/wiki/1st_Academy_Awards#/media/File:1stOscars_1929.jpg",
      link: "https://en.wikipedia.org/wiki/1st_Academy_Awards",
    },
    {
      id: 213,
      date: "29 Oct 1929",
      time: 0,
      type: 0,
      title: "Wall Street Crash",
      description:
        "On October 29, 1929, known as 'Black Tuesday,' the U.S. stock market crashed, leading to the beginning of the Great Depression, a global economic crisis characterized by high unemployment, poverty, and financial hardships. 📷 Crowd gathering on Wall Street after the 1929 crash",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1929%2F10%2F29%2Fthumbs%2F213-Crowd_outside_nyse_600x600.jpeg?alt=media&token=5b32be5f-ccf3-4900-9105-4f74bcbba4b4",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1929%2F10%2F29%2F213-Crowd_outside_nyse.jpeg?alt=media&token=6837e34b-23fe-41c7-abba-a30d7550c88b",
      imgsource:
        "https://en.wikipedia.org/wiki/Wall_Street_Crash_of_1929#/media/File:Crowd_outside_nyse.jpg",
      link: "https://en.wikipedia.org/wiki/Wall_Street_Crash_of_1929",
    },
    {
      id: 214,
      date: "14 Feb 1929",
      time: 0,
      type: 0,
      title: "St. Valentine's Day Massacre",
      description:
        "In Chicago, seven members of Chicago's North Side Gang were killed in a gang-related massacre led by Al Capone's South Side Gang. 📷 The seven men slain during the Saint Valentine's Day Massacre",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1929%2F02%2F14%2Fthumbs%2F214-SaintValentine'sDayMassacre_600x600.jpeg?alt=media&token=1060ec9a-6dfc-48a0-a3f9-9da5784c5eac",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1929%2F02%2F14%2F214-SaintValentine'sDayMassacre.jpeg?alt=media&token=fb134362-61de-4fe2-8972-25219c484eab",
      imgsource:
        "https://en.wikipedia.org/wiki/Saint_Valentine's_Day_Massacre#/media/File:SaintValentine'sDayMassacre.jpg",
      link: "https://en.wikipedia.org/wiki/Saint_Valentine's_Day_Massacre",
    },
    {
      id: 215,
      date: "18 Nov 1928",
      time: 0,
      type: 0,
      title: "Mickey Mouse Debut",
      description:
        "Mickey Mouse made his first public appearance in the cartoon 'Steamboat Willie,' becoming an iconic character. 📷 This is a poster for Steamboat Willie. The poster art copyright is believed to belong to the distributor of the film, Celebrity Productions, the publisher, The Walt Disney Company, or the graphic artist.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1928%2F11%2F18%2Fthumbs%2F215-Steamboat_Willie_600x600.jpeg?alt=media&token=30f96412-01ab-48c8-8065-e310fffd633f",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1928%2F11%2F18%2F215-Steamboat_Willie.jpeg?alt=media&token=6c7c18ca-1b11-4da7-8b06-ad47eea6dd37",
      imgsource:
        "https://en.wikipedia.org/wiki/Steamboat_Willie#/media/File:Steamboat_Willie.jpg",
      link: "https://en.wikipedia.org/wiki/Mickey_Mouse",
    },
    {
      id: 216,
      date: "9 Aug 1930",
      time: 0,
      type: 0,
      title: "Betty Boop Released",
      description:
        "Betty Boop, a cartoon character known for her distinctive appearance and animated shorts, was created by Max Fleischer and made her first appearance in the cartoon 'Dizzy Dishes,' which was released on August 9, 1930. Betty Boop quickly became a popular and iconic character in the world of animation during the early 1930s.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1930%2F08%2F09%2Fthumbs%2F216-Betty_Boop_colored_patent_600x600.jpeg?alt=media&token=c3b49c73-f8de-4b1f-88eb-df21818c7ab4",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1930%2F08%2F09%2F216-Betty_Boop_colored_patent.png?alt=media&token=9093b277-a057-44eb-afe0-47afae601a8a",
      imgsource:
        "https://en.wikipedia.org/wiki/Betty_Boop#/media/File:Betty_Boop_colored_patent.png",
      link: "https://en.wikipedia.org/wiki/Betty_Boop",
    },
    {
      id: 217,
      date: "28 Sep 1928",
      time: 0,
      type: 0,
      title: "Alexander Fleming's Discovery",
      description:
        "Scottish biologist Alexander Fleming discovered penicillin, the first widely used antibiotic, revolutionizing medicine. 📷 Sample of penicillin mould presented by Alexander Fleming to Douglas Macleod in 1935",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1928%2F09%2F28%2Fthumbs%2F217-Sample_of_penicillin_mould_presented_by_Alexander_Fleming_to_Douglas_Macleod%2C_1935_(9672239344)_600x600.jpeg?alt=media&token=a1e4bc77-286e-4f2e-8c22-581dfe2d59d2",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1928%2F09%2F28%2F217-Sample_of_penicillin_mould_presented_by_Alexander_Fleming_to_Douglas_Macleod%2C_1935_(9672239344).jpeg?alt=media&token=9c8fc686-2277-4188-a86c-6e016651bd5e",
      imgsource:
        "https://en.wikipedia.org/wiki/Discovery_of_penicillin#/media/File:Sample_of_penicillin_mould_presented_by_Alexander_Fleming_to_Douglas_Macleod,_1935_(9672239344).jpg",
      link: "https://en.wikipedia.org/wiki/Discovery_of_penicillin",
    },
    {
      id: 218,
      date: "28 Jul 1928",
      time: 0,
      type: 0,
      title: "Olympic Summer Games 1928",
      description:
        "The 1928 Summer Olympics were held in Amsterdam, Netherlands, featuring the participation of 46 nations. 📷 Poster for the 1928 Summer Olympics",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1928%2F07%2F28%2Fthumbs%2F218-1928_Olympics_poster_600x600.jpeg?alt=media&token=1434e754-13c4-4538-8037-2d3ad445d897",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1928%2F07%2F28%2F218-1928_Olympics_poster.jpeg?alt=media&token=17b5ec31-8607-49f6-bbd7-09cf9b9068c7",
      imgsource:
        "https://en.wikipedia.org/wiki/1928_Summer_Olympics#/media/File:1928_Olympics_poster.jpg",
      link: "https://en.wikipedia.org/wiki/1928_Summer_Olympics",
    },
    {
      id: 219,
      date: "7 Jan 1927",
      time: 0,
      type: 0,
      title: "First Commercial Transatlantic Telephone Call",
      description:
        "The first commercial transatlantic telephone call was made between New York and London. 📷 James M. Terrill on a telephone at a desk, Grubbs Vocational College, approximately 1910s",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1927%2F01%2F07%2Fthumbs%2F219-10010454_600x600.jpeg?alt=media&token=c3b7b702-61de-46dd-b542-7c96de47d05b",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1927%2F01%2F07%2F219-10010454.jpeg?alt=media&token=e8e5248a-b30b-43b8-bc8e-26570709b632",
      imgsource:
        "https://libraries.uta.edu/sites/default/files/2021-01/10010454.jpg",
      link: "https://libraries.uta.edu/news-events/blog/today-history-first-transatlantic-phone-call",
    },
    {
      id: 220,
      date: "1 Jan 1927",
      time: 0,
      type: 0,
      title: "Great Mississippi Flood",
      description:
        "A catastrophic flood along the Mississippi River and its tributaries displaced hundreds of thousands of people and caused widespread devastation. 📷 Yazoo & Mississippi Valley Railroad Station, Egremont, Sharkey County, April 2, 1927 (Mississippi Department of Archives and History, Archives and Records Services Division [# 000078054])",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1927%2F01%2F01%2Fthumbs%2F220-Figure-88.-Flood-1927-Mississippi-River_600x600.jpeg?alt=media&token=29b7053e-4f77-4a77-84cc-94dffc7c892b",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1927%2F01%2F01%2F220-Figure-88.-Flood-1927-Mississippi-River.jpg?alt=media&token=f57a7c07-dbb5-49e8-9bfc-46ef81efd25c",
      imgsource: "",
      link: "https://mississippiencyclopedia.org/entries/mississippi-river-flood-1927/",
    },
    {
      id: 221,
      date: "20 Oct 1927",
      time: 0,
      type: 0,
      title: "Ford Model A",
      description:
        "The Ford Motor Company introduced the Ford Model A as the successor to the Model T, revolutionizing the automotive industry. 📷 A 1928 Model A Ford in front of Bowen Island Public Library, owned by Dan C. of Bowen Island.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1927%2F10%2F20%2Fthumbs%2F221-1928_Model_A_Ford_600x600.jpeg?alt=media&token=d646e7e2-8481-430a-9e5c-e3638e4f2769",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1927%2F10%2F20%2F221-1928_Model_A_Ford.jpeg?alt=media&token=44e001dd-b6d4-46ff-9404-5bb5ef6e40ea",
      imgsource:
        "https://en.wikipedia.org/wiki/Ford_Model_A_(1927%E2%80%931931)#/media/File:1928_Model_A_Ford.jpg",
      link: "https://en.wikipedia.org/wiki/Ford_Model_A_(1927%E2%80%931931)",
    },
    {
      id: 222,
      date: "20 May 1927",
      time: 0,
      type: 0,
      title: "Charles Lindbergh's Transatlantic Flight",
      description:
        "On May 20-21, 1927, Charles Lindbergh made the first solo nonstop transatlantic flight from New York to Paris in his aircraft, the Spirit of St. Louis. 📷 The Spirit of St. Louis carried Charles Lindbergh from New York to Paris in 33 and a half hours, the first nonstop flight across the Atlantic Ocean. (Image credit: Library of Congress)",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1927%2F05%2F20%2Fthumbs%2F222-Charles-Lindbergh-with-Spirit-of-St.Louis_600x600.jpeg?alt=media&token=04051b6f-2f6c-4fad-84a2-4b3e33fe9908",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1927%2F05%2F20%2F222-Charles-Lindbergh-with-Spirit-of-St.Louis.jpeg?alt=media&token=d7e17e5f-306a-44d4-b037-f5977c6578b4",
      imgsource:
        "https://cdn.mos.cms.futurecdn.net/sHmHGaNmsDbNZpCMdSQk2A-1920-80.jpg.webp",
      link: "https://www.space.com/16677-charles-lindbergh.html",
    },
    {
      id: 223,
      date: "6 Oct 1927",
      time: 0,
      type: 0,
      title: "The Jazz Singer",
      description:
        "The Jazz Singer' the first feature-length motion picture with synchronized dialogue sequences, marked the transition from silent films to 'talkies.' 📷 Poster for the movie The Jazz Singer (1927), featuring stars Eugenie Besserer and Al Jolson. Warner Bros. (original rights holder)",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1927%2F10%2F06%2Fthumbs%2F223-The_Jazz_Singer_1927_Poster_600x600.jpeg?alt=media&token=46c0a6f3-aaad-41a4-85a2-2a4c42af3446",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1927%2F10%2F06%2F223-The_Jazz_Singer_1927_Poster.jpeg?alt=media&token=e979a1ba-22f9-49a8-aad9-af0d4452b258",
      imgsource:
        "https://en.wikipedia.org/wiki/The_Jazz_Singer#/media/File:The_Jazz_Singer_1927_Poster.jpg",
      link: "https://en.wikipedia.org/wiki/The_Jazz_Singer",
    },
    {
      id: 224,
      date: "14 Oct 1926",
      time: 0,
      type: 0,
      title: "A.A. Milne's 'Winnie-the-Pooh'",
      description:
        "English author A.A. Milne published 'Winnie-the-Pooh,' introducing the beloved character and his friends to the world. 📷 Winnie-the-Pooh in an illustration by E. H. Shepard",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1926%2F10%2F14%2Fthumbs%2F224-Pooh_Shepard1928_600x600.jpeg?alt=media&token=14a91bfd-e2a4-4613-bcd3-8e297c943ecb",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1926%2F10%2F14%2F224-Pooh_Shepard1928.jpeg?alt=media&token=146be1eb-16d5-4e49-ba40-08e515cef71c",
      imgsource:
        "https://en.wikipedia.org/wiki/Winnie-the-Pooh_(book)#/media/File:Pooh_Shepard1928.jpg",
      link: "https://en.wikipedia.org/wiki/Winnie-the-Pooh_(book)",
    },
    {
      id: 225,
      date: "26 Nov 1926",
      time: 0,
      type: 0,
      title: "Route 66 Established",
      description:
        "The U.S. Highway 66, known as Route 66, was officially established as one of the country's first highways, running from Chicago to Los Angeles. 📷 Screenshot from WIkipedia of Final routing of U.S. Route 66 in red, earlier alignments in pink",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1926%2F11%2F26%2Fthumbs%2F225-US-Route-66_600x600.jpeg?alt=media&token=7a9c2f1d-1ce5-4acb-a0ed-578d2116548e",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1926%2F11%2F26%2F225-US-Route-66.png?alt=media&token=ea4c9ea5-3800-4fa4-976e-3ee446936afc",
      imgsource: "https://en.wikipedia.org/wiki/U.S._Route_66",
      link: "https://en.wikipedia.org/wiki/U.S._Route_66",
    },
    {
      id: 226,
      date: "4 May 1926",
      time: 0,
      type: 0,
      title: "General Strike in the UK",
      description:
        "The United Kingdom experienced a general strike, with millions of workers participating in labor protests. 📷 Tyldesley miners outside the Miners' Hall during the strike",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1926%2F05%2F04%2Fthumbs%2F226-Tyldesley_miners_outside_the_Miners_Hall_during_the_1926_General_Strike_600x600.jpeg?alt=media&token=33eabd6c-5918-4c1b-b4ca-17ca8029dc70",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1926%2F05%2F04%2F226-Tyldesley_miners_outside_the_Miners_Hall_during_the_1926_General_Strike.jpeg?alt=media&token=682adc21-e779-4831-9164-3dbd28dc44e7",
      imgsource:
        "https://en.wikipedia.org/wiki/1926_United_Kingdom_general_strike#/media/File:Tyldesley_miners_outside_the_Miners_Hall_during_the_1926_General_Strike.jpg",
      link: "https://en.wikipedia.org/wiki/1926_United_Kingdom_general_strike",
    },
    {
      id: 227,
      date: "6 Jun 1925",
      time: 0,
      type: 0,
      title: "Chrysler Corporation Founded",
      description:
        "Walter Chrysler founded the Chrysler Corporation, a major American automobile manufacturer. 📷 1955 Imperial car model, in its first year as a separate make, apart from Chrysler, shown on display at January 1955 Chicago Auto Show",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1925%2F06%2F06%2Fthumbs%2F227-Chrysler_Imperial_car-1955_600x600.jpeg?alt=media&token=07802a04-d7ad-48de-a5cb-f2d8689b5b52",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1925%2F06%2F06%2F227-Chrysler_Imperial_car-1955.jpeg?alt=media&token=67088e4e-5835-4896-800d-0bbe2b4aa373",
      imgsource:
        "https://en.wikipedia.org/wiki/Chrysler#/media/File:Chrysler_Imperial_car-1955.JPG",
      link: "https://en.wikipedia.org/wiki/Chrysler",
    },
    {
      id: 228,
      date: "10 Apr 1925",
      time: 0,
      type: 0,
      title: "The Great Gatsby",
      description:
        "F. Scott Fitzgerald's novel 'The Great Gatsby' was published, exploring the excesses and disillusionment of the Roaring Twenties. 📷 The front dust jacket art of the first edition",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1925%2F04%2F10%2Fthumbs%2F228-The_Great_Gatsby_Cover_1925_Retouched_600x600.jpeg?alt=media&token=76474e4f-e5be-4864-bdb4-466b97b30d4a",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1925%2F04%2F10%2F228-The_Great_Gatsby_Cover_1925_Retouched.jpeg?alt=media&token=ccda903a-0cb3-49c4-8c90-9b9c1cee62f3",
      imgsource:
        "https://en.wikipedia.org/wiki/The_Great_Gatsby#/media/File:The_Great_Gatsby_Cover_1925_Retouched.jpg",
      link: "https://en.wikipedia.org/wiki/The_Great_Gatsby#/media/File:The_Great_Gatsby_Cover_1925_Retouched.jpg",
    },
    {
      id: 229,
      date: "12 Dec 1925",
      time: 0,
      type: 0,
      title: "First Motel",
      description:
        "The first motel, the 'Motel Inn,' opened in San Luis Obispo, California, marking a new era in lodging for travelers. 📷 Photographed in 1976",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1925%2F12%2F12%2Fthumbs%2F229-Motel_Inn%2C_San_Luis_Obispo%2C_California_LCCN2017709980.tif_600x600.jpeg?alt=media&token=609e5f17-2385-43c8-ad5b-10a6393d5494",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1925%2F12%2F12%2F229-Motel_Inn%2C_San_Luis_Obispo%2C_California_LCCN2017709980.tif.jpg?alt=media&token=ba899547-8b05-4a22-86c5-e8e1da217745",
      imgsource:
        "https://en.wikipedia.org/wiki/Motel_Inn#/media/File:Motel_Inn,_San_Luis_Obispo,_California_LCCN2017709980.tif",
      link: "https://en.wikipedia.org/wiki/Motel_Inn",
    },
    {
      id: 230,
      date: "10 May 1924",
      time: 0,
      type: 0,
      title: "J. Edgar Hoover Appointed",
      description:
        "J. Edgar Hoover was appointed as the first Director of the Federal Bureau of Investigation (FBI).",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1924%2F05%2F10%2Fthumbs%2F230-6b547eb1-9824-4efd-9491-c924d7b11b0d_600x600.jpeg?alt=media&token=43d1c270-779f-4d4c-b9ab-686362a2986a",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1924%2F05%2F10%2F230-6b547eb1-9824-4efd-9491-c924d7b11b0d.jpeg?alt=media&token=ddff1f39-34b0-4bff-a99b-56c41d7daf02",
      imgsource: "https://www.fbi.gov/image-repository/young-hoover.jpeg/view",
      link: "https://www.fbi.gov/history/directors/j-edgar-hoover",
    },
    {
      id: 231,
      date: "27 Nov 1924",
      time: 0,
      type: 0,
      title: "First Macy's Thanksgiving Day Parade",
      description:
        "The first Macy's Thanksgiving Day Parade took place in New York City. 📷 Macy's Thanksgiving Day parade in 1924. (RV1864/Flickr)",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1924%2F11%2F27%2Fthumbs%2F231-c1e8c49bb1ec5aa1ccde32b15823d351_600x600.jpeg?alt=media&token=bb581dec-0824-4f4a-9717-bfd46605af7c",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1924%2F11%2F27%2F231-c1e8c49bb1ec5aa1ccde32b15823d351.jpeg?alt=media&token=af8685bd-6fb4-4e94-9ef8-cb07c799c407",
      imgsource:
        "https://historydaily.org/content/453047/c1e8c49bb1ec5aa1ccde32b15823d351.jpg",
      link: "https://historydaily.org/first-macys-thanksgiving-day-parade",
    },
    {
      id: 232,
      date: "25 Jan 1924",
      time: 0,
      type: 0,
      title: "First Winter Olympics 1924",
      description:
        "The first Winter Olympics were held in Chamonix, France, featuring five sports. 📷 Poster for the 1924 Winter Olympic Games",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1924%2F01%2F25%2Fthumbs%2F233-1924WOlympicPoster_600x600.jpeg?alt=media&token=376edc1a-cc3e-4bfe-8b67-f5a4d754550e",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1924%2F01%2F25%2F233-1924WOlympicPoster.jpeg?alt=media&token=a656a4a9-b861-4478-a648-2cfa9790d856",
      imgsource:
        "https://en.wikipedia.org/wiki/1924_Winter_Olympics#/media/File:1924WOlympicPoster.jpg",
      link: "https://olympics.com/en/olympic-games/chamonix-1924",
    },
    {
      id: 233,
      date: "16 Oct 1923",
      time: 0,
      type: 0,
      title: "Disney Brothers Studio",
      description:
        "Walt Disney and Roy O. Disney founded the Disney Brothers Studio, which would later become The Walt Disney Company. 📷 Logo used since 2007",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1923%2F10%2F16%2Fthumbs%2F233-Walt-Disney-Animation-Studios_600x600.jpeg?alt=media&token=41871c90-9f7e-4b92-8bd5-a567d98213e8",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1923%2F10%2F16%2F233-Walt-Disney-Animation-Studios.png?alt=media&token=a24d5870-3a51-4d0a-9c37-0dcfb50c083c",
      imgsource:
        "https://en.wikipedia.org/wiki/Walt_Disney_Animation_Studios#/media/File:Walt_Disney_Animation_Studios_logo.svg",
      link: "https://disneyanimation.com/",
    },
    {
      id: 234,
      date: "2 Aug 1923",
      time: 0,
      type: 0,
      title: "Calvin Coolidge Becomes U.S. President",
      description:
        "Calvin Coolidge was sworn in as the 30th President of the United States following the death of Warren G. Harding.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1923%2F08%2F02%2Fthumbs%2F234-Calvin_Coolidge_cph.3g10777_(cropped)_600x600.jpeg?alt=media&token=920ee8ae-0ceb-457a-9dfd-aaa65ae6ab81",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1923%2F08%2F02%2F234-Calvin_Coolidge_cph.3g10777_(cropped).jpg?alt=media&token=72ab3be4-6251-40db-a04d-7a818de51251",
      imgsource:
        "https://en.wikipedia.org/wiki/Calvin_Coolidge#/media/File:Calvin_Coolidge_cph.3g10777_(cropped).jpg",
      link: "https://en.wikipedia.org/wiki/Presidency_of_Calvin_Coolidge",
    },
    {
      id: 235,
      date: "3 Mar 1923",
      time: 0,
      type: 0,
      title: "Time Magazine Debut",
      description:
        "Time magazine published its first issue, becoming one of the most influential news magazines in the United States.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1923%2F03%2F03%2Fthumbs%2F235-TIME-Magazine-1stCover_600x600.jpeg?alt=media&token=1167dee0-7735-4484-9961-a297134ec1ca",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1923%2F03%2F03%2F235-TIME-Magazine-1stCover.jpeg?alt=media&token=302f728d-d877-4958-bc27-85a6093e2a5e",
      imgsource:
        "https://api.time.com/wp-content/uploads/2015/02/1101230303_400.jpg",
      link: "https://time.com/3721195/first-ever-issue-vault/",
    },
    {
      id: 236,
      date: "1 Sep 1923",
      time: 0,
      type: 0,
      title: "The Great Kanto Earthquake",
      description:
        "On September 1, 1923, the Great Kanto Earthquake struck Japan, causing widespread destruction in Tokyo and Yokohama and resulting in the loss of tens of thousands of lives. 📷 Ryounkaku buildung; collapsed at the Great Kanto Earthquake. Ryounkaku（Asakusa Jūnikai）was Japan's first western style skyscraper that stood in the Asakusa district of Tokyo from 1890 until 1923.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1923%2F09%2F01%2Fthumbs%2F236-Ryounkaku_600x600.jpeg?alt=media&token=6880a921-6614-4bd2-aad7-5fcb14e93f4a",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1923%2F09%2F01%2F236-Ryounkaku.jpeg?alt=media&token=04105c1a-d071-42db-8219-8ba9d166a915",
      imgsource:
        "https://en.wikipedia.org/wiki/1923_Great_Kant%C5%8D_earthquake#/media/File:Ryounkaku.jpg",
      link: "https://en.wikipedia.org/wiki/1923_Great_Kant%C5%8D_earthquake",
    },
    {
      id: 237,
      date: "27 Oct 1922",
      time: 0,
      type: 0,
      title: "Benito Mussolini's March on Rome",
      description:
        "Benito Mussolini and his Fascist Party marched on Rome, leading to his appointment as Prime Minister of Italy. 📷 Benito Mussolini and his Blackshirts during the March",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1922%2F10%2F27%2Fthumbs%2F237-Naples_Fascist_rally_on_24_October_1922_(2)_600x600.jpeg?alt=media&token=0f8dc249-c6eb-4aba-8589-09217aacca32",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1922%2F10%2F27%2F237-Naples_Fascist_rally_on_24_October_1922_(2).jpeg?alt=media&token=4c5b6cf5-c8b3-438b-b9b1-2c317ab93908",
      imgsource:
        "https://en.wikipedia.org/wiki/March_on_Rome#/media/File:Naples_Fascist_rally_on_24_October_1922_(2).jpg",
      link: "https://en.wikipedia.org/wiki/March_on_Rome",
    },
    {
      id: 238,
      date: "11 Jun 1922",
      time: 0,
      type: 0,
      title: "Nanook of the North",
      description:
        "Nanook of the North is a landmark silent documentary film directed by Robert J. Flaherty. Released in 1922, it is considered one of the earliest examples of documentary filmmaking and is known for its portrayal of the Inuit people of the Canadian Arctic.📷 Promotional poster for the 1922 documentary Nanook of the North.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1922%2F06%2F11%2Fthumbs%2F238-Nanook_of_the_north_600x600.jpeg?alt=media&token=b5c350b5-491b-4d3d-a582-84bc1d0d00d1",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1922%2F06%2F11%2F238-Nanook_of_the_north.jpeg?alt=media&token=a9713312-1494-4b30-8781-a646af7364de",
      imgsource:
        "https://en.wikipedia.org/wiki/Nanook_of_the_North#/media/File:Nanook_of_the_north.jpg",
      link: "https://en.wikipedia.org/wiki/Nanook_of_the_North",
    },
    {
      id: 239,
      date: "4 Nov 1922",
      time: 0,
      type: 0,
      title: "Discovery of King Tut's Tomb",
      description:
        "British archaeologist Howard Carter discovered the tomb of the Egyptian pharaoh Tutankhamun (King Tut), revealing a treasure trove of artifacts. 📷 Inside Pharaoh Tutankhamun's tomb, 18th dynasty",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1922%2F11%2F04%2Fthumbs%2F239-1920px-Inside_Pharaoh_Tutankhamun's_tomb%2C_18th_dynasty_600x600.jpeg?alt=media&token=413c4c82-d9eb-4a99-93d5-9ca49d432150",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1922%2F11%2F04%2F239-1920px-Inside_Pharaoh_Tutankhamun's_tomb%2C_18th_dynasty.jpeg?alt=media&token=29cca3e7-2056-4bb0-bc50-dff3ce6ba12a",
      imgsource:
        "https://en.wikipedia.org/wiki/Tomb_of_Tutankhamun#/media/File:Inside_Pharaoh_Tutankhamun's_tomb,_18th_dynasty.jpg",
      link: "https://education.nationalgeographic.org/resource/king-tuts-tomb-discovered/",
    },
    {
      id: 240,
      date: "13 Sep 1922",
      time: 0,
      type: 0,
      title: "The Great Fire of Smyrna",
      description:
        "A devastating fire in Smyrna (now İzmir, Turkey) resulted in the destruction of much of the city and the displacement of thousands of Greek and Armenian residents. 📷 Great Fire of Smyrna as seen from an Italian ship. Original photo 13.5 x 8.5 cm.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1922%2F09%2F13%2Fthumbs%2F240-Great_Fire_of_Smyrna_600x600.jpeg?alt=media&token=1f2e6a54-42ba-411b-a501-fa7d22286f59",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1922%2F09%2F13%2F240-Great_Fire_of_Smyrna.jpeg?alt=media&token=cdbc9439-5f3e-427e-9658-017875eae34c",
      imgsource:
        "https://en.wikipedia.org/wiki/Burning_of_Smyrna#/media/File:Great_Fire_of_Smyrna.jpg",
      link: "https://en.wikipedia.org/wiki/Burning_of_Smyrna",
    },
    {
      id: 241,
      date: "11 Jan 1922",
      time: 0,
      type: 0,
      title: "First Injeciton of Insulin",
      description:
        "Insulin was discovered by Frederick Banting and Charles Best in 1921. The first recipient was a 14-year-old boy named Leonard Thompson, who received the insulin on January 11, 1922. 📷 Insulin consists of two peptide chains, linked with two disulphide bridges shown in yellow (the third disulfide bridge has no role in the linkage)",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1922%2F01%2F11%2Fthumbs%2F241-Insulin_struct_600x600.jpeg?alt=media&token=2bcdd188-4a77-42e3-8ae5-d069ccf72fd6",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1922%2F01%2F11%2F241-Insulin_struct.png?alt=media&token=6a02ea80-9746-4c0d-bf00-5c92bdb90558",
      imgsource:
        "https://en.wikipedia.org/wiki/Insulin#/media/File:Insulin_struct.png",
      link: "https://diabetes.org/blog/history-wonderful-thing-we-call-insulin",
    },
    {
      id: 242,
      date: "18 Oct 1922",
      time: 0,
      type: 0,
      title: "British Broadcasting Company (BBC)",
      description:
        "The British Broadcasting Company (BBC) was founded, becoming one of the world's leading broadcasters. 📷 The logo of the BBC in use from 20 October 2021. On white background",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1922%2F10%2F18%2Fthumbs%2F242-BBC_600x600.jpeg?alt=media&token=91eeb5f3-ac3e-40bb-9fb1-d87d9df4c111",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1922%2F10%2F18%2F242-BBC.png?alt=media&token=d3acbc4b-f843-418d-8749-131501c3c666",
      imgsource:
        "https://en.wikipedia.org/wiki/BBC#/media/File:BBC_Logo_2021.svg",
      link: "https://www.bbc.com/",
    },
    {
      id: 243,
      date: "23 Jul 1921",
      time: 0,
      type: 0,
      title: "Founding of the Communist Party of China",
      description:
        "The Chinese Communist Party (CCP) was officially founded in Shanghai, marking a significant political development in China's history. 📷 Screenshot from Wikipedia",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1921%2F07%2F21%2Fthumbs%2F243-CCP_600x600.jpeg?alt=media&token=b14c623a-01b5-4833-9000-19198bbac58c",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1921%2F07%2F21%2F243-CCP.png?alt=media&token=1841ada8-b109-460c-9353-9e3e5dc96851",
      imgsource:
        "https://en.wikipedia.org/wiki/Chinese_Communist_Party#/media/File:Danghui.svg",
      link: "https://en.wikipedia.org/wiki/Chinese_Communist_Party",
    },
    {
      id: 244,
      date: "4 Mar 1921",
      time: 0,
      type: 0,
      title: "Warren G. Harding's Inauguration",
      description:
        "Warren G. Harding was inaugurated as the 29th President of the United States, marking the beginning of the Roaring Twenties era.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1921%2F03%2F04%2Fthumbs%2F244-Inauguration%20in%201921_600x600.jpeg?alt=media&token=55d5fe2f-7007-4c6f-9440-13dab50af403",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1921%2F03%2F04%2F244-Inauguration%20in%201921.jpeg?alt=media&token=edac1856-7ad3-427d-822a-58bf9de25775",
      imgsource:
        "https://en.wikipedia.org/wiki/Inauguration_of_Warren_G._Harding#/media/File:USA_inauguration_1921.jpg",
      link: "https://en.wikipedia.org/wiki/Inauguration_of_Warren_G._Harding",
    },
    {
      id: 245,
      date: "8 Sep 1921",
      time: 0,
      type: 0,
      title: "First Miss America Pageant",
      description:
        "The first Miss America Pageant was held in Atlantic City, New Jersey, marking the beginning of the Miss America tradition. Margaret Gorman, Miss Washington, DC, was the winner. 📷 Atlantic City was home to the first Miss America Pageant in 1921 which included representatives from 7 cities and Atlantic City. Sixteen year-old Margaret Gorman from Washington, DC (at far left in white hat) won the first competition. (H009.394.5fir329; ACFPL Atlantic City Heritage Collections)",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1921%2F09%2F08%2Fthumbs%2F245-Miss-America-1921_600x600.jpeg?alt=media&token=23aa57cc-c5be-4aec-8134-e18ade6ff4fd",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1921%2F09%2F08%2F245-Miss-America-1921.png?alt=media&token=f9dfcf88-6279-4642-aefd-8e109c1f9fb2",
      imgsource:
        "https://www.atlanticcityexperience.org/exhibits/exhibit-nucky-johnson-prohibition/miss-america-1921",
      link: "https://en.wikipedia.org/wiki/Miss_America_1921",
    },
    {
      id: 246,
      date: "2 Nov 1920",
      time: 0,
      type: 0,
      title: "First Commercial Radio Station",
      description:
        "KDKA in Pittsburgh, Pennsylvania, became the first commercial radio station to broadcast regularly scheduled programs. 📷 round 1920, radio broadcasting started to get popular. The Brox Sisters, a popular singing group, gathered around the radio at the time.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1920%2F11%2F02%2Fthumbs%2F246-Brox_(Sisters)_LCCN2014717186_600x600.jpeg?alt=media&token=758d0a63-42e7-444f-b416-c1d58ad674fc",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1920%2F11%2F02%2F246-Brox_(Sisters)_LCCN2014717186.jpeg?alt=media&token=b160684e-76f7-4bad-93b7-ba331bbc8cf3",
      imgsource:
        "https://en.wikipedia.org/wiki/History_of_radio#/media/File:Brox_(Sisters)_LCCN2014717186.jpg",
      link: "https://www.fcc.gov/media/radio/history-of-commercial-radio",
    },
    {
      id: 247,
      date: "4 Jun 1920",
      time: 0,
      type: 0,
      title: "Treaty of Trianon",
      description:
        "The Treaty of Trianon was signed, formally ending World War I between the Allies and Hungary. It led to significant territorial changes in Hungary. 📷 Arrival of the two signatories, Ágost Benárd and Alfréd Drasche-Lázár, on 4 June 1920 at the Grand Trianon in Versailles",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1920%2F06%2F04%2Fthumbs%2F247-1920px-Signature_de_la_Paix_avec_la_Hongrie%2C_en_te%CC%82te_Bena%CC%81rd_A%CC%81gost_hongrois(passant_devant_un_piquet_d'honneur_a%CC%80_Versailles)_600x600.jpeg?alt=media&token=54ec17cc-d597-4f18-9c77-4ba088ebeea8",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1920%2F06%2F04%2F247-1920px-Signature_de_la_Paix_avec_la_Hongrie%2C_en_te%CC%82te_Bena%CC%81rd_A%CC%81gost_hongrois(passant_devant_un_piquet_d'honneur_a%CC%80_Versailles).jpeg?alt=media&token=dc11bac1-5938-41fc-952b-f35ae69fcddd",
      imgsource:
        "https://en.wikipedia.org/wiki/Treaty_of_Trianon#/media/File:Signature_de_la_Paix_avec_la_Hongrie,_en_t%C3%AAte_Ben%C3%A1rd_%C3%81gost_hongrois(passant_devant_un_piquet_d'honneur_%C3%A0_Versailles).jpg",
      link: "https://en.wikipedia.org/wiki/Treaty_of_Trianon",
    },
    {
      id: 248,
      date: "19 Jan 1920",
      time: 0,
      type: 0,
      title: "Formation of the American Civil Liberties Union (ACLU)",
      description:
        "The ACLU was founded to protect and defend civil liberties and individual rights in the United States. 📷 The new ACLU logo, introduced in 2017 as part of the organization's new brand identity. On white background",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1920%2F01%2F19%2Fthumbs%2F248-ACLU_600x600.jpeg?alt=media&token=f286a193-29bf-4141-af19-c6aec0098324",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1920%2F01%2F19%2F248-ACLU.png?alt=media&token=9a714258-a9cc-452c-9c5b-617ee9f26348",
      imgsource:
        "https://en.wikipedia.org/wiki/American_Civil_Liberties_Union#/media/File:New_ACLU_Logo_2017.svg",
      link: "https://en.wikipedia.org/wiki/American_Civil_Liberties_Union",
    },
    {
      id: 249,
      date: "16 May 1920",
      time: 0,
      type: 0,
      title: "Joan of Arc Canonization",
      description:
        "Joan of Arc, the French heroine of the Hundred Years' War, was canonized as a saint by the Catholic Church. 📷 Canonization Mass of Joan of Arc in Saint Peter's Basilica.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1920%2F05%2F16%2Fthumbs%2F249Joan_of_Arc_Canonization_600x600.jpeg?alt=media&token=027ead1c-eb33-47f6-92ab-852c87b99979",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1920%2F05%2F16%2F249Joan_of_Arc_Canonization.jpeg?alt=media&token=4a5ac3fa-9c6d-4c37-8dc5-afe17ff6040c",
      imgsource:
        "https://en.wikipedia.org/wiki/Canonization_of_Joan_of_Arc#/media/File:Joan_of_Arc_Canonization.jpg",
      link: "https://en.wikipedia.org/wiki/Canonization_of_Joan_of_Arc",
    },
    {
      id: 250,
      date: "28 Jun 1919",
      time: 0,
      type: 0,
      title: "Treaty of Versailles",
      description:
        "The Treaty of Versailles was signed, officially ending World War I and imposing terms and reparations on Germany. 📷 Cover of the English version",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1919%2F06%2F28%2Fthumbs%2F250-Treaty_of_Versailles%2C_English_version_600x600.jpeg?alt=media&token=a2c31033-d7c4-4946-b940-32a4b178e1fe",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1919%2F06%2F28%2F250-Treaty_of_Versailles%2C_English_version.jpeg?alt=media&token=ddaebe37-2fdc-4f4c-968e-10961e08a8f8",
      imgsource:
        "https://en.wikipedia.org/wiki/Treaty_of_Versailles#/media/File:Treaty_of_Versailles,_English_version.jpg",
      link: "https://en.chateauversailles.fr/discover/history/key-dates/treaty-versailles-1919",
    },
    {
      id: 251,
      date: "27 Jul 1919",
      time: 0,
      type: 0,
      title: "Chicago Race Riot of 1919",
      description:
        "The Chicago race riots of 1919 were a series of violent racial conflicts sparked by tensions between white and Black residents, resulting in deaths and widespread property damage. 📷 News coverage of the 1919 Chicago Race Riot",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1919%2F07%2F27%2Fthumbs%2F251-1919_Chicago_Race_Riot_600x600.jpeg?alt=media&token=33ec2645-3874-440a-8e34-c83dbab43321",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1919%2F07%2F27%2F251-1919_Chicago_Race_Riot.jpeg?alt=media&token=7a1c8806-e99e-4e6e-892c-d7867be2d43e",
      imgsource:
        "https://en.wikipedia.org/wiki/Chicago_race_riot_of_1919#/media/File:1919_Chicago_Race_Riot.jpg",
      link: "https://en.wikipedia.org/wiki/Chicago_race_riot_of_1919",
    },
    {
      id: 252,
      date: "10 Sep 1919",
      time: 0,
      type: 0,
      title: "Treaty of Saint-Germain of 1919",
      description:
        "The Treaty of Saint-Germain-en-Laye (1919) was one of the post-World War I peace treaties. It dealt with Austria and led to the dissolution of the Austro-Hungarian Empire and the recognition of Austria as an independent nation. 📷 Austrian chancellor Renner addressing the delegates during the signing ceremony",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1919%2F09%2F10%2Fthumbs%2F252-TratadoDeSaintGermainRenner_600x600.jpeg?alt=media&token=4357dfb9-4cbb-4371-b69a-72d4e8d50ae3",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1919%2F09%2F10%2F252-TratadoDeSaintGermainRenner.png?alt=media&token=ba1f51f4-35c0-4a5d-bb86-96ac8dd54715",
      imgsource:
        "https://en.wikipedia.org/wiki/Treaty_of_Saint-Germain-en-Laye_(1919)#/media/File:TratadoDeSaintGermainRenner.png",
      link: "https://en.wikipedia.org/wiki/Treaty_of_Saint-Germain-en-Laye_(1919)",
    },
    {
      id: 253,
      date: "13 Apr 1919",
      time: 0,
      type: 0,
      title: "Amritsar Massacre",
      description:
        "In Amritsar, India, British troops opened fire on a peaceful gathering, resulting in hundreds of deaths and leading to increased demands for Indian independence. 📷 Martyr's memorial in Jallianwala Bagh",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1919%2F04%2F13%2Fthumbs%2F253-1024px-Jallianwala_Bagh_in_Day_light_600x600.jpeg?alt=media&token=27c19bce-2abe-4b89-81a3-7909eee8d64f",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1919%2F04%2F13%2F253-1024px-Jallianwala_Bagh_in_Day_light.jpeg?alt=media&token=e2ac8533-4d93-406d-9a3f-7efa58dc8f30",
      imgsource:
        "https://en.wikipedia.org/wiki/Jallianwala_Bagh_massacre#/media/File:Jallianwala_Bagh_in_Day_light.JPG",
      link: "https://en.wikipedia.org/wiki/Jallianwala_Bagh_massacre",
    },
    {
      id: 254,
      date: "30 Oct 1918",
      time: 0,
      type: 0,
      title: "Armistice of Mudros",
      description:
        "The Ottoman Empire signed the Armistice of Mudros with the Allies, effectively ending its involvement in World War I. 📷 HMS Agamemnon on an earlier visit to Mudros during the Dardanelles campaign in 1915.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1918%2F10%2F30%2Fthumbs%2F254-FO-93_110_80-Armistice-of-Mudros-protcol6_600x600.jpeg?alt=media&token=7710d0af-0c7d-4c51-95e0-16750ba209d6",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1918%2F10%2F30%2F254-FO-93_110_80-Armistice-of-Mudros-protcol6.jpeg?alt=media&token=c6d1d909-618b-4018-9ac5-837a53779bc7",
      imgsource:
        "https://blog.nationalarchives.gov.uk/milestones-peace-armistice-mudros/",
      link: "https://en.wikipedia.org/wiki/Armistice_of_Mudros",
    },
    {
      id: 255,
      date: "4 Mar 1918",
      time: 0,
      type: 0,
      title: "Spanish Flu Pandemic",
      description:
        "The Spanish Flu Pandemic (1918-1919) was a global influenza outbreak caused by the H1N1 virus. It began during World War I and spread worldwide, infecting millions and causing widespread deaths. 📷 H1N1 virus",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1918%2F03%2F04%2Fthumbs%2F255-H1N1_navbox_600x600.jpeg?alt=media&token=37e69254-784e-41e3-8f65-32cafb92076c",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1918%2F03%2F04%2F255-H1N1_navbox.jpeg?alt=media&token=26d666e0-585c-4bda-b6db-22b7ec888a3b",
      imgsource:
        "https://en.wikipedia.org/wiki/Spanish_flu#/media/File:H1N1_navbox.jpg",
      link: "https://en.wikipedia.org/wiki/Spanish_flu",
    },
    {
      id: 256,
      date: "11 Nov 1918",
      time: 0,
      type: 0,
      title: "End of World War I",
      description:
        "World War I ended with the signing of the Armistice of Compiègne on November 11, 1918, bringing an end to the conflict that had raged for four years. 📷 A German trench occupied by British Soldiers near the Albert-Bapaume road at Ovillers-la-Boisselle, July 1916 during the Battle of the Somme. The men are from A Company, 11th Battalion, The Cheshire Regiment.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1918%2F11%2F11%2Fthumbs%2F256-Cheshire_Regiment_trench_Somme_1916_600x600.jpeg?alt=media&token=bf6eb091-f294-43f1-86c5-ae748848136b",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1918%2F11%2F11%2F256-Cheshire_Regiment_trench_Somme_1916.jpeg?alt=media&token=5a7133cf-7733-4cbe-b0f3-1a23c5547630",
      imgsource:
        "https://en.wikipedia.org/wiki/World_War_I#/media/File:Cheshire_Regiment_trench_Somme_1916.jpg",
      link: "https://en.wikipedia.org/wiki/World_War_I",
    },
    {
      id: 257,
      date: "7 Nov 1917",
      time: 0,
      type: 0,
      title: "Russian Civil War",
      description:
        "The Russian Civil War (1917-1923) was a conflict that followed the Russian Revolution. It pitted the Bolshevik Red Army against anti-Bolshevik White forces and foreign interventions, leading to immense suffering and the rise of Soviet Russia. 📷 Screenshot from Wikipedia of A review of Red Army troops in Moscow. ",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1917%2F11%2F07%2Fthumbs%2F257-Russian-Civil-War_600x600.jpeg?alt=media&token=ec547623-a1cc-4d9a-a393-8ad32242bb35",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1917%2F11%2F07%2F257-Russian-Civil-War.png?alt=media&token=285b2467-ffd7-4158-8811-3c0d7443ecc3",
      imgsource:
        "https://upload.wikimedia.org/wikipedia/commons/1/1a/Russian_Civil_War_montage.png",
      link: "https://en.wikipedia.org/wiki/Russian_Civil_War",
    },
    {
      id: 258,
      date: "31 Jul 1917",
      time: 0,
      type: 0,
      title: "Battle of Passchendaele",
      description:
        "During World War I, the Battle of Passchendaele (Third Battle of Ypres) was fought in Belgium between the Allies and the Central Powers. 📷 Soldiers of an Australian 4th Division field artillery brigade on a duckboard track passing through Chateau Wood, near Hooge in the Ypres salient, 29 October 1917. The leading soldier is Gunner James Fulton and the second soldier is Lieutenant Anthony Devine. The men belong to a battery of the 10th Field Artillery Brigade. Australian War Memorial collection number E01220.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1917%2F07%2F31%2Fthumbs%2F258-Chateauwood_600x600.jpeg?alt=media&token=1116065c-6a5d-4593-a9b9-77d6636044a0",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1917%2F07%2F31%2F258-Chateauwood.jpeg?alt=media&token=da2d3559-5a1d-4619-b119-fd2fa1f925b6",
      imgsource:
        "https://en.wikipedia.org/wiki/Battle_of_Passchendaele#/media/File:Chateauwood.jpg",
      link: "https://en.wikipedia.org/wiki/Battle_of_Passchendaele",
    },
    {
      id: 259,
      date: "1 Nov 1917",
      time: 0,
      type: 0,
      title: "Third Battle of Gaza",
      description:
        "During World War I, British forces captured Gaza from the Ottoman Empire in the Third Battle of Gaza. 📷 Map of Gaza with attacks on 1 and 2 November",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1917%2F11%2F01%2Fthumbs%2F259-Wavell_p.128_600x600.jpeg?alt=media&token=64d24f43-fdb7-4eb5-82aa-becb5a14b5fc",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1917%2F11%2F01%2F259-Wavell_p.128.jpeg?alt=media&token=7920ea57-e3d9-451e-800b-4b29f4dd6709",
      imgsource:
        "https://en.wikipedia.org/wiki/Third_Battle_of_Gaza#/media/File:Wavell_p.128.jpeg",
      link: "https://en.wikipedia.org/wiki/Third_Battle_of_Gaza",
    },
    {
      id: 260,
      date: "6 Dec 1917",
      time: 0,
      type: 0,
      title: "Halifax Explosion",
      description:
        "A massive explosion in Halifax, Nova Scotia, Canada, caused by a collision between two ships, resulted in significant destruction and loss of life. 📷 The pyrocumulus cloud produced by the explosion",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1917%2F12%2F06%2Fthumbs%2F260-1024px-Halifax_Explosion_blast_cloud_restored_600x600.jpeg?alt=media&token=f84534ba-cdf6-420a-94c6-8d5a19830906",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1917%2F12%2F06%2F260-1024px-Halifax_Explosion_blast_cloud_restored.jpeg?alt=media&token=ea68e997-9fcd-4845-b5d0-a8f40e075359",
      imgsource:
        "https://en.wikipedia.org/wiki/Halifax_Explosion#/media/File:Halifax_Explosion_blast_cloud_restored.jpg",
      link: "https://en.wikipedia.org/wiki/Halifax_Explosion",
    },
    {
      id: 261,
      date: "1 Jul 1916",
      time: 0,
      type: 0,
      title: "Battle of Somme",
      description:
        "The Battle of the Somme, one of the bloodiest battles of World War I, was fought between British and French forces against the German Empire in northern France. 📷 Troops of the British Indian Army at Battle of the Somme.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1916%2F07%2F01%2Fthumbs%2F261-Indian_bicycle_troops_Somme_1916_IWM_Q_3983_600x600.jpeg?alt=media&token=81d77e06-786b-4d58-88c9-e260fbcd3377",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1916%2F07%2F01%2F261-Indian_bicycle_troops_Somme_1916_IWM_Q_3983.jpeg?alt=media&token=ae03ad8a-9ee4-446b-b89f-07f056ccfdcb",
      imgsource:
        "https://en.wikipedia.org/wiki/Battle_of_the_Somme#/media/File:Indian_bicycle_troops_Somme_1916_IWM_Q_3983.jpg",
      link: "https://en.wikipedia.org/wiki/Battle_of_the_Somme",
    },
    {
      id: 262,
      date: "30 Dec 1916",
      time: 0,
      type: 0,
      title: "Rasputin Assassinated",
      description:
        "Grigori Rasputin, a controversial figure and advisor to the Russian royal family, was assassinated in Russia.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1916%2F12%2F16%2Fthumbs%2F262-1024px-Rasputin_PA_600x600.jpeg?alt=media&token=c98e3bf8-8a9f-4d30-9494-2db3330b745d",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1916%2F12%2F16%2F262-1024px-Rasputin_PA.jpeg?alt=media&token=a70acce6-d17e-4528-a77c-023147588c0f",
      imgsource:
        "https://en.wikipedia.org/wiki/Grigori_Rasputin#/media/File:Rasputin_PA.jpg",
      link: "https://en.wikipedia.org/wiki/Grigori_Rasputin",
    },
    {
      id: 263,
      date: "9 Mar 1916",
      time: 0,
      type: 0,
      title: "Battle of Columbus 1916",
      description:
        "Mexican revolutionary Pancho Villa led a raid on the U.S. town of Columbus, New Mexico, resulting in a U.S. military response.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1916%2F03%2F09%2Fthumbs%2F263-Columbus_600x600.jpeg?alt=media&token=34f1d533-3e2e-45c3-916d-ea95bdee52f9",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1916%2F03%2F09%2F263-Columbus.jpeg?alt=media&token=b224f99d-d2a6-4e56-b3bb-a6b72f78ff7f",
      imgsource:
        "https://en.wikipedia.org/wiki/Battle_of_Columbus_(1916)#/media/File:Columbus.jpg",
      link: "https://en.wikipedia.org/wiki/Battle_of_Columbus_(1916)",
    },
    {
      id: 264,
      date: "8 Nov 1915",
      time: 0,
      type: 0,
      title: "RMS Ancona Sinking",
      description:
        "The Italian passenger ship RMS Ancona was torpedoed and sunk by a German submarine in the Mediterranean, resulting in significant loss of life. 📷 Ancona sailing from New York for Italy",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1915%2F11%2F08%2Fthumbs%2F264-1920px-Merchant_Marine_-_Well-Known_Vessels_-_THE_ITALIAN_LINER_ANCONA_WHICH_was_sunk_by_the_German_submarines%2C_sailing_from_New_York_for_Italy_-_NARA_-_45499968_600x600.jpeg?alt=media&token=9e17e802-d1ef-47dc-ae86-d6af917c2715",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1915%2F11%2F08%2F264-1920px-Merchant_Marine_-_Well-Known_Vessels_-_THE_ITALIAN_LINER_ANCONA_WHICH_was_sunk_by_the_German_submarines%2C_sailing_from_New_York_for_Italy_-_NARA_-_45499968.jpeg?alt=media&token=b85826df-25c5-45cc-91c4-bb89bdee955c",
      imgsource:
        "https://en.wikipedia.org/wiki/SS_Ancona#/media/File:Merchant_Marine_-_Well-Known_Vessels_-_THE_ITALIAN_LINER_ANCONA_WHICH_was_sunk_by_the_German_submarines,_sailing_from_New_York_for_Italy_-_NARA_-_45499968.jpg",
      link: "https://en.wikipedia.org/wiki/SS_Ancona",
    },
    {
      id: 265,
      date: "24 Apr 1915",
      time: 0,
      type: 0,
      title: "Armenian Genocide",
      description:
        "The Ottoman Empire began a systematic campaign of deportations and mass killings of Armenians, leading to the Armenian Genocide, which resulted in the deaths of hundreds of thousands of Armenians. 📷 Column of Armenian deportees guarded by gendarmes in Harput vilayet",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1915%2F04%2F24%2Fthumbs%2F265-Column_of_deportees_walking_through_Harput_vilayet_during_the_Armenian_genocide_600x600.jpeg?alt=media&token=1e0da23c-7f13-4401-8fba-2f19ac3bf21b",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1915%2F04%2F24%2F265-Column_of_deportees_walking_through_Harput_vilayet_during_the_Armenian_genocide.jpeg?alt=media&token=fca17b33-efe2-4541-9a4f-066ee5648035",
      imgsource:
        "https://en.wikipedia.org/wiki/Armenian_genocide#/media/File:Column_of_deportees_walking_through_Harput_vilayet_during_the_Armenian_genocide.jpg",
      link: "https://en.wikipedia.org/wiki/Armenian_genocide",
    },
    {
      id: 266,
      date: "7 May 1915",
      time: 0,
      type: 0,
      title: "Lusitania Sinking",
      description:
        "The British ocean liner RMS Lusitania was torpedoed and sunk by a German submarine off the coast of Ireland, leading to the loss of nearly 1,200 lives and contributing to the United States' entry into World War I. 📷 Painting of the sinking, from the German Federal Archives",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1915%2F05%2F07%2Fthumbs%2F266-Bundesarchiv_DVM_10_Bild-23-61-17%2C_Untergang_der__Lusitania__600x600.jpeg?alt=media&token=c7620250-3e7b-4380-bff3-6b7c53ec0482",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1915%2F05%2F07%2F266-Bundesarchiv_DVM_10_Bild-23-61-17%2C_Untergang_der__Lusitania_.jpeg?alt=media&token=759a8321-ff0a-457b-b294-0dd6029e39dc",
      imgsource:
        "https://en.wikipedia.org/wiki/Sinking_of_the_RMS_Lusitania#/media/File:Bundesarchiv_DVM_10_Bild-23-61-17,_Untergang_der_%22Lusitania%22.jpg",
      link: "https://en.wikipedia.org/wiki/Sinking_of_the_RMS_Lusitania",
    },
    {
      id: 267,
      date: "24 Dec 1914",
      time: 0,
      type: 0,
      title: "Christmas Truce",
      description:
        "During World War I, a spontaneous Christmas truce occurred along parts of the Western Front, with soldiers from opposing sides ceasing hostilities to celebrate Christmas together. 📷 British and German troops meeting in no man's land during the unofficial truce (British troops from the Northumberland Hussars, 7th Division, Bridoux–Rouge Banc Sector)",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1914%2F12%2F24%2Fthumbs%2F267-Christmas_Truce_1914_600x600.jpeg?alt=media&token=fc8d637f-3560-4cc3-a15d-d6cdd9de18e7",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1914%2F12%2F24%2F267-Christmas_Truce_1914.png?alt=media&token=b676cb5a-5f13-4317-8de8-46393c1321da",
      imgsource:
        "https://en.wikipedia.org/wiki/Christmas_truce#/media/File:Christmas_Truce_1914.png",
      link: "https://en.wikipedia.org/wiki/Christmas_truce",
    },
    {
      id: 268,
      date: "28 Jul 1914",
      time: 0,
      type: 0,
      title: "Outbreak of World War II",
      description:
        "The assassination of Archduke Franz Ferdinand of Austria-Hungary on June 28, 1914, in Sarajevo triggered a series of events that led to the outbreak of World War I. Austria-Hungary declared war on Serbia on July 28, 1914, setting off a chain reaction of declarations of war among European powers. 📷 Russian forest trench at the Battle of Sarikamish, 1914–1915",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1914%2F07%2F28%2Fthumbs%2F268-Sarikam_600x600.jpeg?alt=media&token=2f202ae4-4991-47a0-b257-c51825583fce",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1914%2F07%2F28%2F268-Sarikam.jpeg?alt=media&token=b68f66ac-3544-42eb-a90b-82ae914e6062",
      imgsource:
        "https://en.wikipedia.org/wiki/World_War_I#/media/File:Sarikam.jpg",
      link: "https://en.wikipedia.org/wiki/World_War_I",
    },
    {
      id: 269,
      date: "15 Aug 1914",
      time: 0,
      type: 0,
      title: "Panama Canal Opens",
      description:
        "The Panama Canal officially opened on August 15, 1914, connecting the Atlantic and Pacific Oceans and greatly facilitating global maritime trade.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1914%2F08%2F15%2Fthumbs%2F269-vaporancon_600x600.jpeg?alt=media&token=c3ed206c-9a3b-4616-8f51-fbcfb0ad78b3",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1914%2F08%2F15%2F269-vaporancon.jpeg?alt=media&token=49a23fab-fd6a-4982-bc88-38716f0b39f8",
      imgsource: "https://pancanal.com/en/history-of-the-panama-canal/",
      link: "https://en.wikipedia.org/wiki/Panama_Canal",
    },
    {
      id: 270,
      date: "28 Jun 1914",
      time: 0,
      type: 0,
      title: "Assassination of Archduke Franz Ferdinand",
      description:
        "The assassination of Archduke Franz Ferdinand and his wife, Sophie, in Sarajevo by Gavrilo Princip, a member of a Serbian nationalist group, marked the start of the July Crisis that led to World War I. 📷 Photograph of the Archduke and his wife emerging from the Sarajevo Town Hall to board their car, a few minutes before the assassination",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1914%2F06%2F28%2Fthumbs%2F270-Postcard_for_the_assassination_of_Archduke_Franz_Ferdinand_in_Sarajevo_600x600.jpeg?alt=media&token=43013e46-2226-46dc-b6b4-26e2c119e833",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1914%2F06%2F28%2F270-Postcard_for_the_assassination_of_Archduke_Franz_Ferdinand_in_Sarajevo.jpeg?alt=media&token=e5f990b9-2b59-474a-aaed-a8ba381842cd",
      imgsource:
        "https://en.wikipedia.org/wiki/Assassination_of_Archduke_Franz_Ferdinand#/media/File:Postcard_for_the_assassination_of_Archduke_Franz_Ferdinand_in_Sarajevo.jpg",
      link: "https://en.wikipedia.org/wiki/Assassination_of_Archduke_Franz_Ferdinand",
    },
    {
      id: 271,
      date: "24 Apr 1913",
      time: 0,
      type: 0,
      title: "The Woolworth Building",
      description:
        "The Woolworth Building, completed in 1913, is an iconic skyscraper in New York City. It was one of the world's tallest buildings at the time and is known for its Gothic Revival architecture. 📷 Woolworth Building (New York City) Photo taken by User:Aude on November 13, 2005.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1913%2F04%2F24%2Fthumbs%2F271-1024px-Woolworth_bldg_nov2005c_600x600.jpeg?alt=media&token=595b892a-13f3-4cec-8b53-8d0a3d50f30e",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1913%2F04%2F24%2F271-1024px-Woolworth_bldg_nov2005c.jpeg?alt=media&token=04ea6389-2ec8-4cdb-86e1-c317e064f79c",
      imgsource:
        "https://en.wikipedia.org/wiki/Woolworth_Building#/media/File:Woolworth_bldg_nov2005c.jpg",
      link: "https://en.wikipedia.org/wiki/Woolworth_Building",
    },
    {
      id: 272,
      date: "4 Mar 1913",
      time: 0,
      type: 0,
      title: "Woodrow Wilson's Inauguration",
      description:
        "Woodrow Wilson was inaugurated as the 28th President of the United States. 📷 Woodrow Wilson takes the oath of office for his first term of the Presidency in Washington, DC.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1913%2F03%2F04%2Fthumbs%2F272-WilsonFirstInauguration_600x600.jpeg?alt=media&token=fae00edc-cdbd-45bd-a469-fe2531a2ad3b",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1913%2F03%2F04%2F272-WilsonFirstInauguration.jpeg?alt=media&token=c544f08e-af6f-41d6-934b-0394c35b4298",
      imgsource:
        "https://en.wikipedia.org/wiki/First_inauguration_of_Woodrow_Wilson#/media/File:WilsonFirstInauguration.jpg",
      link: "https://en.wikipedia.org/wiki/First_inauguration_of_Woodrow_Wilson",
    },
    {
      id: 273,
      date: "3 Mar 1913",
      time: 0,
      type: 0,
      title: "Suffragette March on Washington",
      description:
        "Women's suffrage activists organized a massive suffragette march on Washington, D.C., demanding the right to vote for women. The Just Government League of Maryland marching with a variation of the Gadsden flag.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1913%2F03%2F03%2Fthumbs%2F273-The_Just_Government_League_of_Maryland_marching_in_the_Women's_suffrage_parage%2C_March_3%2C_1913_600x600.jpeg?alt=media&token=198c79d1-555e-4eac-9118-bbc4a852fd96",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1913%2F03%2F03%2F273-The_Just_Government_League_of_Maryland_marching_in_the_Women's_suffrage_parage%2C_March_3%2C_1913.jpeg?alt=media&token=4f3d6456-5fff-4082-9d55-6e50b3f86701",
      imgsource:
        "https://en.wikipedia.org/wiki/Woman_Suffrage_Procession#/media/File:The_Just_Government_League_of_Maryland_marching_in_the_Women's_suffrage_parage,_March_3,_1913.jpg",
      link: "https://en.wikipedia.org/wiki/Woman_Suffrage_Procession",
    },
    {
      id: 274,
      date: "8 Oct 1912",
      time: 0,
      type: 0,
      title: "The Balkan Wars",
      description:
        "The Balkan Wars, a series of conflicts in the Balkan Peninsula, began with the First Balkan War (1912-1913) and continued with the Second Balkan War (1913). These wars resulted in significant territorial changes in the region. 📷 Bulgarian forces waiting to start their assault on Adrianople",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1912%2F10%2F08%2Fthumbs%2F274-Bulgarian_army_adrinople_600x600.jpeg?alt=media&token=280df64e-12b5-4eca-9d1a-2faffde38308",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1912%2F10%2F08%2F274-Bulgarian_army_adrinople.jpeg?alt=media&token=3c02457d-2f7a-4b5a-afa4-411d039f1a95",
      imgsource:
        "https://en.wikipedia.org/wiki/Balkan_Wars#/media/File:Bulgarian_army_adrinople.jpg",
      link: "https://en.wikipedia.org/wiki/Balkan_Wars",
    },
    {
      id: 275,
      date: "20 Apr 1912",
      time: 0,
      type: 0,
      title: "Fenway Park Opens",
      description:
        "Fenway Park, one of the oldest baseball stadiums in the United States and the home of the Boston Red Sox, officially opened in Boston. 📷 Fenway Park in 1915",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1912%2F04%2F20%2Fthumbs%2F275-Fenwaypark1_600x600.jpeg?alt=media&token=1fd4a362-daab-43f9-852b-3a0d361c68b7",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1912%2F04%2F20%2F275-Fenwaypark1.jpeg?alt=media&token=b574e2a4-e35e-4589-8a75-a7405b8c19a5",
      imgsource:
        "https://en.wikipedia.org/wiki/Fenway_Park#/media/File:Fenwaypark1.jpg",
      link: "https://en.wikipedia.org/wiki/Fenway_Park",
    },
    {
      id: 276,
      date: "14 Apr 1912",
      time: 0,
      type: 0,
      title: "Titanic Sinking",
      description:
        "The RMS Titanic, one of the most famous ocean liners in history, struck an iceberg and sank on its maiden voyage. Over 1,500 people lost their lives in the disaster. 📷 The Titanic. UNIVERSAL HISTORY ARCHIVE/GETTY IMAGES",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1912%2F04%2F14%2Fthumbs%2F276-titanic-gettyimages-113629179_600x600.jpeg?alt=media&token=a6eb10ce-d158-401b-a155-6270a003b10e",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1912%2F04%2F14%2F276-titanic-gettyimages-113629179.jpeg?alt=media&token=c2c00d2b-9192-4399-a9b8-60b4c7241d2c",
      imgsource: "https://www.history.com/topics/early-20th-century-us/titanic",
      link: "https://en.wikipedia.org/wiki/Sinking_of_the_Titanic",
    },
    {
      id: 277,
      date: "12 Mar 1912",
      time: 0,
      type: 0,
      title: "The Girl Scouts of the USA",
      description:
        "Juliette Gordon Low founded the Girl Scouts of the USA in Savannah, Georgia. 📷 This is the logo owned by Girl Scouts of the USA for Girl Scouts of the USA. Further details: Logo of the Girl Scouts of the USA",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1912%2F03%2F12%2Fthumbs%2F277-Girl_Scouts_of_the_USA.svg_600x600.jpeg?alt=media&token=15765b00-764e-44b8-8052-2c0e26ca5341",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1912%2F03%2F12%2F277-Girl_Scouts_of_the_USA.svg.png?alt=media&token=d74e1ad6-c123-4502-a231-7ed25eb42fbd",
      imgsource:
        "https://en.wikipedia.org/wiki/Girl_Scouts_of_the_USA#/media/File:Girl_Scouts_of_the_USA.svg",
      link: "https://www.girlscouts.org/",
    },
    {
      id: 278,
      date: "14 Dec 1911",
      time: 0,
      type: 0,
      title: "Roald Amundsen Reaches the South Pole",
      description:
        "Norwegian explorer Roald Amundsen became the first person to reach the South Pole, arriving on December 14, 1911. 📷 Members of Roald Amundsen's South Pole expedition 1910-12 at the pole itself, December 1911, (from left to right): Roald Amundsen, Helmer Hanssen, Sverre Hassel and Oscar Wisting",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1911%2F12%2F14%2Fthumbs%2F278-1920px-At_the_South_Pole%2C_December_1911_600x600.jpeg?alt=media&token=95010f05-bac0-4cea-92ef-f7e2c4fa9138",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1911%2F12%2F14%2F278-1920px-At_the_South_Pole%2C_December_1911.jpeg?alt=media&token=2a545d0f-adc6-4adf-9513-6bd2cccaa693",
      imgsource:
        "https://en.wikipedia.org/wiki/Amundsen%27s_South_Pole_expedition#/media/File:At_the_South_Pole,_December_1911.jpg",
      link: "https://en.wikipedia.org/wiki/Amundsen%27s_South_Pole_expedition",
    },
    {
      id: 279,
      date: "21 Aug 1911",
      time: 0,
      type: 0,
      title: "Mona Lisa Theft",
      description:
        "Leonardo da Vinci's iconic painting, the Mona Lisa, was stolen from the Louvre Museum in Paris by Vincenzo Peruggia. It was recovered in 1913. 📷 The Mona Lisa in the Uffizi Gallery in Florence, 1913. Museum director Giovanni Poggi (right) inspects the painting.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1911%2F08%2F21%2Fthumbs%2F279-Monalisa_uffizi_1913_600x600.jpeg?alt=media&token=2c625a7d-51ee-44aa-b968-81a37f527f3f",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1911%2F08%2F21%2F279-Monalisa_uffizi_1913.jpeg?alt=media&token=fcc5acef-383d-47b9-8e81-33ede8053593",
      imgsource:
        "https://en.wikipedia.org/wiki/Vincenzo_Peruggia#/media/File:Monalisa_uffizi_1913.jpg",
      link: "https://www.npr.org/2011/07/30/138800110/the-theft-that-made-the-mona-lisa-a-masterpiece",
    },
    {
      id: 280,
      date: "14 Sep 1911",
      time: 0,
      type: 0,
      title: "Russian Prime Minister Stolypin Assassinated",
      description:
        "Pyotr Stolypin, the Prime Minister of Russia, was assassinated on September 14,1911, in Kyiv, Ukraine, during a performance at the Kiev Opera House. There had been earlier assassination attempts on him in 1906 and 1907. Stolypin's assassination marked a significant political event during the turbulent period leading up to the Russian Revolution of 1917.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1911%2F09%2F14%2Fthumbs%2F280-Pyotr_Stolypin_LOC_07327_600x600.jpeg?alt=media&token=fd163582-44b1-4272-9676-67bd577aabdd",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1911%2F09%2F14%2F280-Pyotr_Stolypin_LOC_07327.jpeg?alt=media&token=019dfebb-2416-4e9b-90df-c350b17b6224",
      imgsource:
        "https://en.wikipedia.org/wiki/Pyotr_Stolypin#/media/File:Pyotr_Stolypin_LOC_07327.jpg",
      link: "https://en.wikipedia.org/wiki/Pyotr_Stolypin",
    },
    {
      id: 281,
      date: "20 Aug 1910",
      time: 0,
      type: 0,
      title: "The Great Fire of 1910",
      description:
        "The Great Fire of 1910, also known as the 'Big Blowup, was a devastating wildfire that swept through parts of Idaho, Montana, and Washington, burning millions of acres.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1910%2F08%2F20%2Fthumbs%2F281-4929737957_fd478f4046_o_600x600.jpeg?alt=media&token=e037ba86-fc9a-41bc-b46c-81c9d58954b8",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1910%2F08%2F20%2F281-4929737957_fd478f4046_o.jpeg?alt=media&token=e63c516c-85ed-470c-822f-d3b46681f925",
      imgsource:
        "https://www.nationalforests.org/our-forests/your-national-forests-magazine/blazing-battles-the-1910-fire-and-its-legacy",
      link: "https://en.wikipedia.org/wiki/Great_Fire_of_1910",
    },
    {
      id: 282,
      date: "20 Apr 1910",
      time: 0,
      type: 0,
      title: "Halley's Comet",
      description:
        "Halley's Comet made a highly anticipated appearance in the night sky, visible from Earth. 📷 Halley in April 1910, from Harvard's Southern Hemisphere Station, taken with an 8-inch Bache Doublet",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1910%2F04%2F20%2Fthumbs%2F282-Halley's_comet_1910_600x600.jpeg?alt=media&token=2c34fbf8-c9a8-400d-9f10-02953c27ab97",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1910%2F04%2F20%2F282-Halley's_comet_1910.jpeg?alt=media&token=2f7b5106-713e-4667-a95d-c5bf80873231",
      imgsource:
        "https://en.wikipedia.org/wiki/Halley's_Comet#/media/File:Halley's_comet_1910.jpg",
      link: "https://solarsystem.nasa.gov/asteroids-comets-and-meteors/comets/1p-halley/in-depth/",
    },
    {
      id: 283,
      date: "8 Feb 1910",
      time: 0,
      type: 0,
      title: "Boy Scouts of America",
      description:
        "The Boy Scouts of America organization was founded, promoting character development and outdoor skills for young boys. 📷 This is the logo for Boy Scouts of America.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1910%2F02%2F08%2Fthumbs%2F283-Boy_Scouts_of_America_corporate_trademark.svg_600x600.jpeg?alt=media&token=ebba3e48-cf55-42f6-af59-a77d6d963fd9",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1910%2F02%2F08%2F283-Boy_Scouts_of_America_corporate_trademark.svg.png?alt=media&token=f484bfa4-b3b4-4b8b-a98b-ad07f7dd90f3",
      imgsource:
        "https://en.wikipedia.org/wiki/Boy_Scouts_of_America#/media/File:Boy_Scouts_of_America_corporate_trademark.svg",
      link: "https://www.scouting.org/",
    },
    {
      id: 284,
      date: "12 Feb 1909",
      time: 0,
      type: 0,
      title:
        "Founding of the National Association for the Advancement of Colored People (NAACP)",
      description:
        "The NAACP, a prominent civil rights organization in the United States, was founded in New York City to advocate for the rights of African Americans.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1909%2F02%2F12%2Fthumbs%2F284-NAACP_seal.svg_600x600.jpeg?alt=media&token=b05f93e6-dd1e-4275-a7ec-97a3ea59162d",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1909%2F02%2F12%2F284-NAACP_seal.svg.png?alt=media&token=7c9fca05-072f-4d9d-9681-e31c480a52b9",
      imgsource:
        "https://en.wikipedia.org/wiki/NAACP#/media/File:NAACP_seal.svg",
      link: "https://en.wikipedia.org/wiki/NAACP",
    },
    {
      id: 285,
      date: "11 Apr 1909",
      time: 0,
      type: 0,
      title: "Founding of Tel Aviv",
      description:
        "Tel Aviv, now one of Israel's major cities, was founded on the outskirts of the ancient port city of Jaffa. 📷 Hashalom interchange, Tel Aviv, July 2019",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1909%2F04%2F11%2Fthumbs%2F285-Hashalom_interchange_600x600.jpeg?alt=media&token=f93650a8-6689-4531-9bbb-c47ec7eebd63",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1909%2F04%2F11%2F285-Hashalom_interchange.jpeg?alt=media&token=200e8b62-faf9-4081-967b-dc6bf8a0562b",
      imgsource:
        "https://en.wikipedia.org/wiki/Tel_Aviv#/media/File:Hashalom_interchange.jpg",
      link: "https://en.wikipedia.org/wiki/Tel_Aviv",
    },
    {
      id: 286,
      date: "30 Jun 1908",
      time: 0,
      type: 0,
      title: "The Tunguska Event",
      description:
        "A massive explosion occurred over the remote Tunguska region in Siberia, Russia, believed to be caused by the airburst of a meteoroid or comet fragment. It devastated the area but caused no direct human casualties.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1908%2F06%2F30%2Fthumbs%2F286-Tunguska_Ereignis_600x600.jpeg?alt=media&token=39a4ad4f-b79e-433d-a335-089e4d989a30",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1908%2F06%2F30%2F286-Tunguska_Ereignis.jpeg?alt=media&token=cfc82046-e53b-43bc-af70-afdca4e47f46",
      imgsource:
        "https://en.wikipedia.org/wiki/Tunguska_event#/media/File:Tunguska_Ereignis.jpg",
      link: "https://en.wikipedia.org/wiki/Tunguska_event",
    },
    {
      id: 287,
      date: "24 Jul 1908",
      time: 0,
      type: 0,
      title: "Young Turk Revolution",
      description:
        "The Young Turk Revolution in the Ottoman Empire led to the overthrow of Sultan Abdul Hamid II, resulting in a more constitutional and reformist government. 📷 Declaration of the Young Turk Revolution by the leaders of the Ottoman millets in 1908",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1908%2F07%2F24%2Fthumbs%2F287-Declaration_of_the_1908_Revolution_in_Ottoman_Empire_600x600.jpeg?alt=media&token=7a07d630-9355-4c34-91cc-377ed0ba68a5",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1908%2F07%2F24%2F287-Declaration_of_the_1908_Revolution_in_Ottoman_Empire.png?alt=media&token=4eacf9b0-950a-4e87-91e9-262fb7c8a62e",
      imgsource:
        "https://en.wikipedia.org/wiki/Young_Turk_Revolution#/media/File:Declaration_of_the_1908_Revolution_in_Ottoman_Empire.png",
      link: "https://en.wikipedia.org/wiki/Young_Turk_Revolution",
    },
    {
      id: 288,
      date: "27 Apr 1908",
      time: 0,
      type: 0,
      title: "1908 Summer Olympics",
      description:
        "The 1908 Summer Olympics were held in London, marking the first time that the modern Olympic Games were hosted by the United Kingdom.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1908%2F04%2F27%2Fthumbs%2F288-Olympic_games_1908_London_600x600.jpeg?alt=media&token=d282bebc-b134-4634-a5a5-4d2b3c800211",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1908%2F04%2F27%2F288-Olympic_games_1908_London.jpeg?alt=media&token=76b93476-dbae-4b3c-af23-6dd727f62cc7",
      imgsource:
        "https://en.wikipedia.org/wiki/1908_Summer_Olympics#/media/File:Olympic_games_1908_London.jpg",
      link: "https://en.wikipedia.org/wiki/1908_Summer_Olympics",
    },
    {
      id: 289,
      date: "9 Feb 1907",
      time: 0,
      type: 0,
      title: "Mud March for Women's Suffrage",
      description:
        "Thousands of women marched in London in support of women's suffrage, demanding the right to vote and greater political representation. 📷 The rally at the base of Nelson's Column, Trafalgar Square",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1907%2F02%2F09%2Fthumbs%2F289-Rally_at_Trafalgar_Square%2C_part_of_the_Mud_March_600x600.jpeg?alt=media&token=3dd9db60-fc3a-495f-9925-f918776848e5",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1907%2F02%2F09%2F289-Rally_at_Trafalgar_Square%2C_part_of_the_Mud_March.jpeg?alt=media&token=19834265-a5aa-4816-940d-c78a7f93ca06",
      imgsource:
        "https://en.wikipedia.org/wiki/Mud_March_(suffragists)#/media/File:Rally_at_Trafalgar_Square,_part_of_the_Mud_March.jpg",
      link: "https://en.wikipedia.org/wiki/Mud_March_(suffragists)#",
    },
    {
      id: 290,
      date: "13 Nov 1907",
      time: 0,
      type: 0,
      title: "First Helicopter Flight",
      description:
        "French inventor Paul Cornu made the first sustained, controlled flight in a helicopter, demonstrating advancements in aviation technology. 📷 In 1907, the French inventor Paul Cornu made a helicopter that used two 20-foot (6-meter) counter-rotating rotors driven by a 24-hp (18-kW) Antoinette engine. It lifted its inventor to about five feet (1.5 meters) and remained aloft one minute.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1907%2F11%2F13%2Fthumbs%2F290-HE2G8_600x600.jpeg?alt=media&token=e49929c6-fbec-4617-a500-f9b4cc173db6",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1907%2F11%2F13%2F290-HE2G8.jpeg?alt=media&token=10444a48-1e0f-46f1-95dd-10c4e1c731a4",
      imgsource:
        "https://en.wikipedia.org/wiki/Cornu_helicopter#/media/File:HE2G8.jpg",
      link: "https://en.wikipedia.org/wiki/Cornu_helicopter",
    },
    {
      id: 291,
      date: "19 Feb 1906",
      time: 0,
      type: 0,
      title: "First Kellogg's Cereal",
      description:
        "W.K. Kellogg introduced Corn Flakes, one of the first ready-to-eat breakfast cereals, revolutionizing breakfast routines.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1906%2F02%2F19%2Fthumbs%2F291-2880px-Kellogg's-Logo.svg_600x600.jpeg?alt=media&token=e37874f7-38d8-45fb-8b7c-f9b6d7fa212b",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1906%2F02%2F19%2F291-2880px-Kellogg's-Logo.svg.png?alt=media&token=987c7172-ea93-45fe-a3d2-5fc4fe72b599",
      imgsource:
        "https://en.wikipedia.org/wiki/Kellogg%27s#/media/File:Kellogg's-Logo.svg",
      link: "https://www.kelloggs.ie/en_IE/who-we-are/our-history.html#:~:text=1906%20%E2%80%94%20W.K.,%2Dfor%2Dyou%20breakfast%20foods.",
    },
    {
      id: 292,
      date: "18 Apr 1906",
      time: 0,
      type: 0,
      title: "1906 San Francisco earthquake",
      description:
        "The San Francisco Earthquake of 1906 was a catastrophic seismic event that struck San Francisco, California, and the surrounding areas, resulting in widespread destruction, fires, and loss of life.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1906%2F04%2F18%2Fthumbs%2F292-Post-and-Grant-Avenue-Look_600x600.jpeg?alt=media&token=fa1b7d66-1d86-4826-b846-e21d8bff2d73",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1906%2F04%2F18%2F292-Post-and-Grant-Avenue-Look.jpeg?alt=media&token=dc19fbee-6b79-45cb-b47c-dee9c3729329",
      imgsource:
        "https://en.wikipedia.org/wiki/1906_San_Francisco_earthquake#/media/File:Post-and-Grant-Avenue-Look.jpg",
      link: "https://en.wikipedia.org/wiki/1906_San_Francisco_earthquake",
    },
    {
      id: 293,
      date: "5 Sep 1906",
      time: 0,
      type: 0,
      title: "The First Forward Pass in Football",
      description:
        "American football saw a significant rule change when the first forward pass was legalized. 📷 A quarterback has just released the ball for a forward pass",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1906%2F09%2F05%2Fthumbs%2F293-20130310_-_Molosses_vs_Spartiates_-_024_600x600.jpeg?alt=media&token=bb27a313-49dc-4e08-a2a5-c682ec4c6b8d",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1906%2F09%2F05%2F293-20130310_-_Molosses_vs_Spartiates_-_024.jpeg?alt=media&token=565d06c8-c1b1-4789-85bd-5711637c1c67",
      imgsource:
        "https://en.wikipedia.org/wiki/Forward_pass#/media/File:20130310_-_Molosses_vs_Spartiates_-_024.jpg",
      link: "https://www.history.com/news/forward-pass-football-invented-origins",
    },
    {
      id: 294,
      date: "6 Aug 1905",
      time: 0,
      type: 0,
      title: "First Russian Duma",
      description:
        "The first Russian State Duma, a legislative assembly, was convened as part of the political reforms following the Russian Revolution of 1905. 📷 Tsar Nicholas II's opening speech before the two chambers in the Winter Palace (1906)",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1905%2F08%2F06%2Fthumbs%2F294-Bundesarchiv_Bild_183-H28740%2C_St._Petersburg%2C_Ero%CC%88ffnung_der_Parlamente_600x600.jpeg?alt=media&token=db48de81-08a4-4533-99bd-c55a3437b826",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1905%2F08%2F06%2F294-Bundesarchiv_Bild_183-H28740%2C_St._Petersburg%2C_Ero%CC%88ffnung_der_Parlamente.jpg?alt=media&token=73ae0e0d-deaf-43d2-a4a6-01a3cf2966b9",
      imgsource:
        "https://en.wikipedia.org/wiki/State_Duma_(Russian_Empire)#/media/File:Bundesarchiv_Bild_183-H28740,_St._Petersburg,_Er%C3%B6ffnung_der_Parlamente.jpg",
      link: "http://duma.gov.ru/en/duma/about/history/information/",
    },
    {
      id: 295,
      date: "22 Jan 1905",
      time: 0,
      type: 0,
      title: "Russian Revolution of 1905",
      description:
        "The Russian Revolution of 1905, also known as the 'Bloody Sunday' uprising, began with a peaceful protest in St. Petersburg, Russia, which turned violent when the Imperial Guard fired upon the demonstrators. This event marked the start of widespread protests and strikes throughout the Russian Empire. 📷 On the morning of January 9 (at the Narva Gates). Engraving by an unknown artist.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1905%2F01%2F22%2Fthumbs%2F295-Shestviye_u_Narvskikh_vorot_600x600.jpeg?alt=media&token=f498b7b5-79dd-481d-b883-b0877c18ec69",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1905%2F01%2F22%2F295-Shestviye_u_Narvskikh_vorot.jpeg?alt=media&token=a0b957a1-163a-49ae-b7f7-258c38948fe6",
      imgsource:
        "https://en.wikipedia.org/wiki/Russian_Revolution_of_1905#/media/File:Shestviye_u_Narvskikh_vorot.jpg",
      link: "https://en.wikipedia.org/wiki/Russian_Revolution_of_1905",
    },
    {
      id: 296,
      date: "20 Aug 1905",
      time: 0,
      type: 0,
      title: "The Tongmenghui",
      description:
        "The Tongmenghui, or the Chinese United League, was a secret revolutionary society founded by Sun Yat-sen in 1905, aiming to overthrow the Qing Dynasty and establish a republic in China. 📷 Emblem of the Kuomintang.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1905%2F08%2F20%2Fthumbs%2F296-Emblem_of_the_Kuomintang.svg_600x600.jpeg?alt=media&token=4388829f-8fe3-41a1-887d-d3dffe8e4459",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1905%2F08%2F20%2F296-Emblem_of_the_Kuomintang.svg.png?alt=media&token=62885787-9c53-4b0a-b433-204bdc5bbd06",
      imgsource:
        "https://en.wikipedia.org/wiki/Tongmenghui#/media/File:Emblem_of_the_Kuomintang.svg",
      link: "https://en.wikipedia.org/wiki/Tongmenghui",
    },
    {
      id: 297,
      date: "27 Oct 1904",
      time: 0,
      type: 0,
      title: "New York City Subway Opens",
      description:
        "The New York City Subway, one of the world's largest and busiest subway systems, opened its first line. 📷 The City Hall station of the IRT Lexington Avenue Line, part of the first underground line of the subway that opened on October 27, 1904",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1904%2F10%2F27%2Fthumbs%2F297-City_Hall_Subway_station_600x600.jpeg?alt=media&token=abf8a7c6-109d-4ee8-a6a1-974514fd3027",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1904%2F10%2F27%2F297-City_Hall_Subway_station.jpeg?alt=media&token=2a897469-b556-459a-8611-148c6de1397e",
      imgsource:
        "https://en.wikipedia.org/wiki/New_York_City_Subway#/media/File:City_Hall_Subway_station.jpg",
      link: "https://en.wikipedia.org/wiki/History_of_the_New_York_City_Subway",
    },
    {
      id: 298,
      date: "1 Jul 1903",
      time: 0,
      type: 0,
      title: "The First Tour de France",
      description:
        "The inaugural Tour de France, a prestigious cycling race, was held, becoming an annual tradition in the world of cycling. 📷 Tour de France logo used since 2019",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1903%2F07%2F01%2Fthumbs%2F298-Tour-De-France_600x600.jpeg?alt=media&token=dc1d39c8-205a-4b22-9f96-dec6b1ab4e8a",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1903%2F07%2F01%2F298-Tour-De-France.png?alt=media&token=e531e922-2fbb-4943-999b-1e91341f3cd2",
      imgsource:
        "https://en.wikipedia.org/wiki/Tour_de_France#/media/File:Tour_de_France_logo_since_2019.svg",
      link: "https://en.wikipedia.org/wiki/Tour_de_France",
    },
    {
      id: 299,
      date: "16 Jun 1903",
      time: 0,
      type: 0,
      title: "The Ford Motor Company Founded",
      description:
        "Henry Ford established the Ford Motor Company, which would later revolutionize the automotive industry with the introduction of mass-produced automobiles.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1903%2F06%2F16%2Fthumbs%2F299-Ford_600x600.jpeg?alt=media&token=caf4392e-31cf-4134-ab74-f2a30bb595bd",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1903%2F06%2F16%2F299-Ford.png?alt=media&token=1afbdb0a-bb91-4b6b-bb9d-404f12e67252",
      imgsource:
        "https://en.wikipedia.org/wiki/Ford_Motor_Company#/media/File:Ford_logo_flat.svg",
      link: "https://en.wikipedia.org/wiki/Ford_Motor_Company",
    },
    {
      id: 300,
      date: "8 Feb 1904",
      time: 0,
      type: 0,
      title: "The Russo-Japanese War",
      description:
        "The Russo-Japanese War began, lasting until 1905 and leading to Japan's emergence as a major world power. 📷 Russo-Japanese War montage",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1904%2F02%2F08%2Fthumbs%2F300-RUSSOJAPANESEWARIMAGE_600x600.jpeg?alt=media&token=2c0e4b51-cd1c-44b5-b943-b7e985fa8060",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1904%2F02%2F08%2F300-RUSSOJAPANESEWARIMAGE.jpeg?alt=media&token=230c5c64-232f-4d13-8a9f-bde188b319cb",
      imgsource:
        "https://en.wikipedia.org/wiki/Russo-Japanese_War#/media/File:RUSSOJAPANESEWARIMAGE.jpg",
      link: "https://en.wikipedia.org/wiki/Russo-Japanese_War",
    },
    {
      id: 301,
      date: "12 Jan 1904",
      time: 0,
      type: 0,
      title: "Herero and Nama Genocide",
      description:
        "The German Empire began a brutal campaign against the Herero and Nama peoples in German South West Africa (now Namibia), resulting in significant loss of life. 📷 Prisoners from the Herero and Nama tribes during the 1904-1908 war against Germany.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1904%2F01%2F12%2Fthumbs%2F301-Herero_and_Nama_prisoners_600x600.jpeg?alt=media&token=5ee02a4f-2e31-487d-a255-117318dbd046",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1904%2F01%2F12%2F301-Herero_and_Nama_prisoners.jpeg?alt=media&token=ce9f7658-ba3a-4092-bc02-0220eb4d2ed0",
      imgsource:
        "https://en.wikipedia.org/wiki/Herero_and_Namaqua_genocide#/media/File:Herero_and_Nama_prisoners.jpg",
      link: "https://en.wikipedia.org/wiki/Herero_and_Namaqua_genocide",
    },
    {
      id: 302,
      date: "17 Dec 1903",
      time: 0,
      type: 0,
      title: "Wright Brothers' First Powered Flight",
      description:
        "Orville and Wilbur Wright achieved the first controlled, sustained, powered flight in Kitty Hawk, North Carolina, marking the birth of aviation. 📷 Seconds into the first airplane flight, near Kitty Hawk, North Carolina, on December 17, 1903",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1903%2F12%2F17%2Fthumbs%2F302-1920px-Wright_First_Flight_1903Dec17_(full_restore_115)_600x600.jpeg?alt=media&token=62a9dc4b-6812-4076-a151-d832cbe368d1",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1903%2F12%2F17%2F302-1920px-Wright_First_Flight_1903Dec17_(full_restore_115).jpeg?alt=media&token=3c96a0ca-6532-4bf3-99d8-094bfde786ee",
      imgsource:
        "https://en.wikipedia.org/wiki/Wright_Flyer#/media/File:Wright_First_Flight_1903Dec17_(full_restore_115).jpg",
      link: "https://en.wikipedia.org/wiki/Wright_Flyer",
    },
    {
      id: 303,
      date: "1 Jan 1902",
      time: 0,
      type: 0,
      title: "First Rose Bowl Game",
      description:
        "The first Rose Bowl college football game was played in Pasadena, California, becoming an annual tradition.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1902%2F01%2F01%2Fthumbs%2F303-1902-Rose-Bowl-Game-History_600x600.jpeg?alt=media&token=c46b8886-93a8-470a-abe7-8c2e23f59bac",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1902%2F01%2F01%2F303-1902-Rose-Bowl-Game-History.jpeg?alt=media&token=0d392f6d-f26f-46a5-86e4-111275c63965",
      imgsource: "https://tournamentofroses.com/about/rose-bowl-game-history/",
      link: "https://en.wikipedia.org/wiki/1902_Rose_Bowl",
    },
    {
      id: 304,
      date: "23 Apr 1902",
      time: 0,
      type: 0,
      title: "Mount Pelée Eruption",
      description:
        "The volcanic eruption of Mount Pelée on the island of Martinique led to the destruction of the town of Saint-Pierre and the deaths of thousands.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1902%2F04%2F23%2Fthumbs%2F304-Pelee_1902_1_600x600.jpeg?alt=media&token=23448ef9-09b1-442e-a458-648686290ad6",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1902%2F04%2F23%2F304-Pelee_1902_1.jpeg?alt=media&token=1fcba60f-11ac-4cb2-8f96-00a16bf5bef1",
      imgsource:
        "https://en.wikipedia.org/wiki/1902_eruption_of_Mount_Pel%C3%A9e#/media/File:Pelee_1902_1.jpg",
      link: "https://en.wikipedia.org/wiki/1902_eruption_of_Mount_Pel%C3%A9e",
    },
    {
      id: 305,
      date: "31 May 1902",
      time: 0,
      type: 0,
      title: "Treaty of Vereeniging",
      description:
        "The Treaty of Vereeniging was signed, ending the Second Anglo-Boer War in South Africa and leading to the establishment of the Union of South Africa.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1902%2F05%2F31%2Fthumbs%2F305-Peace_Treaty_of_Vereeniging%2C_South_Africa%2C_31_May_1902.pdf_600x600.jpeg?alt=media&token=4256470f-7b30-4a1c-ac1a-195d4c2e7aae",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1902%2F05%2F31%2F305-Peace_Treaty_of_Vereeniging%2C_South_Africa%2C_31_May_1902.pdf.jpg?alt=media&token=ad0faf8b-5ed0-49a8-8031-4048ee89025d",
      imgsource:
        "https://en.wikipedia.org/wiki/File:Peace_Treaty_of_Vereeniging,_South_Africa,_31_May_1902.pdf",
      link: "https://en.wikipedia.org/wiki/Treaty_of_Vereeniging",
    },
    {
      id: 306,
      date: "22 Jan 1901",
      time: 0,
      type: 0,
      title: "Death of Queen Victoria",
      description:
        "Queen Victoria of the United Kingdom passed away after a long reign, marking the end of the Victorian era and the beginning of the Edwardian era. 📷 Queen Victoria's funeral procession",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1901%2F01%2F22%2Fthumbs%2F306-Queen_Victoria's_funeral_procession_600x600.jpeg?alt=media&token=03ddf539-0065-4cb3-ab39-9aad17f8401e",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1901%2F01%2F22%2F306-Queen_Victoria's_funeral_procession.jpeg?alt=media&token=04c90874-a7c6-47ed-93f2-f0a1e2b50ce2",
      imgsource:
        "https://en.wikipedia.org/wiki/Death_and_state_funeral_of_Queen_Victoria#/media/File:Queen_Victoria's_funeral_procession.jpg",
      link: "https://www.rmg.co.uk/stories/topics/death-queen-victoria",
    },
    {
      id: 307,
      date: "14 Sep 1901",
      time: 0,
      type: 0,
      title: "Theodore Roosevelt Becomes U.S. President",
      description:
        "Following the assassination of President William McKinley, Theodore Roosevelt became the 26th President of the United States.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1901%2F09%2F14%2Fthumbs%2F307-President_Roosevelt_-_Pach_Bros_(cropped)_600x600.jpeg?alt=media&token=f3d1ffa3-f6da-442b-89b7-2537f78fd011",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1901%2F09%2F14%2F307-President_Roosevelt_-_Pach_Bros_(cropped).jpeg?alt=media&token=f0209345-cad9-4837-9d17-9c9c0056bcea",
      imgsource:
        "https://en.wikipedia.org/wiki/Theodore_Roosevelt#/media/File:President_Roosevelt_-_Pach_Bros_(cropped).jpg",
      link: "https://en.wikipedia.org/wiki/Presidency_of_Theodore_Roosevelt",
    },
    {
      id: 308,
      date: "10 Dec 1901",
      time: 0,
      type: 0,
      title: "First Nobel Prizes Awarded",
      description:
        "The first Nobel Prizes were awarded in Stockholm, Sweden, in the fields of Physics, Chemistry, Medicine, Literature, and Peace.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1901%2F12%2F10%2Fthumbs%2F308-Nobel_Prize_600x600.jpeg?alt=media&token=46353954-37ea-4fea-ad09-92fcbc3ac204",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1901%2F12%2F10%2F308-Nobel_Prize.png?alt=media&token=65f5191f-44eb-4f52-af90-2248de407080",
      imgsource:
        "https://en.wikipedia.org/wiki/Nobel_Prize#/media/File:Nobel_Prize.png",
      link: "https://www.nobelprize.org/ceremonies/from-the-first-nobel-prize-award-ceremony-1901/",
    },
    {
      id: 309,
      date: "11 Nov 1900",
      time: 0,
      type: 0,
      title: "First Hershey's Chocolate Bar",
      description:
        "The Hershey Company introduced its first milk chocolate bar, laying the foundation for a popular chocolate brand.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1900%2F11%2F11%2Fthumbs%2F309-Hershey_chocolate_bar_600x600.jpeg?alt=media&token=316a4eac-552d-4722-8d43-0e8627eead82",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1900%2F11%2F11%2F309-Hershey_chocolate_bar.jpeg?alt=media&token=225f9491-8439-43df-a2e3-bc79d4d391ad",
      imgsource:
        "https://en.wikipedia.org/wiki/Hershey_bar#/media/File:Hershey_chocolate_bar.jpg",
      link: "https://www.thehersheycompany.com/en_us/home/about-us/the-company/history.html",
    },
    {
      id: 310,
      date: "8 Aug 1900",
      time: 0,
      type: 0,
      title: "First Davis Cup Tennis Match",
      description:
        "The first Davis Cup international tennis competition was held between the United States and Great Britain.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1900%2F08%2F08%2Fthumbs%2F310-OTD_0808jpg-1296x675_600x600.jpeg?alt=media&token=b34244f6-af29-469d-bde7-d33cf3374e9a",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1900%2F08%2F08%2F310-OTD_0808jpg-1296x675.jpeg?alt=media&token=d60ee48d-4e90-4a20-8ce5-2b84405cc693",
      imgsource:
        "https://www.tennismajors.com/others-news/davis-cup-news/august-8-1900-the-day-the-davis-cup-was-born-445940.html",
      link: "https://en.wikipedia.org/wiki/List_of_Davis_Cup_champions",
    },
    {
      id: 311,
      date: "27 Aug 1900",
      time: 0,
      type: 0,
      title: "Galveston Hurricane",
      description:
        "The Galveston Hurricane of 1900 struck the city of Galveston, Texas, resulting in one of the deadliest natural disasters in U.S. history, with thousands of casualties. 📷 Floating wreckage near Texas City – typical scene for miles along the water front",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1900%2F08%2F27%2Fthumbs%2F311-Floating_wreckage%2C_Galveston_hurricane%2C_1900_600x600.jpeg?alt=media&token=863461cc-8e91-4a73-bb08-5aa3dbae9625",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1900%2F08%2F27%2F311-Floating_wreckage%2C_Galveston_hurricane%2C_1900.jpeg?alt=media&token=78e5009b-f967-432c-bd1e-b2e45d20c69d",
      imgsource:
        "https://en.wikipedia.org/wiki/1900_Galveston_hurricane#/media/File:Floating_wreckage,_Galveston_hurricane,_1900.jpg",
      link: "https://en.wikipedia.org/wiki/1900_Galveston_hurricane",
    },
    {
      id: 312,
      date: "1 Aug 1932",
      time: 0,
      type: 0,
      title: "Thiepval Memorial to the Missing of the Somme",
      description:
        "The Thiepval Memorial to the Missing of the Somme is a war memorial in France, located near the village of Thiepval in the Somme department. It is one of the most prominent and significant memorials dedicated to the British and Commonwealth soldiers who died during the Battle of the Somme in World War I. 📷 A monument, designed by Lutyens, dedicated to the 70,000+ British and Commonwealth soldiers killed at the Somme whose bodies were never found or identified. The tall white bands at the bottom of the monument are covered in the names of those soldiers.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1932%2F08%2F01%2Fthumbs%2F312-Thiepval_Memorial_to_the_missing_600x600.jpeg?alt=media&token=db4ba65d-3681-4ff2-bb66-bab6e0fe4243",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1932%2F08%2F01%2F312-Thiepval_Memorial_to_the_missing.jpeg?alt=media&token=7a1af3bc-9896-4425-a873-46fd27755af1",
      imgsource:
        "https://en.wikipedia.org/wiki/Thiepval_Memorial#/media/File:Thiepval_Memorial_to_the_missing.jpg",
      link: "https://en.wikipedia.org/wiki/Thiepval_Memorial",
    },
    {
      id: 313,
      date: "10 Oct 1911",
      time: 0,
      type: 0,
      title: "Xinhai Revolution",
      description:
        "The Xinhai Revolution, also known as the 1911 Revolution, was a Chinese uprising against the Qing Dynasty, resulting in the establishment of the Republic of China and the end of imperial rule. 📷 Nanjing Road during Xinhai Revolution",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1911%2F10%2F10%2Fthumbs%2F313-Xinhai_Revolution_in_Shanghai_600x600.jpeg?alt=media&token=02bea1fd-9d78-4f8a-a677-03f4cfe9c4b1",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1911%2F10%2F10%2F313-Xinhai_Revolution_in_Shanghai.jpeg?alt=media&token=aca0b602-80d2-4e98-8eb7-31b5f3353eaf",
      imgsource:
        "https://en.wikipedia.org/wiki/1911_Revolution#/media/File:Xinhai_Revolution_in_Shanghai.jpg",
      link: "https://en.wikipedia.org/wiki/1911_Revolution",
    },
    {
      id: 314,
      date: "15 May 1940",
      time: 0,
      type: 0,
      title: "McDonald's ",
      description:
        "McDonald's was originally founded by Richard and Maurice McDonald in 1940. They opened a drive-in restaurant in San Bernardino, California, and developed the 'Speedee Service System', which emphasized fast food preparation and efficient service. Ray Kroc, who later became associated with McDonald's, joined the company in 1954 and eventually bought the rights to expand the brand, leading to the establishment of the McDonald's Corporation as it is known today. So, while Ray Kroc played a significant role in the expansion and success of McDonald's, the original founders were Richard and Maurice McDonald.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1940%2F05%2F15%2Fthumbs%2F314-McDonald's_Golden_Arches.svg_600x600.jpeg?alt=media&token=d6ae78b8-7cbb-4ff8-b1d4-ba9692cef6b0",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1940%2F05%2F15%2F314-McDonald's_Golden_Arches.svg.png?alt=media&token=e0c7e3a8-0b7e-4183-a1a1-87aba1075edf",
      imgsource:
        "https://en.wikipedia.org/wiki/McDonald%27s#/media/File:McDonald's_Golden_Arches.svg",
      link: "https://en.wikipedia.org/wiki/McDonald%27s",
    },
    {
      id: 315,
      date: "15 Nov 1969",
      time: 0,
      type: 0,
      title: "First Wendy's Opens",
      description:
        "Wendy's is an American fast-food restaurant chain founded by Dave Thomas in 1969. Known for its square-shaped burgers, it offers a menu of hamburgers, chicken sandwiches, and sides.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1969%2F11%2F15%2Fthumbs%2F315-Wendy's_full_logo_2012.svg_600x600.jpeg?alt=media&token=b174b7c3-90a8-4edb-b18b-a1dc434f4e94",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1969%2F11%2F15%2F315-Wendy's_full_logo_2012.svg.png?alt=media&token=ffea2e75-6301-4765-9b53-38f3afb9fe21",
      imgsource:
        "https://en.wikipedia.org/wiki/Wendy's#/media/File:Wendy's_full_logo_2012.svg",
      link: "https://www.wendys.com/wendys-story",
    },
    {
      id: 316,
      date: "23 May 1946",
      time: 0,
      type: 0,
      title: "Chick-Fil-A",
      description:
        "Chick-fil-A was founded on May 23, 1946, by S. Truett Cathy in Hapeville, Georgia, USA. It is known for its chicken-based menu items, including the famous Chick-fil-A sandwich. It was originally named, the Dwarf Grill. ",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1946%2F05%2F23%2Fthumbs%2F316-History_Dwarf-House_600x600.jpeg?alt=media&token=6fbf8ac4-032c-476b-a5b0-de0e700e7fcf",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1946%2F05%2F23%2F316-History_Dwarf-House.jpeg?alt=media&token=1a4d3a27-a7d2-4679-b6f0-9cc4503b1070",
      imgsource: "",
      link: "https://en.wikipedia.org/wiki/Chick-fil-A",
    },
    {
      id: 317,
      date: "11 Jan 1978",
      time: 0,
      type: 0,
      title: "Jollibee",
      description:
        "Jollibee is a popular Filipino fast-food restaurant chain founded in 1978 by Tony Tan Caktiong. It is known for its unique blend of Western-style fast food, such as burgers and fried chicken, with Filipino flavors and dishes. Jollibee's mascot is a bee named 'Jollibee,' and the chain has a wide range of menu items, including the iconic Jolly Spaghetti, Chickenjoy fried chicken, and the Yumburger. Jollibee is not only a well-loved fast-food brand in the Philippines but also has a presence in various countries around the world, making it one of the most successful Filipino restaurant chains globally.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1978%2F01%2F11%2Fthumbs%2F317-Jollibee_2011_logo.svg_600x600.jpeg?alt=media&token=71d11210-90d2-4bb7-b63b-5a229625580f",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1978%2F01%2F11%2F317-Jollibee_2011_logo.svg.png?alt=media&token=68d4c1bb-b9b6-4995-b10f-60dc1ff2a3d1",
      imgsource:
        "https://en.wikipedia.org/wiki/Jollibee#/media/File:Jollibee_2011_logo.svg",
      link: "https://jollibeegroup.com/history-milestones/",
    },
    {
      id: 318,
      date: "18 Mar 1985",
      time: 0,
      type: 0,
      title: "Chowking",
      description:
        "Chowking is a Filipino fast-food chain known for its fusion of Chinese and Filipino cuisine, serving dishes like noodles, rice bowls, dim sum, and various Asian-inspired snacks and desserts. Founded by Robert F. Kuan, the first Chowking store opened in Rotary Arcade, Makati.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1985%2F03%2F18%2Fthumbs%2F318-Chowking_600x600.jpeg?alt=media&token=a414c20f-f23f-405f-b1e1-de1692deaa37",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1985%2F03%2F18%2F318-Chowking.png?alt=media&token=0dddfe87-84f7-4803-8a19-4f6f549f0aed",
      imgsource:
        "https://en.wikipedia.org/wiki/Chowking#/media/File:Chowking_logo.svg",
      link: "https://www.chowking.com/about-us/",
    },
    {
      id: 319,
      date: "12 Jun 1972",
      time: 0,
      type: 0,
      title: "Popeyes",
      description:
        "Popeyes is an American fast-food restaurant chain famous for its Southern-style fried chicken and signature sides, offering a menu of flavorful and spicy fried chicken dishes. Founded by Alvin Charles Copeland in New Orleans, Louisiana. 📷 A Popeyes restaurant in West Miami, Florida, United States.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1972%2F06%2F12%2Fthumbs%2F319-Popeyes_Louisiana_Kitchen_(51195358373)_600x600.jpeg?alt=media&token=3e41af91-ae98-486c-be6b-7afe5f12c6a3",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1972%2F06%2F12%2F319-Popeyes_Louisiana_Kitchen_(51195358373).jpeg?alt=media&token=92c98cb8-fb93-4fa3-9c3d-3ff14561a30f",
      imgsource:
        "https://en.wikipedia.org/wiki/Popeyes#/media/File:Popeyes_Louisiana_Kitchen_(51195358373).jpg",
      link: "https://franchising.popeyes.com/",
    },
    {
      id: 320,
      date: "28 Aug 1965",
      time: 0,
      type: 0,
      title: "Subway",
      description:
        "Subway is an American fast-food restaurant chain specializing in submarine sandwiches, salads, and wraps. Customers can customize their sandwiches with a variety of bread, toppings, and condiments.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1965%2F08%2F28%2Fthumbs%2F320-subway-logo_600x600.jpeg?alt=media&token=aa4d73b8-2810-449a-be9b-de826e92b46a",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1965%2F08%2F28%2F320-subway-logo.avif?alt=media&token=be663faa-5975-41d4-baa9-4e69e3573836",
      imgsource:
        "https://www.subway.com/en-us/-/media/Project/Remote-Order/Images/Logo/subway-logo.png",
      link: "https://www.subway.com/en-us/aboutus/history",
    },
    {
      id: 321,
      date: "20 Mar 1930",
      time: 0,
      type: 0,
      title: "KFC ",
      description:
        "KFC, which stands for Kentucky Fried Chicken, was founded by Colonel Harland Sanders. The first Kentucky Fried Chicken restaurant was established in North Corbin, Kentucky, USA, in 1930. Colonel Sanders is the creator of KFC's famous secret recipe of 11 herbs and spices, which is used to season the chain's signature fried chicken. KFC has since become one of the world's largest and most recognizable fast-food chains, known for its fried chicken and various chicken-based menu items.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1930%2F03%2F20%2Fthumbs%2F321-KFC_600x600.jpeg?alt=media&token=e532e45e-8eb1-4657-a8d6-c6eabd9d4d0f",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/calengrid.appspot.com/o/images%2F1930%2F03%2F20%2F321-KFC.png?alt=media&token=5ef91790-a5a4-414d-9204-af5e9e24a4e8",
      imgsource:
        "https://en.wikipedia.org/wiki/KFC#/media/File:KFC_logo-image.svg",
      link: "https://global.kfc.com/our-heritage/",
    },
  ];
}
