export const SPINNER_TYPE = "grow"; //border

export function getReleaseVersion() {
  return "alpha";
}

export function isClickableKeywords() {
  return false;
}

export function getSuggestions() {
  return [
    "kids",
    "school",
    "youth",
    "community",
    "volunteer",
    "couple programme",
    "marriage",
    "vocation",
    "bookshop",
    "cafe",
    "mass timing",
    "charity",
    "free legal aid",
    "migrant support",
    "financial grant",
    "nursing home",
    "elderly",
    "funeral",
  ];
}
export function getWebVersion() {
  return "0.0.9";
}
