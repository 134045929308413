export function getStartOfDay(date = new Date()) {
  date.setHours(0, 0, 0);
  return date;
}

export function formatTime(timeCreated) {
  var diff = Date.now() - timeCreated;

  let periods = {
    month: 30 * 24 * 60 * 60 * 1000,
    week: 7 * 24 * 60 * 60 * 1000,
    day: 24 * 60 * 60 * 1000,
    hour: 60 * 60 * 1000,
    minute: 60 * 1000,
  };

  if (diff > periods.month) {
    // it was at least a month ago
    return Math.floor(diff / periods.month) + "mon ago";
  } else if (diff > periods.week) {
    return Math.floor(diff / periods.week) + "w ago";
  } else if (diff > periods.day) {
    return Math.floor(diff / periods.day) + "d ago";
  } else if (diff > periods.hour) {
    return Math.floor(diff / periods.hour) + "h ago";
  } else if (diff > periods.minute) {
    return Math.floor(diff / periods.minute) + "m ago";
  }
  return "Just now";
}

export function getLongMonths() {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months;
}

export function mapToDay(value) {
  if (value < 0 || value > 7) {
    return "Sunday";
  }
  const weekday = new Array(7);
  weekday[0] = "Sunday";
  weekday[1] = "Monday";
  weekday[2] = "Tuesday";
  weekday[3] = "Wednesday";
  weekday[4] = "Thursday";
  weekday[5] = "Friday";
  weekday[6] = "Saturday";
  return weekday[value];
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function dateDifference(start, end) {
  let newDate = end;
  if (end === "Present") {
    newDate = new Date();
  }
  const startDate = new Date(start);
  const endDate = new Date(newDate);
  let years = endDate.getFullYear() - startDate.getFullYear();
  let months = endDate.getMonth() - startDate.getMonth();
  let days = endDate.getDate() - startDate.getDate();

  if (days < 0) {
    months--;
    const lastDayOfMonth = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      0
    ).getDate();
    days = lastDayOfMonth - startDate.getDate() + endDate.getDate();
  }

  if (months < 0) {
    years--;
    months = 12 + months;
  }

  const result = [];
  if (years > 0) {
    result.push(`${years} yr${years > 1 ? "s" : ""}`);
  }
  if (months > 0) {
    result.push(`${months} mo${months > 1 ? "s" : ""}`);
  }
  // console.log(years, months);
  if (years === 0 && months === 0 && days > 0) {
    result.push(`${days} day${days !== 1 ? "s" : ""}`);
  }

  return result.join(" ");
}
