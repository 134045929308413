import React from "react";
import { useState, useRef, useEffect } from "react";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import CallMadeRoundedIcon from "@mui/icons-material/CallMadeRounded";
import TodayRoundedIcon from "@mui/icons-material/TodayRounded";
import CakeRoundedIcon from "@mui/icons-material/CakeRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import { Button, Image, Modal } from "react-bootstrap";
import ScheduleRoundedIcon from "@mui/icons-material/ScheduleRounded";
import Header from "../../../common/Header";
import { isValidEmail } from "../../../utils/validate";
import { addEmailToDB, checkEmailFromDB } from "../../../services/userlist";
import AddEmailSuccess from "../components/AddEmailSuccess";
import Terms from "../components/Terms";
import EmailInput from "../../../common/EmailInput";
import SubmitButton from "../../../common/SubmitButton";
import UpdateModal from "../../updatemodal/page/UpdateModal";
import { getContents } from "../../../utils/source";
import { dateDifference } from "../../../utils/date";
// Modal.setAppElement("#root");
function Landing() {
  const [imageIndex, setImageIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [entry, setEntry] = useState({});
  const [contents, setContents] = useState([]);
  const [emailErr, setEmailErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [showText, setShowText] = useState(false);
  const touchStartXRef = useRef(null);

  // Event handler for touch start
  const handleTouchStart = (event) => {
    touchStartXRef.current = event.touches[0].clientX;
  };

  // Event handler for touch end
  const handleTouchEnd = (event) => {
    if (touchStartXRef.current !== null) {
      const touchEndX = event.changedTouches[0].clientX;
      const deltaX = touchEndX - touchStartXRef.current;

      if (deltaX > 50) {
        // Right swipe detected
        console.log("Right swipe detected");
        moveNext();
        // Add your logic for the right swipe
      } else if (deltaX < -50) {
        // Left swipe detected
        console.log("Left swipe detected");
        // Add your logic for the left swipe
        movePrev();
      }

      // Reset the touchStartXRef
      touchStartXRef.current = null;
    }
  };
  // Add event listener when the component mounts
  useEffect(() => {
    window.addEventListener("keydown", handleArrowKeys);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleArrowKeys);
    };
  }, [showModal, imageIndex]);

  useEffect(() => {
    // if (!user || !isSuperAdminRole(user)) {
    //   console.error("Not Authorised!");
    //   history.push("/home");
    // }
    const data = getContents();
    // console.log(data);
    //sort
    data.sort((a, b) => {
      const obj1 = new Date(a.date).getTime();
      const obj2 = new Date(b.date).getTime();
      if (obj1 < obj2) {
        return 1;
      } else if (obj1 > obj2) {
        return -1;
      } else {
        return 0;
      }
    });
    //exclude no photo
    const contents = data.filter((i) => i.photo.trim() !== "");
    setContents(contents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const moveNext = () => {
    let count = imageIndex - 1;
    count = count < 0 ? 0 : count;
    console.log("Left arrow key pressed:", count);
    setImageIndex(count);
    setShowText(false);
    setEntry(contents[count]);
  };
  const movePrev = () => {
    let count = imageIndex + 1;
    count = count > contents.length - 1 ? contents.length - 1 : count;
    console.log("Right arrow key pressed:", count);
    setShowText(false);
    setImageIndex(count);
    setEntry(contents[count]);
  };
  // Event handler for left and right arrow keys
  const handleArrowKeys = (event) => {
    if (event.key === "ArrowLeft") {
      // Handle left arrow key press
      if (showModal) {
        // let count = imageIndex - 1;
        // count = count < 0 ? 0 : count;
        // console.log("Left arrow key pressed:", count);
        // setImageIndex(count);
        // setEntry(contents[count]);
        moveNext();
      }
      // Add your logic here for the left arrow key
    } else if (event.key === "ArrowRight") {
      if (showModal) {
        // let count = imageIndex + 1;
        // count = count > contents.length - 1 ? contents.length - 1 : count;
        // console.log("Right arrow key pressed:", count);
        // setImageIndex(count);
        // setEntry(contents[count]);
        movePrev();
      }
    }
  };

  const displayImages = (list) => {
    let style =
      "col-4 col-sm-4 col-md-2 col-lg-2 text-center smallpadding  zoom pointer";
    // if (list.length === 1) {
    //   style = "col-lg-8 text-center py-3 mx-auto zoom pointer";
    // } else {
    //   if (list.length === 2) {
    //     style = "col-6 text-center py-3 zoom pointer";
    //   }
    // }
    // console.log({ list });
    return list.map((item, index) => (
      <div key={index} className={`${style}`}>
        {/* <Link to={`/v/${item.id}`}> */}
        {/* <div className="d-flex justify-content-start">
          <span
            className="shadow stickbadge badge rounded-pill text-bg-light text-dark"
            style={{ fontSize: "9px" }}>
            {item.date}
          </span>
        </div> */}
        {/* <Zoom> */}
        {/* <OverlayTrigger
          trigger="hover"
          overlay={<Tooltip>{item.title}</Tooltip>}> */}
        <div
          className="square-image"
          onClick={() => {
            setShowModal(true);
            setShowText(false);
            setEntry(item);
            setImageIndex(index);
          }}>
          <img
            alt={`${new Date(item.date).toLocaleString()}`}
            // rounded
            className="responsive image"
            src={item?.thumbnail?.trim() !== "" ? item?.thumbnail : item?.photo}
          />
          {/* <div className="shadow pill-label" style={{ fontSize: "9px" }}>
            {item.date}
          </div> */}
          <div className="overlaylabel">
            <div className="textlabel">{item.date}</div>
          </div>
        </div>
        {/* </OverlayTrigger> */}
        {/* </Zoom> */}
        {/* <p className="mb-0 text-left font-weight-bold twolineentry">
          {item.caption}
        </p> */}

        {/* {item.price && <p className="text-left text-danger">{item.price}</p>} */}
        {/* </Link> */}
      </div>
    ));
  };
  // const handleSubmit = () => {
  //   const valid = isValidEmail(email.trim().toLowerCase());

  //   if (!valid) {
  //     setEmailErr("Please provide a valid email");
  //     setLoading(false);
  //   } else {
  //     setLoading(true);
  //     setEmailErr("");
  //     //process here
  //     checkEmailFromDB(email.trim().toLowerCase()).then(async (found) => {
  //       if (!found) {
  //         //add
  //         await addEmailToDB(email.trim().toLowerCase());
  //       }
  //       setLoading(false);
  //       setShowSuccess(true);
  //     });
  //   }
  // };

  return (
    <div>
      <Header count={contents.length} />
      <UpdateModal />
      <div className="row justify-content-center mx-0">
        <div className="col-lg-10 px-1">
          <main className="container-fluid text-center pt-0">
            <div className="row ">{displayImages(contents)}</div>
            {entry?.title && (
              <Modal
                // size={size}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                show={showModal}
                onHide={() => {
                  setShowModal(false);
                }}
                animation={true}
                centered
                // backdrop="static"
                keyboard={true}>
                <h4 className="mt-3 ms-3 me-2 mb-0">{entry.title}</h4>
                <p className="defaultstandardfontsize ms-3 mb-0 d-flex align-items-center">
                  {entry.type === 1 ? (
                    <CakeRoundedIcon
                      className="text-muted me-1"
                      style={{ fontSize: "14px" }}
                    />
                  ) : (
                    <ScheduleRoundedIcon
                      className="text-muted me-1"
                      style={{ fontSize: "14px" }}
                    />
                  )}
                  {entry.date} &bull;{" "}
                  {dateDifference(new Date(entry.date), new Date())} ago
                </p>

                <Modal.Body className="pt-2 text-start">
                  <Image
                    style={{ border: "1px solid #e0e0e0" }}
                    alt={`${new Date(entry.date).toLocaleString()}`}
                    rounded
                    className="shadow responsive mb-1"
                    src={
                      entry?.thumbnail?.trim() !== ""
                        ? entry?.thumbnail
                        : entry?.photo
                    }
                  />

                  {entry?.imgsource?.trim() !== "" && (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="notextdeco"
                      href={entry.imgsource}>
                      <p
                        className="pointer text-dark mt-2 mb-0 d-flex align-items-center text-truncate"
                        style={{ fontSize: "10px" }}>
                        <PhotoCameraOutlinedIcon
                          className="text-muted me-1"
                          style={{ fontSize: "16px" }}
                        />
                        {entry?.imgsource}
                      </p>
                    </a>
                  )}
                  <div
                    onClick={() => setShowText(true)}
                    className={`defaultstandardfontsize mt-2 mb-0 text-start ${
                      showText === false ? "pointer clamp2" : ""
                    }`}>
                    {/* display as is */}
                    {entry.description}
                  </div>

                  <div className="d-flex justify-content-between my-2">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="notextdeco"
                      href={entry.link}>
                      <div className="">
                        <span
                          className="ps-1 mx-0 pt-1 stickbadge badge rounded-pill text-bg-warning text-dark"
                          style={{ fontSize: "10px" }}>
                          <CallMadeRoundedIcon style={{ fontSize: "14px" }} />
                          {entry?.link?.length > 50
                            ? entry?.link?.substring(0, 49) + "~"
                            : entry?.link}
                        </span>
                      </div>
                    </a>
                    {/* </div> */}
                    {/* <div className="text-end mt-4 me-0"> */}
                    <Button
                      size={"sm"}
                      variant="link"
                      className="text-muted pe-0"
                      onClick={() => setShowModal(false)}>
                      <CloseRoundedIcon />
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
            )}
          </main>
        </div>
      </div>
    </div>
  );
}

export default Landing;
